import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';

import Typography from '../../components/overrides/Typography';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import EditSaveButton from '../../components/core/EditSaveButton';
import TextInput from '../../components/core/TextInput';
import PhoneInput from '../../components/core/PhoneInput';
import { actionSetAnimationSlideRight } from '../../store/Animations/AnimationsAction';
import { useViewport } from '../../utils/useViewport';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import {
  RESERVE_EDIT_CONTACT_DETAILS,
  RESERVE_EDIT_CONTACT_DETAILS_NOTICE,
  ONBOARDING_CONFIRM_INFO_INPUT_LABEL,
  CONTACT_NUMBER_EMPTY_ERROR,
  INVALID_PHONE_INPUT_ERROR,
} from '../../assets/copy';
import AppTheme from '../../theme';
import { isValidNumber } from 'libphonenumber-js';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(5, 0, 2, 0),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      maxWidth: '50%',
    },
  },
  group: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(4),
    },
  },
  error: {
    marginTop: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  name: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const widthBreakpoint = 960;

const EditContact = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { draftReservation } = useSelector((state) => state.reservations);
  const { width } = useViewport();
  const [formData, setFormData] = useState(draftReservation);
  const [isValueUpdated, setIsValueUpdated] = useState(false);
  const [inputBorder, setInputBorder] = useState(
    `2px solid ${AppTheme.palette.primary.main}`
  );

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phoneNumber,
    },
  });

  useEffect(() => {
    errors?.phone
      ? setInputBorder(`2px solid ${AppTheme.palette.red[50]}`)
      : setInputBorder(`2px solid ${AppTheme.palette.primary.main}`);
  }, [errors.phone]);

  const handleOnChange = (e) => {
    setFormData((prevValue) => ({ ...prevValue, phoneNumber: e.target.value }));
    setIsValueUpdated(true);
  };

  const handleBack = () => {
    dispatch(actionSetAnimationSlideRight(true));
    history.goBack();
  };

  const handleSave = () => {
    dispatch(actionSetDraftReservation(formData));
    dispatch(actionSetAnimationSlideRight(true));
    history.goBack();
  };

  const note =
    'Note: To update your email, please go to your Capital One Profile in the Capital One mobile app or through capitalone.com';

  return (
    <BackButtonLayout
      back={handleBack}
      buttonRole="link"
      title={RESERVE_EDIT_CONTACT_DETAILS}>
      <Grid item>
        <Typography variant="medium1Normal">
          {RESERVE_EDIT_CONTACT_DETAILS_NOTICE}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={width > widthBreakpoint ? 'medium2Normal' : 'medium1Normal'}
          className={classes.name}>
          {`${formData.firstName} ${formData.lastName}`}
        </Typography>
        <Typography
          variant={width > widthBreakpoint ? 'medium2Normal' : 'medium1Normal'}>
          {formData.emailAddress}
        </Typography>
        <form noValidate className={classes.form}>
          <FormGroup className={classes.group}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={ONBOARDING_CONFIRM_INFO_INPUT_LABEL}
                  name="phone"
                  autoComplete="off"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    inputMode: 'tel',
                    style: { border: inputBorder },
                  }}
                  inputComponent={PhoneInput}
                  {...field}
                  onChange={(e) => {
                    field?.onChange(e);
                    handleOnChange(e);
                  }}
                />
              )}
              rules={{
                required: true,
                validate: (values) => isValidNumber(values, 'US'),
              }}
            />
            {errors?.phone?.type === 'required' && (
              <Typography
                variant="error"
                color="error"
                className={classes.error}>
                {CONTACT_NUMBER_EMPTY_ERROR}
              </Typography>
            )}
            {errors?.phone?.type === 'validate' && (
              <Typography
                variant="error"
                className={classes.error}
                color="error">
                {INVALID_PHONE_INPUT_ERROR}
              </Typography>
            )}
          </FormGroup>
        </form>
        <Box className={classes.buttonContainer}>
          <EditSaveButton
            onSave={handleSave}
            role="link"
            disabled={errors?.phone || !isValueUpdated}
          />
        </Box>
        <Typography variant="small2Normal">{note}</Typography>
      </Grid>
    </BackButtonLayout>
  );
};

export default EditContact;
