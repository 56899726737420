import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ChefHeader from '../../components/premier/ChefHeader';

const useStyles = makeStyles((theme) => ({
  mobileContainer: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0, 3),
  },
  mobileHeader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0, 3),
  },
  mobileImg: {
    backgroundImage: ({ item }) => {
      return item?.images?.[0] && `url(${item.images[0]})`;
    },
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'inline-block',
    width: '100%',
    height: '0',
    paddingBottom: 'calc(100%)',
  },
}));

const MobileChefHeader = ({ item }) => {
  const classes = useStyles({ item });

  return (
    <>
      <Box className={classes.mobileContainer}>
        <div className={classes.mobileImg} />
      </Box>
      <Box className={classes.mobileHeader}>
        <ChefHeader
          subtitle={item.subtitle}
          chefName={item.chefName}
          description={item.description}
        />
      </Box>
    </>
  );
};

export default MobileChefHeader;
