import Typography from '../overrides/Typography';
import { Box, FormControl, Select } from '@material-ui/core';
import DropDownSvg from '../../assets/icons/dropdown.svg';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  contentColumn: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5, 0),
      flex: '1 1 100%',
    },
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
      padding: '11px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 48,
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  image: {
    marginRight: theme.spacing(2),
    top: `calc(40%)`,
  },
  hiddenStyles: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}));

const FABMarketSelector = ({ markets, value, handleSelectMarket }) => {
  const classes = useStyles();
  const getIconComponent = useCallback((selectProps) => {
    return (
      <img
        src={DropDownSvg}
        alt=""
        className={clsx(classes.image, selectProps.className)}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.contentColumn}>
      <Typography variant="small2Normal" gutterBottom id="cityLabel">
        City
      </Typography>
      <label
        htmlFor="marketSelect"
        aria-hidden="true"
        className={classes.hiddenStyles}
        id="marketLabel">
        {'Market'}
      </label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          id="marketSelect"
          native
          value={value}
          onChange={handleSelectMarket}
          inputProps={{ 'aria-labelledby': 'cityLabel' }}
          IconComponent={getIconComponent}>
          {markets.list.map((item) => (
            <option value={item.id} key={`fab-market-${item.id}`}>
              {item.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FABMarketSelector;
