import { put, takeLatest } from 'redux-saga/effects';
import { listAccolades } from '../../graphql/queries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './AccoladesAction';
import * as TYPES from './AccoladesTypes';

export function* accoladeListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listAccolades,
      variables: { filter },
    });
    yield put(
      ACTIONS.actionAccoladeListSuccess(
        result.data.listAccolades.items?.sort(
          (accolade1, accolade2) => accolade1.sortOrder - accolade2.sortOrder
        )
      )
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionAccoladeListFail(e));
  }
}

export default function* accoladesSaga() {
  yield takeLatest(TYPES.ACCOLADE_LIST_REQUEST, accoladeListRequestHandler);
}
