import React from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, Container } from '@material-ui/core';
import Typography from '../../overrides/Typography';
import clsx from 'clsx';
import {
  UiCloseLined,
  UiChevronLeftLined,
} from '@cof/omni-gravity-icons-react';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      padding: theme.spacing(2, 0, 0),
      width: ({ width }) => width ?? '480px',
      maxWidth: '480px',
    },
  },
  headerNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    textAlign: 'center',
    gap: theme.spacing(1),
  },
  title: {
    width: '100%',
    fontSize: '24px',
    lineHeight: '32px',
    margin: ({ subtitle }) =>
      subtitle ? theme.spacing(1, 0, 0.5) : theme.spacing(1, 0),
  },
  subtitle: {
    color: theme.palette.spoon.black[90],
  },
  pointer: {
    cursor: 'pointer',
  },
  invisible: {
    visibility: 'hidden',
  },
  content: {
    overflowY: 'auto',
  },
  header: ({ subtitle }) => {
    return (
      subtitle && {
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.spoon.gray[10]}`,
      }
    );
  },
}));

export const Dialog = ({
  open,
  onBackButtonClick,
  onClose,
  title,
  subtitle,
  width,
  disableBackdropClick,
  showHeader = true,
  children,
  ...rest
}) => {
  const classes = useStyles({ width, subtitle });
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      disableBackdropClick={disableBackdropClick}
      aria-labelledby="dialog-title"
      {...rest}>
      {showHeader && (
        <Container className={classes.header}>
          <div className={classes.headerNav}>
            <UiChevronLeftLined
              className={clsx({
                [classes.pointer]: true,
                [classes.invisible]: !onBackButtonClick,
              })}
              onClick={onBackButtonClick}
              aria-label="back"
              role="button"
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onBackButtonClick();
                }
              }}
            />
            <UiCloseLined
              className={classes.pointer}
              onClick={onClose}
              aria-label="close"
              role="button"
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onClose();
                  e.preventDefault();
                }
              }}
            />
          </div>
          <>
            <Typography
              variant="medium3Semibold"
              className={classes.title}
              component="h2"
              id="dialog-title">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="medium1Normal" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </>
        </Container>
      )}
      <div className={classes.content}>{children}</div>
    </MuiDialog>
  );
};

export default Dialog;

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showHeader: PropTypes.bool,
  width: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
};
