import Typography from '../../components/overrides/Typography';
import { HOME_HERO_DESCRIPTION_TEXT, HOME_HERO_TITLE } from '../../assets/copy';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useViewport } from '../../utils/useViewport';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginTop: 0,
    },
  },
  description: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginBottom: 0,
      maxWidth: 544,
    },
  },
  headerButton: {
    [theme.breakpoints.up('xl')]: {
      padding: '9px 29px',
      maxWidth: 256,
    },
  },
}));

const widthBreakpoint = 960;

const DiscoverSection = () => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <div>
      <Typography
        variant={width > widthBreakpoint ? 'large1Light' : 'medium3Light'}
        color="secondary"
        component="h1"
        className={classes.title}>
        {HOME_HERO_TITLE}
      </Typography>
      <Typography
        variant={width > widthBreakpoint ? 'medium1Normal' : 'small2Normal'}
        color="secondary"
        className={classes.description}>
        {HOME_HERO_DESCRIPTION_TEXT}
      </Typography>
    </div>
  );
};

export default DiscoverSection;
