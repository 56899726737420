import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { trackPageView } from '../../utils/useOneTag';
import { useFeatureFlags } from '../../utils/useFeatureFlags';

const TrackedRoute = (props) => {
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();

  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);

  useEffect(() => {
    if (refreshLandingPageFlagIsEnabled && window.location.pathname === '/') {
      trackPageView('/new-home-page');
    }
  }, [refreshLandingPageFlagIsEnabled]);
  return <Route {...props} />;
};

export default TrackedRoute;
