import * as types from './FeatureToggleTypes';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';

const initialState = {
  features: new Map(),
  isRequestPending: false,
  hasRequestSucceeded: false,
};

export const featureToggleReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case types.GET_FEATURE_TOGGLES_REQUEST:
      return {
        ...state,
        isRequestPending: true,
        hasRequestSucceeded: false,
      };
    case types.GET_FEATURE_TOGGLES_SUCCESS:
      return {
        ...state,
        features: payload,
        isRequestPending: false,
        hasRequestSucceeded: true,
      };
    case types.GET_FEATURE_TOGGLES_FAILURE:
      return {
        ...state,
        isRequestPending: false,
        hasRequestSucceeded: false,
      };
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default featureToggleReducer;
