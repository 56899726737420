import { put, takeLatest, call } from 'redux-saga/effects';
import * as ACTIONS from './AnimationsAction';
import * as TYPES from './AnimationsTypes';

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function* resetAnimations() {
  yield call(delay, 500);
  yield put(ACTIONS.actionResetAnimations());
}

export default function* animationsSaga() {
  yield takeLatest(
    [
      TYPES.SET_ANIMATION_PUSH_UP,
      TYPES.SET_ANIMATION_SLIDE_LEFT,
      TYPES.SET_ANIMATION_SLIDE_RIGHT,
    ],
    resetAnimations
  );
}
