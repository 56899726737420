import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { EVENT_CTA_TEXT, SOLD_OUT_BADGE_TEXT } from '../assets/copy';
import VenueBadge from '../components/core/EventBadge';
import { BADGE_TYPES } from './constants/BadgeTypes';
import DirectionalButton from '../components/buttons/DirectionalButton';
import { VENUE_TYPES } from './constants/FilterTypes';
import { isPastEvent, isSoldOut } from './eventHelpers';
import { useSelector } from 'react-redux';
import DateTime from './dateTimeHelpers';

const useEventFlag = (venue, date = null) => {
  const venueHasEvent = venue?.venueType === VENUE_TYPES.EVENT;
  const venueHasBadge = venue?.badgeText?.length > 0;
  const { submittedDate } = useSelector((state) => state?.availability);
  const currentDate = new DateTime();
  const selectedDate = new DateTime(submittedDate);
  const salesStartDate = new DateTime(venue?.salesStartDate);
  const salesEndDate = new DateTime(venue?.salesEndDate);
  const isComingSoon = currentDate < salesStartDate;

  const isPast = isPastEvent(venue);
  const isEventSoldOut = isSoldOut(venue);
  const isEventOnSale =
    salesStartDate <= currentDate && currentDate <= salesEndDate;

  const isEventNoLongerAvailable =
    venue?.eventSalesClosed || isEventSoldOut || isComingSoon || isPast;

  const isSelectedDateValid = venue?.eventDates?.some(
    (item) => new DateTime(item) === selectedDate
  );

  const history = useHistory();

  const redirectToVenuePage = useCallback(
    () => history.push(`/venue/${venue.id}`),
    [history, venue]
  );

  const renderVenueBadge = useCallback(() => {
    if (venueHasEvent && venue?.isSoldOut) {
      return (
        <VenueBadge text={SOLD_OUT_BADGE_TEXT} type={BADGE_TYPES.SOLD_OUT} />
      );
    }
    if (venueHasBadge) {
      return <VenueBadge text={venue?.badgeText} type={BADGE_TYPES.DEFAULT} />;
    }
    return null;
  }, [venueHasEvent, venueHasBadge, venue?.badgeText, venue?.isSoldOut]);

  const renderGetTicketLink = useCallback(
    (isGetTicketLinkShown = false, linkText = EVENT_CTA_TEXT) => {
      if (venueHasEvent && isGetTicketLinkShown) {
        return (
          <DirectionalButton
            onClickHandler={redirectToVenuePage}
            text={linkText}
            aria-label={linkText}
          />
        );
      }
      return null;
    },
    [venueHasEvent, redirectToVenuePage]
  );

  return {
    venueHasEvent,
    venueHasBadge,
    isEventNoLongerAvailable,
    isEventSoldOut,
    renderVenueBadge,
    renderGetTicketLink,
    selectedDate,
    isComingSoon,
    isSelectedDateValid,
    isPast,
    isEventOnSale,
  };
};

export default useEventFlag;
