import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useViewport } from '../../utils/useViewport';
import Transition from '../animation/Transition';

const useStyles = makeStyles((theme) => ({
  bgImg: {
    clip: 'rect(auto, auto, 400px, auto)',
    height: '40%',
    position: 'fixed',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      clip: 'auto',
      height: '100%',
      right: 0,
      width: '50%',
    },
  },
  fadeAnimation: {
    '&-enter': {
      opacity: 0,
      '&-active': {
        opacity: 1,
        transition: 'all 2s',
      },
    },
    '&-exit': {
      opacity: 1,
      '&-active': {
        opacity: 0,
        transition: 'all 2s',
      },
    },
  },
}));

const widthBreakpoint = 960;

const StepBackground = ({
  active,
  backgroundStyles,
  slideAnimation,
  ...transitionProps
}) => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <Transition
      timeout={width <= widthBreakpoint ? 2000 : 1000}
      delay={width <= widthBreakpoint ? 1200 : 1500}
      classNames={
        width <= widthBreakpoint ? classes.fadeAnimation : slideAnimation
      }
      active={active}
      {...transitionProps}>
      <Box className={clsx(classes.bgImg, backgroundStyles)} />
    </Transition>
  );
};

export default StepBackground;
