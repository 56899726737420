import * as types from './AtmospheresTypes';

//atmosphere list
export const actionAtmosphereListRequest = () => ({
  type: types.ATMOSPHERE_LIST_REQUEST,
});

export const actionAtmosphereListSuccess = (payload) => ({
  type: types.ATMOSPHERE_LIST_SUCCESS,
  payload,
});

export const actionAtmosphereListFail = (payload) => ({
  type: types.ATMOSPHERE_LIST_FAIL,
  payload,
});
