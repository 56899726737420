import React, { useMemo } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  RESERVATIONS_PAST_TAB_LABEL,
  RESERVATIONS_UPCOMING_TAB_LABEL,
} from '../../assets/copy';
import Typography from '../../components/overrides/Typography';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import { RESERVATIONS_TYPE } from './constants';
import { mapToReservationsLink, useCurrentTab } from './helpers';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: '#FFF',
    textAlign: 'center',
    boxShadow: 'none',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 5),
    },
  },
  tabText: {
    textTransform: 'none',
  },
  tab: {
    ...focusVisible(),
    margin: '4px',
  },
}));

const CustomTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    height: 4,
  },
}))(Tabs);

const ReservationsTabs = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const currentTab = useCurrentTab();
  const toUpcomingTab = useMemo(
    () => mapToReservationsLink(RESERVATIONS_TYPE.UPCOMING),
    []
  );
  const toPastTab = useMemo(
    () => mapToReservationsLink(RESERVATIONS_TYPE.PAST),
    []
  );
  return (
    <AppBar className={classes.tabs} position="static">
      <CustomTabs
        value={currentTab}
        variant={width > WIDTH_BREAKPOINT ? 'standard' : 'fullWidth'}
        tabIndex={0}
        >
        <Tab
          component={Link}
          className={classes.tab}
          label={
            <Typography
              className={classes.tabText}
              id={`reservations-tab-${RESERVATIONS_TYPE.UPCOMING}`}
              variant="medium2Semibold">
              {RESERVATIONS_UPCOMING_TAB_LABEL}
            </Typography>
          }
          to={toUpcomingTab}
          value={RESERVATIONS_TYPE.UPCOMING}
          onKeyPress={toUpcomingTab}
        />
        <Tab
          component={Link}
          className={classes.tab}
          label={
            <Typography
              className={classes.tabText}
              id={`reservations-tab-${RESERVATIONS_TYPE.PAST}`}
              variant="medium2Semibold">
              {RESERVATIONS_PAST_TAB_LABEL}
            </Typography>
          }
          to={toPastTab}
          value={RESERVATIONS_TYPE.PAST}
          onKeyPress={toPastTab}
        />
      </CustomTabs>
    </AppBar>
  );
};

export default ReservationsTabs;
