import React, { useEffect } from 'react';
import { Box, Card, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import Typography from '../../overrides/Typography';
import { cardHoverStyles } from '../../../utils/constants/hoverStyles';
import TextIcon from '../../core/TextIcon';
import { joinText } from '../../../utils/eventHelpers';
import SignatureCollectionBadge from '../../core/Badge/SignatureCollectionBadge';
import NewRestaurantBadge from '../../core/Badge/NewRestaurantBadge';
import {
  trackClickWithDescription,
  trackPageImpression,
} from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: theme.spacing(3),
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(9, 5, 3),
    },
  },
  cardRoot: {
    flexGrow: 1,
  },
  media: {
    maxWidth: '100%',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(2),
      width: 31,
    },
  },
  badges: {
    position: 'absolute',
    top: 16,
    left: 16,
    zIndex: 1,
    display: 'flex',
    flexFlow: 'row wrap',
  },
  badgeContainer: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    position: 'relative',
    height: '40px',
  },
  textContainerWithAccolades: {
    paddingTop: theme.spacing(1),
  },
  cardUpperText: {
    paddingLeft: theme.spacing(2),
    paddingBottom: 5,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  cardLowerText: {
    paddingLeft: theme.spacing(2),
    color: '#757575',
  },
  awards: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  accoladesText: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  title: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.black,
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    background: theme.palette.gray[30],
    marginTop: theme.spacing(5),
  },
  contentWrapper: {
    position: 'relative',
    zIndex: '100',
    maxWidth: '10em',
    height: '1.5em',
    margin: '5px',
    borderRadius: '14px',
    float: 'left',
    top: '-200px',
    border: '1px solid',
    [theme.breakpoints.up('xl')]: {
      position: 'relative',
      zIndex: '100',
      maxWidth: '10em',
      height: '1.5em',
      margin: '5px',
      borderRadius: '14px',
      float: 'left',
      top: '-200px',
      border: '1px solid',
    },
  },
  slideImage: {
    width: '100%',
    height: '202px',
    objectFit: 'cover',
    [theme.breakpoints.up('xl')]: {
      height: '279px',
    },
  },
  slideImageWithAccolades: {
    height: '182px',
    [theme.breakpoints.up('xl')]: {
      height: '256px',
    },
  },
  card: ({ onCardClick }) => ({
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.gray[15],
    borderRadius: theme.spacing(1),
    width: '100%',
    height: '300px',
    ...(onCardClick && cardHoverStyles),
    [theme.breakpoints.down('lg')]: {
      borderRadius: theme.spacing(0.5),
      width: '100%',
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '624px',
      height: '377px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '300px',
    },
  }),
  accoladesIcon: {
    filter: 'brightness(0) grayscale(1)',
  },
}));

export const InventoryCard = ({ venue, subheading }) => {
  const awards =
    venue.awards.items.length > 0
      ? venue.awards.items.map((item) => item.award)
      : null;
  const hasAccolades = awards && awards.length > 0;

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (venue.name)
      trackPageImpression(
        'curated-inventory',
        'curated-inventory-venue-card',
        venue.name
      );
  }, [venue?.name]);

  const handleVenueSelect = (item) => {
    trackClickWithDescription(
      'curated-inventory-venue-card',
      'Card',
      `venueName: ${item.name}`
    );
    history.push(`/venue/${item.id}`);
  };

  const handleKeyDown = (event, item) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleVenueSelect(item);
    }
  };

  const renderBadges = () => {
    return (
      <Box className={classes.badges}>
        {venue.isSignatureCollection && (
          <Box className={classes.badgeContainer}>
            <SignatureCollectionBadge />
          </Box>
        )}
        {venue.labels.includes('New') && (
          <Box className={classes.badgeContainer}>
            <NewRestaurantBadge />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Card
      className={clsx(classes.card)}
      role={'link'}
      aria-label={`Go to ${venue.name} venue details page.`}
      onClick={() => handleVenueSelect(venue)}
      tabIndex={0}
      onKeyDown={(event) => handleKeyDown(event, venue)}>
      <div
        style={{
          position: 'relative',
        }}>
        {renderBadges()}
        {venue && (
          <img
            src={venue.imagesFull[0]}
            className={clsx(classes.slideImage, {
              [classes.slideImageWithAccolades]: hasAccolades,
            })}
            alt=""
          />
        )}
      </div>
      {hasAccolades && (
        <Box mt={2} className={classes.awards}>
          <TextIcon
            key={awards[0].id}
            src={awards[0].icon}
            variant={'small4Semibold'}
            text={`${joinText(
              [awards[0].secondaryText, awards[0].primaryText],
              ' - '
            )}`}
            iconStyleOverride={classes.accoladesIcon}
            textStyleOverride={classes.accoladesText}
          />
        </Box>
      )}
      <div
        className={clsx(classes.textContainer, {
          [classes.textContainerWithAccolades]: hasAccolades,
        })}>
        <Typography
          variant={'medium2Light'}
          className={classes.cardUpperText}
          component="h2">
          {venue.name}
        </Typography>

        <Typography
          variant={'small2Normal'}
          className={classes.cardLowerText}
          component="h2">
          {subheading}
        </Typography>
      </div>
    </Card>
  );
};
