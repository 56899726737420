import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import * as TYPES from './ConfigTypes';

const initialState = {
  isLoaded: false,
};

export default function configReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.GET_CONFIG_SUCCESS:
    case TYPES.SET_CONFIG:
      if (!payload || Object.keys(payload).length === 0) {
        return state;
      }
      return {
        ...payload,
        isLoaded: true,
      };
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
