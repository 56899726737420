import { put, takeLatest } from 'redux-saga/effects';
import { listTags } from '../../graphql/queries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './TagsAction';
import * as TYPES from './TagsTypes';

export function* tagListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listTags,
      variables: { filter },
    });
    yield put(ACTIONS.actionTagListSuccess(result.data.listTags.items));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionTagListFail(e));
  }
}

export default function* tagsSaga() {
  yield takeLatest(TYPES.TAG_LIST_REQUEST, tagListRequestHandler);
}
