import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BottomDrawer } from '../../core/BottomDrawer';
import { Dialog } from '../../core/Dialog';
import { ChooseDate } from './ChooseDate';
import { EditPaymentMethod } from './EditPaymentMethod';
import { EditDietPreferences } from './EditDietPrefererences';
import { ReservationConfirmed } from './ReservationConfirmed';
import {
  CHOOSE_DATE_AND_TIME_TEXT,
  COMPLETE_YOUR_RESERVATION_TEXT,
  EDIT_PAYMENT_METHOD_TEXT,
  RESERVATION_CONFIRMED_SUCCESS,
  EDIT_RESERVATION_DIETARY_PREFERENCES_TEXT,
  DINING_OPTION_TITLE,
} from '../../../assets/copy';
import {
  actionClearDateRangeAvailabilities,
  actionClearListAvailabilities,
  actionGetDateRangeAvailabilityRequest,
} from '../../../store/Availability/AvailabilityAction';
import { generateGetDateRangeAvailabilityInput } from '../../../utils/generateAvailabilityInput';
import {
  getDaysInMonthFromDate,
  getRemainingDaysInMonth,
} from '../../../utils/dayMonthHelper';
import { useDispatch, useSelector } from 'react-redux';
import useReservationMethods from '../../../utils/useReservationMethods';
import {
  trackClickWithDescription,
  trackPageImpression,
} from '../../../utils/useOneTag';
import ReservationPage from '../../../pages/reserve';
import { RESERVATION_TYPE } from '../../../utils/constants/Reservation';
import {
  actionSetCloseReservationModal,
  actionSetDraftReservation,
  actionSetOpenResevationModal,
} from '../../../store/Reservations/ReservationsAction';
import { ErrorDialog } from '../../core/Dialog/ErrorDialog';
import { actionResetErrorState } from '../../../store/Error/ErrorAction';
import { ERROR_PAGE_TYPES } from '../../../utils/constants/ErrorPageTypes';
import DiningOptionSelection from './DiningOptionSelection';

export const STEPS = Object.freeze({
  CHOOSE_DATE: 'choose-date',
  SELECT_DINING_OPTION: 'select-dining-option',
  CONFIRM_RESERVATION: 'confirm-reservation',
  RESERVATION_CONFIRMED: 'reservation-confirmed',
  EDIT_PAYMENT_METHOD: 'edit-payment-method',
  EDIT_DIETARY_PREFERENCES: 'edit-dietary-preferences',
});

export const TableBookingModal = ({
  open,
  onClose,
  venue,
  isDrawer,
  stepOverride,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(stepOverride ?? STEPS.CHOOSE_DATE);
  const [isPaymentMethodEdited, setIsPaymentMethodEdited] = useState(false);
  const ContainerComponent = isDrawer ? BottomDrawer : Dialog;

  const { submittedGuests } = useSelector((state) => state.availability);
  const { errorType } = useSelector((state) => state.error);
  const { draftReservation } = useSelector((state) => state.reservations);

  const {
    setDraftReservationFromCurrentUser,
    updateDraftReservation,
    updateDraftReservationVenue,
    getReservationSubtitle,
  } = useReservationMethods();

  // Override onClose handler for Dialog to suppress it if the backdrop is clicked
  const dialogOnCloseHandler = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose(event, reason);
    }
  };

  useEffect(() => {
    dispatch(actionClearListAvailabilities());
    dispatch(actionClearDateRangeAvailabilities());
    if (venue) {
      const currentDate = new Date();
      const input = generateGetDateRangeAvailabilityInput(
        venue,
        currentDate,
        submittedGuests,
        getRemainingDaysInMonth(currentDate) +
          getDaysInMonthFromDate(currentDate, 1)
      );
      dispatch(actionGetDateRangeAvailabilityRequest(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue]);

  useEffect(() => {
    if (open && step === STEPS.CHOOSE_DATE) {
      setDraftReservationFromCurrentUser();
      updateDraftReservationVenue(venue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, venue]);

  useEffect(() => {
    if (open) {
      trackPageImpression(step, isDrawer ? 'drawer' : 'modal', venue?.name);
      dispatch(actionSetOpenResevationModal());
    }

    // on component unmount, run cleanup function which sets isReservationModalOpen to false in the Redux store.
    return () => {
      dispatch(actionSetCloseReservationModal());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, open]);

  const reset = () => {
    setStep(STEPS.CHOOSE_DATE);
    setIsPaymentMethodEdited(false);
  };

  const stepMap = useMemo(
    () => ({
      [STEPS.CHOOSE_DATE]: {
        title: CHOOSE_DATE_AND_TIME_TEXT,
        component: (
          <ChooseDate
            venue={venue}
            onTimeButtonClick={(date, timeslot, guests) => {
              updateDraftReservation({
                venue,
                ...timeslot,
                time: timeslot.timeSlot,
                date,
                guests,
                bookingPolicy: timeslot.bookingPolicy || venue.bookingPolicy,
                isCardholderTable: timeslot.isExclusive,
              });

              if (timeslot.slotName) {
                setStep(STEPS.SELECT_DINING_OPTION);
              } else {
                setStep(STEPS.CONFIRM_RESERVATION);
              }
            }}
          />
        ),
      },
      [STEPS.SELECT_DINING_OPTION]: {
        title: DINING_OPTION_TITLE,
        subtitle: getReservationSubtitle(),
        previousStep: STEPS.CHOOSE_DATE,
        component: (
          <DiningOptionSelection
            onConfirm={() => setStep(STEPS.CONFIRM_RESERVATION)}
            isCompact={isDrawer}
          />
        ),
      },
      [STEPS.CONFIRM_RESERVATION]: {
        title: COMPLETE_YOUR_RESERVATION_TEXT,
        previousStep: draftReservation.slotName
          ? STEPS.SELECT_DINING_OPTION
          : STEPS.CHOOSE_DATE,
        component: (
          <ReservationPage
            onDietEdit={() => setStep(STEPS.EDIT_DIETARY_PREFERENCES)}
            onConfirm={() => setStep(STEPS.RESERVATION_CONFIRMED)}
            onEditPaymentMethod={() => setStep(STEPS.EDIT_PAYMENT_METHOD)}
            onNoAvailabilityLinkClick={() => setStep(STEPS.CHOOSE_DATE)}
            isModal={true}
            isPaymentMethodEdited={isPaymentMethodEdited}
            reservationType={RESERVATION_TYPE.CREATE}
          />
        ),
      },
      [STEPS.EDIT_PAYMENT_METHOD]: {
        title: EDIT_PAYMENT_METHOD_TEXT,
        previousStep: STEPS.CONFIRM_RESERVATION,
        component: (
          <EditPaymentMethod
            venue={venue}
            onSave={() => {
              setIsPaymentMethodEdited(true);
              setStep(STEPS.CONFIRM_RESERVATION);
            }}
          />
        ),
      },
      [STEPS.EDIT_DIETARY_PREFERENCES]: {
        title: EDIT_RESERVATION_DIETARY_PREFERENCES_TEXT,
        previousStep: STEPS.CONFIRM_RESERVATION,
        component: (
          <EditDietPreferences
            venue={venue}
            onSave={(diets, allergies) => {
              updateDraftReservation({
                diets,
                allergies,
              });
              setStep(STEPS.CONFIRM_RESERVATION);
            }}
          />
        ),
      },
      [STEPS.RESERVATION_CONFIRMED]: {
        title: RESERVATION_CONFIRMED_SUCCESS,
        component: <ReservationConfirmed onDone={onClose} />,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [venue, onClose]
  );

  const { title, subtitle, previousStep, component } = useMemo(
    () => stepMap[step],
    [step, stepMap]
  );

  if (errorType != null && !ERROR_PAGE_TYPES[errorType]?.isInlineAlert) {
    const errorDialogOnCloseHandler = () => {
      dispatch(actionResetErrorState());
      dispatch(actionSetDraftReservation({}));
      reset();
      onClose();
    };

    return <ErrorDialog open={true} onClose={errorDialogOnCloseHandler} />;
  }

  return (
    <ContainerComponent
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={(event, reason) => {
        trackClickWithDescription(
          'bottom-sheet-close-button',
          'Button',
          venue?.name
        );
        if (isDrawer) {
          onClose(event, reason);
        } else {
          dialogOnCloseHandler(event, reason);
        }
      }}
      TransitionProps={{ onExited: reset }} // reset after Dialog closes
      SlideProps={{ onExited: reset }} // reset after BottomDrawer closes
      onBackButtonClick={
        previousStep
          ? () => {
              trackClickWithDescription(
                'bottom-sheet-back-button',
                'Button',
                venue?.name
              );
              setStep(previousStep);
            }
          : undefined
      }
      disableBackdropClick>
      {component}
    </ContainerComponent>
  );
};

export default TableBookingModal;

TableBookingModal.propTypes = {
  venue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sevenRoomsVenueId: PropTypes.string.isRequired,
    experienceId: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    paymentPolicy: PropTypes.string,
    bookingPolicy: PropTypes.string,
    isSignatureCollection: PropTypes.bool,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isDrawer: PropTypes.bool,
  stepOverride: PropTypes.number,
};
