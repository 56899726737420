import React, { useEffect, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PageSection from '../PageSection';
import Typography from '../../overrides/Typography';
import { useViewport } from '../../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { useIsMounted } from '../../../utils/useIsMounted';

const desktopHeroGradient =
  'linear-gradient(30deg, rgb(14, 29, 43), rgb(14, 29, 43), rgba(14, 29, 43, 1), rgba(14, 29, 43, 0.8), rgba(14, 29, 43, 0.2), rgba(0, 0, 0, 0.1))';
const mobileHeroGradient =
  'linear-gradient(30deg, rgb(14, 29, 43), rgb(14, 29, 43), rgba(14, 29, 43, 1), rgba(14, 29, 43, 0.8), rgba(14, 29, 43, 0.2), rgba(0, 0, 0, 0.1))';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: ({ mobileHeroImg }) =>
      `${mobileHeroGradient}, url(${mobileHeroImg})`,
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    height: 380,
    backgroundSize: 'cover',
    marginBottom: theme.spacing(9.375), //75px
    [theme.breakpoints.up('lg')]: {
      height: 400,
      backgroundSize: 'cover',
      borderBottom: `1px solid ${theme.palette.charcoal[60]}`,
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: ({ desktopHeroImg }) =>
        `${desktopHeroGradient}, url(${desktopHeroImg}) `,
    },
  },
  container: {
    maxWidth: 545,
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  title: {
    borderBottom: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    margin: '0 auto',
    width: '100%',
    fontFamily: ['OptimistRight', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '36px',
    [theme.breakpoints.up('xl')]: {
      fontSize: 32,
      lineHeight: '42px',
    },
  },
  subtitle: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const TopHero = ({ title, subtitle = '', desktopHeroImg, mobileHeroImg }) => {
  const classes = useStyles({ mobileHeroImg, desktopHeroImg });
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;
  const headerRef = useRef();
  const mounted = useIsMounted();

  useEffect(() => {
    headerRef.current?.focus();
  }, [headerRef]);

  return (
    <Box className={classes.root}>
      <PageSection className={classes.container} variant="grid">
        <h1
          className={classes.title}
          aria-live="polite"
          tabIndex={-1}
          ref={headerRef}>
          {mounted && title}
        </h1>
        {subtitle && (
          <Typography
            component="p"
            variant={isMobile ? 'small4Normal' : 'medium2Normal'}
            className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </PageSection>
    </Box>
  );
};

export default TopHero;
