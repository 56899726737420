import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../../store';
import { useFeatureFlags } from '../../utils/useFeatureFlags';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  commonLayout: {
    marginTop: theme.spacing(7),
    minHeight: 'calc(100vh - 56px)',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(8),
      minHeight: 'calc(100vh - 144px)',
    },
  },
}));

const PageLayout = ({ children }) => {
  const classes = useStyles();
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();

  return (
    <Box
      className={clsx({
        [classes.commonLayout]: !refreshLandingPageFlagIsEnabled,
      })}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Box>
  );
};

export default PageLayout;
