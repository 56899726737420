import VenueSkeletonLoader from '../../components/loading/VenueSkeletonLoader';
import Carousel from '../../components/core/Carousel';
import { WIDTH_LG_BREAKPOINT } from '../../utils/constants/Breakpoints';
import clsx from 'clsx';
import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import { getFormattedShortDateWithWeekday } from '../../utils/dateTime';
import { AVAILABILITY_NONE_TEXT } from '../../assets/copy';
import carouselStyles from './carouselStyles';

const useStyles = makeStyles((theme) => ({
  ...carouselStyles(theme),
  date: {
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      padding: theme.spacing(2, 2, 1),
    },
    display: 'flex',
    padding: theme.spacing(2, 0, 1),
    overflow: 'hidden',
    flexDirection: 'column',
  },
}));

const StackedAvailability = ({
  loader,
  timeslots,
  list,
  item,
  defaultItemIndex,
}) => {
  const classes = useStyles();

  const showAvailabilityHeader = () => {
    return (
      <Box className={classes.date}>
        <>
          <Typography
            component="h3"
            variant="medium1Semibold"
            className={classes.formattedDate}>
            {getFormattedShortDateWithWeekday(item?.date)}
          </Typography>
          {(!timeslots || timeslots.length === 0) && (
            <Typography variant="medium1Normal" className={classes.noAvail}>
              {AVAILABILITY_NONE_TEXT}
            </Typography>
          )}
        </>
      </Box>
    );
  };

  return (
    <>
      {loader ? (
        <VenueSkeletonLoader showAvailableTimesHeader={false} length={12} />
      ) : (
        timeslots.length >= 0 && (
          <>
            {showAvailabilityHeader()}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {timeslots && timeslots.length > 0 && (
                <Carousel
                  breakpointWidth={WIDTH_LG_BREAKPOINT}
                  useDirectionalButton
                  containerClass={classes.carouselContainer}
                  galleryClass={classes.carousel}
                  customGapFactor={1}
                  hiddenWidth={16}
                  defaultItemIndex={defaultItemIndex}
                  buttonClassName={clsx(classes.arrowIcon, classes.arrowButton)}
                  leftArrowClassName={clsx(
                    classes.arrowIcon,
                    classes.leftArrow
                  )}
                  rightArrowClassName={clsx(
                    classes.arrowIcon,
                    classes.rightArrow
                  )}>
                  {list}
                </Carousel>
              )}
            </div>
          </>
        )
      )}
    </>
  );
};

export default StackedAvailability;
