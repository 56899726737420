/**
 * Returns two arrays, the first containing the elements of the input array for
 * which the filter function evaluates to true, and the second containing the
 * rest of the elements.
 *
 * @param {*} array input array to be partitioned
 * @param {*} filterFn filter function which takes each item in the input array
 *                     and returns a boolean. If true, the item will be pushed
 *                     to the first array; if false, the item will be pushed to
 *                     the second array.
 * @returns two arrays, the first containing matching elements, and the second
 *          containing the remaining elements.
 */
export const partitionArray = (array, filterFn) => {
  const matches = [];
  const nonMatches = [];

  for (const item of array) {
    if (filterFn(item)) {
      matches.push(item);
    } else {
      nonMatches.push(item);
    }
  }

  return [matches, nonMatches];
};
