import React from 'react';
import Badge from './';
import { NEW_RESTAURANT_TEXT } from '../../../assets/copy';

const NewRestaurantBadge = () => {
  return (
    <Badge text={NEW_RESTAURANT_TEXT} size="medium" type="newRestaurant" />
  );
};

export default NewRestaurantBadge;
