import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useCopy } from '../../../../utils/useCopy';
import { useSearchLink, useSearchParams } from '../../helpers';
import SearchResultsLinkBase from './SearchResultsLinkBase';

const useStyles = makeStyles((theme) => ({
  pageLink: {
    ...theme.typography.small4Normal,
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.medium1Normal,
    },
  },
  unselectedBase: {
    color: theme.palette.digitalGray[95],
  },
  unselectedDesktop: {
    '&:hover': {
      background: theme.palette.interactionBlue[10],
      color: theme.palette.interactionBlue[60],
    },
  },
  selected: {
    background: theme.palette.interactionBlue[60],
    color: theme.palette.background.white,
  },
}));

const SearchResultsPageLink = ({ pageNumber }) => {
  const classes = useStyles();
  const { pageNumber: currentPage } = useSearchParams();
  const isCurrentPage = useMemo(
    () => pageNumber === currentPage,
    [pageNumber, currentPage]
  );
  const { getCopy } = useCopy();
  const ariaLabel = useMemo(
    () =>
      getCopy(
        isCurrentPage
          ? 'SEARCH_PAGINATION_CURRENT_PAGE'
          : 'SEARCH_PAGINATION_PAGE',
        { pageNumber }
      ),
    [getCopy, isCurrentPage, pageNumber]
  );
  const to = useSearchLink({ pageNumber });

  return (
    <SearchResultsLinkBase
      className={clsx(
        classes.pageLink,
        isCurrentPage
          ? classes.selected
          : [classes.unselectedBase, !isMobile && classes.unselectedDesktop]
      )}
      aria-label={ariaLabel}
      {...(isCurrentPage && { 'aria-current': 'page' })}
      to={to}>
      {pageNumber}
    </SearchResultsLinkBase>
  );
};

export default SearchResultsPageLink;
