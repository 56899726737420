import React from 'react';

import { makeStyles } from '@material-ui/styles';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '160px',
    borderRadius: 4,
    overflow: 'hidden',
  },
}));

const GoogleMap = ({ address, handleMapOnClick, zoom, ...restProps }) => {
  const classes = useStyles();
  const encodedAddress = encodeURIComponent(address);
  const destination = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${encodedAddress}&zoom=${zoom}`;

  return (
    <div
      className={classes.map}
      data-cy={restProps['data-cy']}
      onClick={handleMapOnClick}
      aria-labelledby="map"
      aria-roledescription="map"
      role="region">
      <iframe
        id="map"
        aria-label="map"
        width="100%"
        height="160"
        style={{ border: 0 }}
        title="map"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src={destination}></iframe>
    </div>
  );
};

export default GoogleMap;
