import { put, takeLatest } from 'redux-saga/effects';
import { listCuisines } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './CuisinesAction.js';
import * as TYPES from './CuisinesTypes';

export function* cuisineListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listCuisines,
      variables: { filter },
    });
    yield put(ACTIONS.actionCuisineListSuccess(result.data.listCuisines.items));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionCuisineListFail(e));
  }
}

export default function* cuisinesSaga() {
  yield takeLatest(TYPES.CUISINE_LIST_REQUEST, cuisineListRequestHandler);
}
