import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
  COPYRIGHT_LABEL,
  PRIVACY_POLICY_LABEL,
  PRIVACY_POLICY_LINK_URL,
  TERMS_OF_SERVICE_LABEL,
  TERMS_OF_SERVICE_LINK_URL,
} from '../../../assets/copy';
import React, { useCallback } from 'react';
import Divider from '../../overrides/Divider';
import { useViewport } from '../../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: ({ isLoading, isSearch }) => {
      if (isSearch) return isLoading ? 'fixed' : 'relative';
      return 'static';
    },
    bottom: ({ isLoading, isSearch }) => {
      if (isSearch) return isLoading ? 0 : 56;
      return -0;
    },
    height: 80,
    color: theme.palette.digitalGray[60],
    borderBottom: `1px solid ${theme.palette.digitalGray[30]}`,
    zIndex: 999,
    background: theme.palette.background.grayLight,
    display: 'flex!important',
    width: '100%',
    fontSize: '12px',
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    alignItems: 'center',
    padding: theme.spacing(0, 3),

    [theme.breakpoints.up('xl')]: {
      bottom: ({ isLoading, isSearch }) => {
        if (isSearch) return isLoading ? 0 : 64;
        return -0;
      },
      flexWrap: 'nowrap',
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(0, 5),
    },
  },
  mobileFooter: {
    height: 'initial',
    flexDirection: 'column',
    padding: ({ hasFAB, isSearch }) => {
      if (hasFAB || isSearch) return theme.spacing(3, 3, 13.5);
      return theme.spacing(3);
    },
  },
  divider: {
    // 2.125 is 17px
    margin: theme.spacing(0, 2.125),
    backgroundColor: theme.palette.digitalGray[90],
  },
  footerTextColor: {
    color: theme.palette.digitalGray[90],
    backgroundColor: theme.palette.background.grayLight,
    border: 'none',
  },
  footerContentLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  footerContent: {
    display: 'flex',
  },
  mobileFooterContent: {
    marginTop: theme.spacing(1.5),
  },
}));

const FooterContent = ({ isSearch = false, isLoading, hasFAB }) => {
  const classes = useStyles({ isSearch, isLoading, hasFAB });
  const desktopDivider = () => (
    <Divider
      className={clsx(classes.divider)}
      orientation="vertical"
      flexItem
    />
  );

  const { width } = useViewport();

  const isFooterMobile = width < WIDTH_BREAKPOINT;
  const handleOpenPrivacyLink = useCallback(() => {
    window.open(PRIVACY_POLICY_LINK_URL).focus();
  }, []);

  const handleOpenTnC = useCallback(() => {
    window.open(TERMS_OF_SERVICE_LINK_URL).focus();
  }, []);

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={clsx(classes.footer, isFooterMobile && classes.mobileFooter)}>
      <Grid item className={clsx(classes.footerContent)}>
        <div>{COPYRIGHT_LABEL}</div>
      </Grid>

      <Grid
        item
        className={clsx(
          classes.footerContent,
          classes.footerContentCenter,
          isFooterMobile && classes.mobileFooterContent
        )}>
        <button
          className={clsx(classes.footerTextColor, classes.footerContentLink)}
          role="link"
          onClick={() => handleOpenPrivacyLink()}
          onKeyPress={(event) =>
            event.key === 'Enter' && handleOpenPrivacyLink()
          }
          rel="noopener">
          {PRIVACY_POLICY_LABEL}
        </button>
        {desktopDivider()}
        <button
          className={clsx(classes.footerTextColor, classes.footerContentLink)}
          role="link"
          onClick={() => handleOpenTnC()}
          onKeyPress={(event) => event.key === 'Enter' && handleOpenTnC()}
          rel="noopener">
          {TERMS_OF_SERVICE_LABEL}
        </button>
      </Grid>
    </Grid>
  );
};

export default FooterContent;
