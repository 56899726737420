import * as types from './FilterTypes';

export const actionCacheCurrentFilters = () => ({
  type: types.CACHE_CURRENT_FILTERS,
});

export const actionRemoveCurrentFilterItem = ({ id, type }) => ({
  type: types.REMOVE_CURRENT_FILTER_ITEM,
  payload: { id, type },
});

export const actionResetCachedFilters = () => ({
  type: types.RESET_CACHED_FILTERS,
});

export const actionResetCurrentFilter = (payload) => ({
  type: types.RESET_CURRENT_FILTER,
  payload,
});

export const actionResetCurrentFilters = () => ({
  type: types.RESET_CURRENT_FILTERS,
});

export const actionSetCurrentFilters = (payload) => ({
  type: types.SET_CURRENT_FILTERS,
  payload,
});

export const actionSetCurrentFiltersFromCache = () => ({
  type: types.SET_CURRENT_FILTERS_FROM_CACHE,
});

export const actionSetFilterOverlayVisible = (payload) => ({
  type: types.SET_FILTER_OVERLAY_VISIBLE,
  payload,
});
