import { useMemo } from 'react';
import { SEARCH_PARAMS } from '../../../utils/constants/QueryParamWhitelists';
import { useQueryParams } from '../../../utils/useQueryParams';

export const useSearchParams = () => {
  const {
    [SEARCH_PARAMS.PAGE_NUMBER]: pageNumberQueryParam,
    [SEARCH_PARAMS.SESSION_ID]: sessionId,
  } = useQueryParams();
  const pageNumber = useMemo(
    () => (!!pageNumberQueryParam ? +pageNumberQueryParam : 0),
    [pageNumberQueryParam]
  );

  return { pageNumber, sessionId };
};
