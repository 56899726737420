import { Box } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import React from 'react';
import { WIDTH_LG_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { makeStyles } from '@material-ui/styles';
import PlaceholderLoader from '../../components/loading/PlaceholderLoader';
import TimeAvailability from '../../components/core/TimesButton/TimeAvailability';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertUserAllergies,
  convertUserDiets,
} from '../../utils/reservationFilters';
import {
  actionSetDraftReservation,
  actionSetOpenSheet,
} from '../../store/Reservations/ReservationsAction';
import { actionSetAnimationPushUp } from '../../store/Animations/AnimationsAction';
import { useHistory } from 'react-router';
import Divider from '../../components/overrides/Divider';
import { TIME_AVAILABILITY_TYPES } from './timeAvailabilityTypes';
import { redirectToReservePage } from '../reserve/helpers/reserveHelpers';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  timeBasic: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    marginLeft: ({ type }) => {
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
        return theme.spacing(3);
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(0);
      },
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(5);
      },
    },
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(2, 3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(3, 0, 0);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(2, 0, 0);
      },
    },
    [theme.breakpoints.up('xl')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(3, 5, 0);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(2, 0, 0);
        return theme.spacing(4, 0, 0, 7);
      },
    },
  },
  divider: {
    margin: ({ type }) => {
      if (
        type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE ||
        type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION
      )
        return theme.spacing(3, 0, 0);
      return theme.spacing(4, 0, 0);
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
      marginLeft: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return 'auto';
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return 0;
        return theme.spacing(7);
      },
      marginRight: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return 'auto';
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return 0;
        return theme.spacing(7);
      },
      width: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL)
          return 'calc(100% - 56px)';
        return '587px';
      },
    },
  },
}));

const AvailableTimes = ({ width, venue, title, list, type, handleEdit }) => {
  const classes = useStyles({ type });
  const { loadingAvailability, submittedGuests } = useSelector(
    (state) => state?.availability
  );
  const { user } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUpdate = (date, time, timeslot) => {
    const draftReservation = {
      venue: { ...venue, availability: list },
      userId: user.id,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: user.defaultTipAmount,
      allergies: convertUserAllergies(user.allergies),
      diets: convertUserDiets(user.diets),
      notes: user.notes || '',
      specialOccasions: [],
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      ...timeslot,
      time: time,
      date,
      guests: submittedGuests,
    };

    if (timeslot.slotName === '') {
      dispatch(actionSetDraftReservation(draftReservation));
      dispatch(actionSetAnimationPushUp(true));
      redirectToReservePage(history);
    } else {
      dispatch(actionSetDraftReservation(draftReservation));
      dispatch(actionSetOpenSheet());
    }
  };

  const showAvailabilityList = () => {
    if (!list) {
      return (
        <>
          <Box className={classes.timeBasic}>
            <PlaceholderLoader length={7} />
          </Box>
        </>
      );
    }

    return (
      list &&
      list.length > 0 &&
      list.map((item, index) => {
        return (
          <div key={`list-venue-${index}`}>
            <Box>
              <TimeAvailability
                item={item}
                handleClick={handleEdit || handleUpdate}
                loader={loadingAvailability}
                type={type}
                className={classes.timeAvailability}
              />
              <Divider className={classes.divider} />
            </Box>
          </div>
        );
      })
    );
  };

  return (
    <Box className={classes.container}>
      {title && (
        <Typography
          variant={
            width > WIDTH_LG_BREAKPOINT ? 'medium2Semibold' : 'medium1Semibold'
          }
          className={classes.subTitle}
          component="h2">
          {title}
        </Typography>
      )}
      {showAvailabilityList()}
    </Box>
  );
};

export default AvailableTimes;
