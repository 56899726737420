import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import TimeButton from './TimeButton';
import { useSelector, useDispatch } from 'react-redux';
import { actionSetAnimationPushUp } from '../../../store/Animations/AnimationsAction';
import {
  checkIndexOfSelectedDate,
  sanitizeAvailability,
} from '../../../utils/generateAvailabilityInput';
import { useViewport } from '../../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import StackedAvailability from '../../../pages/venue/StackedAvailability';
import HorizontalAvailability from '../../../pages/venue/HorizontalAvailability';
import { TIME_AVAILABILITY_TYPES } from '../../../pages/venueAvailability/timeAvailabilityTypes';
import useEventFlag from '../../../utils/useEventFlag';
import { VENUE_EVENT_DETAILS_LINK_TEXT } from '../../../assets/copy';
import { useFeatureFlags } from '../../../utils/useFeatureFlags';

const TimeAvailability = ({
  item,
  handleClick,
  type,
  venueId,
  venueStartOfDay,
  loader,
  onMount,
  venueParent = null,
}) => {
  const [timeslots, setTimeslots] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const { width } = useViewport();
  const overBreakpointLimit = width > WIDTH_BREAKPOINT;

  const { refreshAvailabilityCalendarFlagIsEnabled } = useFeatureFlags();

  useEffect(() => {
    if (item?.timeslots) {
      setTimeslots(item.timeslots);
      setIsMounted(true);
    }
  }, [item?.timeslots, venueStartOfDay]);

  const { venue } = useSelector((state) => state?.venues);
  const { submittedDate, submittedTime } = useSelector(
    (state) => state?.availability
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const checkIndex = useCallback(
    () => {
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH) return 0;
      return checkIndexOfSelectedDate(submittedDate, submittedTime, timeslots);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submittedDate, submittedTime, timeslots]
  );
  const defaultItemIndex = refreshAvailabilityCalendarFlagIsEnabled
    ? 0
    : checkIndex();

  const goToVenueDetailPage = () => {
    dispatch(actionSetAnimationPushUp(true));
    // On the search results page, it appears that venueParent & venueId are defined correctly. On the venue details page, it appears that only the
    // venue object is defined. Without knowing every scenario where this component is utilized, we err on the safe side and check each value.
    // Will be refactored later ...
    history.push(`/venue/${venueParent?.id ?? venueId ?? venue?.id ?? ''}`);
  };

  useEffect(() => {
    onMount && onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedList = () => {
    const sanitizedTimeslots =
      type === TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH
        ? sanitizeAvailability(
            timeslots,
            submittedDate,
            submittedTime,
            timeslots.length
          )
        : timeslots;
    return sanitizedTimeslots?.map((timeslot) => (
      <TimeButton
        venueParent={venueParent}
        text={timeslot?.timeDisplay}
        timeslot={timeslot}
        date={item?.date}
        key={`date=${item?.date}-time-${timeslot?.timeDisplay}-${timeslot?.slotOptionId}`}
        onClick={handleClick}
        isMobile={!overBreakpointLimit}
        venue={venue}
      />
    ));
  };

  const eventStatus = useEventFlag(venueParent, submittedDate);

  const renderHorizontalAvailability = () => {
    if (eventStatus.venueHasEvent) {
      // Pass an empty array if the special event flag is sold out and the link is handled in the HorizontalAvailability component
      const shouldShowLinkText =
        eventStatus?.isEventSoldOut || eventStatus?.isComingSoon;
      let specialEventsList = shouldShowLinkText ? [] : renderedList();
      let specialEventsTimeslots = shouldShowLinkText ? [] : timeslots;
      return (
        <HorizontalAvailability
          list={specialEventsList}
          timeslots={specialEventsTimeslots}
          defaultItemIndex={defaultItemIndex}
          seeMoreAvailability={goToVenueDetailPage}
          item={item}
          type={type}
          venueParent={venueParent}
          linkText={VENUE_EVENT_DETAILS_LINK_TEXT}
        />
      );
    }
    return (
      <HorizontalAvailability
        list={renderedList()}
        timeslots={timeslots}
        defaultItemIndex={defaultItemIndex}
        seeMoreAvailability={goToVenueDetailPage}
        item={item}
        type={type}
        venueParent={venueParent}
      />
    );
  };

  return (
    isMounted && (
      <Box>
        {type === 'stack' ? (
          <StackedAvailability
            loader={loader}
            timeslots={timeslots}
            list={renderedList()}
            item={item}
            defaultItemIndex={defaultItemIndex}
            type={type}
          />
        ) : (
          renderHorizontalAvailability()
        )}
      </Box>
    )
  );
};

export default TimeAvailability;
