import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import * as TYPES from './AwardsTypes';

const initialState = {
  list: null,
  award: null,
};

export default function awardReducer(state = initialState, action) {
  const { type, payload } = action;
  if (type === TYPES.AWARD_LIST_SUCCESS) {
    return {
      ...state,
      list: payload,
    };
  } else if (type === SIGNOUT_USER_SUCCESS) {
    return initialState;
  }
  return state;
}
