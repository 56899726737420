import React from 'react';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import {
  SEARCH_RESULT_INFLECTION,
  SEARCH_RESULT_NO_RESULT_JOIN,
  SEARCH_RESULT_NO_RESULT_PREFIX,
  SEARCH_RESULT_OTHER_LABEL,
  SEARCH_RESULT_SEE_ALL_PREFIX,
} from '../../../assets/copy';
import createInflection from '../../../utils/inflection/createInflection';
import DirectionalButton from '../../buttons/DirectionalButton';
import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
    padding: theme.spacing(1, 0),
    width: '100%',
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  resultsHeading: {
    lineHeight: theme.spacing(3),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xxl')]: {
      padding: 0,
    },
  },
  noResultsContainer: {
    padding: theme.spacing(4, 3, 2.5),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(6, 0, 4.5),
    },
  },
  noResultsHeading: {
    marginRight: 6,
  },
  noResultsSubheading: {
    width: '100%',
    height: theme.spacing(5),
    backgroundColor: theme.palette.background.grayLight,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
      padding: theme.spacing(0, 2),
    },
  },
  seeAllLink: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const SearchResultsHeader = ({ seeAllVenues }) => {
  const classes = useStyles();
  const { cachedQuery: searchQuery } = useSelector((state) => state.search);
  const { name: marketName } = useSelector((state) => state.markets.market);
  const { inLocalMarket, totalCount } = useSelector((state) => state.venues);

  if (!searchQuery || inLocalMarket) {
    return (
      <Card className={classes.root} elevation={0}>
        <Typography
          aria-hidden="true"
          className={classes.resultsHeading}
          variant="small2Semibold">
          {createInflection(SEARCH_RESULT_INFLECTION, totalCount ?? 0)}
        </Typography>
      </Card>
    );
  }

  return (
    <>
      <Box className={classes.noResultsContainer}>
        <Typography
          aria-hidden="true"
          className={classes.noResultsHeading}
          variant="medium2Normal">
          {SEARCH_RESULT_NO_RESULT_PREFIX +
            searchQuery +
            SEARCH_RESULT_NO_RESULT_JOIN +
            marketName}
        </Typography>
        <DirectionalButton
          classContainer={classes.seeAllLink}
          directional="right"
          linkTextVariant="medium1Semibold"
          onClickHandler={seeAllVenues}
          text={SEARCH_RESULT_SEE_ALL_PREFIX + marketName}
          variant="text"
        />
      </Box>
      {!!totalCount && (
        <Box className={classes.noResultsSubheading}>
          <Typography variant="small2Semibold">
            {SEARCH_RESULT_OTHER_LABEL}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SearchResultsHeader;
