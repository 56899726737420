import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PHONE_NUMBER_INFO } from '../../assets/copy';
import InfoBlock from '../../components/core/InfoBlock';
import { actionUpdateUserRequest } from '../../store/User/UserAction';
import { makeStyles } from '@material-ui/core/styles';
import { useViewport } from '../../utils/useViewport';
import { Box } from '@material-ui/core';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  phoneNumberAlertBox: {
    margin: theme.spacing(2.5, 3, 0),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(3),
    },
  },
}));

const PhoneNumberAlert = ({ style }) => {
  const { width } = useViewport();
  const classes = useStyles();
  const user = useSelector((state) => state?.user?.user);
  const [isPhoneNumberAlertHidden, setIsPhoneNumberAlertHidden] = useState(
    user?.phoneNumber === ''
  );
  const phoneNumberAlertBoxClasses =
    width < WIDTH_BREAKPOINT && classes.phoneNumberAlertBox;
  const dispatch = useDispatch();
  const hidePhoneNumberHandler = () => {
    setIsPhoneNumberAlertHidden(true);
    dispatch(
      actionUpdateUserRequest({ id: user.id, hidePhoneNumberAlert: true })
    );
  };

  return (
    <>
      {!isPhoneNumberAlertHidden && !user.hidePhoneNumberAlert && (
        <Box className={clsx(phoneNumberAlertBoxClasses, style)}>
          <InfoBlock
            text={UPDATE_PHONE_NUMBER_INFO}
            closeButtonHandler={hidePhoneNumberHandler}
            showCloseButton={true}
          />
        </Box>
      )}
    </>
  );
};

export default PhoneNumberAlert;
