import { useViewport } from '../../utils/useViewport';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import Alert from '../../components/core/Alert';
import { ALERT_VARIANTS } from '../../utils/constants/AlertVariants';
import Typography from '../../components/overrides/Typography';
import {
  RESERVE_NO_AVAILABILITY,
  RESERVE_VIEW_MORE_AVAILABILITY,
} from '../../assets/copy';
import { Link } from 'react-router-dom';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  viewMore: {
    textDecoration: 'underline',
    color: 'black',
  },
  alertBox: {
    margin: theme.spacing(3),
  },
}));

const ShowAlert = () => {
  const { width } = useViewport();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const venueId = useSelector((state) => state?.venues?.venue?.id);
  const navigateToVenueAvailability = (e) => {
    if (location?.state?.type === 'update') {
      // this line is needed for Safari since it won't redirect unless the e.preventDefault() is called
      e.preventDefault();
      history.goBack();
    } else {
      history.push(`/venue/${venueId}/check-availability`);
    }
  };
  const alertBoxClasses = width > WIDTH_BREAKPOINT ? '' : classes.alertBox;
  return (
    <Box className={alertBoxClasses}>
      <Alert variant={ALERT_VARIANTS.ERROR}>
        <Box role="alert" tabIndex={0} aria-describedby="alertTitle">
          <Typography id="alertTitle">
            {RESERVE_NO_AVAILABILITY}{' '}
            <Link
              className={classes.viewMore}
              onClick={(e) => navigateToVenueAvailability(e)}>
              {RESERVE_VIEW_MORE_AVAILABILITY}
            </Link>
          </Typography>
        </Box>
      </Alert>
    </Box>
  );
};

export default ShowAlert;
