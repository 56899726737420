import { getCookie, setCookie, removeCookie } from './cookies';
import { generateRandomString } from './random';

class SilentRefresh {
  static MILLIS_TO_REFRESH =
    Number(process.env.REACT_APP_REFRESH_MINUTES || 9) * 60 * 1000;
  static REFRESH_INTERVAL = 30 * 1000; // 30 seconds
  static IDLE_TIMEOUT_MILLIS =
    Number(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || 44) * 60 * 1000;
  static COOKIE_CONFIG = {
    path: '/',
    domain: `.${window.location.hostname}`,
    secure: true,
  };

  constructor() {
    if (this.isSilentRefreshing) {
      console.debug('SilentRefresh constructor - isSilentRefreshing');
      return;
    }
    this._c1BaseUrl = process.env.REACT_APP_C1_BASE_URL;
    this._c1ClientId = process.env.REACT_APP_C1_CLIENT_ID;

    if (this._c1BaseUrl == null || this._c1ClientId == null) {
      throw new Error('C1 base URL or client ID not set');
    }
    this._refreshIframe = document.createElement('iframe');
    this._refreshIframe.id = 'silentRefresh';
    this._refreshIframe.style.display = 'none';
    document.body.appendChild(this._refreshIframe);

    if (this._tokenExpire == null) {
      this._setTokenExpire(Date.now() + SilentRefresh.MILLIS_TO_REFRESH);
    }
  }

  teardown() {
    this._disableRefresh();
    const { path, domain } = SilentRefresh.COOKIE_CONFIG;
    removeCookie('tokenExpire', { path, domain });
    removeCookie('isAuthenticated', { path, domain });
    sessionStorage.removeItem('expirationBeforeRefresh');
  }

  get _tokenExpire() {
    const tokenExpire = getCookie('tokenExpire');
    if (tokenExpire != null) {
      return Number(tokenExpire);
    }
    return null;
  }

  _setTokenExpire(timestamp) {
    setCookie('tokenExpire', timestamp, SilentRefresh.COOKIE_CONFIG);
  }

  get isAuthenticated() {
    const isAuthenticated = getCookie('isAuthenticated');
    return isAuthenticated === 'true';
  }

  setIsAuthenticated() {
    setCookie('isAuthenticated', 'true', SilentRefresh.COOKIE_CONFIG);
  }

  get isSilentRefreshing() {
    return window.frameElement?.id === 'silentRefresh';
  }

  _setExpirationBeforeRefresh() {
    const expirationTimestamp = sessionStorage.getItem('expirationTimestamp');
    sessionStorage.setItem('expirationBeforeRefresh', expirationTimestamp);
  }

  revertExpirationTimestamp() {
    const expirationBeforeRefresh = sessionStorage.getItem(
      'expirationBeforeRefresh'
    );
    sessionStorage.setItem('expirationTimestamp', expirationBeforeRefresh);
    sessionStorage.removeItem('expirationBeforeRefresh');
  }

  _setOauthState() {
    const state = generateRandomString();
    sessionStorage.setItem('oauthState', state);
    return state;
  }

  initializeTokenRefresh() {
    this._disableRefresh();

    this._interval = setInterval(() => {
      this._handleRefresh();
    }, SilentRefresh.REFRESH_INTERVAL);
  }

  _handleRefresh() {
    if (
      this._tokenExpire != null &&
      this._tokenExpire - Date.now() <= 60000 &&
      this.isAuthenticated
    ) {
      return this._getNewToken();
    }
  }

  _getNewToken() {
    const oauthSearchParams = new URLSearchParams({
      client_id: this._c1ClientId,
      grant_type: 'authorization_code',
      redirect_uri: encodeURI(window.location.origin),
      scope: 'openid',
      state: `${this._setOauthState()}-refresh`,
      response_type: 'code',
      prompt: 'none',
    }).toString();
    const refreshIframeUrl = `${this._c1BaseUrl}/oauth2/authorize?${oauthSearchParams}`;
    this._refreshIframe.onload = () => {
      this._setTokenExpire(Date.now() + SilentRefresh.MILLIS_TO_REFRESH);
    };
    this._setExpirationBeforeRefresh();
    this._refreshIframe.setAttribute('src', refreshIframeUrl);
  }

  _disableRefresh() {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }
}

let silentRefresh;

export const getSilentRefreshInstance = () => {
  if (silentRefresh == null) {
    silentRefresh = new SilentRefresh();
  }
  return silentRefresh;
};
