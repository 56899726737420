import { useEffect, useState } from 'react';

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(
    () => setIsMounted(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return isMounted;
};
