import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useViewport } from '../../../utils/useViewport';
import {
  WIDTH_BREAKPOINT,
  BREAKPOINT_VALUES,
} from '../../../utils/constants/Breakpoints';
import DatepickerInput from './parts/DatepickerInput';
import DatePicker from 'react-datepicker';
import '../../../components/core/Calendar/css/react-datepicker.css';
import { months } from '../../../components/core/DatePicker/lib/dataSource';
import { getMonth, isValid, isMatch, format } from 'date-fns';
import LeftArrowDisabled from '../../../assets/icons/leftArrow.svg';
import LeftArrow from '../../../assets/icons/left-blue.svg';
import RightArrow from '../../../assets/icons/rightArrowBlue.svg';
import ScreenReaderText from '../ScreenReaderText';
import clsx from 'clsx';
import { CALENDAR_DIALOG_SCREENREADER_TEXT } from '../../../assets/copy';
import { DATE_FORMAT_MONTH_FIRST } from '../../../assets/dateFormats';

const useStyles = makeStyles((theme) => ({
  rdtPicker: {
    boxSizing: 'border-box',
    marginTop: theme.spacing(1.5),
    background: '#fff',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
  },
  mobilePicker: {
    marginTop: theme.spacing(1.5),
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigationContainerHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  navigationButton: {
    backgroundColor: theme.palette.background.white,
    border: 'none',
    width: 48,
    height: 48,
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 0.25, 0, 0.25),
    },
  },
  calendarMonth: {
    marginTop: theme.spacing(1.5),
    fontFamily: 'Optimist,sans-serif',
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'center',
    height: 38,
  },
  prevMonthArrow: {
    opacity: '50%',
    cursor: 'auto',
  },
}));

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

const DatepickerCalendar = ({ selectedDate, onChange, minDate, message }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;
  const isSmallMobile = width <= BREAKPOINT_VALUES.xs;
  const [calendarClasses, setCalendarClasses] = useState([classes.rdtPicker]);
  const mobileClass = useMemo(
    () => [classes.mobilePicker],
    [classes.mobilePicker]
  );
  const smallMobileClass = useMemo(
    () => [classes.mobilePicker, classes.smallMobilePicker],
    [classes.mobilePicker, classes.smallMobilePicker]
  );

  const rawChange = (date) => {
    const formattedSelectedDate = format(new Date(selectedDate), 'MM/dd/yyyy');
    // rawChange was called because of an event. if the incoming date is null
    // a new date was not selected.  Keep the current selected date
    const rawDate = date?.target?.value || formattedSelectedDate;
    const validDate =
      isMatch(rawDate, DATE_FORMAT_MONTH_FIRST.toLowerCase()) &&
      rawDate.length === DATE_FORMAT_MONTH_FIRST.length &&
      isValid(new Date(rawDate));
    // if above validation fails we need to alert the user of an error
    // passing null to onChange will trigger the error message
    onChange(validDate ? rawDate : null);
  };

  useEffect(() => {
    setCalendarClasses(isMobile ? mobileClass : classes.rdtPicker);
  }, [
    isMobile,
    mobileClass,
    isSmallMobile,
    smallMobileClass,
    classes.rdtPicker,
  ]);

  return (
    <DatePicker
      date
      selected={selectedDate}
      onChange={onChange}
      onChangeRaw={rawChange}
      customInput={<DatepickerInput />}
      popperClassName={calendarClasses}
      showPopperArrow={false}
      minDate={minDate}
      placeholderText={`MM/DD/YYYY ${message}`}
      monthDisabledBefore="true"
      popperModifiers={[sameWidth]}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className={classes.navigationContainer}>
          <ScreenReaderText aria-live="polite" aria-atomic="true">
            {!isMobile && CALENDAR_DIALOG_SCREENREADER_TEXT}
          </ScreenReaderText>
          <button
            className={clsx(
              classes.navigationButton,
              !prevMonthButtonDisabled && classes.navigationContainerHover
            )}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}>
            {prevMonthButtonDisabled ? (
              <img
                src={LeftArrowDisabled}
                alt="previous month"
                className={classes.prevMonthArrow}
              />
            ) : (
              <img src={LeftArrow} alt="previous month" />
            )}
          </button>
          <div
            aria-label={months[getMonth(date)]}
            className={classes.calendarMonth}>
            {months[getMonth(date)]}
          </div>
          <button
            className={clsx(
              classes.navigationButton,
              classes.navigationContainerHover
            )}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}>
            <img src={RightArrow} alt="next month" />
          </button>
        </div>
      )}
    />
  );
};

export default DatepickerCalendar;
