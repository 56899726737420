import { Box } from '@material-ui/core';
import VenueSkeletonLoader from '../../components/loading/VenueSkeletonLoader';
import Availability from './Availability';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Divider from '../../components/overrides/Divider';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';

const useStyles = makeStyles((theme) => ({
  loaderSpacing: {
    width: '100%',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* hide scrollbar on IE and Edge */,
    scrollbarWidth: 'none' /* hide scrollbar on Firefox */,
    padding: theme.spacing(3, 0, 0),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 0, 0),
      margin: theme.spacing(0, 0, 0, 7),
    },
  },
  desktopDivider: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% - 56px)',
      marginBottom: theme.spacing(1),
    },
    width: '100%',
  },
  loaderContainer: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 0,
    },
  },
}));

const SelectedDateAvailability = ({
  list,
  venue,
  guests,
  user,
  time,
  date,
  loadingAvailability,
  type = TIME_AVAILABILITY_TYPES.HORIZONTAL,
  resType = RESERVATION_TYPE.CREATE,
}) => {
  const classes = useStyles({ type });
  if (!list || loadingAvailability) {
    return (
      <Box className={classes.loaderSpacing}>
        <VenueSkeletonLoader
          showAvailableTimesHeader={true}
          length={7}
          showLinkPlaceHolder={false}
          className={classes.loaderContainer}
        />
        <Divider className={classes.desktopDivider} />
      </Box>
    );
  }

  return (
    <Availability
      venue={venue}
      guests={guests}
      user={user}
      time={time}
      date={date}
      type={type}
      list={list}
      resType={resType}
    />
  );
};

export default SelectedDateAvailability;
