import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import SearchOverlay from '../../../pages/search/SearchOverlay';
import { actionSetNavigationMenuVisible } from '../../../store/AppData/AppDataAction';
import MenuAnimation from '../../animation/MenuAnimation';
import MobileMenu from './MobileMenu';
import MobileMenuButton from './MobileMenuButton';
import SearchButton from './SearchButton';

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1.5),
    position: 'relative',
  },
}));

const MobileAppBar = ({ buttonsClassName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);
  const { overlayVisible } = useSelector((state) => state.search);

  useEffect(() => {
    if (overlayVisible) document.body.classList.add('disable-search-scroll');
    if (!overlayVisible)
      document.body.classList.remove('disable-search-scroll');
    return () => document.body.classList.remove('disable-search-scroll');
  }, [overlayVisible]);

  const handleShowMenu = () => {
    setMenuVisible(true);
    dispatch(actionSetNavigationMenuVisible(true));
  };

  const handleHideMenu = () => {
    setMenuVisible(false);
    dispatch(actionSetNavigationMenuVisible(false));
  };

  return (
    <>
      <Grid item className={clsx(buttonsClassName, classes.buttons)}>
        <SearchButton menuVisible={menuVisible} />
        <MobileMenuButton
          handlePress={handleShowMenu}
          menuVisible={menuVisible}
        />
      </Grid>
      <MenuAnimation isOpen={menuVisible}>
        <MobileMenu close={handleHideMenu} isOpen={menuVisible} />
      </MenuAnimation>
      {!menuVisible && (
        <MenuAnimation isOpen={overlayVisible}>
          <SearchOverlay />
        </MenuAnimation>
      )}
    </>
  );
};

export default MobileAppBar;
