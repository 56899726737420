import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { SEE_MORE_RESTAURANTS_BUTTON_TEXT } from '../../assets/copy';
import Typography from '../../components/overrides/Typography';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  link: {
    textAlign: 'center',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: theme.palette.chip.active,
  },
}));

const handleSeeMoreClick = (event) => {
  trackClick(event, "reserve:see-more-restaurants", "Button")
}

const SeeMoreRestaurantsLink = ({ className }) => {
  const classes = useStyles();

  return (
    <Link onClick={handleSeeMoreClick} className={clsx(classes.link, className)} to={{ pathname: '/' }}>
      <Typography variant="medium2Normal" className={classes.text}>
        {SEE_MORE_RESTAURANTS_BUTTON_TEXT}
      </Typography>
    </Link>
  );
};

export default SeeMoreRestaurantsLink;
