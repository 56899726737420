import React from 'react';
import { Box, makeStyles, Backdrop, Modal, Fade } from '@material-ui/core';
import FilledButton from '../../core/FilledButton';
import { useViewport } from '../../../utils/useViewport';
import { useSelector, useDispatch } from 'react-redux';
import { actionSetCloseSheet } from '../../../store/Reservations/ReservationsAction';
import {
  CLOSE_BUTTON_ALT_TEXT,
  RESERVATION_SHEET_PRICE_UNIT,
  SELECT_BUTTON_LABEL,
} from '../../../assets/copy';
import CloseSvg from '../../svg/Close';
import generateSafeHtmlTags from '../../../utils/generateSafeHtmlTags';
import { useHistory } from 'react-router';
import { actionSetAnimationPushUp } from '../../../store/Animations/AnimationsAction';
import moment from 'moment';
import createInflection from '../../../utils/inflection/createInflection';
import Typography from '../../overrides/Typography';
import { useComponentWillUnmount } from '../../../utils/useComponentWillUnmount';
import { redirectToReservePage } from '../../../pages/reserve/helpers/reserveHelpers';

const useStyles = makeStyles((theme) => ({
  bullet: {
    margin: theme.spacing(0, 1),
  },
  modal: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90%',
    width: '100%',
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.white,
    [theme.breakpoints.up('xl')]: {
      borderRadius: theme.spacing(0.5),
      left: '50%',
      overflow: 'hidden',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 736,
    },
  },
  title: {
    padding: theme.spacing(2, 3, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subTitle: {
    padding: theme.spacing(0, 3, 2, 3),
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
  },
  contentSection: {
    flex: 8,
    flexWrap: 'wrap',
    display: 'flex',
    wordBreak: 'break-word',
    width: 'auto',
    overflowY: 'auto',
  },
  close: {
    paddingRight: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectButton: {
    [theme.breakpoints.up('xl')]: {
      width: 150,
    },
  },
  action: {
    borderTop: `1px solid ${theme.palette.secondary[60]}`,
    backgroundColor: theme.palette.background.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up('xl')]: {
      position: 'static',
    },
  },
}));

const widthBreakpoint = 960;

const ReservationSheet = ({ type = 'create' }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const history = useHistory();
  const { draftReservation, isReservationSheetOpen } = useSelector(
    (state) => state.reservations
  );

  const widthBreakPointPadding = width > widthBreakpoint ? 3 : 3.25; //3.25 is 26px

  useComponentWillUnmount(() => {
    dispatch(actionSetCloseSheet());
  });
  const handleHideModal = () => {
    dispatch(actionSetCloseSheet(false));
  };

  const title = () => (
    <>
      <Box
        id="title"
        className={classes.title}
        display="flex"
        justifyContent="space-between"
        aria-label={draftReservation?.venue?.name}>
        <Typography
          variant={
            width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
          }
          component="h1">
          {draftReservation?.venue?.name}
        </Typography>
        <CloseSvg
          width={16}
          height={16}
          fill="#0D74AF"
          className={classes.close}
          onClick={() => dispatch(actionSetCloseSheet())}
          onKeyPress={(e) =>
            e.key === 'Enter' && dispatch(actionSetCloseSheet())
          }
          tabIndex={0}
          aria-label={CLOSE_BUTTON_ALT_TEXT}
          role="button"
          cursor="pointer"
        />
      </Box>
      <Box
        className={classes.subTitle}
        display="flex"
        id="subTitle"
        aria-label={moment(draftReservation?.date).format('MMM DD')}>
        <Box display="flex">
          <Typography variant={'medium1Normal'} component="p">
            {moment(draftReservation?.date).format('MMM DD')}
          </Typography>
          <Typography
            variant={'medium1Normal'}
            component="p"
            aria-hidden="true"
            className={classes.bullet}>
            •
          </Typography>
          <Typography
            id="time"
            variant={'medium1Normal'}
            component="p"
            className={classes.subtitle}>
            {draftReservation?.timeSlot}
          </Typography>
          <Typography
            variant={'medium1Normal'}
            component="p"
            aria-hidden="true"
            className={classes.bullet}>
            •
          </Typography>
          <Typography
            id="guests"
            variant={'medium1Normal'}
            component="p"
            className={classes.subtitle}>
            {createInflection('Guests', draftReservation?.guests)}
          </Typography>
        </Box>
      </Box>
    </>
  );

  const goToReservationPage = () => {
    dispatch(actionSetAnimationPushUp(true));
    redirectToReservePage(history, {
      type
    });
  };

  const currencyFormat = (amount) => {
    return amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const displayPrice = () =>
    draftReservation?.payment?.cost > 0 && (
      <Box display={'flex'}>
        <Typography variant="medium3Semibold">
          {currencyFormat(draftReservation?.payment?.cost)}
        </Typography>
        <Typography variant="small4Normal">
          {RESERVATION_SHEET_PRICE_UNIT}
        </Typography>
      </Box>
    );

  const action = () => {
    return (
      <>
        <Box tabIndex={0}>
          <Typography
            variant={
              width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
            }>
            {draftReservation?.slotName}
          </Typography>
          {displayPrice()}
        </Box>

        <Box width={'150px'}>
          <FilledButton
            text={SELECT_BUTTON_LABEL}
            variant="medium2Normal"
            onClick={goToReservationPage}
            style={classes.selectButton}
            aria-label={SELECT_BUTTON_LABEL}
          />
        </Box>
      </>
    );
  };

  return (
    <Modal
      open={isReservationSheetOpen}
      onClose={handleHideModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      className={classes.modal}>
      <Fade in={isReservationSheetOpen}>
        <Box
          className={classes.content}
          aria-labelledby="title subTitle time guests">
          {title()}
          {draftReservation?.slotBodyHtml?.trim() && (
            <Box
              px={widthBreakPointPadding}
              className={classes.contentSection}
              tabIndex={0}>
              <Typography
                variant={'medium1Normal'}
                dangerouslySetInnerHTML={generateSafeHtmlTags(
                  draftReservation?.slotBodyHtml
                )}></Typography>
            </Box>
          )}
          <Box>
            <Box className={classes.action}>{action()}</Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ReservationSheet;
