import React, { useEffect } from 'react';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Transition from '../../components/animation/Transition';
import { actionSetAnimationPushUp } from '../../store/Animations/AnimationsAction';
import { PROGRESS_BAR_LABEL } from '../../assets/copy';

const rootBoxClass = {
  position: 'absolute',
  width: '100%',
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

/**
 * root class is used on PageLayout, while embedded class is used in embedded
 * child component, so it needs the spinner height adjusted to stay in same spot.
 * This is needed if user visits the app through a deep link, otherwise it will
 * look like the loading spinner is "jumping" when the timeout for PersistGate
 * ends in PageLayout, but is still waiting to be authenticated from the
 * backend.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    ...rootBoxClass,
    height: 'calc(100% - 56px)',
  },
  embedded: {
    ...rootBoxClass,
    height: 'calc(100% - 200px)',
  },
  circle: {
    color: '#0276B1',
  },
}));

const CommonLoading = ({ active, embedded }) => {
  // util
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!active) {
      dispatch(actionSetAnimationPushUp(true));
    }
  }, [active, dispatch]);

  return (
    <Transition timeout={0} active={active}>
      <Box
        className={embedded ? classes.embedded : classes.root}
        aria-live="assertive">
        <CircularProgress
          size={48}
          className={classes.circle}
          role="progressbar"
          aria-label={PROGRESS_BAR_LABEL}
          title="Loading"
          aria-valuenow={0}
        />
      </Box>
    </Transition>
  );
};

export default CommonLoading;
