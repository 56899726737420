import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  HOME_ENTERTAINMENT_BANNER_TEXT,
  RESERVATION_ENTERTAINMENT_BANNER_TEXT,
} from '../../../assets/copy';
import BannerImg from '../../../assets/images/entertainment-banner.png';
import { BREAKPOINT_VALUES } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';
import PageSection from '../../core/PageSection';
import Typography from '../../overrides/Typography';
import BannerButton from './BannerButton';
import BannerLink from './BannerLink';

const useStyles = makeStyles((theme) => ({
  bannerImg: ({ isReservationType }) => ({
    display: 'flex',
    alignSelf: 'center',
    margin: isReservationType
      ? theme.spacing(0, 0, 3)
      : theme.spacing(5, 'auto'),
    borderRadius: 4,
    backgroundImage: `linear-gradient(rgba(16, 37, 63, 0.68) 0% 100%, ${theme.palette.blue[40]} 100% 100%), url(${BannerImg})`,
    maxWidth: isReservationType ? 327 : 1120,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: isReservationType ? 120 : 254,
    [theme.breakpoints.up(isReservationType ? 'sm' : 'lg')]: {
      backgroundPosition: '100% 30%',
    },
    [theme.breakpoints.down(isReservationType ? 'sm' : 'lg')]: {
      margin: isReservationType ? theme.spacing(0, 0, 3) : theme.spacing(5, 3),
      ...(isReservationType && {
        maxWidth: 'unset',
        width: '100%',
      }),
    },
  }),
  bannerContent: ({ isReservationType }) => ({
    alignItems: isReservationType ? 'flex-start' : 'center',
    boxSizing: 'border-box',
    display: 'flex',
    gap: isReservationType ? theme.spacing(1) : theme.spacing(4),
    flexDirection: 'column',
    height: '100%',
    justifyContent: isReservationType ? 'flex-start' : 'center',
    padding: isReservationType
      ? theme.spacing(2, 2, 2.5)
      : theme.spacing(3.75, 2.5, 0),
    textAlign: isReservationType ? 'left' : 'center',
  }),
}));

const ENTERTAINMENT_LINK =
  process.env.REACT_APP_C1_ENTERTAINMENT_URL ||
  'https://entertainment.capitalone.com';

const EntertainmentBanner = ({
  type = 'home', // home or reservation
}) => {
  const { width } = useViewport();
  const isReservationType = useMemo(() => type === 'reservation', [type]);
  const widthBreakpoint = useMemo(
    () => (isReservationType ? BREAKPOINT_VALUES.md : BREAKPOINT_VALUES.xl),
    [isReservationType]
  );
  const typographyVariant = useMemo(() => {
    if (isReservationType) return 'small3Normal';

    return width >= widthBreakpoint ? 'medium3Light' : 'medium2Normal';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, widthBreakpoint]);
  const classes = useStyles({ isReservationType });

  const handleExploreExperience = useCallback(() => {
    window.open(ENTERTAINMENT_LINK);
  }, []);

  return (
    <div className={classes.bannerImg}>
      <PageSection className={classes.bannerContent}>
        <Typography
          variant={typographyVariant}
          color="secondary"
          component="h1">
          {isReservationType
            ? RESERVATION_ENTERTAINMENT_BANNER_TEXT
            : HOME_ENTERTAINMENT_BANNER_TEXT}
        </Typography>

        {isReservationType ? (
          <BannerLink handleClick={handleExploreExperience} />
        ) : (
          <BannerButton
            variant={
              width >= widthBreakpoint ? 'medium2Normal' : 'medium1Semibold'
            }
            handleClick={handleExploreExperience}
          />
        )}
      </PageSection>
    </div>
  );
};

export default EntertainmentBanner;
