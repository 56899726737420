import { FormControl, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { ICON_MAP_PIN_ALT_TEXT } from '../../../assets/copy';
import DropDown from '../../../assets/icons/dropdown.svg';
import MapPin from '../../../assets/icons/mapPin2.svg';
import { HOME_SELECT_CITY_ID } from '../../../assets/selectors';
import Typography from '../../overrides/Typography';
import SearchControlContainer from './SearchControlContainer';
import SearchStartAdornment from './SearchStartAdornment';

const useStyles = makeStyles((theme) => ({
  dropdownArrow: {
    marginRight: theme.spacing(2),
    top: '40%',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
      padding: '11px 8px 11px 40px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  select: {
    height: 48,
    margin: 0,
    padding: 0,
  },
}));

const SearchSelect = ({ handleChange, handleFocus, handleBlur, ...props }) => {
  const classes = useStyles();
  const { list, market } = useSelector((state) => state.markets);

  return (
    <SearchControlContainer pt={2}>
      <FormControl
        aria-label="select state"
        className={classes.formControl}
        variant="outlined">
        <Typography
          className={classes.label}
          component="label"
          htmlFor={HOME_SELECT_CITY_ID}
          variant="small2Normal">
          City
        </Typography>
        <Select
          className={classes.select}
          id={HOME_SELECT_CITY_ID}
          native
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={market?.id}
          IconComponent={(selectProps) => (
            <img
              src={DropDown}
              alt=""
              className={clsx(classes.dropdownArrow, selectProps.className)}
            />
          )}
          startAdornment={
            <SearchStartAdornment src={MapPin} alt={ICON_MAP_PIN_ALT_TEXT} />
          }
          {...props}>
          {list.map((item, index) => (
            <option value={item.id} key={`market-${index}`}>
              {item.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </SearchControlContainer>
  );
};

export default SearchSelect;
