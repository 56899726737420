export const sortByName = (list) => {
  return list.sort((a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const sortByDateDesc = (list) => {
  return list?.sort((a, b) => {
    return new Date(b?.realDateTime) - new Date(a?.realDateTime);
  });
};

export const sortByDateAsc = (list) => {
  return list?.sort((a, b) => {
    return new Date(a?.realDateTime) - new Date(b?.realDateTime);
  });
};

export const sortBySortOrder = (list) =>
  list.sort((a, b) =>
    a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0
  );
