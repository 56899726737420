import * as types from './NeighborhoodsTypes';

//Neighborhood list
export const actionNeighborhoodListRequest = () => ({
  type: types.NEIGHBORHOOD_LIST_REQUEST,
});

export const actionNeighborhoodListSuccess = (payload) => ({
  type: types.NEIGHBORHOOD_LIST_SUCCESS,
  payload,
});

export const actionNeighborhoodListFail = (payload) => ({
  type: types.NEIGHBORHOOD_LIST_FAIL,
  payload,
});
