import InfoBlock from '../../components/core/InfoBlock';
import Divider from '../../components/overrides/Divider';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { TIME_AVAILABILITY_TYPES } from './timeAvailabilityTypes';

const useStyles = makeStyles((theme) => ({
  noAvailabilityDivider: {
    margin: theme.spacing(2, 0, 0),
    [theme.breakpoints.up('xl')]: {
      margin: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(3, 5, 0);
        return theme.spacing(2, 7, 2);
      },
    },
  },
  noAvailabilityContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noAvailabilityContent: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '587px',
    },
  },
}));

const NoAvailabilityInfo = ({
  text,
  containerClassName,
  contentClassName,
  useDivider = true,
  dividerClassName,
  type,
}) => {
  const classes = useStyles({ type });
  return (
    <>
      <div
        className={clsx(classes.noAvailabilityContainer, containerClassName)}>
        <div className={clsx(classes.noAvailabilityContent, contentClassName)}>
          <InfoBlock>{text}</InfoBlock>
        </div>
      </div>
      {useDivider && (
        <Divider
          className={clsx(classes.noAvailabilityDivider, dividerClassName)}
        />
      )}
    </>
  );
};

export default NoAvailabilityInfo;
