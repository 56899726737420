export const useConfig = () => {
  const config = {
    isProduction: process.env.NODE_ENV === 'production',
    isTest: process.env.NODE_ENV === 'test',
    isDevelopment: process.env.NODE_ENV === 'development',
    isDebug:
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_DEBUG_FLAG === 'true',
  };

  if (config.isDebug) {
    console.log(config);
  }

  return config;
};
