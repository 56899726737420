import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import DropDownSvg from '../../assets/icons/dropdown.svg';
import { HOUR_PERIOD_FORMAT } from '../../assets/timeFormats';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
      padding: '11px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 48,
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  image: {
    marginRight: theme.spacing(2),
    top: ` calc(40%)`,
  },
}));

const TimePicker = (props) => {
  const classes = useStyles();

  let timeValue = props.beginLimit || '6:00 AM';
  let endLimit = props.endLimit || '5:45 AM';
  let step = props.step || 30;

  const generateTimeslots = () => {
    const startTime = moment(timeValue, [HOUR_PERIOD_FORMAT]);
    const currentTime = startTime.clone();
    const endTime = moment(endLimit, [HOUR_PERIOD_FORMAT]);
    endTime.add('days', 1);
    const timeSlotOptions = [];

    while (currentTime.isBefore(endTime)) {
      const time = currentTime.format(HOUR_PERIOD_FORMAT);
      const option = (
        <option key={time} value={time}>
          {time}
        </option>
      );
      timeSlotOptions.push(option);
      currentTime.add('minutes', step);
    }
    return timeSlotOptions;
  };

  const options = generateTimeslots();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        inputProps={{ 'aria-labelledby': props.labelId }}
        native
        value={props.defaultValue}
        onChange={props.onChange}
        name={props.name}
        IconComponent={(selectProps) => (
          <img
            src={DropDownSvg}
            alt=""
            className={clsx(classes.image, selectProps.className)}
          />
        )}>
        {options}
      </Select>
    </FormControl>
  );
};

TimePicker.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  beginLimit: PropTypes.string,
  endLimit: PropTypes.string,
  step: PropTypes.number,
};

export default TimePicker;
