import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import SearchOverlay from '../../../pages/search/SearchOverlay';
import MenuAnimation from '../../animation/MenuAnimation';
import Divider from '../../overrides/Divider';
import DesktopMenu from './DesktopMenu';
import SearchButton from './SearchButton';
import UserNavigationDropdown from './UserNavigationDropdown';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flexShrink: 0,
    margin: 'initial',
  },
  divider: {
    alignSelf: 'center',
    height: 24,
    marginRight: 12,
    [theme.breakpoints.up('xxl')]: {
      marginLeft: 6,
      marginRight: 18,
    },
  },
}));

const DesktopAppBar = ({ buttonsClassName }) => {
  const classes = useStyles();
  const { overlayVisible } = useSelector((state) => state.search);

  useEffect(() => {
    if (overlayVisible) document.body.classList.add('disable-search-scroll');
    if (!overlayVisible)
      document.body.classList.remove('disable-search-scroll');
    return () => document.body.classList.remove('disable-search-scroll');
  }, [overlayVisible]);

  return (
    <>
      {!overlayVisible && (
        <>
          <DesktopMenu />
          <Grid item className={clsx(buttonsClassName, classes.buttons)}>
            <SearchButton />
          </Grid>
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <UserNavigationDropdown />
        </>
      )}
      <MenuAnimation isOpen={overlayVisible}>
        <SearchOverlay />
      </MenuAnimation>
    </>
  );
};

export default DesktopAppBar;
