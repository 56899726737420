export const defaultProps = {
  isPopup: true,
  isOpen: false,
  value: new Date(),
  min: new Date(2021, 0, 1),
  max: new Date(2021, 12, 1),
  showFooter: false,
  showHeader: true,
  showCaption: false,
  dateConfig: {
    month: {
      format: 'M',
      caption: 'Mon',
      step: 1,
    },
    date: {
      format: 'D',
      caption: 'Day',
      step: 1,
    },
  },
  headerFormat: 'M DD',
  confirmText: 'Done',
  cancelText: 'Clear',
  onChange: () => {},
  onSelect: () => {},
  onCancel: () => {},
};

export const dateConfigMap = {
  month: {
    format: 'M',
    caption: 'Mon',
    step: 1,
  },
  date: {
    format: 'D',
    caption: 'Day',
    step: 1,
  },
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
