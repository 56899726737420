import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  EXPLORE_RESTAURANTS_BUTTON_TEXT,
  ICON_EMPTY_LIST,
} from '../../assets/copy';
import { actionSetAnimationPushUp } from '../../store/Animations/AnimationsAction';
import FilledButton from '../core/FilledButton';
import Typography from '../overrides/Typography';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(5),
    },
    ...focusVisible(),
  },
  img: {
    display: 'block',
    margin: theme.spacing(4, 'auto', 0),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2),
    },
  },
  message: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  title: {
    paddingTop: theme.spacing(3),
    textAlign: 'center',
  },
}));

const NoReservations = ({ imageSrc, title, message }) => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(actionSetAnimationPushUp(true));
    history.push(`/`);
  };

  return (
    <Grid container justify="center">
      <Grid className={classes.content} item xs={12} xl={4}>
        <img
          alt={ICON_EMPTY_LIST}
          aria-hidden
          className={classes.img}
          src={imageSrc}
        />
        <Typography
          component="h2"
          className={classes.title}
          variant="medium3Light">
          {title}
        </Typography>
        <Typography className={classes.message} variant="medium1Normal">
          {message}
        </Typography>
        <FilledButton
          onClick={handleClick}
          style={classes.button}
          text={EXPLORE_RESTAURANTS_BUTTON_TEXT}
        />
      </Grid>
    </Grid>
  );
};

export default NoReservations;
