import React from 'react';
import { makeStyles } from '@material-ui/core';

import Typography from '../overrides/Typography';
import { useViewport } from '../../utils/useViewport';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.text.secondary,
  },
  chefName: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(1),
    },
  },
  description: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const widthBreakpoint = 960;

const ChefHeader = ({ subtitle, chefName, description }) => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <>
      <Typography
        variant={width > widthBreakpoint ? 'medium3Light' : 'medium2Normal'}
        className={classes.chefName}
        component="h2">
        {chefName}
      </Typography>
      <Typography
        variant={
          width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
        }
        className={classes.subtitle}
        component="h3">
        {subtitle}
      </Typography>
      <Typography variant="small2Normal" className={classes.description}>
        {description}
      </Typography>
    </>
  );
};

export default ChefHeader;
