import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { shouldNotShowFooter } from '../../../utils/hasFooter';
import { useLocation } from 'react-router';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '320px',
  },
  buttonFixed: {
    position: 'fixed',
    right: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: theme.spacing(4),
    zIndex: ({ isSearchPage }) => (isSearchPage ? 1000 : 99),
  },
  transparentBox: {
    height: '78px', //size of the FAB (42px height + 32px padding)
    backgroundColor: 'transparent',
  },
}));

const FixedBottomButtonWrapper = ({ children, onClick, isSearchPage }) => {
  const classes = useStyles({ isSearchPage });
  const location = useLocation();
  const handleBottomButtonClick = (event) => {
    onClick(event);
    trackClick(event, 'fab', "Button");
  }
  return (
    <>
      {shouldNotShowFooter(location?.pathname) && (
        <Box className={classes.transparentBox}></Box>
      )}
      <Box
        className={clsx(classes.root, classes.buttonFixed)}
        role="button"
        onClick={handleBottomButtonClick}
        onKeyPress={(e) => {e.key === 'Enter' && handleBottomButtonClick(e)}}
        tabIndex={0}
        data-cy="fab">
        {children}
      </Box>
    </>
  );
};

export default FixedBottomButtonWrapper;
