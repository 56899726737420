import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  RESERVATIONS_NO_PAST_SUBTITLE,
  RESERVATIONS_NO_PAST_TITLE,
  RESERVATIONS_PAST_TAB_LABEL,
} from '../../assets/copy';
import TableChairsImg from '../../assets/icons/drawn-table-chairs.svg';
import SmallCard from '../core/Card/SmallCard';
import NoReservations from './NoReservations';

const useStyles = makeStyles((theme) => ({
  cards: {
    paddingBottom: theme.spacing(3),
  },
}));

const PastReservation = ({ reservation, selectReservation, ...props }) => {
  const handleClick = useCallback(
    () => selectReservation(reservation),
    [reservation, selectReservation]
  );
  const handleKeyPress = useCallback(
    (e) => e.key === 'Enter' && selectReservation(reservation),
    [reservation, selectReservation]
  );

  return (
    <Grid
      item
      key={`past_reservation_${reservation.id}`}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      xl={4}
      xs={12}
      {...props}>
      <SmallCard
        hoverable={true}
        reservation={reservation}
        venue={reservation.venue}
        label={RESERVATIONS_PAST_TAB_LABEL}
      />
    </Grid>
  );
};

const PastReservations = ({ pastReservations, ...props }) => {
  const classes = useStyles();

  if (!pastReservations?.length) {
    return (
      <NoReservations
        imageSrc={TableChairsImg}
        title={RESERVATIONS_NO_PAST_TITLE}
        message={RESERVATIONS_NO_PAST_SUBTITLE}
      />
    );
  }

  return (
    <Grid container spacing={4}>
      {pastReservations.map((reservation) => (
        <PastReservation
          className={classes.cards}
          reservation={reservation}
          {...props}
        />
      ))}
    </Grid>
  );
};

export default PastReservations;
