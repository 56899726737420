import { MULTIPLE_CITIES_TEXT, MULTIPLE_DATE_TEXT } from '../assets/copy';
import { EVENT_LABEL_TYPES } from './constants/EventTypes';
import { VENUE_TYPES } from './constants/FilterTypes';
import { getFullMonthAndDate } from './dateTime';
import DateTime from './dateTimeHelpers';

export const isEvent = (venue) => venue?.venueType === VENUE_TYPES.EVENT;

export const isPastEvent = (venue) => {
  if (!isEvent(venue)) {
    return false;
  }
  const lastDate = getLastDateOfEvents(venue?.eventDates);
  if (!lastDate) {
    return true;
  }
  const lastEventDate = new DateTime(lastDate);
  const now = new DateTime();
  return lastEventDate < now;
};

export const isSoldOut = (venue) => {
  if (!isEvent(venue)) {
    return false;
  }
  if (venue?.isSoldOut) {
    return true;
  }
  const salesEndDate = new DateTime(venue?.salesEndDate);
  const now = new DateTime();
  return salesEndDate < now;
};

export const isVenueAvailable = (venue) =>
  !isPastEvent(venue) && !venue.eventSalesClosed;

export const filterUnavailableRelatedEvents = (venue) => {
  if (venue.relatedVenues?.items?.length) {
    const venueClone = { ...venue };
    const relatedVenuesClone = { ...venueClone.relatedVenues };
    const itemsClone = [...relatedVenuesClone.items].filter(
      ({ relatedVenue }) => !isPastEvent(relatedVenue) && !relatedVenue.isHidden
    );
    relatedVenuesClone.items = itemsClone;
    venueClone.relatedVenues = relatedVenuesClone;
    return venueClone;
  }
  return venue;
};

export const joinText = (arr, separator = ' ') => arr?.filter(content => !!content).join(separator);

export const displayCardEventTimes = (eventTimes) => eventTimes?.length === 1 ? eventTimes[0] : ""; 

export const displayCardEventDates = (eventDates) => {
  if (eventDates?.length === 1) {
    return getFullMonthAndDate(eventDates[0]);
  }else{
    return MULTIPLE_DATE_TEXT;
  }
}

export const isVenueAlwaysOn = (venue) => venue?.labels?.some(label => label === EVENT_LABEL_TYPES.ALWAYS_ON);

const getLastDateOfEvents = (eventDates) =>
  eventDates?.length > 0 && eventDates[eventDates?.length - 1];

const isMultipleLocation = (venue, relatedVenues = []) => relatedVenues?.some(relatedVenue => venue?.state === relatedVenue?.state && venue?.market?.name === relatedVenue?.market?.name);

export const displayLocationText = (venue, relatedVenues) => isMultipleLocation(venue, relatedVenues) ? MULTIPLE_CITIES_TEXT : joinText([venue?.city, venue?.state], ', ');

