import React from 'react';
import { InputBase, withStyles, makeStyles } from '@material-ui/core';

import Typography from '../overrides/Typography';
import { useGeneratedKey } from '../../utils/useGeneratedKey';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
}));

const TextInput = withStyles((theme) => ({
  input: {
    ...theme.typography.medium1Normal,
    boxSizing: 'border-box',
    height: 48,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.digitalGray[60]}`,
    width: '100%',
    padding: '0 12px',
    color: theme.palette.secondary[100],
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:active, &:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  inputTypeSearch: {
    '&.MuiInputBase-inputAdornedStart': {
      paddingLeft: theme.spacing(5),
    },
    '&.MuiInputBase-inputAdornedEnd': {
      paddingRight: theme.spacing(5),
    },
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
}))(InputBase);

const DEFAULT_CSS_ID = 'text-input';
const CInput = ({ id = DEFAULT_CSS_ID, labelProps = {}, ...props }) => {
  const classes = useStyles();
  const cssId = useGeneratedKey(id, id === DEFAULT_CSS_ID);

  return (
    <div className={classes.root}>
      {props?.label && (
        <Typography
          component="label"
          className={classes.label}
          color={props.color}
          htmlFor={cssId}
          variant="small2Normal"
          {...labelProps}>
          {props.label}
        </Typography>
      )}
      <TextInput id={cssId} {...props} />
    </div>
  );
};

export default CInput;
