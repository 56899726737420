import * as types from './AccoladesTypes';

//accolade list
export const actionAccoladeListRequest = () => ({
  type: types.ACCOLADE_LIST_REQUEST,
});

export const actionAccoladeListSuccess = (payload) => ({
  type: types.ACCOLADE_LIST_SUCCESS,
  payload,
});

export const actionAccoladeListFail = (payload) => ({
  type: types.ACCOLADE_LIST_FAIL,
  payload,
});
