import Typography from '../../components/overrides/Typography';
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import TipModal from '../../components/core/Stripe/TipModal';
import TipChip from '../../components/core/Stripe/TipChip';
import {
  PAYMENT_RULE_TYPE,
  GRATUITY_TYPE,
} from './helpers/paymentAvailabilityTypes';
import {
  PAYMENT_CUSTOM_TIP_LABEL,
  PAYMENT_SECTION_LABEL,
  PAYMENT_TIP_LABEL,
  RESERVATION_RECEIPT_PREFIX_TIP_LABEL,
  RESERVATION_RECEIPT_RESERVATION_FEE_LABEL,
  RESERVATION_RECEIPT_SERVICE_FEE_LABEL,
  RESERVATION_RECEIPT_TOTAL_LABEL,
  RESERVATION_SERVICE_CHARGE_TOOLTIP,
} from '../../assets/copy';
import {
  sanitizeTotalAmount,
  calculateDifference,
  calculateProduct,
  displayUSD,
} from '../../utils/currencyHelper';
import InfoIconTooltip from '../../components/core/InfoIconTooltip';

const useStyles = makeStyles((theme) => ({
  root: ({ isModal }) => ({
    padding: isModal ? 0 : theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: isModal ? 0 : theme.spacing(3, 0),
    },
  }),
  title: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  innerContainer: {
    padding: theme.spacing(2, 0),
  },
  lastContainer: {
    paddingBottom: 0,
  },
  innerBox: {
    display: 'flex',
    placeContent: 'space-between',
  },
  tipContainer: {
    display: 'flex',
    gap: 4.27,
  },
  serviceCharge: {
    display: 'flex',
  },
  blackToolTip: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    lineHeight: '21px',
    fontSize: '14px',
    width: 'auto',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(4),
      width: '256px',
    },
  },
  informationIcon: {
    margin: theme.spacing(0.5, 0, 0, 1),
    width: '.85rem',
    height: '.85rem',
  },
}));

const SpecificGratuity = ({ mandatedGratuityAmt }) => {
  const classes = useStyles();

  return (
    <Box className={classes.innerBox}>
      <Typography variant="small2Semibold">
        {RESERVATION_RECEIPT_PREFIX_TIP_LABEL}
      </Typography>
      <Typography variant="small2Normal">
        {displayUSD(mandatedGratuityAmt)}
      </Typography>
    </Box>
  );
};

const ClientGratuity = ({
  subtotal,
  totalAmount,
  originalAmount,
  tipProperties,
  setModalOpen,
  setCalculationState,
}) => {
  const classes = useStyles();

  const onCustomTipChipClick = useCallback(
    () => setModalOpen(true),
    [setModalOpen]
  );

  return (
    <>
      <Box className={classes.innerBox}>
        <Box className={classes.title}>
          <Typography variant="medium1Semibold">{PAYMENT_TIP_LABEL}</Typography>
        </Box>
        <Typography variant="small2Normal">
          {displayUSD(calculateDifference(totalAmount, originalAmount))}
        </Typography>
      </Box>
      <Box className={classes.tipContainer}>
        {Object.keys(tipProperties).map((tipAmount) =>
          tipAmount === 'custom' ? (
            <TipChip
              onPress={onCustomTipChipClick}
              selected={tipProperties[tipAmount].isSelected}
              variant="custom">
              {PAYMENT_CUSTOM_TIP_LABEL}
            </TipChip>
          ) : (
            <TipChip
              onPress={() => setCalculationState(subtotal, tipAmount)}
              selected={tipProperties[tipAmount].isSelected}>
              {`${tipAmount}%`}
            </TipChip>
          )
        )}
      </Box>
    </>
  );
};

const ReservationChargesContainer = ({
  reservation,
  setModalOpen,
  totalAmount,
  originalAmount,
  tipProperties,
  setCalculationState,
  isModal,
}) => {
  const classes = useStyles({ isModal });

  const {
    subtotal,
    serviceChargeAmt,
    taxAmt,
    gratuityType,
    mandatedGratuityAmt,
  } = reservation;
  const serviceChargeTooltipTitle = RESERVATION_SERVICE_CHARGE_TOOLTIP;

  if (
    reservation.ccPaymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT &&
    (gratuityType === GRATUITY_TYPE.SPECIFIC_GRATUITY ||
      gratuityType === GRATUITY_TYPE.CLIENT_GRATUITY)
  ) {
    return (
      <>
        <Container className={classes.root}>
          <Box className={classes.title}>
            <Typography variant="medium1Semibold" component="h3">
              {PAYMENT_SECTION_LABEL}
            </Typography>
          </Box>
          <Container className={classes.innerContainer}>
            <Box className={classes.subTitle}>
              <Typography variant="small2Normal">
                {RESERVATION_RECEIPT_RESERVATION_FEE_LABEL}
              </Typography>
              <Typography variant="small2Normal">
                {displayUSD(subtotal)}
              </Typography>
            </Box>
            {serviceChargeAmt > 0 && (
              <Box className={classes.subTitle}>
                <Typography
                  variant="small2Normal"
                  className={classes.serviceCharge}>
                  {RESERVATION_RECEIPT_SERVICE_FEE_LABEL}
                  <InfoIconTooltip
                    title={serviceChargeTooltipTitle}
                    tooltipStyle={classes.blackToolTip}
                    infoIconStyle={classes.informationIcon}
                  />
                </Typography>
                <Typography variant="small2Normal">
                  {displayUSD(serviceChargeAmt)}
                </Typography>
              </Box>
            )}
            {taxAmt > 0 && (
              <Box className={classes.subTitle}>
                <Typography variant="small2Normal">Tax</Typography>
                <Typography variant="small2Normal">
                  {displayUSD(taxAmt)}
                </Typography>
              </Box>
            )}
          </Container>
          <Divider />
          <Container className={classes.innerContainer}>
            {gratuityType === 'SPECIFIC_GRATUITY' ? (
              <SpecificGratuity mandatedGratuityAmt={mandatedGratuityAmt} />
            ) : (
              <ClientGratuity
                subtotal={subtotal}
                totalAmount={totalAmount}
                originalAmount={originalAmount}
                tipProperties={tipProperties}
                setModalOpen={setModalOpen}
                setCalculationState={setCalculationState}
              />
            )}
          </Container>
          <Divider />
          <Container
            className={`${classes.innerContainer} ${classes.lastContainer}`}>
            <Box className={classes.innerBox}>
              <Typography variant="small2Semibold">
                {RESERVATION_RECEIPT_TOTAL_LABEL}
              </Typography>
              <Typography variant="small2Normal">
                {displayUSD(totalAmount)}
              </Typography>
            </Box>
          </Container>
        </Container>
        <Divider />
      </>
    );
  } else {
    return null;
  }
};

const ReservationCharges = ({
  reservationObj,
  originalAmount,
  totalAmount,
  setTotalAmount,
  customTip,
  setCustomTip,
  tipProperties,
  setTipProperties,
  setPaymentGratuity,
  isModal,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const setCalculationState = useCallback(
    (subtotal, percentage) => {
      const total = sanitizeTotalAmount(
        originalAmount,
        calculateProduct(subtotal, tipProperties[percentage].percentage)
      );
      const gratuityAmount = calculateDifference(total, originalAmount);
      setTotalAmount(total);
      setPaymentGratuity(gratuityAmount);

      const tipPropertiesClone = { ...tipProperties };
      Object.keys(tipPropertiesClone).forEach(
        (tip) => (tipPropertiesClone[tip].isSelected = false)
      );
      tipPropertiesClone[percentage].isSelected = true;
      setTipProperties(tipPropertiesClone);
    },
    [
      originalAmount,
      setPaymentGratuity,
      setTipProperties,
      setTotalAmount,
      tipProperties,
    ]
  );

  return (
    <>
      <ReservationChargesContainer
        reservation={reservationObj}
        setModalOpen={setModalOpen}
        totalAmount={totalAmount}
        originalAmount={originalAmount}
        tipProperties={tipProperties}
        setCalculationState={setCalculationState}
        isModal={isModal}
      />
      <TipModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setTotalAmount={setTotalAmount}
        setCustomTip={setCustomTip}
        originalAmount={originalAmount}
        customTip={customTip}
        tipProperties={tipProperties}
        setTipProperties={setTipProperties}
        setPaymentGratuity={setPaymentGratuity}
      />
    </>
  );
};

export default ReservationCharges;
