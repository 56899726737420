import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actionSetSearchCurrentInputValue } from '../../../store/Search/SearchAction';

export const useSearchChangeHandler = ({ update, reset }) => {
  const dispatch = useDispatch();

  const handleSearchChange = useCallback(
    (event) => {
      let inputValue = '';

      if (event?.target?.value != null) {
        inputValue = event.target.value;
      } else if (typeof event === 'string') {
        inputValue = event;
      }

      const searchInputValue = inputValue.toLowerCase();

      if (searchInputValue && typeof update === 'function') {
        update(searchInputValue);
      } else if (typeof reset === 'function') {
        reset(searchInputValue);
      }

      dispatch(actionSetSearchCurrentInputValue(searchInputValue));
    },
    [dispatch, update, reset]
  );

  return handleSearchChange;
};
