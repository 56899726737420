import { useCallback, useMemo, useState } from 'react';

export const SPECIAL_DATE_TYPES = Object.freeze({
  ANNIVERSARY: 'userAnniversary',
  PARTNER_BIRTHDAY: 'partnerBirthday',
  USER_BIRTHDAY: 'userBirthday',
});

export const useSpecialDate = ({ type, user }) => {
  const storedDate = useMemo(() => user?.[type] || null, [type, user]);
  const [currentDate, setCurrentDate] = useState(storedDate);
  const [dateError, setDateError] = useState();
  const isDateUpdated = useMemo(
    () => storedDate !== currentDate,
    [storedDate, currentDate]
  );
  const changeDate = useCallback(
    (value) => setCurrentDate(value),
    [setCurrentDate]
  );

  return {
    dateError,
    changeDate,
    currentDate,
    isDateUpdated,
    setDateError,
    storedDate,
  };
};
