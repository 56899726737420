import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Container, TextareaAutosize } from '@material-ui/core';
import { useHistory } from 'react-router';

import Typography from '../../components/overrides/Typography';
import SelectText from '../../components/core/SelectText';
import {
  RESERVE_EDIT_SPECIAL_REQUESTS,
  PROFILE_DIETARY_PREFERENCES_NOTES_LABEL,
  NOTES_PLACEHOLDER,
  SPECIAL_OCCASION_LABEL,
  RESERVE_EDIT_SPECIAL_OCCASIONS_UPDATE_LABEL,
  SPECIAL_REQUESTS_SECTION_LABEL,
  RESERVE_PRESELECTED_SPECIAL_OCCASION_TEXT,
  SPECIAL_REQUESTS_OUT_OF,
  SPECIAL_REQUESTS_CHARACTERS_USED,
} from '../../assets/copy';
import InfoBlock from '../../components/core/InfoBlock';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import { generateScreenReaderText } from '../../utils/stringHelperMethods';

const MAX_LENGTH = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0, 2),
    },
  },
  label: {
    margin: `${theme.spacing(1)} 0`,
  },
  title: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  edit: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  notes: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
    },
  },
  note: {
    border: '1px solid #A8A8A8',
    borderRadius: '5px',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontSize: '16px',
    lineHeight: '24px',
  },
  item: {
    marginRight: theme.spacing(1),
  },
}));

const Additional = ({
  notes,
  type,
  editable = true,
  updateNotes,
  selectOccasions,
  updateSelectOccasions,
  showInfoBlock,
}) => {
  const { specialOccasions } = useSelector((state) => state.appData);
  const classes = useStyles();
  const history = useHistory();

  const [notesText, setNotesText] = React.useState(notes || '');
  const [notesBusy, setNotesBusy] = React.useState(true);

  const handleChange = (e) => {
    setNotesBusy(false);
    if (notesText.length <= 250) {
      updateNotes(e.target.value);
      setNotesText(e.target.value);
    }
  };

  const handleEditAddition = () => {
    history.push('/reserve/note');
  };

  const handleSelectOccasion = (item) => {
    const filter = selectOccasions.filter(
      (occasion) => occasion.id !== item.id
    );
    if (filter.length === selectOccasions.length) {
      updateSelectOccasions([...selectOccasions, item]);
    } else {
      updateSelectOccasions(filter);
    }
  };

  return (
    <Container className={classes.root}>
      <Box className={classes.title}>
        {type === 'Complete' ? (
          <Typography component="h2" variant="medium1Semibold">
            {SPECIAL_REQUESTS_SECTION_LABEL}
          </Typography>
        ) : (
          <>
            <Typography component="h2" variant="medium1Semibold">
              {SPECIAL_REQUESTS_SECTION_LABEL}
            </Typography>
            {editable && (
              <Typography
                aria-label={RESERVE_EDIT_SPECIAL_REQUESTS}
                variant="medium1Semibold"
                className={classes.edit}
                role="button"
                onClick={handleEditAddition}
                tabIndex="0">
                Edit
              </Typography>
            )}
          </>
        )}
      </Box>
      <Grid container item className={classes.notes}>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center">
          {type === 'Complete' ? (
            <>
              <Typography
                variant="small2Normal"
                className={classes.label}
                gutterBottom
                id="addNotes">
                {RESERVE_EDIT_SPECIAL_REQUESTS}
              </Typography>
              <ScreenReaderText
                variant="small2Normal"
                className={classes.label}
                gutterBottom
                aria-busy={notesBusy}
                role="status"
                aria-live="polite"
                aria-atomic="true">
                {generateScreenReaderText(
                  ' ',
                  notesText.length,
                  SPECIAL_REQUESTS_OUT_OF,
                  MAX_LENGTH,
                  SPECIAL_REQUESTS_CHARACTERS_USED
                )}
              </ScreenReaderText>
              <Typography
                variant="small2Normal"
                className={classes.label}
                gutterBottom
                aria-hidden="true">
                {notesText.length}/{MAX_LENGTH}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="small2Normal"
                className={classes.label}
                gutterBottom>
                {PROFILE_DIETARY_PREFERENCES_NOTES_LABEL}
              </Typography>
              <ScreenReaderText
                variant="small2Normal"
                className={classes.label}
                gutterBottom
                aria-busy={notesBusy}
                role="status"
                aria-live="polite"
                aria-atomic="true">
                {generateScreenReaderText(
                  ' ',
                  notesText.length,
                  SPECIAL_REQUESTS_OUT_OF,
                  MAX_LENGTH,
                  SPECIAL_REQUESTS_CHARACTERS_USED
                )}
              </ScreenReaderText>
              <Typography
                variant="small2Normal"
                className={classes.label}
                gutterBottom
                aria-hidden="true">
                {notesText.length}/{MAX_LENGTH}
              </Typography>
            </>
          )}
        </Grid>
        <TextareaAutosize
          aria-labelledby="addNotes"
          placeholder={NOTES_PLACEHOLDER}
          value={notes}
          maxLength={250}
          className={classes.note}
          onChange={handleChange}
        />
      </Grid>
      <Grid container item direction="column">
        <Typography variant="small2Normal" className={classes.label}>
          {SPECIAL_OCCASION_LABEL}
        </Typography>
        {showInfoBlock && (
          <Box mb={2}>
            <InfoBlock text={RESERVE_PRESELECTED_SPECIAL_OCCASION_TEXT} />
          </Box>
        )}
        <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
          <ScreenReaderText>
            {RESERVE_EDIT_SPECIAL_OCCASIONS_UPDATE_LABEL}
          </ScreenReaderText>
          {specialOccasions.map((item) => (
            <SelectText
              key={`keytext-${item.id}`}
              item={item}
              list={selectOccasions}
              onClick={() => handleSelectOccasion(item)}
            />
          ))}
        </Box>
      </Grid>
    </Container>
  );
};

export default Additional;
