import React from 'react';
import { useSelector } from 'react-redux';
import FooterContent from './FooterContent';
import {
  shouldNotShowFAB,
  shouldNotShowFooter,
} from '../../../utils/hasFooter';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { errorType } = useSelector((state) => state.error);
  const location = useLocation();
  const { venue } = useSelector((state) => state.venues);
  const shouldShowFooter =
    errorType === null && !shouldNotShowFooter(location?.pathname);
  const hasFAB =
    !shouldNotShowFAB(location?.pathname) &&
    !venue?.eventSalesClosed &&
    !venue?.isSoldOut &&
    venue?.isActive;

  if (shouldShowFooter) {
    return (
      <footer>
        <FooterContent hasFAB={hasFAB} />
      </footer>
    );
  } else {
    return null;
  }
};

export default Footer;
