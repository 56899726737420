import React, { useRef } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { DismissButton, useOverlay } from '@react-aria/overlays';

const useStyles = makeStyles((theme) => ({
  popover: {
    position: 'absolute',
    width: '100%',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)!important',
    borderRadius: `${theme.spacing(0.5)}!important`,
  },
}));

const SuggestedResultsPopover = (props) => {
  const classes = useStyles();
  const defaultRef = useRef();
  const { isOpen, onClose, popoverRef = defaultRef, children } = props;

  const { overlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
    },
    popoverRef
  );

  return (
    <Paper
      aria-label="popover"
      elevation={3}
      {...overlayProps}
      ref={props.popoverRef}
      label="popover"
      className={classes.popover}>
      {children}
      <DismissButton onDismiss={onClose} aria-label="dismiss"></DismissButton>
    </Paper>
  );
};

export default SuggestedResultsPopover;
