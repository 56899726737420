import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SEARCH_LOAD_MORE } from '../../../assets/copy';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants/Pagination';
import TextButton from '../../core/TextButton';
import { useSearchLink, useSearchParams } from '../helpers';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    marginTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const ShowMoreLink = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname, search, state } = useSearchLink({ pageNumber: 1 });
  const { pageNumber } = useSearchParams();
  const { totalCount } = useSelector((state) => state.venues);
  const shouldShowMore = useMemo(
    () => pageNumber === 0 && totalCount > DEFAULT_PAGE_SIZE,
    [pageNumber, totalCount]
  );

  const goToFirstPage = useCallback(
    () => history.replace({ pathname, search }, state),
    [history, pathname, search, state]
  );

  const handleSearchShowMoreClick = (event) => {
    goToFirstPage(event);
    trackClick(event, "search-show-more", "Button");
  }

  if (!shouldShowMore) return null;

  return (
    <Box className={classes.linkContainer} role="presentation">
      <TextButton onClick={handleSearchShowMoreClick} data-cy="search-show-more">
        {SEARCH_LOAD_MORE}
      </TextButton>
    </Box>
  );
};

export default ShowMoreLink;
