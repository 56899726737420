import moment from 'moment';

const getNearestInterval = () => {
  const currentTime = moment();
  const currentMinute = currentTime.minutes();

  let defaultTime = '';

  if (currentMinute <= 30) {
    defaultTime = currentTime.minutes(30).seconds(0).format('h:mm A');
  } else {
    defaultTime = currentTime
      .add(1, 'hours')
      .minutes(0)
      .seconds(0)
      .format('h:mm A');
  }
  return defaultTime;
};

export const getDefaultDateForFab = () => {
  const currentDate = new Date(); // using moment() to get the instance of the current date because the FAB uses the built-in Date
  if (currentDate.getHours() === 5 && currentDate.getMinutes() === 30) {
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return currentDate.toDateString();
};

export const getDefaultTimeForFab = () => {
  const currentTime = moment();
  const beginningTime = moment();
  const endingTime = moment();
  beginningTime.hour(5).minutes(30).seconds(0); // 5:30 AM
  endingTime.hour(19).minutes(0).seconds(0); // 7:00 PM

  if (currentTime.isBetween(beginningTime, endingTime)) {
    return currentTime.hours(19).minutes(0).seconds(0).format('h:mm A');
  }
  // Past 7:00 PM
  return getNearestInterval();
};

export const getDefaultDateTimeForFab = () => {
  const currentTime = moment();
  const beginningTime = moment();
  const endingBusinessDayTime = moment();
  const endingTimeThreshold = moment();
  const currentDate = new Date();
  let defaultDate = sessionStorage.getItem('defaultDate');
  let defaultTime = sessionStorage.getItem('defaultTime');

  if (defaultDate && defaultTime) {
    return {
      defaultDate,
      defaultTime,
    };
  }

  beginningTime.hour(5).minutes(30).seconds(0); // 5:30 AM
  endingBusinessDayTime.hour(5).minutes(59).seconds(59); // 5:59 AM
  endingTimeThreshold.hour(19).minutes(0).seconds(0); // 7:00 PM

  //Between 5:30AM - 5:59AM => add one day to selected date.
  if (currentTime.isBetween(beginningTime, endingBusinessDayTime)) {
    currentDate.setDate(currentDate.getDate() + 1);
  }
  defaultDate = currentDate.toDateString();

  //Between 5:30AM - 7:00PM => default time to 7:00PM.
  if (currentTime.isBetween(beginningTime, endingTimeThreshold)) {
    defaultTime = currentTime.hours(19).minutes(0).seconds(0).format('h:mm A');
    // Any other time. (7:01PM-5:29AM) => default time to nearest 30 min interval
  } else {
    defaultTime = getNearestInterval();
  }

  return {
    defaultDate,
    defaultTime,
  };
};
