import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import DefaultImage from '../../assets/images/media.png';
import { priceCheck } from '../../utils/venue';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from '../../utils/stringHelperMethods';
import { chefItemBaseStyles } from './ChefItemStyles';
import { useViewport } from '../../utils/useViewport';
import OverflowRestaurantDetails from './OverflowRestaurantDetails';
import useEventFlag from '../../utils/useEventFlag';
import { EVENT_TEXT } from '../../assets/copy';
import { Box } from '@material-ui/core';
import { enterKeyPressHandler } from '../../utils/keyPresses';

const useStyles = makeStyles((theme) => ({
  ...chefItemBaseStyles(theme),
  cardContent: {
    overflow: 'hidden',
    alignSelf: 'flex-start',
    padding: theme.spacing(3),
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  media: {
    height: ({ allSelectionsSection }) => (allSelectionsSection ? 124 : 168),
    flexBasis: ({ allSelectionsSection }) => (allSelectionsSection ? 124 : 168),
    flexGrow: 0,
    flexShrink: 0,
    position: 'relative',
  },
  badgeContainer: {
    position: 'relative',
    marginTop: theme.spacing(1),
    '& > div': {
      position: 'static',
      top: 0,
      left: 0,
    },
  },
}));

//update to pass venue instead of name desc
export default function ChefItem({ large, venue, allSelectionsSection }) {
  const classes = useStyles({ large, allSelectionsSection });
  const history = useHistory();
  const { width } = useViewport();
  const MAX_WIDTH_OF_CARD_TEXT = large ? 256 : 240;

  const eventStatus = useEventFlag(venue);
  const shouldShowBadge =
    eventStatus.venueHasEvent || eventStatus.venueHasBadge;

  const eventText = eventStatus?.venueHasEvent
    ? convertNullToEmptyString(eventStatus?.renderVenueBadge()?.props?.text) +
      ' ' +
      EVENT_TEXT
    : '';

  const handleClick = () => {
    history.push(`/venue/${venue.id}`);
  };

  const generateVenueText = () => {
    return generateScreenReaderText(
      ',',
      eventText,
      venue?.name,
      venue.cuisines.items[0]?.cuisine.name || '',
      priceCheck(venue?.priceRange),
      venue.city,
      venue.state
    );
  };
  const onKeyPressHandler = enterKeyPressHandler && handleClick;

  return (
    <Paper elevation={4} className={classes.root}>
      {venue && (
        <Card
          className={classes.card}
          tabIndex={0}
          aria-label={generateVenueText()}
          role="link"
          onClick={handleClick}
          onKeyPress={onKeyPressHandler}>
          <Box className={classes.cardActionArea}>
            <CardMedia
              className={classes.media}
              image={venue.images[0] || DefaultImage} alt="venue image"></CardMedia>
            <CardContent className={classes.cardContent}>
              <OverflowRestaurantDetails
                large={false}
                vertical={false}
                maxCardWidth={MAX_WIDTH_OF_CARD_TEXT}
                width={width}
                venue={venue}
              />
              {shouldShowBadge && (
                <Box className={classes.badgeContainer}>
                  {eventStatus.renderVenueBadge()}
                </Box>
              )}
            </CardContent>
          </Box>
        </Card>
      )}
    </Paper>
  );
}
