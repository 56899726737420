import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  column: ({ imageCount, index }) => {
    if (imageCount === 1) return { gridArea: '1 / 1 / span 2 / span 2' };

    const shouldSpan2Rows = index === 0 || imageCount === 2;

    return {
      gridColumn: index === 0 ? '1 / span 1' : '2 / span 1',
      ...(shouldSpan2Rows && { gridRow: '1 / span 2' }),
    };
  },
  image: ({ imageCount }) => ({
    borderRadius: 4,
    border: `1px solid ${theme.palette.digitalGray[20]}`,
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    ...(imageCount > 1 && { cursor: 'pointer' }),
  }),
}));

const VenueImageColumn = ({
  imageCount,
  index,
  onPress,
  columnKey,
  ...props
}) => {
  const classes = useStyles({ imageCount, index });

  return (
    <Box
      className={classes.column}
      {...(columnKey != null && { key: columnKey })}>
      <img
        alt=""
        className={classes.image}
        {...(onPress != null && {
          onClick: onPress,
          onKeyPress: (e) => e?.key === 'Enter' && onPress(e),
          tabIndex: 0,
        })}
        {...props}
      />
    </Box>
  );
};

export default VenueImageColumn;
