import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAuth } from '../../utils/useAuth';
import CommonLoading from '../common/CommonLoading';
import { setPermalinkSlug, getPermalinkRouteRedirect } from '../../utils/sessionStorageHelper';
import { getPermalinkRouteRedirectLocation } from '../onboarding/onboardingHelpers';

const PermaLink = () => {
  const { slug } = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  useEffect(() => {
    setPermalinkSlug(slug);
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.session.isAuthenticated ) return;
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.session.isAuthenticated]);

  useEffect(() => {
    if (!getPermalinkRouteRedirect()) {
      return <CommonLoading active={true} embedded={true} />
    }
    history.replace({
      pathname: getPermalinkRouteRedirectLocation(),
      search: location.search,
    });
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [history, getPermalinkRouteRedirect(), getPermalinkRouteRedirectLocation()]);

  return (
    !user.session.isAuthenticated && (
      <CommonLoading active={!user.session.isAuthenticated} embedded={true} />
    )
  );
};

export default PermaLink;
