import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { DateCalendar, DateRangeCalendar } from '@cof/omni-react';
import DateTime from '../../../utils/dateTimeHelpers';
import { PROGRESS_BAR_LABEL } from '../../../assets/copy';
import clsx from "clsx";

function isDateDisabled(date, availableDates) {
  if (availableDates == null) return false;
  return !availableDates.some((d) => {
    return d.toDateString() === date.toDateString();
  });
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    width: 'min-content',
  },
  calendarContainer: {
    display: 'inline-block',
    position: 'relative',
    fontFamily: theme.typography.medium1Normal.fontFamily,
    '& th[scope="col"]': {
      fontWeight: '400',
    },
    '& span[data-omni-part="day"]': {
      marginBottom: 0,
    },
  },
  opacity25: {
    opacity: 0.25,
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 35px)',
    left: 'calc(50% - 24px)',
  },
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
}));

const OneMonthCalendar = ({ hasShadow, availableDates, ...rest }) => (
  <DateCalendar
    {...rest}
    variant={hasShadow ? 'picker' : 'inline'}
    isDateDisabled={(date) => isDateDisabled(date, availableDates)}
  />
);

const TwoMonthCalendar = ({
  hasShadow,
  availableDates,
  date,
  onDateSelected,
  ...rest
}) => {
  return (
    <DateRangeCalendar
      {...rest}
      variant={hasShadow ? 'picker' : 'inline'}
      startDate={date}
      onStartDateSelected={(date) => {
        onDateSelected(date);
        const dt = DateTime.fromJSDate(date);
        const selectedDateString = dt.date;
        const selectedDateButton = document.querySelector(
          `button[data-date="${selectedDateString}"]`
        );
        if (selectedDateButton) {
          // forces Date Range Calendar to only select one date
          setTimeout(() => {
            selectedDateButton.click();
          }, 0);
        }
      }}
      endDate={date}
      onEndDateSelected={() => {}}
      isDateDisabled={(date) => isDateDisabled(date, availableDates)}
    />
  );
};

export const AvailabilityCalendar = ({ isWide, isLoading, ...rest }) => {
  const classes = useStyles({ isLoading });
  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress
            size={48}
            className={classes.circle}
            role="progressbar"
            title={PROGRESS_BAR_LABEL}
            classes={{ root: classes.loader }}
          />
        </div>
      )}
      <div className={clsx({
          [classes.calendarContainer]: true,
          [classes.opacity25]: isLoading,
        })}>
        {isWide ? (
          <TwoMonthCalendar {...rest} />
        ) : (
          <OneMonthCalendar {...rest} />
        )}
      </div>
    </div>
  );
};

export default AvailabilityCalendar;

AvailabilityCalendar.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.object),
  isWide: PropTypes.bool,
  hasShadow: PropTypes.bool,
  isLoading: PropTypes.bool,

  // DateCalendar props
  onDateSelected: PropTypes.func.isRequired,
  onVisibleDateChange: PropTypes.func,
  date: PropTypes.object,
  showTodayLabel: PropTypes.bool,
  minDate: PropTypes.object,
};
