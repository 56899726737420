import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants/Pagination';
import { useCopy } from '../../../utils/useCopy';
import ScreenReaderText from '../../core/ScreenReaderText';
import Typography from '../../overrides/Typography';
import { useSearchParams } from '../helpers/useSearchParams';

const useStyles = makeStyles((theme) => ({
  count: {
    color: theme.palette.digitalGray[60],
    paddingTop: theme.spacing(3),
    textAlign: 'center',
  },
}));

const SearchResultsCount = () => {
  const classes = useStyles();
  const { totalCount } = useSelector((state) => state.venues);
  const { pageNumber } = useSearchParams();
  const { getCopy } = useCopy();
  const pageLength = useSelector(
    (state) => state.venues.pages?.[pageNumber]?.length
  );
  const firstItem = useMemo(() => {
    const page = pageNumber === 1 ? 0 : pageNumber;

    return 1 + page * DEFAULT_PAGE_SIZE;
  }, [pageNumber]);
  const lastItem = useMemo(
    () => firstItem - 1 + (pageLength ?? 0),
    [firstItem, pageLength]
  );
  const text = useMemo(
    () => getCopy('SEARCH_RESULTS_COUNT', { firstItem, lastItem, totalCount }),
    [firstItem, getCopy, lastItem, totalCount]
  );
  const screenReaderText = useMemo(
    () =>
      getCopy('SEARCH_RESULTS_SCREEN_READER_COUNT', {
        firstItem,
        lastItem,
        totalCount,
      }),
    [firstItem, getCopy, lastItem, totalCount]
  );

  if (totalCount === 0) return null;
  return (
    <>
      <ScreenReaderText aria-atomic="true" aria-live="assertive">
        {screenReaderText}
      </ScreenReaderText>
      <Typography className={classes.count} aria-hidden>
        {text}
      </Typography>
    </>
  );
};

export default SearchResultsCount;
