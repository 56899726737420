import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { SEARCH_PARAMS } from '../../utils/constants/QueryParamWhitelists';
import { BASE_SEARCH_PATHNAME, DEFAULT_REDIRECT } from './constants';
import { isValidSearchRedirect, mapToQueryParam } from './helpers';

const SearchRedirect = ({ children }) => {
  const { totalPages } = useSelector((state) => state.venues);
  const { currentSessionId } = useSelector((state) => state.search);

  const getQueryParam = useCallback((search, key) => {
    if (!search) return null;

    const searchParamMap = new URLSearchParams(search);

    return searchParamMap.get(key);
  }, []);

  const render = useCallback(
    ({ location }) => {
      const pageQueryParam = +getQueryParam(
        location.search,
        SEARCH_PARAMS.PAGE_NUMBER
      );
      const sessionId = getQueryParam(
        location.search,
        SEARCH_PARAMS.SESSION_ID
      );
      const redirectTo = isValidSearchRedirect(location.state?.redirectTo)
        ? location.state.redirectTo
        : DEFAULT_REDIRECT;

      if (sessionId !== currentSessionId) return <Redirect to={redirectTo} />;
      if (!!pageQueryParam && pageQueryParam > totalPages) {
        return (
          <Redirect
            to={{
              pathname: BASE_SEARCH_PATHNAME,
              search: mapToQueryParam({ sessionId: currentSessionId }),
              state: { redirectTo },
            }}
          />
        );
      }

      return children;
    },
    [currentSessionId, children, getQueryParam, totalPages]
  );

  return <Route path={BASE_SEARCH_PATHNAME} render={render} />;
};

export default SearchRedirect;
