
import { put, takeLatest, select } from 'redux-saga/effects';

import { getHomePage } from '../../graphql/customQueries';

import * as ACTIONS from './HomePageAction';
import * as TYPES from './HomePageTypes';
import { callGraphqlWithToken } from '../helpers';

export function* getHomePageRequestHandler(data) {
    const { homePage } = yield select((state) => state);
    // If forceCall is enabled or the state of the homePage is null. The saga would call the DiningPortalHomePageService
    if (data.payload.forceCall || homePage.homePage === null) {
        try {
            const result = yield callGraphqlWithToken({
                query: getHomePage,
                variables: { marketId: data.payload.marketId }
            });
            yield put(ACTIONS.actionGetHomePageSuccess(result.data.getHomePage));
    
        } catch (e) {
            console.error(e);
            yield put(ACTIONS.actionGetHomePageFail(e));
        }
    } else {
        // Just return the current state
        yield put(ACTIONS.actionGetHomePageSuccess(homePage.homePage)); 
    }
}


export default function* homePageSaga() {
    yield takeLatest(TYPES.GET_HOME_PAGE_REQUEST, getHomePageRequestHandler)
}