import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../theme';
import { CuratedPicks, Events, Hero, SignatureCollection } from './sections';
import TableBookingModal, {
  STEPS,
} from '../../components/spoon/TableBookingModal';
import CommonLoading from '../common/CommonLoading';
import { useHomepageData } from '../../utils/useHomepageData';
import { useSelector } from 'react-redux';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import { useHistory } from 'react-router';
import { useAuth } from '../../utils/useAuth';

export const Home = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { user } = useAuth();
  const { stepUp, draftReservation } = useSelector(
    (state) => state.reservations
  );
  const [stepOverride, setStepOverride] = useState(STEPS.CHOOSE_DATE);

  const shouldOnboard = useOnboardingRedirect();

  // useEffect to redirect user to reserve flow after step up
  useEffect(() => {
    if (stepUp?.shouldRedirect) {
      if (stepUp?.isModal) {
        setStepOverride(STEPS.CONFIRM_RESERVATION);
        openBookingModal(draftReservation?.venue);
      } else {
        history.push('/reserve');
      }
    }
  }, [
    history,
    stepUp?.shouldRedirect,
    stepUp?.isModal,
    draftReservation?.venue,
  ]);

  const { data: homepageData, isLoading: homepageDataIsLoading } =
    useHomepageData();

  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [bookingModalVenue, setBookingModalVenue] = useState(null);
  const openBookingModal = (venue) => {
    setBookingModalVenue(venue);
    setBookingModalOpen(true);
  };

  const isLoading =
    homepageDataIsLoading !== false || !user.session.isAuthenticated;

  return (
    <>
      <CommonLoading active={isLoading || shouldOnboard} embedded />
      {!isLoading && !shouldOnboard && (
        <>
          <Hero
            venues={homepageData.hero}
            openBookingModal={openBookingModal}
          />
          {homepageData.signatureVenue && (
            <SignatureCollection
              venue={homepageData.signatureVenue}
              openBookingModal={openBookingModal}
            />
          )}
          {homepageData?.events?.length > 0 && <Events events={homepageData.events} />}
          {homepageData.curatedVenues && (
            <CuratedPicks curatedVenues={homepageData.curatedVenues} />
          )}
          <TableBookingModal
            open={bookingModalOpen}
            onClose={() => setBookingModalOpen(false)}
            venue={bookingModalVenue}
            isDrawer={isMobile}
            stepOverride={stepOverride}
          />
        </>
      )}
    </>
  );
};

Home.propTypes = {};

export default Home;
