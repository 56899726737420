import React from 'react';
import {
  InputAdornment,
  InputBase,
  makeStyles,
  NativeSelect,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMarkets } from '../../../utils/useMarkets';
import { HOME_SELECT_CITY } from '../../../assets/copy';
import { useDispatch } from 'react-redux';
import { actionGetMarketRequest } from '../../../store/Markets/MarketsAction';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { isSafari } from 'react-device-detect';
import { ReactComponent as MapPinIcon } from '../../../assets/icons/grv-map-pin.svg';

const useStyles = makeStyles((theme) => ({
  root: {},
  inputSelect: {
    padding: `${theme.spacing(0.5, 1, 0.5, 3.75)} !important`,
    ...focusVisible(),
    letterSpacing: isSafari ? '-1px' : 'normal',
    '&:focus': {
      backgroundColor: theme.palette.digitalGray[10],
    },
    ...theme.typography.small2Semibold,
    fontSize: 12,
    color: theme.palette.interactionBlue[50],
    backgroundColor: 'white',
    [theme.breakpoints.up('xxl')]: {
      fontSize: 14,
    },
    height: 24,
    borderRadius: '2px !important',
  },
  inputAdornment: {
    marginRight: theme.spacing(0.5),
    position: 'absolute',
    left: 5,
    zIndex: 2,
  },
  inputBase: {
    width: 'auto',
  },
}));

export const MarketSelector = ({
  classes: classesOverride,
  onMarketChange,
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { markets } = useMarkets();
  const dispatch = useDispatch();

  const handleSelect = (event) => {
    const id = event.target.value;
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_id: event.currentTarget.id,
      ui_interaction_element_name: 'select-city',
      ui_interaction_element_type: 'Dropdown',
      ui_interaction_element_description: id,
    });
    dispatch(actionGetMarketRequest({ id }));
    onMarketChange?.(event);
  };

  return (
    <div className={classes.root}>
      <NativeSelect
        aria-label={HOME_SELECT_CITY}
        onChange={handleSelect}
        IconComponent={() => null}
        variant="outlined"
        value={markets.market.id}
        classes={{
          root: classes.inputSelect,
        }}
        input={
          <InputBase
            startAdornment={
              <InputAdornment
                position="start"
                classes={{ root: classes.inputAdornment }}>
                <MapPinIcon />
              </InputAdornment>
            }
            classes={{ input: classes.inputBase }}
          />
        }
        inputProps={{ 'aria-label': HOME_SELECT_CITY }}>
        {markets.list.map((market) => (
          <option key={market.id} value={market.id}>
            {market.name}
          </option>
        ))}
      </NativeSelect>
    </div>
  );
};

MarketSelector.propTypes = {
  classes: PropTypes.object,
  onMarketChange: PropTypes.func,
};

export default MarketSelector;
