import { put, takeLatest } from 'redux-saga/effects';
import { listAllergys } from '../../graphql/queries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './AllergiesAction';
import * as TYPES from './AllergiesTypes';

export function* allergyListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listAllergys,
      variables: { filter },
    });
    yield put(ACTIONS.actionAllergyListSuccess(result.data.listAllergys.items));
  } catch (error) {
    console.error(error);
    yield dispatchError(error.message);
    yield put(ACTIONS.actionAllergyListFail(error));
  }
}

export default function* allergysSaga() {
  yield takeLatest(TYPES.ALLERGY_LIST_REQUEST, allergyListRequestHandler);
}
