import React, { useCallback, useMemo } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  EDIT_PROFILE_SPECIAL_DATES_TITLE,
  PROFILE_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL,
  PROFILE_SPECIAL_DATES_USER_ANNIVERSARY_LABEL,
  PROFILE_SPECIAL_DATES_USER_BIRTHDAY_LABEL,
  PROFILE_CONFIRM_SPECIAL_DATES,
} from '../../assets/copy';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import EditSaveButton from '../../components/core/EditSaveButton';
import {
  SPECIAL_DATE_TYPES,
  useSaveUserCallback,
  useSpecialDate,
} from '../../components/profile/helpers';
import SpecialDateInput from '../../components/profile/SpecialDateInput';
import Typography from '../../components/overrides/Typography';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      margin: 0,
    },
  },
  inputContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      [theme.breakpoints.up('xl')]: {
        paddingBottom: theme.spacing(3),
      },
    },
  },
  errorColor: {
    color: theme.palette.red[50],
    '& > input': {
      borderColor: theme.palette.red[50],
      '&:active, &:focus': {
        borderColor: theme.palette.red[50],
      },
    },
  },
  input: {
    '& > input': {
      color: theme.palette.digitalGray[60],
      '&:active, &:focus': {
        color: theme.palette.secondary[100],
      },
    },
  },
  saveButton: {
    '& button': {
      ...focusVisible(),
    },
  },
}));

const SpecialDatePage = ({ goBack, user }) => {
  const classes = useStyles();

  const {
    dateError: anniversaryError,
    changeDate: changeAnniversary,
    currentDate: currentAnniversary,
    isDateUpdated: isAnniversaryUpdated,
    setDateError: setAnniversaryError,
    storedDate: anniversary,
  } = useSpecialDate({ type: SPECIAL_DATE_TYPES.ANNIVERSARY, user });

  const {
    dateError: partnerBirthdayError,
    changeDate: changePartnerBirthday,
    currentDate: currentPartnerBirthday,
    isDateUpdated: isPartnerBirthdayUpdated,
    setDateError: setPartnerBirthdayError,
    storedDate: partnerBirthday,
  } = useSpecialDate({ type: SPECIAL_DATE_TYPES.PARTNER_BIRTHDAY, user });

  const {
    dateError: userBirthdayError,
    changeDate: changeUserBirthday,
    currentDate: currentUserBirthday,
    isDateUpdated: isUserBirthdayUpdated,
    setDateError: setUserBirthdayError,
    storedDate: userBirthday,
  } = useSpecialDate({ type: SPECIAL_DATE_TYPES.USER_BIRTHDAY, user });

  const hasError = useMemo(
    () => userBirthdayError || partnerBirthdayError || anniversaryError,
    [userBirthdayError, partnerBirthdayError, anniversaryError]
  );
  const isDateUpdated = useMemo(
    () =>
      isUserBirthdayUpdated || isPartnerBirthdayUpdated || isAnniversaryUpdated,
    [isUserBirthdayUpdated, isPartnerBirthdayUpdated, isAnniversaryUpdated]
  );

  const saveUser = useSaveUserCallback(user);

  const saveSpecialDates = useCallback(() => {
    const updatedValue = {
      [SPECIAL_DATE_TYPES.ANNIVERSARY]: currentAnniversary || null,
      [SPECIAL_DATE_TYPES.PARTNER_BIRTHDAY]: currentPartnerBirthday || null,
      [SPECIAL_DATE_TYPES.USER_BIRTHDAY]: currentUserBirthday || null,
    };

    saveUser(updatedValue);
    goBack();
  }, [
    currentAnniversary,
    currentPartnerBirthday,
    currentUserBirthday,
    goBack,
    saveUser,
  ]);

  return (
    <BackButtonLayout back={goBack} title={EDIT_PROFILE_SPECIAL_DATES_TITLE}>
      <Container maxWidth="sm" className={classes.container}>
        <Typography className={classes.infoStyle} variant="medium1Normal">
          {PROFILE_CONFIRM_SPECIAL_DATES}
        </Typography>
        <Box className={classes.inputContainer}>
          <SpecialDateInput
            label={PROFILE_SPECIAL_DATES_USER_BIRTHDAY_LABEL}
            initialValue={userBirthday}
            onChange={changeUserBirthday}
            inputClass={classes.input}
            errorClass={classes.errorColor}
            setError={setUserBirthdayError}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <SpecialDateInput
            label={PROFILE_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL}
            initialValue={partnerBirthday}
            onChange={changePartnerBirthday}
            inputClass={classes.input}
            errorClass={classes.errorColor}
            setError={setPartnerBirthdayError}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <SpecialDateInput
            label={PROFILE_SPECIAL_DATES_USER_ANNIVERSARY_LABEL}
            initialValue={anniversary}
            onChange={changeAnniversary}
            inputClass={classes.input}
            errorClass={classes.errorColor}
            setError={setAnniversaryError}
          />
        </Box>
      </Container>
      <Grid item xs={12} className={classes.saveButton}>
        <EditSaveButton
          onSave={saveSpecialDates}
          disabled={hasError || !isDateUpdated}
        />
      </Grid>
    </BackButtonLayout>
  );
};

export default SpecialDatePage;
