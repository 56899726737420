import { makeStyles } from '@material-ui/core/styles';
import { BACK_BUTTON_LABEL, CLOSE_BUTTON_DESCRIPTION } from '../../assets/copy';
import { useViewport } from '../../utils/useViewport';
import CloseButton from '../core/CloseButton';

const useStyles = makeStyles((theme) => ({
  close: {
    float: 'right',
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));
const widthBreakpoint = 960;

const CloseButtonForm = ({ handleClose }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const dimensions = width > widthBreakpoint ? 21 : 16;
  return (
    <CloseButton
      className={classes.close}
      close={handleClose}
      description={CLOSE_BUTTON_DESCRIPTION}
      fill="#0D74AF"
      height={dimensions}
      label={BACK_BUTTON_LABEL}
      role="link"
      variant="svg"
      width={dimensions}
    />
  );
};

export default CloseButtonForm;
