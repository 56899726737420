import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => {
    if (!search) return {};

    const searchParamMap = new URLSearchParams(search);

    return [...searchParamMap.keys()].reduce(
      (params, key) => ({
        ...params,
        [key]: searchParamMap.get(key),
      }),
      {}
    );
  }, [search]);

  return queryParams;
};
