import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  BOOKING_POLICY_LABEL,
  DINING_OPTION_TITLE,
  RESERVATION_CREATE_TITLE,
  RESERVATION_UPCOMING_TITLE,
  PAYMENT_POLICY_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_SECTION_LABEL,
  PROGRESS_BAR_LABEL,
} from '../../assets/copy';
import { PAYMENT_RULE_TYPE } from './helpers/paymentAvailabilityTypes';
import ShowAlert from './Alert';
import ContactDetail from '../../components/profile/ContactDetail';
import Divider from '../../components/overrides/Divider';
import FormSection from './FormSection';
import { actionSetAnimationSlideLeft } from '../../store/Animations/AnimationsAction';
import Additional from './Additional';
import { Box, CircularProgress, Container } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import PaymentMethods from '../profile/PaymentMethods';
import StripePaymentForm from '../../components/core/Stripe/StripePaymentForm';
import Policy from '../../components/core/Policy';
import { makeStyles } from '@material-ui/core/styles';
import { PAYMENT_METHOD_COMPONENT_TYPES } from '../profile/paymentComponentTypes';
import ReservationCharges from './ReservationCharges';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import PhoneNumberInfo from '../../components/profile/PhoneNumberInfo';
import { RESERVATION_FORM_TYPES } from '../../utils/constants/ReservationFormTypes';
import { useEffect, useState } from 'react';
import { formatPhone } from '../../utils/formatPhone';
import PhoneNumberAlert from './PhoneNumberAlert';

const useStyles = makeStyles((theme) => ({
  diningOptionsContainer: {
    padding: `${theme.spacing(3)}`,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  diningOptionsName: {
    marginTop: theme.spacing(1),
  },
  policyContainer: {
    padding: `${theme.spacing(3)}`,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  paymentPolicy: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),
  },
  bookingPolicy: {
    width: '100%',
    padding: theme.spacing(0),
  },
  paymentMethods: {
    padding: theme.spacing(3, 3),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '768px',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  spacing: {
    marginTop: theme.spacing(2),
  },
}));

const Payment = ({
  paymentRule,
  shouldShowStripeForm,
  stripeAlertMessage,
  stripeProperties,
  onEditPayment,
}) => {
  const classes = useStyles();

  if (!paymentRule) {
    return null;
  }

  if (shouldShowStripeForm) {
    return (
      <>
        <StripePaymentForm
          stripeAlertMessage={stripeAlertMessage}
          {...stripeProperties}
        />
      </>
    );
  }

  return (
    <PaymentMethods
      className={classes.paymentMethods}
      type={PAYMENT_METHOD_COMPONENT_TYPES.RESERVE}
      onEdit={onEditPayment}
    />
  );
};

const ReservationDetails = ({
  type,
  reservation,
  paymentRule,
  stripeProperties,
  reservationChargesProperties,
  updateNotes,
  updateSelectOccasions,
  showErrorAlert = false,
  diningOptionsSlotName,
  paymentPolicy,
  bookingPolicy,
  showInfoBlock,
  onEditPayment,
  shouldShowStripeForm,
  stripeAlertMessage,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { paymentLoading } = useSelector((state) => state?.availability);
  const { user } = useSelector((state) => state?.user);
  const [formPhoneNumber, setFormPhoneNumber] = useState('');
  const contactProps = {
    name: `${reservation.firstName} ${reservation.lastName}`,
    phone: formatPhone(reservation.phoneNumber),
    email: reservation.emailAddress,
    editable: type === 'Complete',
  };

  const dietaryProps = {
    title: PROFILE_DIETARY_PREFERENCES_SECTION_LABEL,
    subTitle1: PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
    subTitle2: PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
    data1: reservation.diets?.map((item) => item.name).join(', '),
    data2: reservation.allergies?.map((item) => item.name).join(', '),
  };

  const additionalProps = {
    type: type,
    notes: reservation.notes,
    selectOccasions: reservation.specialOccasions,
    updateNotes: updateNotes,
    updateSelectOccasions: updateSelectOccasions,
    editable: false,
    showInfoBlock,
  };

  const isBookingOrPaymentPolicyNotNull =
    ((paymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT ||
      paymentRule === PAYMENT_RULE_TYPE.SAVE_FOR_LATER) &&
      paymentPolicy) ||
    bookingPolicy;

  const getPageTitle = () => {
    const titleText =
      type === 'Upcoming'
        ? RESERVATION_UPCOMING_TITLE
        : RESERVATION_CREATE_TITLE;
    return (
      <Box role="status">
        <ScreenReaderText>{titleText}</ScreenReaderText>
      </Box>
    );
  };

  const renderAlert = () => {
    return showErrorAlert ? (
      <Box>
        <ShowAlert />
        <Box className={classes.spacing}>
          <PhoneNumberAlert />
        </Box>
      </Box>
    ) : (
      <PhoneNumberAlert />
    );
  };

  useEffect(() => {
    setFormPhoneNumber(
      type === RESERVATION_FORM_TYPES.COMPLETE
        ? user.phoneNumber
        : reservation.phoneNumber
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {renderAlert()}
      <ContactDetail {...contactProps} phone={formPhoneNumber} />
      <PhoneNumberInfo
        isPhoneNumberFieldDisplayed={
          type === RESERVATION_FORM_TYPES.COMPLETE && user.phoneNumber === ''
        }
        phone={contactProps.phone}
      />
      <Divider />
      <FormSection
        {...dietaryProps}
        handleEdit={() => {
          dispatch(actionSetAnimationSlideLeft(true));
          history.push('/reserve/diet');
        }}
      />
      <Divider />
      <Additional {...additionalProps} />
      {diningOptionsSlotName && (
        <>
          <Divider />
          <Container className={classes.diningOptionsContainer}>
            <Box
              display="flex"
              flexDirection="column"
              aria-label={DINING_OPTION_TITLE}>
              <Typography component="h2" variant="medium1Semibold">
                {DINING_OPTION_TITLE}
              </Typography>
              <Typography
                variant="medium1Normal"
                className={classes.diningOptionsName}
                gutterBottom>
                {diningOptionsSlotName}
              </Typography>
            </Box>
          </Container>
        </>
      )}
      {type === 'Complete' && (
        <>
          {paymentLoading ? (
            <Box align={'center'} mt={2} mb={2} aria-live="assertive">
              <CircularProgress
                align={'center'}
                role="progressbar"
                aria-label={PROGRESS_BAR_LABEL}
                title="Loading"
                aria-valuenow={0}
              />
            </Box>
          ) : (
            <>
              <ReservationCharges {...reservationChargesProperties} />
              <Payment
                paymentRule={paymentRule}
                shouldShowStripeForm={shouldShowStripeForm}
                stripeAlertMessage={stripeAlertMessage}
                stripeProperties={stripeProperties}
                onEditPayment={onEditPayment}
              />
            </>
          )}
          <Box role="status">{!paymentLoading && getPageTitle()}</Box>
        </>
      )}
      <Divider />
      {isBookingOrPaymentPolicyNotNull && (
        <>
          <Container className={classes.policyContainer}>
            {reservation?.requiresCreditCard &&
              paymentPolicy &&
              (paymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT ||
                paymentRule === PAYMENT_RULE_TYPE.SAVE_FOR_LATER) && (
                <Policy
                  header={PAYMENT_POLICY_LABEL}
                  venuePolicy={paymentPolicy}
                  className={classes.paymentPolicy}
                />
              )}
            {bookingPolicy && (
              <Policy
                header={BOOKING_POLICY_LABEL}
                venuePolicy={bookingPolicy}
                className={classes.bookingPolicy}
              />
            )}
          </Container>
          <Divider />
        </>
      )}
    </>
  );
};

export default ReservationDetails;
