import { useEffect } from 'react';
import { useAuth } from '../../utils/useAuth';
import { persistor } from '../../store';

const Signout = () => {
  const { user, signout } = useAuth();

  useEffect(() => {
    signout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.session.isAuthenticated) {
      persistor.purge().then(() => {
        window.location.href = process.env.REACT_APP_C1_SIGNOUT_REDIRECT_URL;
      });
    }
  }, [user.session.isAuthenticated]);

  return null;
};

export default Signout;
