import React from 'react';
import { Box } from '@material-ui/core';
import GravityButton from '../gravity/button';

const DirectionalButton = ({
  classContainer,
  text,
  onClickHandler,
  ariaLabel = '',
  linkTextVariant = 'medium1Semibold',
  direction = 'right',
  buttonClassName,
  variant = 'text',
  customHexColor,
  isRippleDisabled = false,
  focusable = true,
  ...restProps
}) => {
  return (
    <>
      <Box className={classContainer}>
        <GravityButton
          variant={variant}
          directional={direction}
          compact={true}
          onClick={onClickHandler}
          aria-label={ariaLabel}
          buttonClassName={buttonClassName}
          customHexColor={customHexColor}
          disableRipple={isRippleDisabled}
          linkTextVariant={linkTextVariant}
          tabIndex={focusable ? 0 : -1}
          {...restProps}>
          {text}
        </GravityButton>
      </Box>
    </>
  );
};

export default DirectionalButton;
