import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ChefHeader from './ChefHeader';

const useStyles = makeStyles((theme) => ({
  content: {
    boxSizing: 'border-box',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 5, 6),
    },
  },
  desktopContainer: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: theme.spacing(0, 5),
    boxSizing: 'border-box',
  },
  grid: ({ index }) => ({
    display: 'grid',
    // Control direction of chef pic and description
    gridTemplateAreas: index % 2 === 0 ? `"text picture"` : `"picture text"`,
  }),
  twoColumnGrid: {
    gap: theme.spacing(7),
    gridTemplateColumns: `repeat(2, 1fr)`,
  },
  chefPicContainer: {
    gridArea: 'picture',
  },
  chefPic: {
    backgroundImage: ({ item }) => {
      return item?.images?.[0] && `url(${item.images[0]})`;
    },
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'inline-block',
    width: '100%',
    height: '0',
    paddingBottom: 'calc(100%)',
  },
  chefContainer: {
    gridArea: 'text',
    justifyContent: 'center',
    flexWrap: 'unset',
    flexDirection: 'column',
  },
}));

const DesktopChefHeader = ({ item, index }) => {
  const classes = useStyles({ item, index });
  const gridClasses = [classes.grid, classes.twoColumnGrid];

  return (
    <Box className={classes.desktopContainer}>
      <Box className={clsx(gridClasses)}>
        <Grid className={classes.chefPicContainer}>
          <div className={classes.chefPic}></div>
        </Grid>
        <Grid container className={classes.chefContainer}>
          <ChefHeader
            subtitle={item.subtitle}
            chefName={item.chefName}
            description={item.description}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default DesktopChefHeader;
