import * as types from './AppDataTypes';

//SpeicalOccasion list
export const actionSpecialOccasionsListRequest = () => ({
  type: types.SPECIAL_OCCASIONS_LIST_REQUEST,
});

export const actionSpecialOccasionsListSuccess = (payload) => ({
  type: types.SPECIAL_OCCASIONS_LIST_SUCCESS,
  payload,
});

export const actionSpecialOccasionsListFail = (payload) => ({
  type: types.SPECIAL_OCCASIONS_LIST_FAIL,
  payload,
});

// NavigationMenu Visibility
export const actionSetNavigationMenuVisible = (payload) => ({
  type: types.SET_NAVIGATION_MENU_VISIBLE,
  payload,
});
