import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { actionSetCurrentFiltersFromCache } from '../../store/Filter/FilterAction';
import { actionSetCurrentSearchSessionIdFromCache } from '../../store/Search/SearchAction';

const SearchResultWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Blocks user from going back until filters, session ID are set from cache
  useEffect(() => {
    return history.block((_, action) => {
      if (action === 'POP') {
        dispatch(actionSetCurrentFiltersFromCache());
        dispatch(actionSetCurrentSearchSessionIdFromCache());
      }

      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return <>{children}</>;
};

export default SearchResultWrapper;
