import * as React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography as MuiTypography } from '@material-ui/core';
import typography from '../../theme/typography';

const useStyles = makeStyles((theme) => ({
  root: ({ variant }) => ({
    ...theme.typography[variant],
  }),
}));

const colorMap = {
  primary: 'textPrimary',
  secondary: 'textSecondary',
};

const Typography = ({
  variant = 'medium1Normal',
  color = 'primary',
  className,
  children,
  redact = false,
  maxWidth,
  ...otherProps
}) => {
  const classes = useStyles({ variant, color });

  if (redact) {
    otherProps['data-heap-redact-text'] = 'true';
  }

  return (
    <MuiTypography
      className={clsx(classes.root, className)}
      variant={typography.hasOwnProperty(variant) ? 'body1' : variant}
      color={colorMap[color] || color}
      {...otherProps}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
