import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '../overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import {
  HOME_VIEW_ALL_ALT_TEXT_PREFIX,
  HOME_VIEW_ALL_BUTTON_TEXT,
  LEARN_MORE_BUTTON_TEXT,
  ABOUT_THE_TEXT,
  SIGNATURE_COLLECTION_TEXT,
} from '../../assets/copy';
import DirectionalButton from '../buttons/DirectionalButton';
import palette from '../../theme/palette';
import { enterKeyPressHandler } from '../../utils/keyPresses';
import { focusVisible } from '../../utils/ada/contrastStyles';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 5),
      marginBottom: theme.spacing(4),
    },
  },
  title: {
    color: ({ isHighlighted }) =>
      isHighlighted
        ? theme.palette.secondary.contrastText
        : theme.palette.secondary.main,
  },
  description: {
    marginTop: theme.spacing(1),
    color: ({ isHighlighted }) =>
      isHighlighted
        ? theme.palette.secondary.contrastText
        : theme.palette.secondary.main,
  },
  viewAll: {
    display: 'flex',
    cursor: 'pointer',
    '& button': {
      padding: theme.spacing(0),
      ...focusVisible(),
    },
  },
  customLink: {
    color: ({ isHighlighted }) =>
      isHighlighted
        ? theme.palette.secondary.contrastText
        : theme.palette.secondary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingLeft: theme.spacing(0.5),
  },
}));

const widthBreakpoint = 960;

const GalleryHeader = ({
  category,
  title,
  description,
  onClick,
  showHeaderLink = true,
  customLink = null,
  label,
  isCompact = false,
  isEventCarousel = false,
  isEventsDetailPage = false,
}) => {
  const classes = useStyles({
    isHighlighted: category?.category?.isHighlighted,
  });

  const { width } = useViewport();
  const contrastText = category?.category?.isHighlighted
    ? palette.interactionBlue[30]
    : null;

  const getTypographyVariant = () => {
    if (isEventsDetailPage) {
      return width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold';
    } else {
      return width > widthBreakpoint ? 'medium4Normal' : 'medium2Semibold';
    }
  };

  const handleViewAllHomeButtonClick = (event) => {
    onClick(event);
    trackClick(event, "homepage-view-all", "Button");
  }

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Typography
          variant={getTypographyVariant()}
          className={classes.title}
          component="h2">
          {title}
        </Typography>
        {showHeaderLink && !isEventsDetailPage && (
          <DirectionalButton
            classContainer={classes.viewAll}
            role="link"
            text={HOME_VIEW_ALL_BUTTON_TEXT}
            onClickHandler={handleViewAllHomeButtonClick}
            aria-label={HOME_VIEW_ALL_ALT_TEXT_PREFIX + title}
            data-cy="homepage-view-all"
            customHexColor={contrastText}
          />
        )}
      </Grid>
      <Typography
        variant={width > widthBreakpoint ? 'medium1Normal' : 'small2Normal'}
        className={classes.description}>
        {description}
        {customLink?.name && (
          <Typography
            tabIndex={0}
            aria-label={`${LEARN_MORE_BUTTON_TEXT} ${ABOUT_THE_TEXT} ${SIGNATURE_COLLECTION_TEXT}`}
            title={label}
            role="button"
            variant={width > widthBreakpoint ? 'medium1Normal' : 'small2Normal'}
            onClick={customLink.action}
            onKeyPress={enterKeyPressHandler && customLink?.action}
            component="span"
            className={classes.customLink}>
            {customLink.name}
          </Typography>
        )}
      </Typography>
    </div>
  );
};

export default GalleryHeader;
