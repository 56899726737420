import { useContext, useEffect, useState } from 'react';
import { INITIAL_SCROLL_REF, ScrollContext } from './withScrollContext';

export const useScrollContextRef = () => {
  const [ref, setRef] = useState(INITIAL_SCROLL_REF);
  const scrollContextRef = useContext(ScrollContext);

  useEffect(
    () => setRef(scrollContextRef ?? INITIAL_SCROLL_REF),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollContextRef]
  );

  return ref;
};
