import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Typography from '../overrides/Typography';

import { formatMediumSpecialDate } from '../../utils/dateTime';
import {
  DATE_NONE_SELECTED,
  PROFILE_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL,
  PROFILE_SPECIAL_DATES_USER_ANNIVERSARY_LABEL,
  PROFILE_SPECIAL_DATES_USER_BIRTHDAY_LABEL,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  noText: {
    color: theme.palette.digitalGray[60],
  },
  label: {
    color: '#141414',
  },
  value: {
    color: '#141414',
  },
  container: {
    marginBottom: theme.spacing(2),
  },
}));

const SpecialDateInfo = ({
  userBirthday,
  partnerBirthday,
  userAnniversary,
}) => {
  // util
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.container}>
        <Grid>
          <Typography variant="small4Normal" className={classes.label}>
            {PROFILE_SPECIAL_DATES_USER_BIRTHDAY_LABEL}
          </Typography>
        </Grid>
        <Grid>
          {userBirthday ? (
            <Typography
              className={classes.value}
              variant="medium1Normal"
              redact={true}>
              {formatMediumSpecialDate(userBirthday)}
            </Typography>
          ) : (
            <Typography variant="medium1Normal" className={classes.noText}>
              {DATE_NONE_SELECTED}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <Grid>
          <Typography variant="small4Normal" className={classes.label}>
            {PROFILE_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL}
          </Typography>
        </Grid>
        <Grid>
          {partnerBirthday ? (
            <Typography
              className={classes.value}
              variant="medium1Normal"
              redact={true}>
              {formatMediumSpecialDate(partnerBirthday)}
            </Typography>
          ) : (
            <Typography variant="medium1Normal" className={classes.noText}>
              {DATE_NONE_SELECTED}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid>
          <Typography variant="small4Normal" className={classes.label}>
            {PROFILE_SPECIAL_DATES_USER_ANNIVERSARY_LABEL}
          </Typography>
        </Grid>
        <Grid>
          {userAnniversary ? (
            <Typography
              className={classes.value}
              variant="medium1Normal"
              redact={true}>
              {formatMediumSpecialDate(userAnniversary)}
            </Typography>
          ) : (
            <Typography variant="medium1Normal" className={classes.noText}>
              {DATE_NONE_SELECTED}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SpecialDateInfo;
