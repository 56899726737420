import * as types from './CategoriesTypes';

//collection list
export const actionCategoryListRequest = () => ({
  type: types.CATEGORY_LIST_REQUEST,
});

export const actionCategoryListSuccess = (payload) => ({
  type: types.CATEGORY_LIST_SUCCESS,
  payload,
});

export const actionCategoryListFail = (payload) => ({
  type: types.CATEGORY_LIST_FAIL,
  payload,
});
