import React from 'react';
import { makeStyles } from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import PageSection from '../../components/core/PageSection';
import CircularLoader from '../../components/core/Loader/CircularLoader';
import { useSelector } from 'react-redux';
import EventSectionContent from './EventSectionContent';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.error.main}`,
    maxWidth: 1120,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '95%',
    marginTop: theme.spacing(10.0125), //81px
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(7, 'auto', '0'), //56px
    },
  },
}));

const EventSection = () => {
  const classes = useStyles();
  const { getEventsStatus } = useSelector((state) => state.venues);
  return (
    <PageSection>
      {getEventsStatus?.loading ? <CircularLoader /> : <EventSectionContent />}
      <Divider className={classes.divider} />
    </PageSection>
  );
};

export default EventSection;
