import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import LeftSvg from '../../assets/icons/leftArrowCarousel.svg';
import RightSvg from '../../assets/icons/rightArrowCarousel.svg';
import Typography from '../overrides/Typography';
import {
  VIEW_NEXT_TEXT,
  VIEW_PREVIOUS_TEXT,
  VENUE_IMAGE_ALT_TEXT_PREFIX,
  TEXT_JOIN_OF,
  VENUE_PHOTOS_ALT_TEXT_PREFIX,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  carousel: {
    '& .carousel .carousel-status': {
      position: 'fixed',
      margin: 0,
      padding: 0,
      textAlign: 'center',
      width: '100%',
      zIndex: 1,
      top: theme.spacing(12),
      textShadow: 'none',
      display: 'flex',
      placeContent: 'center',
      [theme.breakpoints.up('xl')]: {
        margin: theme.spacing(3, 0),
      },
      [theme.breakpoints.up('md')]: {
        top: theme.spacing(4),
      },
    },
    [theme.breakpoints.up('xl')]: {
      width: '100vh',
      margin: 'auto',
    },
  },
  leftbutton: {
    position: 'absolute',
    zIndex: 99,
    top: 'calc(60% - 20px)',
    left: theme.spacing(2.25),
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    width: 56,
    height: 56,
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(4.25),
      height: 48,
    },
  },
  rightbutton: {
    position: 'absolute',
    zIndex: 99,
    top: 'calc(60% - 20px)',
    right: theme.spacing(2.5),
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    width: 56,
    height: 56,
    [theme.breakpoints.up('xl')]: {
      right: theme.spacing(4.25),
      height: 48,
    },
  },
  image: {
    width: 48,
    height: 48,
  },
  itemContainer: {
    height: theme.spacing(29.75),
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up('xl')]: {
      height: '85vh',
      marginTop: theme.spacing(4),
    },
  },
  item: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up('xl')]: {
      height: '85vh',
    },
  },
  hiddenStyles: {
    display: 'inline-block',
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}));

const VenueHeroImage = ({ images, showArrows, ...restProps }) => {
  const classes = useStyles();

  return (
    images && (
      <main>
        <Carousel
          className={classes.carousel}
          showThumbs={false}
          autoPlay={false}
          showIndicators={false}
          infiniteLoop={true}
          dynamicHeight={false}
          {...restProps}
          statusFormatter={(current, total) => (
            <div aria-live="polite">
              <Typography
                aria-hidden="true"
                variant="small2Normal"
                component="span">
                {current}/{total}
              </Typography>
              <Typography className={classes.hiddenStyles} aria-hidden="true">
                {VENUE_PHOTOS_ALT_TEXT_PREFIX + current + TEXT_JOIN_OF + total}
              </Typography>
            </div>
          )}
          renderArrowPrev={(onClickHandler) =>
            showArrows && (
              <button onClick={onClickHandler} className={classes.leftbutton}>
                <img
                  src={LeftSvg}
                  alt={VIEW_PREVIOUS_TEXT}
                  className={classes.image}
                />
              </button>
            )
          }
          renderArrowNext={(onClickHandler) =>
            showArrows && (
              <button onClick={onClickHandler} className={classes.rightbutton}>
                <img
                  src={RightSvg}
                  alt={VIEW_NEXT_TEXT}
                  className={classes.image}
                />
              </button>
            )
          }>
          {images.map((item, index) => (
            <Box className={classes.itemContainer} key={index}>
              <img
                alt={VENUE_IMAGE_ALT_TEXT_PREFIX + (index + 1)}
                src={item}
                className={classes.item}
                aria-hidden="true"
              />
            </Box>
          ))}
        </Carousel>
      </main>
    )
  );
};

export default VenueHeroImage;
