import React, { useCallback } from 'react';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  SEARCH_PAGINATION_DOTS,
  SEARCH_RESTAURANTS_ALT_TEXT,
} from '../../../assets/copy';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as GravitySearchIcon } from '../../../assets/icons/grv-search.svg';
import { actionSetSearchOverlayVisible } from '../../../store/Search/SearchAction';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { trackClick } from '../../../utils/useOneTag';
import { useFeatureFlags } from '../../../utils/useFeatureFlags';
import Typography from '../../overrides/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    ...focusVisible(),
  },
  searchBar: {
    textTransform: 'none',
    display: 'flex',
    gap: theme.spacing(1),
    width: '296px',
    height: '48px',
    boxSizing: 'border-box',
    padding: theme.spacing(1.5, 2),
    border: '1px solid',
    borderColor: theme.palette.secondary[65],
    borderRadius: '6px',
    cursor: 'pointer',
    '& .MuiButton-label': {
      gap: theme.spacing(1),
      justifyContent: 'flex-start',
    },
  },
  text: {
    color: theme.palette.secondary[65],
  },
  searchButton: {
    '& path': {
      fill: `${theme.palette.secondary[65]} !important`,
    },
  },
}));

export const SearchButton = ({ menuVisible = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleShowSearch = useCallback(
    (e) => {
      trackClick(e, 'app-bar-search', 'Button');
      dispatch(actionSetSearchOverlayVisible(true));
    },
    [dispatch]
  );
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();
  const ImgIcon = refreshLandingPageFlagIsEnabled
    ? GravitySearchIcon
    : SearchIcon;

  if (menuVisible) return null;

  return (
    <IconButton
      className={classes.root}
      onClick={handleShowSearch}
      disableFocusRipple
      aria-label={SEARCH_RESTAURANTS_ALT_TEXT}
      data-cy="app-bar-search">
      <ImgIcon
        alt={SEARCH_RESTAURANTS_ALT_TEXT}
        className={clsx({
          [classes.searchButton]: refreshLandingPageFlagIsEnabled,
        })}
      />
    </IconButton>
  );
};

export const SearchBarButton = ({ menuVisible = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleShowSearch = useCallback(
    (e) => {
      trackClick(e, 'app-bar-search', 'Button');
      dispatch(actionSetSearchOverlayVisible(true));
    },
    [dispatch]
  );

  if (menuVisible) return null;

  return (
    <Button
      disableRipple
      className={clsx(classes.root, classes.searchBar)}
      onClick={handleShowSearch}
      aria-label={SEARCH_RESTAURANTS_ALT_TEXT}>
      <GravitySearchIcon alt={SEARCH_RESTAURANTS_ALT_TEXT} />
      <Typography variant="medium1Normal" className={classes.text}>
        {SEARCH_RESTAURANTS_ALT_TEXT}
        {SEARCH_PAGINATION_DOTS}
      </Typography>
    </Button>
  );
};

export default SearchButton;
