import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 'auto',
    marginLeft: 0,
  },
  containerField: ({ color, size }) => ({
    padding: size === 'normal' ? theme.spacing(2) : theme.spacing(2, 3),
    borderRadius: '0 0 16px 0',
    backgroundColor:
      color === 'light'
        ? theme.palette.blue[70]
        : theme.palette.background.white,
  }),
  text: ({ color, size }) => ({
    textTransform: 'uppercase',
    letterSpacing: size === 'normal' ? '.5px' : '.25px',
    fontSize: size === 'normal' ? '12px' : '16px',
    lineHeight: size === 'normal' ? '16px' : '24px',
    color:
      color === 'light'
        ? theme.palette.spoon.white[100]
        : theme.palette.secondary.main,
  }),
  icon: {
    marginRight: theme.spacing(2),
  },
}));

export const SectionTitle = ({
  text,
  icon,
  withField,
  color = 'light',
  size = 'normal',
}) => {
  const classes = useStyles({ color, size });

  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.containerField]: withField,
      })}>
      {icon && <span className={classes.icon}>{icon}</span>}
      <Typography variant="tiny1Semibold" className={classes.text}>
        {text}
      </Typography>
    </div>
  );
};

export default SectionTitle;

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  withField: PropTypes.bool,
  color: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['normal', 'large']),
};
