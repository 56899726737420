import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { SEARCH_PARAMS } from '../../../utils/constants/QueryParamWhitelists';
import { BASE_SEARCH_PATHNAME, DEFAULT_REDIRECT } from '../constants';
import { useSearchParams } from './useSearchParams';

export const isValidSearchRedirect = (redirectTo) =>
  !!redirectTo &&
  ((typeof redirectTo === 'string' && redirectTo !== BASE_SEARCH_PATHNAME) ||
    (redirectTo.pathname != null &&
      redirectTo.pathname !== BASE_SEARCH_PATHNAME));

export const mapToQueryParam = ({ sessionId, pageNumber = 0 }) => {
  const page = pageNumber ? `&${SEARCH_PARAMS.PAGE_NUMBER}=${pageNumber}` : '';

  return `?${SEARCH_PARAMS.SESSION_ID}=${sessionId}${page}`;
};

export const useSearchLink = ({ pageNumber = 0 }) => {
  const location = useLocation();
  const { sessionId } = useSearchParams();
  const search = useMemo(
    () => mapToQueryParam({ sessionId, pageNumber }),
    [sessionId, pageNumber]
  );
  const redirectTo = useMemo(
    () =>
      isValidSearchRedirect(location.state?.redirectTo)
        ? location.state.redirectTo
        : DEFAULT_REDIRECT,
    [location.state]
  );
  const link = useMemo(
    () => ({
      pathname: BASE_SEARCH_PATHNAME,
      search,
      state: { redirectTo },
    }),
    [search, redirectTo]
  );

  return link;
};
