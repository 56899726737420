import React from 'react';
import { Box } from '@material-ui/core';
import { useViewport } from '../../utils/useViewport';
import LargeCard from '../core/Card/LargeCard';
import SmallCard from '../core/Card/SmallCard';

const widthBreakpoint = 960;

const ReservationCard = ({ type, reservation, ...reservationCardProps }) => {
  const { width } = useViewport();

  const props = {
    ...reservationCardProps,
    venue: reservation.venue,
    reservation: reservation,
  };

  const styleProp = width <= widthBreakpoint ? { px: 3 } : {};

  return (
    <>
      {type === 'Confirmed' && (
        <Box px={3}>
          <LargeCard type="Fill" {...props} />
        </Box>
      )}
      {type === 'Complete' && (
        <Box {...styleProp}>
          {width > widthBreakpoint ? (
            <LargeCard {...props} />
          ) : (
            <SmallCard {...props} />
          )}
        </Box>
      )}
      {type !== 'Confirmed' && type !== 'Complete' && (
        <Box px={3}>
          {width > widthBreakpoint ? (
            <LargeCard {...props} />
          ) : (
            <SmallCard {...props} />
          )}
        </Box>
      )}
    </>
  );
};

export default ReservationCard;
