import React, { useEffect, useMemo, useState } from 'react';
import Lightbox from '@cof/dining-portal-image-lightbox';
import '@cof/dining-portal-image-lightbox/style.css';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CLOSE_BUTTON_DESCRIPTION } from '../../../assets/copy';
import ChevronLeft from '../../../assets/icons/ChevronLeft.svg';
import ChevronRight from '../../../assets/icons/ChevronRight.svg';
import LeftArrow from '../../../assets/icons/leftArrowCarousel.svg';
import RightArrow from '../../../assets/icons/rightArrowCarousel.svg';
import palette from '../../../theme/palette';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';
import CloseButton from '../../core/CloseButton';
import DirectionalButton from '../../buttons/DirectionalButton';
import VenueLightBoxHeader from './VenueLightBoxHeader';

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'highlight auto',
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto',
    },
  },
  arrowButton: {
    padding: 0,
    height: 32,
    width: 32,
    minWidth: 32,
    borderRadius: '50%',
    '&:hover': {
      background: theme.palette.background.grayLight,
      opacity: 0.93,
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'highlight auto',
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto',
    },
  },
}));

const VenueLightBox = ({
  images = [],
  onClose,
  open = false,
  indexOnOpen = 0,
  thumbs = [],
  venueName,
}) => {
  const classes = useStyles();
  const { width } = useViewport();
  const [index, setIndex] = useState(indexOnOpen);
  const isMobile = width < WIDTH_BREAKPOINT;
  const heightOffset = useMemo(() => (isMobile ? 0 : 170), [isMobile]);
  const indexOffset = useMemo(() => index + 1, [index]);
  const lengthOffset = images.length - 1;
  const widthOffset = useMemo(() => (isMobile ? 0 : 150), [isMobile]);

  useEffect(() => setIndex(indexOnOpen), [indexOnOpen]);

  const moveNext = () => {
    setIndex(indexOffset % images.length);
  };

  const movePrev = () => {
    setIndex((index + lengthOffset) % images.length);
  };

  const moveIndex = (selected) => {
    setIndex(selected.index);
  };

  if (!open) return null;

  const header = () => (
    <VenueLightBoxHeader
      title={venueName}
      index={indexOffset}
      images={images}
    />
  );

  return (
    <Box>
      <Lightbox
        mainSrc={images[index]}
        nextSrc={images[indexOffset % images.length]}
        prevSrc={images[(index + lengthOffset) % images.length]}
        mainSrcThumbnail={thumbs[index]}
        nextSrcThumbnail={thumbs[indexOffset % images.length]}
        prevSrcThumbnail={thumbs[(index + lengthOffset) % images.length]}
        onCloseRequest={onClose}
        onMovePrevRequest={movePrev}
        onMoveNextRequest={moveNext}
        onMoveToIndexRequest={moveIndex}
        imageTitle={venueName}
        imageIndex={indexOffset}
        enableZoom={false}
        nextButtonImage={RightArrow}
        prevButtonImage={LeftArrow}
        closeButtonComponent={CloseButton}
        closeButtonComponentProps={{
          className: classes.close,
          description: CLOSE_BUTTON_DESCRIPTION,
          fill: palette.interactionBlue[50],
          disableRipple: true,
        }}
        directionalButtonComponent={DirectionalButton}
        directionalButtonComponentProps={{
          buttonClassName: classes.arrowButton,
          disableRipple: true,
        }}
        thumbnailImages={thumbs}
        imageHeaderComponent={header}
        maxHeightOffset={heightOffset}
        maxWidthOffset={widthOffset}
        thumbnailArrowLeft={ChevronLeft}
        thumbnailArrowRight={ChevronRight}
        widthBreakPoint={WIDTH_BREAKPOINT}
      />
    </Box>
  );
};

export default VenueLightBox;
