import React, { forwardRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import CalendarSvg from '../../../../components/svg/Calendar';
import TextInput from '../../../../components/core/TextInput';
import { VIEW_CALENDAR_DIALOG } from '../../../../assets/copy';
import CalendarInput from '../../CalendarInput';
import { useViewport } from '../../../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../../../utils/constants/Breakpoints';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  input: {
    color: '#141414',
    width: '100%',
    minWidth: '120px',
    height: '48px',
    position: 'relative',
    fontSize: '16px',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    transition:
      'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Optimist,sans-serif',
    fontWeight: 'normal',
    lineHeight: '24px',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  calendarButton: {
    background: 'none',
    border: 'none',
  },
}));

const DatepickerInput = forwardRef(
  ({ onChange, placeholder, value, isSecure, id, onClick }, ref) => {
    const classes = useStyles();
    const { width } = useViewport();
    const isMobile = width <= WIDTH_BREAKPOINT;
    const onFocusHandler = useCallback(() => {
      const position = document.body.getBoundingClientRect().y;
      // Class to disable IOS scrolling behavior when virtual keyboard appears
      isMobile && document.body.classList.add('disable-scroll');
      // When body's position is 'fixed', it is no longer scrollable and will be
      // positioned to the top of document. This sets the position back to where
      // it was before adding the 'disable-scroll' class.
      if (isMobile) document.body.style.top = `${position}px`;
    }, [isMobile]);

    const onBlurHandler = useCallback(() => {
      const offsetX = parseInt(document.body.style.left, 10);
      const offsetY = parseInt(document.body.style.top, 10);

      isMobile && document.body.classList.remove('disable-scroll');
      isMobile && window.scrollTo(offsetX, offsetY * -1);
    }, [isMobile]);

    return (
      <TextInput
        inputComponent={CalendarInput}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        isSecure={isSecure}
        id={id}
        className={classes.input}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        endAdornment={
          <button
            position="end"
            className={clsx('endAdornment', classes.calendarButton)}
            ref={ref}
            aria-label={VIEW_CALENDAR_DIALOG}
            onClick={onClick}
            onKeyPress={onClick}>
            <CalendarSvg width={24} height={24} fill="#0276B1" />
          </button>
        }
        inputProps={{
          inputMode: 'numeric',
        }}
        autoComplete="off"
        margin="dense"
        variant="outlined"
        fullWidth
        aria-live="polite"
      />
    );
  }
);

export default DatepickerInput;
