import React from 'react';
import { makeStyles } from '@material-ui/core';
import Badge from './';
import { Avatar } from '@material-ui/core';
import SignatureCollection from '../../../assets/icons/grv-benefits-badge.svg';
import { SIGNATURE_COLLECTION_TEXT } from '../../../assets/copy';

const useStyles = makeStyles({
  root: {
    cursor: 'default',
  },
  avatar: {
    width: '30px',
    '& > img': {
      height: 16,
      width: 16,
    },
  },
});

const SignatureCollectionBadge = () => {
  const classes = useStyles();
  return (
    <Badge
      text={SIGNATURE_COLLECTION_TEXT}
      size="medium"
      type="signatureCollection"
      icon={
        <Avatar className={classes.avatar} src={SignatureCollection}></Avatar>
      }
    />
  );
};

export default SignatureCollectionBadge;
