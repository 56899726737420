import React, { useCallback, useState } from 'react';
import { Container, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import StepContent from '../../components/onboarding/StepContent';
import StepFooter from '../../components/onboarding/StepFooter';
import StepHeader from '../../components/onboarding/StepHeader';
import { useHistory } from 'react-router-dom';
import Transition from '../../components/animation/Transition';

import {
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  ONBOARDING_CONFIRM_INFORMATION,
  NEXT_BUTTON_TEXT,
  ONBOARDING_DIETARY_PREFERENCES_TITLE,
  SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT,
} from '../../assets/copy';
import { useSelector } from 'react-redux';
import { onboardingBaseStyles } from './OnboardingStyles';
import Typography from '../../components/overrides/Typography';
import DietaryPreferences from '../../components/core/DietaryPreferences';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import FilledButton from '../../components/core/FilledButton';
import { useViewport } from '../../utils/useViewport';
import TextLink from './TextLink';
import { getPermalinkRouteRedirectLocation } from './onboardingHelpers';
import useOnboarding from '../../utils/useOnboarding';

const useStyles = makeStyles((theme) => ({
  ...onboardingBaseStyles(theme),
  wrapper: {
    marginbottom: `${theme.spacing(3)} !important`,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 4, 9, 6),
    },
  },
  allergiesStyle: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 0, 1),
    },
  },
  labelStyle: {
    color: theme.palette.text.secondary,
  },
  button: {
    [theme.breakpoints.up('xl')]: {
      width: 135,
    },
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

const FTUXDietaryPreferences = ({ onSave, active, activeStep, totalSteps }) => {
  const classes = useStyles();
  const history = useHistory();
  const { width } = useViewport();
  const dietsList = useSelector((state) => state.diets.list);
  const allergiesList = useSelector((state) => state.allergies.list);
  const [restrictions, setSelectRestrictions] = useState([]);
  const [allergies, setSelectAllergies] = useState([]);
  const isMobile = width < WIDTH_BREAKPOINT;
  const { handleRestaurant } = useOnboarding();

  const handleSaveSelections = useCallback(() => {
    onSave({ restrictions, allergies });
    history.push(getPermalinkRouteRedirectLocation());
  }, [onSave, allergies, restrictions, history]);

  const getSelectTitle = (titleText) => {
    return <Box className={classes.labelStyle}>{titleText}</Box>;
  };

  return (
    <Box className={classes.root}>
      <StepContent>
        <Transition classNames={classes.slideAnimation} active={active}>
          <Box
            tabIndex={0}
            className={clsx(
              classes.contentWrapper,
              classes.wrapper,
              classes.scrollable
            )}>
            <StepHeader title={ONBOARDING_DIETARY_PREFERENCES_TITLE} />
            <Container maxWidth="lg" className={classes.container}>
              <Typography className={classes.infoStyle} variant="small3Normal">
                {ONBOARDING_CONFIRM_INFORMATION}
              </Typography>
              <DietaryPreferences
                text={getSelectTitle(PROFILE_DIETARY_PREFERENCES_DIETS_LABEL)}
                itemMap={dietsList}
                list={restrictions}
                setRestrictions={setSelectRestrictions}
              />
              <DietaryPreferences
                text={getSelectTitle(
                  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL
                )}
                itemMap={allergiesList}
                list={allergies}
                setRestrictions={setSelectAllergies}
                style={classes.allergiesStyle}
              />
            </Container>
          </Box>
        </Transition>
        <Transition classNames={classes.slideAnimation} active={active}>
          <StepFooter>
            <Grid
              container
              spacing={isMobile ? 2 : 4}
              className={classes.grid}
              alignItems={isMobile ? 'center' : 'flex-start'}
              direction={isMobile ? 'column' : 'row'}>
              <Grid container={isMobile} item>
                <FilledButton
                  text={NEXT_BUTTON_TEXT}
                  variant="medium2NormalButtonFlex"
                  onClick={handleSaveSelections}
                  style={classes.button}
                  disabled={!restrictions?.length && !allergies?.length}
                />
              </Grid>
              <Grid container={isMobile} item>
                <TextLink
                  text={SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT}
                  onClickHandler={handleRestaurant}
                  buttonClassName={classes.exploreButton}
                  className={classes.directionalButton}
                />
              </Grid>
            </Grid>
          </StepFooter>
        </Transition>
      </StepContent>
    </Box>
  );
};

export default FTUXDietaryPreferences;
