import React, { useState, useEffect } from 'react';
import { Dialog, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useViewport } from '../../../utils/useViewport';
import CloseSvg from '../../svg/Close';
import HourGlass from '../../../assets/icons/hour-glass.svg';
import Typography from '../../overrides/Typography';
import FilledButton from '../FilledButton';
import OutlineButton from '../OutlineButton';
import gravityPalette from '../../gravity/global/gravityPalette';
import clsx from 'clsx';
import createInflection from '../../../utils/inflection/createInflection';
import { useHistory } from 'react-router';
import {
  DIALOG_TITLE,
  HOUR_GLASS_ICON_ALT_TEXT,
  INACTIVITY_TEXT,
  NAVIGATION_VIEW_SIGN_OUT,
  RESUME_SESSION_BUTTON_TEXT,
  SECONDS_TEXT,
} from '../../../assets/copy';
const widthBreakpoint = 960;

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  hourGlassIcon: {
    transform: 'translateY(57%)',
  },
  title: {
    fontWeight: 300,
  },
  large2: {
    fontWeight: 400,
    fontSize: 40,
    lineHeight: '52px',
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      height: 416,
      width: 405,
      marginBottom: theme.spacing(4.5),
    },
    boxSizing: 'border-box',
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    backgroundColor: theme.palette.digitalGray[10],
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  hourGlass: {
    border: `2px solid ${theme.palette.background.white}`,
    borderRadius: '50%',
  },
  button: {
    width: '100%',
  },
  seconds: {
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.125),
    },
  },
  signoutButtonSecondary: {
    marginTop: theme.spacing(1),
    color: gravityPalette.button.regressive.active,
    backgroundColor: gravityPalette.button.text.default,
    borderColor: gravityPalette.button.regressive.active,
    border: '2px solid',
    outline: 0,
    '&:hover': {
      backgroundColor: gravityPalette.button.text.active,
      border: '2px solid',
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(2.875),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  closeButtonContainer: {
    transform: 'translateY(-10px)',
  },
}));

const TimerDialog = ({ open, setIsOpen }) => {
  const { width } = useViewport();
  const classes = useStyles();
  const history = useHistory();
  const INITIAL_REMAINING_SECONDS = 60;

  const [remainingSeconds, setRemainingSeconds] = useState(
    INITIAL_REMAINING_SECONDS
  );
  const INTERVAL_SECONDS = 1000;

  useEffect(() => {
    setRemainingSeconds(INITIAL_REMAINING_SECONDS);
  }, [open]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && remainingSeconds > 0) {
        setRemainingSeconds(remainingSeconds - 1);
      } else if (open) {
        clearTimeout(timer);
        setIsOpen(false);
        history.push('/sign-out');
      }
    }, INTERVAL_SECONDS);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingSeconds, open]);

  const handleSignOut = () => history.push('/sign-out');
  const handleResetTimer = () => setIsOpen(false);

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            margin: 0,
            justifyContent: 'center',
          },
        }}
        className={classes.dialog}
        open={open}
        title="timer dialog, "
        id="timerDialog"
        aria-labelledby="dialog-title "
        maxWidth={false}>
        <Box>
          <Box
            display={'flex'}
            justifyContent={'center'}
            className={classes.hourGlassIcon}>
            <img
              alt={HOUR_GLASS_ICON_ALT_TEXT}
              aria-hidden="true"
              src={HourGlass}
              className={classes.hourGlass}
            />
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            className={classes.closeButtonContainer}>
            <Box zIndex={2}>
              <CloseSvg
                height={width < widthBreakpoint ? 14 : 20}
                width={width < widthBreakpoint ? 14 : 20}
                cursor="pointer"
                fill={gravityPalette.button.progressive.default}
                onClick={handleResetTimer}
                onKeyPress={(e) => e.key === 'Enter' && handleResetTimer()}
                role="button"
                tabIndex={0}
                aria-label="close"
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <Box textAlign={'center'} id="dialog-title">
                <Typography variant="large1Light" className={classes.title}>
                  {DIALOG_TITLE}
                </Typography>
              </Box>
              <Box
                textAlign={'center'}
                role="timer"
                aria-atomic="true"
                tabIndex={0}>
                <Typography
                  variant="large1Light"
                  className={clsx(classes.large2, classes.seconds)}>
                  {createInflection(SECONDS_TEXT, remainingSeconds)}
                </Typography>
              </Box>
              <Box textAlign={'center'} id="inactivity-text">
                <Typography variant="medium1Normal">
                  {INACTIVITY_TEXT}
                </Typography>
              </Box>
              <Box className={classes.buttonContainer}>
                <Box>
                  <FilledButton
                    text={RESUME_SESSION_BUTTON_TEXT}
                    onClick={handleResetTimer}
                    role="button"
                    style={`${classes.button}`}></FilledButton>
                </Box>
                <Box>
                  <OutlineButton
                    text={NAVIGATION_VIEW_SIGN_OUT}
                    onClick={handleSignOut}
                    role="button"
                    style={`${classes.button} ${classes.signoutButtonSecondary}`}></OutlineButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default TimerDialog;
