import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paginationItem: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
  },
  dots: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SearchResultsPaginationItem = ({ children, isDots = false }) => {
  const classes = useStyles();

  if (isDots) {
    return (
      <li className={clsx(classes.paginationItem, classes.dots)}>&#8230;</li>
    );
  }
  return <li className={classes.paginationItem}>{children}</li>;
};

export default SearchResultsPaginationItem;
