import { useState } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  CardMedia,
  Box,
} from '@material-ui/core';
import Typography from '../../overrides/Typography';
import CancelLabel from '../../core/Chips/Label';
import CalendarSvg from '../../../assets/icons/calendar.svg';
import TimerSvg from '../../../assets/icons/timer.svg';
import ProfileSvg from '../../../assets/icons/profile.svg';
import { ReactComponent as BenefitsIcon } from '../../../assets/icons/grv-benefits-small.svg';
import createInflection from '../../../utils/inflection/createInflection';
import MediaImg from '../../../assets/images/media.png';
import {
  getFormattedDate,
  getTypeFromReservation,
  getScreenReaderFormattedDate,
} from '../../../utils/reservationFilters';
import {
  CANCELED_LABEL,
  GUEST_INFLECTION,
  RESERVATIONS_DETAILS_LABEL,
  EXCLUSIVE_TABLE_TEXT
} from '../../../assets/copy';

import { generateScreenReaderText } from '../../../utils/stringHelperMethods';

import EllipsisText from '../EllipsisText';
import { useViewport } from '../../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { cardHoverStyles } from '../../../utils/constants/hoverStyles';
import { useFeatureFlags } from '../../../utils/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
  card: ({ isPastCard, hoverable }) => ({
    display: 'flex',
    height: isPastCard ? 162 : 118,
    width: '100%',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2),
    },
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    ...(!isPastCard && {
      [theme.breakpoints.up('xl')]: {
        height: 162,
      },
    }),
    ...(hoverable && cardHoverStyles),
  }),
  content: ({ isPastCard }) => {
    const columnAlign = {
      alignItems: 'flex-start',
      flexDirection: 'column',
    };
    return {
      flex: '1 0 auto',
      display: 'flex',
      placeContent: 'space-between',
      ...(isPastCard
        ? {
            boxSizing: 'border-box',
            height: '100%',
            ...columnAlign,
          }
        : {
            alignItems: 'flex-end',
            [theme.breakpoints.up('xl')]: columnAlign,
          }),
    };
  },
  insider: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.25),
    marginBottom: '2px',
    '& path': {
      fill: `${theme.palette.yellow[40]} !important`,
    },
  },
  cardholderLabel: {
    color: theme.palette.secondary['65'],
    marginLeft: theme.spacing(0.25),
  },
  cover: {
    width: 118,
    height: '100%',
  },
  image: {
    marginRight: theme.spacing(2),
  },
  cardContentBox: {
    width: '100%',
    overflow: 'hidden',
  },
}));

export default function SmallCard({
  reservation,
  venue,
  label,
  type = getTypeFromReservation(reservation), // Other types are Fill or Cancel (from UpdateReservation)
  hoverable = false,
}) {
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();
  const classes = useStyles({
    isPastCard: type === 'Canceled' || type === 'Completed',
    hoverable,
  });
  const [cardVenue] = useState(reservation.venue || venue);

  const generateReservationText = () => {
    return generateScreenReaderText(
      ',',
      RESERVATIONS_DETAILS_LABEL,
      reservation.venue.name,
      getScreenReaderFormattedDate(reservation, type),
      reservation.timeSlot || reservation.time,
      createInflection(GUEST_INFLECTION, reservation.guests)
    );
  };

  const { width } = useViewport();

  return (
    <Box
      role={`${type === 'Completed' ? 'link' : 'none'}`}
      aria-label={`${label ? label : ''} ${generateReservationText()},
      ${type === 'Canceled' ? CANCELED_LABEL : ''} `}
      tabIndex={0}>
      <Card className={classes.card} aria-hidden="true">
        <Box>
          <CardMedia
            component={'img'}
            className={classes.cover}
            image={cardVenue.images[0] || MediaImg}
            alt="venue image"
          />
        </Box>
        <Box overflow={'hidden'} width="100%">
          <CardContent className={classes.content}>
            <Box className={classes.cardContentBox}>
              <EllipsisText
                variant="medium1Semibold"
                maxWidth={'50%'}
                text={cardVenue.name}
              />
              <Box display="flex">
                <img src={CalendarSvg} alt="" className={classes.image} />
                <Typography variant="small2Normal">
                  {getFormattedDate(reservation, type)}
                </Typography>
              </Box>
              <Box display="flex">
                <img src={TimerSvg} alt="" className={classes.image} />
                <Typography variant="small2Normal">
                  {reservation.timeSlot || reservation.time}
                </Typography>
                {((reservation.isCardholderTable || reservation.isExclusive) && refreshLandingPageFlagIsEnabled) && 
                <>
                  <BenefitsIcon className={classes.insider} role="presentation" />
                  <Typography
                    variant="tiny1Normal"
                    className={classes.cardholderLabel}>
                    {EXCLUSIVE_TABLE_TEXT}
                  </Typography>
                </>}
              </Box>
              <Box display="flex">
                <img src={ProfileSvg} alt="" className={classes.image} />
                <Typography variant="small2Normal">
                  {createInflection(GUEST_INFLECTION, reservation.guests)}
                </Typography>
              </Box>
            </Box>
            {type === 'Canceled' && (
              <Box paddingTop={width >= WIDTH_BREAKPOINT && '16px'}>
                <CancelLabel text={CANCELED_LABEL} />{' '}
              </Box>
            )}
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
