import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  CUISINES_LABEL,
  RECENT_SEARCHES_LABEL,
  RESTAURANTS_LABEL,
} from '../../../assets/copy';
import { convertNullToEmptyString } from '../../../utils/stringHelperMethods';
import { mapSearches } from '../helpers/mapSearches';

const CUISINE_BASE = Object.freeze({
  name: CUISINES_LABEL,
  items: [],
});

const RESTAURANT_BASE = Object.freeze({
  name: RESTAURANTS_LABEL,
  items: [],
});

const SEARCH_BASE = Object.freeze({
  name: RECENT_SEARCHES_LABEL,
  items: [],
});

const updateItemName = (item, sectionName) =>
  convertNullToEmptyString(item.name) +
  '\n' +
  convertNullToEmptyString(sectionName) +
  '\n' +
  convertNullToEmptyString(item.id);

export const useResults = ({ suggestedCuisines, suggestedRestaurants }) => {
  const { recentSearches } = useSelector((state) => state.search);

  const sections = useMemo(
    () => [
      { ...CUISINE_BASE, items: suggestedCuisines ?? [] },
      { ...RESTAURANT_BASE, items: suggestedRestaurants ?? [] },
      { ...SEARCH_BASE, items: mapSearches(recentSearches) },
    ],
    [suggestedCuisines, suggestedRestaurants, recentSearches]
  );

  const results = useMemo(() => {
    const updatedResults = [];

    for (const section of sections) {
      updatedResults.push(
        ...(section.items?.map((item) => ({
          ...item,
          name: updateItemName(item, section.name),
        })) ?? [])
      );
    }

    return updatedResults;
  }, [sections]);

  return results;
};
