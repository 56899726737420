import { put, takeLatest } from 'redux-saga/effects';

import { listSpecialOccasions } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './AppDataAction';
import * as TYPES from './AppDataTypes';

export function* specialOccasionsListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listSpecialOccasions,
      variables: { filter },
    });
    yield put(
      ACTIONS.actionSpecialOccasionsListSuccess(
        result.data.listSpecialOccasions.items
      )
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionSpecialOccasionsListFail(e));
  }
}

export default function* appDataSaga() {
  yield takeLatest(
    TYPES.SPECIAL_OCCASIONS_LIST_REQUEST,
    specialOccasionsListRequestHandler
  );
}
