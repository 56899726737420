import React from 'react';
import { makeStyles } from '@material-ui/core';
import PageSection from '../../components/core/PageSection';
import Typography from '../../components/overrides/Typography';
import desktopHeroImg from '../../assets/images/premier-hero-desktop.png';
import mobileHeroImg from '../../assets/images/premier-hero-mobile.png';
import { useViewport } from '../../utils/useViewport';
import { PREMIER_LOCATION_TITLE } from '../../assets/copy';

const desktopHeroGradient =
  'linear-gradient(0deg, #0E1D2B 0%, rgba(14, 29, 43, 0.73) 45.25%, rgba(14, 29, 43, 0.05) 88.25%)';
const mobileHeroGradient =
  'linear-gradient(0deg, #0E1D2B 0%, rgba(16, 37, 63, 0.73) 100%, rgba(14, 29, 43, 0.65) 100%)';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    backgroundImage: `${mobileHeroGradient}, url(${mobileHeroImg})`,
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 290,
    padding: theme.spacing(0, 0, 6),
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `${mobileHeroGradient}, url(${desktopHeroImg}) `,
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: `${desktopHeroGradient}, url(${desktopHeroImg}) `,
      borderBottom: `${theme.spacing(6)} solid #0E1D2B`,
      height: 350,
    },
  },
  container: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  title: {
    borderBottom: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 3),
    paddingBottom: theme.spacing(1),
  },
}));

const widthBreakpoint = 960;

const TopHero = () => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <div className={classes.root}>
      <PageSection className={classes.container} variant="grid">
        <Typography
          variant={width > widthBreakpoint ? 'large1Light' : 'medium3Light'}
          className={classes.title}
          component="h1">
          {PREMIER_LOCATION_TITLE}
        </Typography>
      </PageSection>
    </div>
  );
};

export default TopHero;
