import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Typography from '../overrides/Typography';
import Button from '../overrides/Button';

const OutlineButton = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: 'transparent',
    color: theme.palette.secondary.contrastText,
    border: `2px solid ${theme.palette.secondary.contrastText}`,
    height: 48,
    width: '100%',
    maxWidth: 600,
    '&:disabled': {
      border: `2px solid ${theme.palette.secondary[40]}`,
    },
  },
}))(Button);

export default function CButton({
  text,
  onClick,
  color = 'primary',
  type = 'button',
  style,
  disabled = false,
  variant = 'medium2Normal',
  startIcon = null,
  focusable = true,
  ...otherProps
}) {
  return (
    <OutlineButton
      type={type}
      variant="outlined"
      color={color}
      onClick={onClick}
      className={style}
      startIcon={startIcon}
      disabled={disabled}
      {...otherProps}>
      <Typography variant={variant} color="initial">
        {text}
      </Typography>
    </OutlineButton>
  );
}
