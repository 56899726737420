import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const getCookie = (cookieKey) => {
  return cookies.get(cookieKey);
};

const setCookie = (cookieKey, cookieValue, options) => {
  cookies.set(cookieKey, cookieValue, options);
};

const removeCookie = (cookieKey, options) => {
  cookies.remove(cookieKey, options);
};

export { getCookie, setCookie, removeCookie };
