import { useEffect, useState } from 'react';

/**
 * Returns a random string of alphanumeric characters.
 *
 * Usable for non-secure IDs, such as CSS IDs or keys in a component array
 *
 * @author Xuejia Chen
 * @see {@link https://gist.github.com/6174/6062387}
 */
export const getRandomString = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

/**
 * Generates a semi-unique key with parameter used as base
 *
 * @param {string} base Base string to use, with a default value of `'key'`
 * @param {boolean} unique Flag to ensure key is unique by adding an extra random string suffix, with a default value of `true`
 * @returns {string} Either base string or semi-unique key following the format `${base}-${randomString}`
 */
export const useGeneratedKey = (base = 'key', unique = true) => {
  const [key, setKey] = useState(base);

  useEffect(
    () => setKey(unique ? `${base}-${getRandomString()}` : base),
    [base, unique]
  );

  return key;
};
