import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AVAILABILITY_ALL_BUTTON_TEXT } from '../../../assets/copy';
import DirectionalButton from '../../../components/buttons/DirectionalButton';
import { focusVisible } from '../../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  noAvailTime: {
    margin: theme.spacing(2, 0, 0, 3),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(1, 0, 1.5),
    },
  },
  viewAll: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 1, 0, 3),
    paddingTop: theme.spacing(0.25),
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 1, 3, 0),
    },
    '& button': {
      padding: theme.spacing(0),
    },
  },
  buttonClassName: {
    ...focusVisible(),
  },
  viewAllText: {
    color: theme.palette.primary.main,

    cursor: 'pointer',
    display: 'flex',
    [theme.breakpoints.up('xl')]: {},
  },

  viewAllIcon: {
    margin: theme.spacing(0, 0, 0, 1.5),
  },
}));
const ViewAll = ({ onClick, linkText = AVAILABILITY_ALL_BUTTON_TEXT, venueParent }) => {
  const classes = useStyles();
  const handleViewNextAvailabilityClick = (event) => {
    onClick(event);
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_id: event.currentTarget.id,
      ui_interaction_element_name: "view-next-availability",
      ui_interaction_element_type: 'Link',
      ui_interaction_element_description: venueParent.name
    });
  }
  return (
    <DirectionalButton
      buttonClassName={classes.buttonClassName}
      classContainer={classes.viewAll}
      text={linkText}
      onClickHandler={handleViewNextAvailabilityClick}
      role="link"
      data-cy="view-next-availability"
    />
  );
};

export default ViewAll;
