import { useEffect } from 'react';

const LOCAL_STORAGE_KEY = 'expirationTimestamp';

export const clearExpirationTimestamp = () => {
  sessionStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const useIdleTimeout = ({
  timeout = 1000 * 60 * 44, // 44 minutes
  onTimeout = () => console.log('onTimeout called'),
  onExpired = () => console.log('onExpired called'),
  debounceInterval = 500,
  pollInterval = 1000,
  events = ['mousemove', 'scroll', 'keydown'],
}) => {
  const setExpirationTimestamp = () => {
    const newExpirationTimestamp = Date.now() + timeout;
    sessionStorage.setItem(LOCAL_STORAGE_KEY, newExpirationTimestamp);
  };

  const getExpirationTimestamp = () => {
    const exp = sessionStorage.getItem(LOCAL_STORAGE_KEY);
    return exp == null ? exp : parseInt(exp);
  };

  // Debounce to limit number of times expiration timestamp is set in sessionStorage
  const debounce = (callback, delay) => {
    let _timeout;
    return (...args) => {
      clearTimeout(_timeout);
      _timeout = setTimeout(() => callback(...args), delay);
    };
  };

  // On timeout or expire, clear expiration timestamp and remove event listeners
  const cleanup = () => {
    clearInterval(intervalTimeout);
    for (const event of events) {
      window.removeEventListener(event, setExpirationTimestamp);
    }
  };

  useEffect(() => {
    const exp = getExpirationTimestamp();
    if (exp != null && exp < Date.now()) {
      cleanup();
      onExpired();
      return;
    }

    setExpirationTimestamp();

    for (const event of events) {
      window.addEventListener(
        event,
        debounce(setExpirationTimestamp, debounceInterval)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Poll at regular intervals to see if expiration timestamp has lapsed
  const intervalTimeout = setInterval(() => {
    const exp = getExpirationTimestamp();
    if (exp != null && exp < Date.now()) {
      cleanup();
      onTimeout();
    }
  }, pollInterval);

  return {
    getExpirationTimestamp,
    clearExpirationTimestamp,
  };
};
