import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  MENU_ALT_TEXT,
  NAVIGATION_SHOW_ALT_TEXT,
  EXPANDED_TEXT,
  COLLAPSED_TEXT,
} from '../../../assets/copy';
import MenuSvg from '../../../assets/icons/menu.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/grv-menu.svg';
import { useFeatureFlags } from '../../../utils/useFeatureFlags';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    ...focusVisible(),
  },
}));

const MobileMenuButton = ({ handlePress, menuVisible }) => {
  const classes = useStyles();
  const { overlayVisible } = useSelector((state) => state.search);
  const STATUS = menuVisible ? EXPANDED_TEXT : COLLAPSED_TEXT;
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();

  if (overlayVisible) return null;

  return (
    <IconButton
      className={clsx({ [classes.root]: refreshLandingPageFlagIsEnabled })}
      autoFocus={!refreshLandingPageFlagIsEnabled}
      aria-label={`${NAVIGATION_SHOW_ALT_TEXT} ${STATUS}`}
      onClick={handlePress}
      disableFocusRipple>
      {refreshLandingPageFlagIsEnabled ? (
        <MenuIcon aria-hidden="true" alt={MENU_ALT_TEXT} />
      ) : (
        <img aria-hidden="true" src={MenuSvg} alt={MENU_ALT_TEXT} />
      )}
    </IconButton>
  );
};

export default MobileMenuButton;
