import {
  AVAILABILITY_MORE_BUTTON_TEXT,
  AVAILABILITY_MORE_TEXT,
  AVAILABILITY_OTHERS_TOOLTIP,
} from '../../assets/copy';
import TextButton from '../../components/core/TextButton';
import { makeStyles } from '@material-ui/styles';
import AvailableTimes from './AvailableTimes';
import React from 'react';
import { sanitizeAvailability } from '../../utils/generateAvailabilityInput';
import { Box } from '@material-ui/core';
import NoAvailabilityInfo from './NoAvailabilityInfo';
import { TIME_AVAILABILITY_TYPES } from './timeAvailabilityTypes';
import moment from 'moment';
import { useCopy } from '../../utils/useCopy';
import createInflection from '../../utils/inflection/createInflection';
import InfoIconTooltip from '../../components/core/InfoIconTooltip';

const useStyles = makeStyles((theme) => ({
  viewAllText: {
    color: theme.palette.primary.main,
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  noAvailabilityContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 3, 0),
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
      padding: theme.spacing(3, 0, 0),
    },
  },
  blackToolTip: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    lineHeight: '21px',
    fontSize: '14px',
    width: 'auto',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(6),
      width: '256px',
    },
  },
  informationIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

const NextAvailabilityList = ({
  hasAvailabilityForNext7Days,
  hasAvailabilityForSelectedDay,
  list,
  width,
  venue,
  submittedTime,
  showMore,
  showMoreLink,
  handleShowMore,
  selectedGuests,
  selectedDateString,
  handleEdit,
  type = TIME_AVAILABILITY_TYPES.HORIZONTAL,
}) => {
  const classes = useStyles();
  const { getCopy } = useCopy();

  if (!hasAvailabilityForSelectedDay && !hasAvailabilityForNext7Days) {
    return null;
  }

  const sanitizedList = list?.filter(
    (item) => item.timeslots && item.timeslots.length > 0
  );
  const newList = sanitizedList?.slice(0, 3);

  const sanitizedNext7DaysAvailability = newList?.map((item) => {
    const tentativeSelectedDate = moment(item.date).toDate();
    return {
      ...item,
      timeslots: sanitizeAvailability(
        item.timeslots,
        tentativeSelectedDate,
        submittedTime
      ),
    };
  });

  const titleWithTooltip = (
    <>
      {AVAILABILITY_MORE_TEXT}
      <InfoIconTooltip
        title={AVAILABILITY_OTHERS_TOOLTIP}
        tooltipStyle={classes.blackToolTip}
        infoIconStyle={classes.informationIcon}
      />
    </>
  );

  return (
    <>
      {showMoreLink && (
        <Box
          className={classes.viewAllText}
          role="presentation"
          onKeyPress={(e) => e.key === 'Enter' && handleShowMore(e)}>
          <TextButton onClick={handleShowMore}>
            {AVAILABILITY_MORE_BUTTON_TEXT}
          </TextButton>
        </Box>
      )}
      {showMore && (
        <>
          <AvailableTimes
            width={width}
            venue={venue}
            list={sanitizedNext7DaysAvailability}
            title={titleWithTooltip}
            type={type}
            handleEdit={handleEdit}
          />
          {!hasAvailabilityForNext7Days && (
            <NoAvailabilityInfo
              selectedGuests={selectedGuests}
              selectedDateString={selectedDateString}
              containerClassName={classes.noAvailabilityContainer}
              text={getCopy('AVAILABILITY_NO_TABLES_WITHIN_NEXT_7_DAYS', {
                guestsInflection: createInflection('guest', selectedGuests),
              })}
              type={type}
            />
          )}
        </>
      )}
    </>
  );
};

export default NextAvailabilityList;
