import {
  Box,
  Card,
  CardMedia,
  CardContent,
  makeStyles,
  CardActions,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import Typography from '../../overrides/Typography';
import Badge from '../Badge';
import clsx from 'clsx';
import DirectionalButton from '../../buttons/DirectionalButton';
import {
  EVENT_STATUS_ON_SALE_TEXT,
  EVENT_STATUS_PAST_TEXT,
  EVENT_STATUS_SOLD_OUT_TEXT,
  EVENT_STATUS_COMING_SOON_TEXT,
  EVENT_CTA_TEXT,
  CARDHOLDER_EXCLUSIVE_TEXT,
  MULTIPLE_DATE_TEXT,
  ONLY_TEXT,
} from '../../../assets/copy';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';
import palette from '../../../theme/palette';
import EllipsisText from '../EllipsisText';
import DateTime from '../../../utils/dateTimeHelpers';
import { useHistory } from 'react-router';
import {
  SPECIAL_EVENT_MONTH_DATE,
  SPECIAL_EVENT_MONTH_DATE_YEAR,
} from '../../../assets/dateFormats';
import { isPastEvent, isSoldOut } from '../../../utils/eventHelpers';
import { enterKeyPressHandler } from '../../../utils/keyPresses';
import generateSafeHtmlTags from '../../../utils/generateSafeHtmlTags';
import { ellipsify } from '../../../utils/stringHelperMethods';

const STATUS_ENUM = {
  [EVENT_STATUS_COMING_SOON_TEXT]: palette.green[70],
  [EVENT_STATUS_ON_SALE_TEXT]: palette.green[70],
  [EVENT_STATUS_PAST_TEXT]: palette.digitalGray[60],
  [EVENT_STATUS_SOLD_OUT_TEXT]: palette.digitalGray[60],
};

const useStyles = makeStyles((theme) => {
  const commonStyles = {
    borderRadius: '50%',
    border: `solid 2px ${theme.palette.background.white}`,
    [theme.breakpoints.down('lg')]: {
      height: 32,
      width: 32,
      top: -18,
    },
  };

  return {
    root: ({ isCompact, isEventCarousel }) => {
      const compactStyles = {
        width: 240,
        boxShadow: 'none',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
        cursor: 'pointer',
        [theme.breakpoints.up('xl')]: {
          width: 256,
        },
      };

      const defaultStyles = {
        borderRadius: 4,
        display: isEventCarousel ? 'block' : 'flex',
        flexDirection: isEventCarousel ? 'row' : 'column',
        maxHeight: isEventCarousel ? 381 : 504,
        maxWidth: 544,
        height: '100%',
        width: isEventCarousel ? 352 : 544,
        boxShadow: isEventCarousel ? '0px 1px 4px rgba(0, 0, 0, 0.2)' : 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('lg')]: {
          width: 327,
          maxHeight: 381,
        },
      };

      return isCompact ? compactStyles : defaultStyles;
    },
    cardBannerContainer: ({ isCompact, isEventCarousel }) => ({
      position: 'relative',
      maxHeight: 240,
      height: isCompact || isEventCarousel ? 139 : 240,
      borderRadius: '4px 4px 0 0',
      margin: isCompact || isEventCarousel ? theme.spacing(0.125) : 0,
      [theme.breakpoints.down('lg')]: {
        maxHeight: 139,
      },
    }),
    accoladeImage: ({ isCompact }) => ({
      ...commonStyles,
      height: isCompact ? 32 : 40,
      width: isCompact ? 32 : 40,
      top: isCompact ? -18 : -20,
      position: 'absolute',
    }),
    partnerImageContainer: ({ isCompact }) => ({
      borderRadius: '50%',
      border: `solid 2px ${theme.palette.background.white}`,
      height: isCompact ? 28 : 36,
      width: isCompact ? 28 : 36,
      top: isCompact ? -18 : -20,
      position: 'absolute',
      backgroundColor: theme.palette.charcoal[60],
      [theme.breakpoints.down('lg')]: {
        height: 28,
        width: 28,
        top: -18,
      },
    }),
    partnerImage: ({ isCompact }) => ({
      height: isCompact ? 20 : 24,
      width: isCompact ? 20 : 24,
      padding: isCompact ? theme.spacing(0.5) : theme.spacing(0.8),
      filter: 'brightness(0) invert(1)',
      [theme.breakpoints.down('lg')]: {
        height: 20,
        width: 20,
        padding: theme.spacing(0.5),
      },
    }),
    cardContent: ({ isCompact }) => ({
      padding: theme.spacing(3, 3, 0),
      position: 'relative',
      maxHeight: isCompact ? 180 : 252,
      boxSizing: 'border-box',
      height: 'auto',
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
      ...(isCompact && { padding: theme.spacing(3, 2, 2) }),
    }),
    cardTypography: {
      marginTop: theme.spacing(0.5),
    },
    badgeContainer: ({ isCompact }) => ({
      position: 'absolute',
      top: isCompact ? theme.spacing(1) : theme.spacing(2),
      right: isCompact ? theme.spacing(1) : theme.spacing(2.625),
      [theme.breakpoints.down('lg')]: {
        top: theme.spacing(1),
        right: theme.spacing(1),
      },
    }),
    description: {
      color: theme.palette.secondary[80],
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    statusText: ({ statusText }) => {
      return {
        lineHeight: theme.spacing(2.5),
        color: STATUS_ENUM[statusText],
        margin: 0,
        [theme.breakpoints.down('md')]: {
          lineHeight: theme.spacing(2),
        },
      };
    },
    detailsContainer: ({ isCompact }) => ({
      marginTop: isCompact ? theme.spacing(0) : theme.spacing(2),
    }),
    cardActions: ({ isCompact }) => ({
      padding: theme.spacing(2, 0),
      marginTop: 'auto',
      zIndex: 1,
      backgroundColor: theme.palette.background.white,
    }),
  };
});

const ExtraLargeCard = ({
  venue,
  isCompact = false,
  isEventCarousel = false,
}) => {
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;
  const history = useHistory();
  const DESCRIPTION_SIZE = 130;
  const getEventStatus = useCallback(() => {
    if (venue?.isHidden) {
      return '';
    }
    if (isSoldOut(venue)) {
      return EVENT_STATUS_SOLD_OUT_TEXT;
    }

    if (isPastEvent(venue)) {
      return EVENT_STATUS_PAST_TEXT;
    }

    const currentDate = new DateTime();
    const salesStartDateLuxon = new DateTime(venue?.salesStartDate);
    const salesEndDateLuxon = new DateTime(venue?.salesEndDate);

    if (
      salesStartDateLuxon <= currentDate &&
      currentDate <= salesEndDateLuxon
    ) {
      return EVENT_STATUS_ON_SALE_TEXT;
    }
    if (currentDate < salesStartDateLuxon) {
      return EVENT_STATUS_COMING_SOON_TEXT;
    }

    return '';
  }, [venue]);

  const eventStatusText = getEventStatus(venue);
  const classes = useStyles({
    statusText: eventStatusText,
    isCompact,
    isEventCarousel,
  });

  const generateEventDatesText = (dateList = []) => {
    if (!dateList || dateList.length === 0) {
      return null;
    }

    // Convert dates to Luxon DateTime objects
    const parsedDates = dateList.map((date) => DateTime.fromISO(date));

    // Check if the dates span across multiple years
    const years = [...new Set(parsedDates.map((date) => date.year))];
    if (years.length > 1) {
      return MULTIPLE_DATE_TEXT;
    }

    // Get the first and last dates
    const startDate = parsedDates[0];
    const endDate = parsedDates[parsedDates.length - 1];

    // Format the output using Luxon
    const startStr = startDate.toFormat(SPECIAL_EVENT_MONTH_DATE);
    const endStr = endDate.toFormat(SPECIAL_EVENT_MONTH_DATE_YEAR);

    if (startDate.equals(endDate)) {
      return `${endStr} ${ONLY_TEXT}`;
    }

    return `${startStr} - ${endStr}`;
  };

  const generateLocationText = useCallback(() => {
    return [venue?.neighborhood?.name, venue?.city, venue?.state]
      .filter((item) => !!item)
      .join(', ');
  }, [venue?.neighborhood?.name, venue?.city, venue?.state]);

  const onClickHandler = () => {
    history.push(`/venue/${venue.id}`);
  };
  const onKeyPressHandler = enterKeyPressHandler && onClickHandler;

  const truncate = (description) => {
    if (description?.length <= DESCRIPTION_SIZE) {
      return description;
    } else {
      const shortDescription = description?.slice(0, DESCRIPTION_SIZE);
      const index = shortDescription?.lastIndexOf(' ');
      return ellipsify(shortDescription, index);
    }
  };

  return (
    <Card
      className={classes.root}
      tabIndex={0}
      role="link"
      onClick={onClickHandler}
      onKeyPress={onKeyPressHandler}>
      <CardMedia
        image={venue?.images[0]}
        alt=""
        className={classes.cardBannerContainer}>
        {venue?.isCardholderExclusive && (
          <Box className={classes.badgeContainer}>
            <Badge
              text={CARDHOLDER_EXCLUSIVE_TEXT}
              type={'isCapitalOneExclusive'}
            />
          </Box>
        )}
      </CardMedia>
      <CardContent className={classes.cardContent}>
        {venue?.partners?.items[0]?.partner?.icon && (
          <Box className={classes.partnerImageContainer}>
            <img
              src={venue?.partners?.items[0]?.partner?.icon}
              alt={venue?.partners?.items[0]?.partner?.name}
              className={classes.partnerImage}
            />
          </Box>
        )}
        <Box>
          <Typography
            className={clsx(classes.cardTypography, classes.statusText)}
            variant={'small1Semibold'}>
            {eventStatusText}
          </Typography>
        </Box>
        <Box>
          <EllipsisText
            text={venue?.name}
            className={classes.cardTypography}
            variant={'medium1Semibold'}
          />
          {!isCompact && (
            <Typography
              className={clsx(classes.cardTypography, classes.description)}
              variant="medium1Normal"
              dangerouslySetInnerHTML={generateSafeHtmlTags(
                truncate(venue?.description)
              )}
            />
          )}
        </Box>
        <Box className={classes.detailsContainer}>
          <EllipsisText
            variant={isCompact ? 'small1Normal' : 'small2Normal'}
            text={generateLocationText()}
            color="primary"
          />
          <EllipsisText
            className={!isMobile && classes.cardTypography}
            text={generateEventDatesText(venue?.eventDates)}
            variant={isCompact ? 'small1Normal' : 'small2Normal'}
            color={'primary'}
          />
        </Box>
        <CardActions className={classes.cardActions}>
          <Box>
            <DirectionalButton
              text={EVENT_CTA_TEXT}
              aria-label={EVENT_CTA_TEXT}
              isRippleDisabled
              focusable={false}
            />
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default ExtraLargeCard;
