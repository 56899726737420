import { ReactComponent as UserSvgPath } from '../../assets/icons/user.svg';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  userSvg: {
    '& > path': {
      fill: ({ fill }) => fill,
    },
  },
}));

const UserSvg = ({
  width = 16,
  height = 16,
  fill = palette.secondary.main,
  className,
  ...rest
}) => {
  const classes = useStyles({ fill });

  return (
    <UserSvgPath
      width={width}
      height={height}
      className={clsx(classes.userSvg, className)}
      {...rest}
    />
  );
};
export default UserSvg;
