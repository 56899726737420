import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useNeighborhoodsInMarket = () => {
  const { markets } = useSelector((state) => state);

  const neighborhoodsInMarket = useMemo(() => {
    if (!markets?.market?.neighborhoods) return [];

    const neighborhoodIds = markets.market.venues.items
      .filter((venue) => venue.neighborhoodId)
      .map((venue) => venue.neighborhoodId);

    return markets.market.neighborhoods.items.filter((neighborhood) =>
      neighborhoodIds.includes(neighborhood.id)
    );
  }, [markets?.market]);

  return neighborhoodsInMarket;
};
