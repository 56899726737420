import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { mergeProps } from '@react-aria/utils';
import {
  SEARCH_CLEAR_ALT_TEXT,
  SEARCH_INPUT_LABEL,
  SEARCH_INPUT_PLACEHOLDER,
  SEARCH_NAME,
} from '../../../assets/copy';
import FilledClose from '../../../assets/icons/filledClose.svg';
import Search from '../../../assets/icons/search.svg';
import TextInput from '../../core/TextInput';
import SearchStartAdornment from './SearchStartAdornment';

export const BASE_SEARCH_INPUT_PROPS = {
  label: SEARCH_INPUT_LABEL,
  name: SEARCH_NAME,
  placeholder: SEARCH_INPUT_PLACEHOLDER,
};

const useStyles = makeStyles({
  endAdornment: {
    position: 'absolute',
    right: 0,
    '& .MuiIconButton-root': {
      background: 'none',
    },
  },
});

const EndAdornment = ({ className, handleReset }) => (
  <InputAdornment className={className} position="end">
    <IconButton
      onClick={() => handleReset()}
      aria-label={SEARCH_CLEAR_ALT_TEXT}>
      <img src={FilledClose} alt="" />
    </IconButton>
  </InputAdornment>
);

const SearchInputBase = ({
  inputProps = {},
  handleChange,
  handleSearch,
  handleReset,
  value,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextInput
      {...BASE_SEARCH_INPUT_PROPS}
      fullWidth
      inputProps={mergeProps(inputProps, {
        autoComplete: 'on',
        inputMode: 'search',
        onKeyDown: (e) => {
          if (e?.key === 'Enter') {
            e.preventDefault();
            e.target.blur();
            handleSearch(e);
          }
        },
        value,
      })}
      onChange={handleChange}
      type="search"
      value={value}
      variant="outlined"
      startAdornment={<SearchStartAdornment src={Search} alt={SEARCH_NAME} />}
      endAdornment={
        value && (
          <EndAdornment
            className={classes.endAdornment}
            handleReset={handleReset}
          />
        )
      }
      {...(!!inputProps?.id && { id: inputProps.id })}
      {...props}
    />
  );
};

export default SearchInputBase;
