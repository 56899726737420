import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import * as TYPES from './SearchTypes';

const initialState = {
  recentSearches: [],
  overlayVisible: false,
  cachedQuery: '',
  currentQuery: '',
  currentSearchInputValue: '',
  cachedSessionId: '',
  currentSessionId: '',
};

export default function searchReducer(state = initialState, action) {
  const { type, payload } = action;
  const recentSearches = state.recentSearches ?? [];
  switch (type) {
    case TYPES.ADD_RECENT_SEARCH:
      if (!payload || recentSearches.some((item) => item === payload)) {
        return state;
      }

      return {
        ...state,
        recentSearches: [...new Set([payload, ...recentSearches])],
      };
    case TYPES.CACHE_SEARCH_SESSION_ID:
      if (state.currentSessionId === '') return state;
      return {
        ...state,
        cachedSessionId: state.currentSessionId,
        currentSessionId: '',
      };
    case TYPES.RESET_SEARCH_SESSION_ID_CACHE:
      return {
        ...state,
        cachedSessionId: '',
      };
    case TYPES.SET_CURRENT_SEARCH_SESSION_ID_FROM_CACHE:
      if (state.cachedSessionId === '') return state;
      return {
        ...state,
        currentSessionId: state.cachedSessionId,
      };
    case TYPES.SET_SEARCH_OVERLAY_VISIBLE:
      return {
        ...state,
        overlayVisible: payload,
      };
    case TYPES.SET_SEARCH_CACHED_QUERY:
      return {
        ...state,
        cachedQuery: payload,
      };
    case TYPES.SET_SEARCH_CURRENT_QUERY:
      return {
        ...state,
        currentQuery: payload,
      };
    case TYPES.SET_SEARCH_CURRENT_INPUT_VALUE:
      return {
        ...state,
        currentSearchInputValue: payload,
      };
    case TYPES.SET_SEARCH_CURRENT_SESSION_ID:
      return {
        ...state,
        currentSessionId: payload,
      };
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
