import { CardNumberElement } from '@stripe/react-stripe-js';
import {
  createStripeClientSetupIntent,
  createStripeClientPaymentIntent,
} from '../../../graphql/mutations';
import {
  axiosInstance,
  callGraphqlWithTokenAsync,
} from '../../../store/helpers';
import { PAYMENT_NONCE_ROUTE } from '../../../store/apiRoutes';
import { toCents } from '../../../utils/currencyHelper';

export const getPaymentNonce = async ({
  associatedAccountReferenceId,
  lastFourCardNumber,
  reservationDateTime,
  forwardEndMerchantId,
  ccid,
  profileReferenceId,
}) => {
  try {
    const { data: response } = await axiosInstance.post(PAYMENT_NONCE_ROUTE, {
      associatedAccountReferenceId,
      lastFourCardNumber,
      reservationDateTime,
      forwardEndMerchantId,
      profileReferenceId,
      ccid,
    });

    return response.paymentMethodNonce;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

//payment handler
export const handlePaymentIntentStripe = async (
  stripe,
  elements,
  totalAmount,
  sevenRoomsVenueId,
  name
) => {
  if (!stripe || !elements) {
    return {
      error: { code: 1001, message: 'Stripe is not initialized/available' },
      statusType: 'fail',
    };
  }

  const cardElement = elements.getElement(CardNumberElement);

  const amount = toCents(totalAmount);

  const billingDetails = {
    name,
  };

  let paymentMethodReq;
  try {
    paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails,
    });
  } catch (error) {
    console.error('Error creating payment method:', error);

    return { statusType: 'fail' };
  }

  if (paymentMethodReq.error) {
    return { error: paymentMethodReq.error, statusType: 'fail' };
  }

  const { data: response } = await callGraphqlWithTokenAsync({
    query: createStripeClientPaymentIntent,
    variables: {
      sevenRoomsVenueId,
      amount,
    },
  });

  const result = await stripe.confirmCardPayment(
    response.createStripeClientPaymentIntent?.clientSecret,
    {
      payment_method: paymentMethodReq.paymentMethod.id,
    }
  );
  if (result.error) {
    return {
      error: { code: 1002, message: result.error.message },
      statusType: 'fail',
    };
  }

  if (
    result.paymentIntent &&
    result.paymentIntent.status === 'requires_capture'
  ) {
    return {
      statusType: 'success',
      intentId: result.paymentIntent.id,
    };
  }
};

//setup intent handler
export const handleSetupIntentStripe = async (
  stripe,
  elements,
  sevenRoomsVenueId,
  name
) => {
  if (!stripe || !elements) {
    return {
      error: { code: 1001, message: 'Stripe is not initialized/available' },
      statusType: 'fail',
    };
  }

  const cardElement = elements.getElement(CardNumberElement);
  const billingDetails = {
    name,
  };

  const paymentMethodReq = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    billing_details: billingDetails,
  });

  if (paymentMethodReq.error) {
    return { error: paymentMethodReq.error, statusType: 'fail' };
  }

  const { data: response } = await callGraphqlWithTokenAsync({
    query: createStripeClientSetupIntent,
    variables: { sevenRoomsVenueId },
  });

  const result = await stripe.confirmCardSetup(
    response.createStripeClientSetupIntent?.clientSecret,
    {
      payment_method: paymentMethodReq.paymentMethod.id,
    }
  );

  if (result.error) {
    return {
      error: { code: 1002, message: result.error.message },
      statusType: 'fail',
    };
  }

  if (result.setupIntent && result.setupIntent.status === 'succeeded') {
    return {
      statusType: 'success',
      intentId: result.setupIntent.id,
    };
  }
};
