import React from 'react';
import Typography from '../overrides/Typography';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const EllipsisText = ({ variant, text, className, maxWidth, ...restProps }) => {
  const classes = useStyles();
  return (
    <Typography
      className={clsx(classes.root, className)}
      variant={variant}
      maxWidth={maxWidth}
      {...restProps}>
      {text}
    </Typography>
  );
};

export default EllipsisText;
