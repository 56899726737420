import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard } from 'swiper/modules';
import 'swiper/css';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Pagination } from './Pagination';
import PropTypes from 'prop-types';
import {
  CAROUSEL_TEXT,
  HOME_HERO_CAROUSEL_TITLE,
  SLIDE_TEXT,
} from '../../../assets/copy';
import { CarouselContent } from './CarouselContent';
import { SectionTitle } from '../../spoon/SectionTitle';
import PageSection from '../PageSection';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  swiperContainer: {
    width: '100%',
    height: '568px',
    [theme.breakpoints.up('xxl')]: {
      height: '700px',
    },
  },
  containerStartSlot: {
    position: `absolute`,
    zIndex: 10,
    width: '100%',
  },
  containerEndSlot: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: `column`,
    justifyContent: `flex-end`,
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'white',
    borderTopRightRadius: '146px',
    gap: theme.spacing(2),
    zIndex: 10,
    marginRight: theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(3.5),
    paddingRight: theme.spacing(5),
    maxWidth: theme.spacing(46),
    boxSizing: 'border-box',
    [theme.breakpoints.up('xxl')]: {
      maxWidth: theme.spacing(66),
      bottom: theme.spacing(7),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(5),
      paddingRight: theme.spacing(8),
    },
  },
  contentContainerNoPagination: {
    [theme.breakpoints.up('xxl')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  slideImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const HeroCarousel = ({ pagination, slides }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xxl'));
  const classes = useStyles();

  const handleSlideSwipe = () => {
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'swipe',
      ui_interaction_element_name: 'hero-carousel-slide',
      ui_interaction_element_type: 'Swiper',
    });
  };

  const handleMobileClick = (event) => {
    const learnMoreOnClick = slides[activeIndex].learnMoreOnClick;
    if (
      isDesktop ||
      !learnMoreOnClick ||
      event.target.tagName === 'BUTTON' ||
      event.target.tagName === 'SPAN' ||
      event.target.tagName === 'svg' ||
      event.target.tagName === 'path' ||
      event.target.id === 'button-container' ||
      event.target.id === 'pagination-container' ||
      event.target.parentElement?.id === 'pagination-container'
    )
      return;
    learnMoreOnClick();
  };

  return (
    <Swiper
      modules={[Keyboard]}
      keyboard={{ enabled: true }}
      role="region"
      aria-roledescription={CAROUSEL_TEXT}
      aria-label={HOME_HERO_CAROUSEL_TITLE}
      className={classes.swiperContainer}
      slidesPerView={1}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      onTouchEnd={handleSlideSwipe}>
      {slides.map((slide, index) => (
        <SwiperSlide
          key={`slide-${index}`}
          role="group"
          aria-roledescription={SLIDE_TEXT}
          aria-label={`${slide.heading} ${index + 1} of ${slides.length}`}>
          <img
            src={slide.imageUrl}
            className={classes.slideImage}
            alt=""
            onClick={!isDesktop ? slide.learnMoreOnClick : null}
          />
        </SwiperSlide>
      ))}
      <div slot="container-start" className={classes.containerStartSlot}>
        <PageSection>
          <SectionTitle
            text={HOME_HERO_CAROUSEL_TITLE}
            color="light"
            size={isDesktop ? 'large' : 'normal'}
            withField
          />
        </PageSection>
      </div>
      <div slot="container-end" className={classes.containerEndSlot}>
        <PageSection>
          <div
            className={clsx(classes.contentContainer, {
              [classes.contentContainerNoPagination]: !(
                pagination && slides.length > 1
              ),
            })}
            onClick={handleMobileClick}>
            <CarouselContent
              label={slides[activeIndex].label}
              heading={slides[activeIndex].heading}
              subheading={slides[activeIndex].subheading}
              description={
                slides[activeIndex].descriptionShort
                  ? slides[activeIndex].descriptionShort
                  : slides[activeIndex].description
              }
              learnMoreOnClick={slides[activeIndex].learnMoreOnClick}
              primaryButtonText={slides[activeIndex].primaryButtonText}
              primaryButtonOnClick={slides[activeIndex].primaryButtonOnClick}
              secondaryButtonText={slides[activeIndex].secondaryButtonText}
              secondaryButtonOnClick={
                slides[activeIndex].secondaryButtonOnClick
              }
            />
            {pagination && slides.length > 1 && (
              <Pagination
                length={slides.length}
                activeIndex={activeIndex}
                paginationTracker={{
                  paginationPreviousButtonName: 'hero-scroll-prev-button',
                  paginationNextButtonName: 'hero-scroll-next-button',
                  paginationIndexName: 'hero-scroll-index-button',
                }}
              />
            )}
          </div>
        </PageSection>
      </div>
    </Swiper>
  );
};

HeroCarousel.propTypes = {
  pagination: PropTypes.bool,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      heading: PropTypes.string,
      subheading: PropTypes.string,
      description: PropTypes.string,
      descriptionShort: PropTypes.string,
      label: PropTypes.string,
      learnMoreOnClick: PropTypes.func,
      primaryButtonText: PropTypes.string,
      primaryButtonOnClick: PropTypes.func,
      secondaryButtonText: PropTypes.string,
      secondaryButtonOnClick: PropTypes.func,
    })
  ),
};

export default HeroCarousel;
