import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '../../overrides/Typography';
import {
  AVAILABLE_TIMES_ALT_TEXT,
  EDIT_RESERVATION_NO_PAYMENT_ALLOWED,
} from '../../../assets/copy';
import PlaceholderLoader from '../../loading/PlaceholderLoader';
import { useViewport } from '../../../utils/useViewport';
import useReservationMethods from '../../../utils/useReservationMethods';
import InfoBlock from '../InfoBlock';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetEditReservationInfoBlock } from '../../../store/Reservations/ReservationsAction';
import { useComponentWillUnmount } from '../../../utils/useComponentWillUnmount';
import { TIME_AVAILABILITY_TYPES } from '../../../pages/venueAvailability/timeAvailabilityTypes';
import AvailabilityList from '../../../pages/venueAvailability/AvailabilityList';

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.up('xl')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  times: {
    [theme.breakpoints.up('lg')]: {
      boxSizing: 'border-box',
      width: '587px',
    },
  },
  subTitle: {
    paddingLeft: theme.spacing(3),
    margin: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: 0,
    },
  },
  divider: {
    margin: theme.spacing(0, 0, 0, -3),
    [theme.breakpoints.up('xl')]: {
      marginLeft: 0,
    },
  },
  timeBasic: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1, 3, 2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
  infoBlockContainer: {
    margin: theme.spacing(2, 3),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

const widthBreakpoint = 768;

const AvailabilityContainer = ({ title, label, list }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const { selectTimeSlotEdit } = useReservationMethods();
  const { venue } = useSelector((state) => state.venues);
  const { isEditReservationInfoBlockVisible } = useSelector(
    (state) => state?.reservations
  );
  const dispatch = useDispatch();
  const { availability } = useSelector((state) => state?.availability);

  useComponentWillUnmount(() => {
    dispatch(actionSetEditReservationInfoBlock(false));
  });

  useEffect(() => {
    dispatch(actionSetEditReservationInfoBlock(false));
  }, [availability, dispatch]);

  const showAvailabilityList = () => {
    if (!list || list.length === 0) {
      return (
        <>
          <Box className={classes.timeBasic}>
            <PlaceholderLoader length={7} />
          </Box>
        </>
      );
    }

    return (
      <Box>
        <AvailabilityList
          width={width}
          venue={venue}
          list={list}
          type={TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION}
          handleEdit={selectTimeSlotEdit}
        />
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.times}>
        <Typography
          variant={
            width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
          }
          className={classes.subTitle}
          component="h2">
          {title}
        </Typography>
        {label && (
          <>
            <Typography
              variant={
                width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
              }
              className={classes.subTitle}
              component="h2">
              {AVAILABLE_TIMES_ALT_TEXT}
            </Typography>
          </>
        )}
        <Box></Box>
        {isEditReservationInfoBlockVisible ? (
          <Box className={classes.infoBlockContainer}>
            <InfoBlock text={EDIT_RESERVATION_NO_PAYMENT_ALLOWED} />
          </Box>
        ) : (
          showAvailabilityList()
        )}
      </Box>
    </>
  );
};

export default AvailabilityContainer;
