import { Container, Grid, makeStyles } from '@material-ui/core';
import CheckmarkIcon from '../../../assets/icons/checked-filled-dark.svg';
import { useSelector } from 'react-redux';
import Typography from '../../overrides/Typography';
import ReservationCard from '../../core/Card/ReservationCard';
import { Button, TextButton } from '../Button';
import DateTime from '../../../utils/dateTimeHelpers';
import { useCopy } from '../../../utils/useCopy';
import {
  DONE,
  RESERVATION_CONFIRMED_SUBHEADER,
  RESERVATION_CONFIRMED_VIEW_RESERVATION,
} from '../../../assets/copy';
import { useHistory } from 'react-router';
import { FETCH_STATUS } from '../../../utils/constants/FetchStatus';
import { trackClickWithDescription } from '../../../utils/useOneTag';
import { ModalLoading } from './ModalLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  icon: {
    color: '#E9FCE4',
    height: '64px',
    width: '64px',
  },
  ctaContainer: {
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.spoon.white[100],
    padding: theme.spacing(3),
  },
  ctaButton: {
    fontSize: 16,
    lineHeight: '24px',
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    alignItems: 'center',
    padding: theme.spacing(3, 6, 1),
    overflowX: 'hidden',
  },
  subHeader: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
}));

export const ReservationConfirmed = ({ onDone }) => {
  const classes = useStyles();
  const { getCopy } = useCopy();
  const history = useHistory();
  const { reservation, status: reservationStatus } = useSelector(
    (state) => state.reservations
  );

  const oneTagDescription = `venueName: ${reservation?.venue?.name}, reservationId: ${reservation?.referenceCode}`;

  const viewReservation = () => {
    history.push(`/reservations/${reservation?.id}`);
  };

  if (reservationStatus === FETCH_STATUS.FETCHING) {
    return <ModalLoading />;
  }

  return (
    <div className={classes.root}>
      <Container className={classes.contentContainer}>
        <Grid container justify="left" direction="column" alignItems="left">
          <img alt="checked" className={classes.icon} src={CheckmarkIcon} />
          <Typography variant="medium2Semibold" className={classes.subHeader}>
            {RESERVATION_CONFIRMED_SUBHEADER}
          </Typography>
          <Typography variant="medium1Normal">
            {getCopy('RESERVATION_CONFIRMED_CONFIRMATION_EMAIL_TEXT', {
              emailAddress: reservation.email,
            })}
          </Typography>
        </Grid>
        <ReservationCard
          venueName={reservation.venue.name}
          imageUrl={reservation.venue.images[0]}
          reservationDate={new DateTime(reservation.date).toLocaleString({
            weekday: 'short',
            month: 'long',
            day: 'numeric',
          })}
          reservationTime={reservation.time}
          guestCount={reservation.guests}
          isCardholderTable={reservation.isCardholderTable} // confirm this field
          classes={{ container: classes.fullWidth }}
        />
      </Container>
      <Container className={classes.ctaContainer}>
        <Button
          classes={{ root: classes.ctaButton }}
          onClick={() => {
            trackClickWithDescription(
              'bottom-sheet-reservation-confirmed-done',
              'Button',
              oneTagDescription
            );
            onDone();
          }}>
          {DONE}
        </Button>
        <TextButton
          classes={{ root: classes.ctaButton }}
          onClick={() => {
            trackClickWithDescription(
              'bottom-sheet-reservation-confirmed-view-reservations',
              'Button',
              oneTagDescription
            );
            viewReservation();
          }}>
          {RESERVATION_CONFIRMED_VIEW_RESERVATION}
        </TextButton>
      </Container>
    </div>
  );
};
