const palette = {
  button: {
    progressive: {
      default: '#0276B1',
      hover: '#026597',
      active: '#014E74',
    },
    action: {
      default: '#25810E',
      hover: '#1E6A0B',
      active: '#185309',
    },
    regressive: {
      default: '#7D7D7D',
      hover: '#696969',
      active: '#525252',
    },
    destructive: {
      default: '#CC2427',
      hover: '#BA2629',
      active: '#A3282B',
    },
    ghost: {
      default: '#141414',
      hover: '#FFFFFF',
      active: '#141414',
    },
    text: {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
    },
    disabled: '#F4F4F4',
    disabledText: '#7D7D7D',
  },
  checkbox: {
    unchecked: {
      active: '#696969',
      hover: '#0276B1',
      error: '#CC2427',
      disabled: '#D4D4D4',
    },
    checked: {
      active: '#0276B1',
      error: '#CC2427',
      hover: '#013D58',
      disabled: '#D4D4D4',
    },
  },
  radio: {
    unchecked: {
      active: '#696969',
      hover: '#0276B1',
      error: '#CC2427',
      disabled: '#D4D4D4',
    },
    checked: {
      active: '#0276B1',
      hover: '#013D58',
      error: '#CC2427',
      disabled: '#D4D4D4',
    },
  },
  select: {
    active: '#696969',
    hover: '#0276B1',
    error: '#CC2427',
    disabled: '#F4F4F4',
    disabledText: '#7D7D7D',
  },
  tabs: {
    active: '#0276B1',
    hover: '#026597',
  },
  textField: {
    active: '#0276B1',
    hover: '#026597',
    label: '#141414',
    disabled: '#F4F4F4',
  },
};

export default palette;
