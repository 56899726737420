import React, { useContext, createContext, useMemo } from 'react';

/**
 * Class for interfacing wtih Heap Analytics.
 */
class ReactHeap {
  constructor(heapId) {
    this.initialize(heapId);
  }
  DEFAULT_SCRIPT_ID = 'heap-react';
  // script from https://developers.heap.io/docs/web
  getHeapScript = (id) => `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load("${id}");
  `;
  /**
   * initialize is used to attach heap script to the head of the document. Does not update identity
   */
  initialize = (appId) => {
    if (!appId) throw new Error('You need to provide your Heap Analytics ID.');
    if (!document) return;
    const hasScript = !!document.getElementById(this.DEFAULT_SCRIPT_ID);
    if (hasScript) return;
    const script = document.createElement('script');
    script.innerHTML = this.getHeapScript(appId);
    script.id = this.DEFAULT_SCRIPT_ID;
    script.async = true;
    document.body.appendChild(script);
  };

  get userId() {
    return window.heap?.userId;
  }

  get identity() {
    return window.heap?.identity;
  }

  track(eventName, properties) {
    window.heap?.track(eventName, properties);
  }

  identify(userId) {
    window.heap?.identify(userId);
  }

  addUserProperties(properties) {
    window.heap?.addUserProperties(properties);
  }

  resetIdentity() {
    window.heap?.resetIdentity();
  }

  addEventProperties(properties) {
    window.heap?.addEventProperties(properties);
  }

  removeEventProperty(key) {
    window.heap?.removeEventProperty(key);
  }

  clearEventProperties() {
    window.heap?.clearEventProperties();
  }
}

// React context for Heap
const heapContext = createContext();

/**
 * import useHeap to interface with heap within components
 */
export const useHeap = () => {
  return useContext(heapContext);
};

/**
 * used with app.js to provide access to heap throughout the application
 */
export function ProvideHeap({ children }) {
  const heap = useProvideHeap();
  return <heapContext.Provider value={heap}>{children}</heapContext.Provider>;
}

export const useHeapParams = () => {
  const heapEnabled = process.env.REACT_APP_HEAP_ENABLED === 'true';
  const heapId = process.env.REACT_APP_HEAP_ID;

  return {
    heapEnabled,
    heapId,
  };
};

function useProvideHeap() {
  const heapParams = useHeapParams();
  const heap = useMemo(
    () => heapParams.heapEnabled && new ReactHeap(heapParams.heapId),
    [heapParams]
  );

  return {
    isEnabled: heapParams.heapEnabled,
    // below mapping needed for intellisense and react to know that the functions exist,
    // without the following code you will get errors in console even though the code
    // works at runtime.
    // Heap API docs: https://developers.heap.io/reference/client-side-apis-overview
    track: heap.track,
    identify: heap.identify,
    resetIdentity: heap.resetIdentity,
    addUserProperties: heap.addUserProperties,
    addEventProperties: heap.addEventProperties,
    removeEventProperty: heap.removeEventProperty,
    clearEventProperties: heap.clearEventProperties,
  };
}

export const useHeapTrack = () => {
  const { track, isEnabled } = useHeap();

  const PAGES = Object.freeze({
    HOMEPAGE: 'Homepage',
    RESERVATION_BOOKING_PAGE: 'Reservation Booking Page',
    VENUE_DETAILS_PAGE: 'Venue Details Page',
  });

  const EVENTS = Object.freeze({
    HOMEPAGE: Object.freeze({
      CLICK_VENUE_CARD: `${PAGES.HOMEPAGE} - Venue Card - Click`,
    }),
    RESERVATION_BOOKING_PAGE: Object.freeze({
      LOAD: `${PAGES.RESERVATION_BOOKING_PAGE} - Load`,
    }),
    VENUE_DETAILS_PAGE: Object.freeze({
      CLICK_MAP: `${PAGES.VENUE_DETAILS_PAGE} - Map - Click`,
      CLICK_GET_DIRECTIONS: `${PAGES.VENUE_DETAILS_PAGE} - Get Directions - Click`,
      CLICK_PHONE_NUMBER: `${PAGES.VENUE_DETAILS_PAGE} - Phone Number - Click`,
      CLICK_WEBSITE_LINK: `${PAGES.VENUE_DETAILS_PAGE} - Website Link - Click`,
    }),
  });

  const trackPageEvent = (event, properties) => {
    if (!isEnabled) return;
    if (event == null || properties == null) {
      console.warn('Either event or properties is null or undefined');
      return;
    }
    try {
      track(event, properties);
    } catch (error) {
      console.error('Error in calling Heap track');
    }
  };

  return {
    EVENTS,
    trackPageEvent,
  };
};
