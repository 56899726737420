import React from 'react';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../../../components/overrides/Typography';
import generateSafeHtmlTags from '../../../utils/generateSafeHtmlTags';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
}));

const Policy = ({ header, venuePolicy, className }) => {
  const classes = useStyles();
  return (
    <Container className={className}>
      <Box className={classes.title}>
        <Typography variant="medium1Semibold">{header}</Typography>
      </Box>
      <Box>
        <Typography
          dangerouslySetInnerHTML={generateSafeHtmlTags(
            venuePolicy
          )}></Typography>
      </Box>
    </Container>
  );
};

export default Policy;
