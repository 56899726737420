import * as types from './SeatingOptionsTypes';

//seatingOptions list
export const actionSeatingOptionsListRequest = () => ({
  type: types.SEATING_OPTION_LIST_REQUEST,
});

export const actionSeatingOptionsListSuccess = (payload) => ({
  type: types.SEATING_OPTION_LIST_SUCCESS,
  payload,
});

export const actionSeatingOptionsListFail = (payload) => ({
  type: types.SEATING_OPTION_LIST_FAIL,
  payload,
});
