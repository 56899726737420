import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select as MuiSelect,
  Typography,
} from '@material-ui/core';
import DropDownSvg from '../../../assets/icons/dropdown-light.svg';

const useStyles = makeStyles((theme) => ({
  dropDown: {
    padding: theme.spacing(0, 1),
  },
  root: {
    minWidth: 175,
    width: '100%',
    flex: 1,
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: 'fit-content',
      padding: theme.spacing(1.5, 6, 1.5, 2),
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  label: {
    ...theme.typography.small4Normal,
    paddingBottom: theme.spacing(0.25),
  },
}));

export const Select = ({
  classes: classesOverride,
  label,
  ariaLabel,
  onChange,
  value = '',
  children,
}) => {
  const classes = useStyles({ classes: classesOverride });
  const dropdownIcon = (selectProps) => (
    <img
      src={DropDownSvg}
      className={clsx(classes.dropDown, selectProps.className)}
      alt={'drop-down-icon'}
    />
  );
  return (
    <FormControl variant="outlined" className={classes.root}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <MuiSelect
        native
        value={value}
        onChange={onChange}
        inputProps={{ 'aria-label': ariaLabel }}
        IconComponent={dropdownIcon}>
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;

Select.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
