import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { EDIT_DEFAULT_PAYMENT_TITLE } from '../../assets/copy';
import PageSection from '../../components/core/PageSection';
import {
  BASE_PROFILE_PATHNAME,
  EDIT_PROFILE_PATHNAME,
  PROFILE_SUBROUTES,
} from '../../components/profile/constants';
import {
  actionGetUserRequest,
  actionUpdateUserDefaultPaymentAccount,
  actionUpdateUserRequest,
} from '../../store/User/UserAction';
import { useAuth } from '../../utils/useAuth';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import CommonLoading from '../common/CommonLoading';
import DefaultPaymentEditView from './DefaultPaymentEditView';
import DietPreferencePage from './DietPreferencesPage';
import OverviewPage from './OverviewPage';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';
import PreferredCityPage from './PreferredCityPage';
import SpecialDatePage from './SpecialDatePage';

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { navigationMenuVisible } = useSelector((state) => state.appData);
  const shouldOnboard = useOnboardingRedirect();
  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  const isMounted = useMemo(
    () =>
      user.session.isAuthenticated && !shouldOnboard && !user.isRequestPending,
    [user.session, user.isRequestPending, shouldOnboard]
  );

  const goBack = useCallback(() => history.goBack(), [history]);

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assertion == null && !user.isRequestPending) {
      dispatch(actionGetUserRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSavePaymentMethod = (selectedPayment) => {
    if (selectedPayment) {
      dispatch(
        actionUpdateUserDefaultPaymentAccount({
          payment: selectedPayment,
          user,
        })
      );

      // Hide payment method alert when users choose a default method
      // but don't dismiss the message
      dispatch(
        actionUpdateUserRequest({
          hidePaymentMethodAlert: true,
          id: user.id,
        })
      );
    }

    history.push(BASE_PROFILE_PATHNAME);
  };

  return (
    <>
      <CommonLoading active={!isMounted} embedded={true} />
      {isMounted && !navigationMenuVisible && (
        <main>
          <PageSection>
            <Switch>
              <Redirect
                exact
                from={EDIT_PROFILE_PATHNAME}
                to={PROFILE_SUBROUTES.OVERVIEW}
              />
              <Route exact path={PROFILE_SUBROUTES.OVERVIEW}>
                <OverviewPage user={user} />
              </Route>
              <Route path={PROFILE_SUBROUTES.CITY}>
                <PreferredCityPage goBack={goBack} user={user} />
              </Route>
              <Route path={PROFILE_SUBROUTES.SPECIAL_DATES}>
                <SpecialDatePage goBack={goBack} user={user} />
              </Route>
              <Route path={PROFILE_SUBROUTES.PAYMENT_METHODS}>
                <DefaultPaymentEditView
                  handleBack={goBack}
                  title={EDIT_DEFAULT_PAYMENT_TITLE}
                  type={PAYMENT_METHOD_COMPONENT_TYPES.PROFILE}
                  handleSavePayment={handleSavePaymentMethod}
                />
              </Route>
              <Route path={PROFILE_SUBROUTES.DIET_PREFERENCES}>
                <DietPreferencePage goBack={goBack} user={user} />
              </Route>
            </Switch>
          </PageSection>
        </main>
      )}
    </>
  );
};

export default Profile;
