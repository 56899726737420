import React from 'react';
import { ChevronRightRounded } from '@material-ui/icons';
import { Box } from '@material-ui/core';

const TextLink = ({ text, onClickHandler, buttonClassName, className }) => {
  return (
    <Box
      className={buttonClassName}
      onClick={onClickHandler}
      role="link"
      tabIndex={0}>
      {text}
      <ChevronRightRounded className={className} />
    </Box>
  );
};

export default TextLink;
