import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const useSuggestions = () => {
  const { list: cuisines } = useSelector((state) => state.cuisines ?? {});
  const { items: venuesInMarket } = useSelector(
    (state) => state.markets?.market?.venues ?? {}
  );
  const [suggestedCuisines, setSuggestedCuisines] = useState([]);
  const [suggestedRestaurants, setSuggestedRestaurants] = useState([]);

  const resetSuggestions = useCallback(() => {
    setSuggestedRestaurants([]);
    setSuggestedCuisines([]);
  }, [setSuggestedCuisines, setSuggestedRestaurants]);

  const updateSuggestions = useCallback(
    (searchInput) => {
      if (!searchInput) resetSuggestions();
      setSuggestedCuisines(
        cuisines?.filter((cuisine) =>
          cuisine.name.toLowerCase().includes(searchInput)
        ) ?? []
      );
      setSuggestedRestaurants(
        venuesInMarket?.filter((venue) =>
          venue.name.toLowerCase().includes(searchInput)
        ) ?? []
      );
    },
    [cuisines, resetSuggestions, venuesInMarket]
  );

  return {
    resetSuggestions,
    suggestedCuisines,
    suggestedRestaurants,
    updateSuggestions,
  };
};
