import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { PAYMENT_METHODS_CREDIT_CARD } from '../../../assets/copy';
import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    height: theme.spacing(4.5),
  },
  creditCardImage: {
    width: '51px',
    height: '32px',
    borderRadius: theme.spacing(0.5),
  },
  infoBoxTopRow: {
    lineHeight: '20px',
  },
  infoBoxBottomRow: {
    fontSize: 12,
    lineHeight: '15px',
    color: theme.palette.spoon.gray[100],
  },
}));

export const CreditCard = ({
  cardArtUrl,
  productName,
  lastFourCardNumber,
  variant = 'default',
  classes: classesOverride,
}) => {
  const classes = useStyles({ classes: classesOverride });
  return (
    <div className={classes.root}>
      <img src={cardArtUrl} className={classes.creditCardImage} alt="" />
      <div className={classes.infoBox}>
        <Typography variant="medium1Normal" className={classes.infoBoxTopRow}>
          {productName}
          {variant === 'compact' && `...${lastFourCardNumber}`}
        </Typography>
        {variant === 'default' && (
          <Typography
            variant="small1Normal"
            className={classes.infoBoxBottomRow}>
            {`${PAYMENT_METHODS_CREDIT_CARD} ${lastFourCardNumber}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  cardArtUrl: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  lastFourCardNumber: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'compact']),
  classes: PropTypes.object,
};

export default CreditCard;
