import * as types from './PremierLocationsTypes';

//PremierLocation list
export const actionPremierLocationListRequest = () => ({
  type: types.PREMIERLOCATION_LIST_REQUEST,
});

export const actionPremierLocationListSuccess = (payload) => ({
  type: types.PREMIERLOCATION_LIST_SUCCESS,
  payload,
});

export const actionPremierLocationListFail = (payload) => ({
  type: types.PREMIERLOCATION_LIST_FAIL,
  payload,
});
