import * as types from './AnimationsTypes';

export const actionSetAnimationPushUp = (payload) => ({
  type: types.SET_ANIMATION_PUSH_UP,
  payload,
});

export const actionSetAnimationSlideLeft = (payload) => ({
  type: types.SET_ANIMATION_SLIDE_LEFT,
  payload,
});

export const actionSetAnimationSlideRight = (payload) => ({
  type: types.SET_ANIMATION_SLIDE_RIGHT,
  payload,
});

export const actionResetAnimations = () => ({
  type: types.RESET_ANIMATIONS,
});
