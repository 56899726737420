export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          allergy {
            id
            name
          }
        }
      }
      diets {
        items {
          diet {
            id
            name
          }
        }
      }
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hidePhoneNumberAlert
      hideExclusiveTaggingAlert
    }
  }
`;

export const updateReservation7R = /* GraphQL */ `
  mutation UpdateReservation7R($input: Reservation7RUpdateInput!) {
    updateReservation7R(input: $input) {
      id
      sevenRoomsReservationId
      userId
      venueId
      venue {
        id
        name
        address
        city
        state
        zipcode
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        timezoneName
        images
        websiteUrl
        menuUrl
        sevenRoomsVenueId
        phoneNumber
        bookingPolicy
        paymentPolicy
      }
      date
      time
      guests
      status
      isActive
      isCardholderTable
      sevenRoomsStatus
      statusCode
      sevenRoomsCreated
      sevenRoomsUpdated
      sevenRoomsDeleted
      costOption
      referenceCode
      diets
      allergies
      specialOccasions
      notes
      paymentPolicy
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
      firstName
      lastName
      email
      phone
    }
  }
`;
