import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  convertUserAllergies,
  convertUserDiets,
  convertUserReservationItemsForDraft,
} from './reservationFilters';
import {
  actionCreateReservationRequest,
  actionSetDraftReservation,
  actionSetEditReservationInfoBlock,
  actionSetOpenSheet,
  actionUpdateDraftReservation,
} from '../store/Reservations/ReservationsAction';
import { actionSetAnimationPushUp } from '../store/Animations/AnimationsAction';
import moment from 'moment';
import { DATE_FORMAT_YEAR_FIRST } from '../assets/dateFormats';
import { redirectToReservePage } from '../pages/reserve/helpers/reserveHelpers';
import { RESERVATION_TYPE } from './constants/Reservation';
import { PAYMENT_RULE_TYPE } from '../pages/reserve/helpers/paymentAvailabilityTypes';
import createInflection from './inflection/createInflection';
import { GUEST_INFLECTION, VENUE_DETAILS_JOIN } from '../assets/copy';
import { getFullMonthAndDate } from './dateTime';

/**
 * @typedef {Object} Reservation7R
 * @property {string[]?} allergies  // [ "Fish" ]
 * @property {string?} bookingPolicy
 * @property {string} date  // 2024-12-31
 * @property {string[]?} diets  // [ "Keto" ]
 * @property {string} email
 * @property {string} firstName
 * @property {number} guests
 * @property {boolean?} isExclusive
 * @property {boolean?} isInsider
 * @property {string} lastName
 * @property {string?} notes
 * @property {string?} paymentAccountReferenceId
 * @property {string?} paymentAccountCorrelationId
 * @property {number?} paymentGratuity
 * @property {string?} paymentInstrumentToken
 * @property {string?} paymentIntermediary
 * @property {string?} paymentIntermediaryPolicy
 * @property {string?} paymentPolicy
 * @property {string?} paymentProfileReferenceId
 * @property {string?} paymentRule
 * @property {number?} paymentServiceCharge
 * @property {number?} paymentSubTotal
 * @property {number?} paymentTax
 * @property {string} phone // 1234567890
 * @property {string} sevenRoomsVenueId
 * @property {string?} slotName
 * @property {string?} slotOptionId
 * @property {string[]?} specialOccasions // [ "Anniversary" ]
 * @property {string} time  // 1:00 pm
 * @property {string} userId
 * @property {string} venueId
 */

const useReservationMethods = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reservation, draftReservation } = useSelector(
    (state) => state?.reservations
  );
  const { submittedGuests } = useSelector((state) => state?.availability);

  const { allergies, diets, appData } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const handleUpdate = (date, time, timeslot) => {
    // TO DO: we might want to move the handleUpdate here
  };

  const selectTimeSlotEdit = (date, time, timeslot) => {
    const draftReservationEdit = {
      venue: draftReservation.venue,
      userId: reservation.userId,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: reservation.defaultTipAmount,
      allergies: convertUserReservationItemsForDraft(
        allergies?.list,
        reservation.allergies
      ),
      diets: convertUserReservationItemsForDraft(
        diets?.list,
        reservation.diets
      ),
      specialOccasions: convertUserReservationItemsForDraft(
        appData.specialOccasions,
        reservation.specialOccasions
      ),
      notes: reservation.notes || '',
      emailAddress: reservation.email,
      phoneNumber: reservation.phone,
      firstName: reservation.firstName,
      lastName: reservation.lastName,
      ...timeslot,
      time: time,
      date: moment(date).format(DATE_FORMAT_YEAR_FIRST),
      guests: submittedGuests,
      bookingPolicy: timeslot?.bookingPolicy,
    };

    if (draftReservationEdit.requiresCreditCard) {
      dispatch(actionSetEditReservationInfoBlock(true));
      return;
    }
    if (timeslot.slotName === '') {
      dispatch(actionSetDraftReservation(draftReservationEdit));
      dispatch(actionSetAnimationPushUp(true));
      redirectToReservePage(history, {
        type: RESERVATION_TYPE.UPDATE,
      });
    } else {
      dispatch(actionSetDraftReservation(draftReservationEdit));
      dispatch(actionSetOpenSheet());
    }
  };

  const setDraftReservationFromCurrentUser = () => {
    const draft = {
      userId: user.id,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: user.defaultTipAmount,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      emailAddress: user.emailAddress,
      diets: convertUserDiets(user.diets),
      allergies: convertUserAllergies(user.allergies),
      notes: user.notes || '',
    };

    dispatch(actionSetDraftReservation(draft));
  };

  const updateDraftReservationVenue = (venue) => {
    dispatch(
      actionUpdateDraftReservation({
        venueId: venue.id,
        sevenRoomsVenueId: venue.sevenRoomsVenueId,
        isInsider: venue.isInsider,
        bookingPolicy: venue.bookingPolicy,
      })
    );
  };

  const updateDraftReservationTimeslot = (timeslot) => {
    const date = moment(timeslot?.realDateTime).format(DATE_FORMAT_YEAR_FIRST);

    dispatch(
      actionUpdateDraftReservation({
        slotOptionId: timeslot.slotOptionId,
        slotName: timeslot.slotName,
        time: timeslot.timeSlot,
        isExclusive: timeslot.isExclusive,
        date,
        paymentGratuity: timeslot.payment.gratuity ?? 0,
        paymentServiceCharge: timeslot.payment.serviceChargeAmt,
        paymentRule: timeslot.payment.ccPaymentRule || PAYMENT_RULE_TYPE.NONE,
        paymentSubTotal: timeslot.payment.subtotal,
        paymentTax: timeslot.payment.taxAmt,
        paymentPolicy: timeslot.payment.paymentPolicy ?? '',
      })
    );
  };

  /**
   * Updates fields on the draft reservation. Repeat fields are overwritten.
   * @param {Reservation7R} partialReservation
   */
  const updateDraftReservation = (partialReservation) => {
    dispatch(actionUpdateDraftReservation(partialReservation));
  };

  /**
   * Creates a new reservation.
   * @param {Reservation7R} reservation
   */
  const createReservation = (reservation) => {
    dispatch(actionCreateReservationRequest(reservation));
  };

  const getReservationSubtitle = () => {
    return [
      draftReservation.date
        ? getFullMonthAndDate(draftReservation.date)
        : undefined,
      draftReservation.time?.toUpperCase(),
      createInflection(GUEST_INFLECTION, draftReservation.guests),
    ]
      .filter(Boolean)
      .join(VENUE_DETAILS_JOIN);
  };

  return {
    handleUpdate,
    createReservation,
    getReservationSubtitle,
    selectTimeSlotEdit,
    setDraftReservationFromCurrentUser,
    updateDraftReservationVenue,
    updateDraftReservationTimeslot,
    updateDraftReservation,
  };
};

export default useReservationMethods;
