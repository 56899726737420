export const ADD_RECENT_SEARCH = 'ADD_RECENT_SEARCH';
export const CACHE_SEARCH_SESSION_ID = 'CACHE_SEARCH_SESSION_ID';
export const RESET_SEARCH_SESSION_ID_CACHE = 'RESET_SEARCH_SESSION_ID_CACHE';
export const SET_CURRENT_SEARCH_SESSION_ID_FROM_CACHE =
  'SET_CURRENT_SEARCH_SESSION_ID_FROM_CACHE';
export const SET_SEARCH_OVERLAY_VISIBLE = 'SET_SEARCH_OVERLAY_VISIBLE';
export const SET_SEARCH_CACHED_QUERY = 'SET_SEARCH_CACHED_QUERY';
export const SET_SEARCH_INITIAL_MARKET = 'SET_SEARCH_INITIAL_MARKET';
export const SET_SEARCH_CURRENT_QUERY = 'SET_SEARCH_CURRENT_QUERY';
export const SET_SEARCH_CURRENT_INPUT_VALUE = 'SET_SEARCH_CURRENT_INPUT_VALUE';
export const SET_SEARCH_CURRENT_SESSION_ID = 'SET_SEARCH_CURRENT_SESSION_ID';
