import * as types from './MarketsTypes';

//market list
export const actionMarketListRequest = () => ({
  type: types.MARKET_LIST_REQUEST,
});

export const actionMarketListSuccess = (payload) => ({
  type: types.MARKET_LIST_SUCCESS,
  payload,
});

export const actionMarketListFail = (payload) => ({
  type: types.MARKET_LIST_FAIL,
  payload,
});

//get market
export const actionGetMarketRequest = (payload) => ({
  type: types.GET_MARKET_REQUEST,
  payload,
});

export const actionGetMarketSuccess = (payload) => ({
  type: types.GET_MARKET_SUCCESS,
  payload,
});

export const actionGetMarketFail = (payload) => ({
  type: types.GET_MARKET_FAIL,
  payload,
});

export const actionCacheMarketId = (payload) => ({
  type: types.CACHE_MARKET_ID,
  payload,
});

export const actionResetCachedMarketId = () => ({
  type: types.RESET_CACHED_MARKET_ID,
});

// update venue availability from other stores
export const actionUpdateMarketAvailability = (payload) => ({
  type: types.UPDATE_MARKET_AVAILABILITY,
  payload,
});

export const actionClearMarketAvailability = () => ({
  type: types.CLEAR_MARKET_AVAILABILITY,
});

export const actionNavigateToSearch = (payload) => ({
  type: types.NAVIGATE_TO_SEARCH,
  payload,
});
