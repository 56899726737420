import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PROGRESS_BAR_LABEL } from '../../assets/copy';
import Button from '../overrides/Button';
import Typography from '../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    height: 48,
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: 128,
    },
  },
  interactable: {
    backgroundColor: theme.palette.interactionRed.main,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.interactionRed[60],
    },
    '&:active': {
      backgroundColor: theme.palette.interactionRed[70],
    },
  },
  cancelling: {
    backgroundColor: theme.palette.interactionRed[70],
    cursor: 'default',
    '&:hover, &:active': {
      backgroundColor: theme.palette.interactionRed[70],
    },
  },
  circle: {
    color: theme.palette.text.secondary,
  },
}));

const CancelButton = ({ isCancelling, onCancel, text }) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(
        classes.button,
        isCancelling ? classes.cancelling : classes.interactable
      )}
      disableRipple={isCancelling}
      disableFocusRipple={isCancelling}
      onClick={onCancel}
      {...(isCancelling && { 'aria-label': PROGRESS_BAR_LABEL })}>
      {isCancelling ? (
        <CircularProgress
          aria-label={PROGRESS_BAR_LABEL}
          className={classes.circle}
          size={32}
          role="progressbar"
          title={PROGRESS_BAR_LABEL}
        />
      ) : (
        <Typography color="secondary" variant="medium2Normal">
          {text}
        </Typography>
      )}
    </Button>
  );
};

export default CancelButton;
