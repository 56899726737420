import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Typography from '../overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import { formatPhone } from '../../utils/formatPhone';
import {
  EDIT_BUTTON_TEXT,
  EDIT_BUTTON_ALT_TEXT_PREFIX,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 720,
    },
  },
  edit: {
    alignSelf: 'center',
    textAlign: 'end',
    [theme.breakpoints.up('xl')]: {
      alignSelf: 'auto',
      flex: 1,
      order: 3,
      textAlign: 'right',
    },
  },
  editLabel: {
    ...theme.typography.medium1Semibold,
    cursor: 'pointer',
    color: theme.palette.primary[50],
    textDecoration: 'none',
  },
  valueText: {
    color: '#1F1F1F',
  },
}));

const widthBreakpoint = 960;

const EditSection = ({ label, value, type, to }) => {
  // util
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <Grid
      className={classes.container}
      spacing={width > widthBreakpoint ? 0 : 1}
      container
      justify={width > widthBreakpoint ? 'initial' : 'space-between'}>
      <Grid item xs={9} xl={3}>
        <Typography component="h2" variant="medium1Semibold">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={3} xl={false} className={classes.edit}>
        <Link
          aria-label={EDIT_BUTTON_ALT_TEXT_PREFIX + label}
          className={classes.editLabel}
          to={to}>
          {EDIT_BUTTON_TEXT}
        </Link>
      </Grid>
      <Grid item xs={12} xl={6}>
        {typeof value === 'string' ? (
          <Typography
            variant="medium1Normal"
            className={classes.valueText}
            redact={true}>
            {type === 'phone' ? formatPhone(value) : value}
          </Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};

export default EditSection;
