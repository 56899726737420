import { makeStyles } from '@material-ui/core';
import gravityPalette from '../gravity/global/gravityPalette';
import OutlineButton from '../core/OutlineButton';
import { ERROR_SIGNOUT_BUTTON_TEXT } from '../../assets/copy';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: gravityPalette.button.text.default,
    color: theme.palette.interactionBlue[50],
    border: 'none !important',
    '&:hover': {
      backgroundColor: gravityPalette.button.text.hover,
    },
    '&:active': {
      backgroundColor: gravityPalette.button.text.active,
    },
  },
}));

const ErrorSignoutButton = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <OutlineButton
      type="button"
      text={ERROR_SIGNOUT_BUTTON_TEXT}
      onClick={() => history.replace('/sign-out')}
      style={classes.button}
      variant="medium2Normal"
    />
  );
};

export default ErrorSignoutButton;
