import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Grid, GridList } from '@material-ui/core';
import clsx from 'clsx';
import { useViewport } from '../../utils/useViewport';
import PageSection from '../../components/core/PageSection';
import Typography from '../../components/overrides/Typography';
import { useSearchRedirect } from '../../components/search/helpers';
import { actionSetCurrentFilters } from '../../store/Filter/FilterAction';
import { FILTER_TYPES } from '../../utils/constants/FilterTypes';
import { HOME_NEIGHBORHOODS_SUBTITLE } from '../../assets/copy';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { cardHoverStyles } from '../../utils/constants/hoverStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0, 0, 0),
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 0, 3),
    },
  },
  title: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  text: {
    color: theme.palette.primary.dark,
  },
  gridList: {
    margin: `${theme.spacing(1.75, 0, 0)}!important`,
    flexWrap: 'nowrap',
    display: 'flex',
    overflowX: 'scroll',
    gap: theme.spacing(1),
    transform: 'translateZ(0)',
    padding: theme.spacing(0.5, 0.25),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      margin: `${theme.spacing(2.5, 0, 0)}!important`,
      padding: theme.spacing(0.5, 5),
    },
  },
  paper: {
    padding: '8px 16px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 130,
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    ...cardHoverStyles,
    [theme.breakpoints.up('xl')]: {
      minWidth: 'initial',
      width: 'auto !important',
    },
  },
  galleyList: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.white,
  },

  li: {
    width: 'auto!important',
  },
  hidden: {
    width: theme.spacing(1.5),
    minWidth: theme.spacing(1.5),
    visibility: 'hidden',
    display: 'inline-block',
  },
}));

const widthBreakpoint = 960;

const Neighborhoods = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;

  const dispatch = useDispatch();
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const markets = useSelector((state) => state.markets);
  const [filteredNeighborhoods, setFilteredNeighborhoods] = useState([]);
  const listRef = useRef();

  const handleClick = (event, neighborhood) => {
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_id: event.currentTarget.id,
      ui_interaction_element_name: 'homepage-neighborhood',
      ui_interaction_element_type: 'Button',
      ui_interaction_element_description: neighborhood.name,
    });
    dispatch(
      actionSetCurrentFilters({ [FILTER_TYPES.NEIGHBORHOODS]: [neighborhood] })
    );
    history.push('/search', { redirectTo });
  };

  //TODO: Refactor this later on when the data model and relationships for Market and Neighborhoods is updated
  useEffect(() => {
    if (markets?.market && markets?.market?.neighborhoods) {
      const activeNeighborhoods = markets.market.neighborhoods.items.filter(
        (neighborhood) => neighborhood.isActive
      );
      setFilteredNeighborhoods(activeNeighborhoods);
      listRef.current.scrollLeft = 0;
    }
  }, [markets.market]);

  return (
    <PageSection className={classes.root}>
      <Grid container className={classes.galleyList}>
        <Typography
          variant={
            width > widthBreakpoint ? 'medium4Normal' : 'medium2Semibold'
          }
          className={clsx(classes.title, classes.text)}
          component="h2">
          {HOME_NEIGHBORHOODS_SUBTITLE}
        </Typography>
      </Grid>
      <GridList cellHeight="auto" className={classes.gridList} ref={listRef}>
        {isMobile && <div className={classes.hidden}>&nbsp;</div>}
        {filteredNeighborhoods &&
          filteredNeighborhoods.map((item) => (
            <li
              className={classes.paper}
              key={`Neighborhood-li-${item.id}`}
              data-cy="homepage-neighborhood"
              aria-label={`Restaurants in ${item.name} `}
              value={item.id}
              onClick={(e) => handleClick(e, item)}
              onKeyPress={(e) => e.key === 'Enter' && handleClick(e, item)}>
              <div role="link" tabIndex={0}>
                <Typography variant="medium1Normal" className={classes.text}>
                  {item.name}
                </Typography>
              </div>
            </li>
          ))}
        {isMobile && <div className={classes.hidden}>&nbsp;</div>}
      </GridList>
    </PageSection>
  );
};

export default Neighborhoods;
