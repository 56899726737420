import * as types from './AwardsTypes';

//Award list
export const actionAwardListRequest = () => ({
  type: types.AWARD_LIST_REQUEST,
});

export const actionAwardListSuccess = (payload) => ({
  type: types.AWARD_LIST_SUCCESS,
  payload,
});

export const actionAwardListFail = (payload) => ({
  type: types.AWARD_LIST_FAIL,
  payload,
});
