import React, { useMemo } from 'react';
import { usePress } from '@react-aria/interactions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import mediaImg from '../../assets/images/media.png';
import { useViewport } from '../../utils/useViewport';
import VenueResultAvailability from './VenueResultAvailability';
import {
  actionSetDraftReservation,
  actionSetOpenSheet,
} from '../../store/Reservations/ReservationsAction';
import { actionGetVenueRequest } from '../../store/Venues/VenuesAction';
import { actionSetSearchOverlayVisible } from '../../store/Search/SearchAction';
import {
  convertUserAllergies,
  convertUserDiets,
} from '../../utils/reservationFilters';
import {
  COMMA_JOIN,
  VENUE_DETAILS_JOIN,
  EVENT_TEXT,
  VENUE_IMAGE_FROM_TEXT,
  VENUE_TYPE_EVENT,
  SIGNATURE_COLLECTION_TEXT,
} from '../../assets/copy';
import useEventFlag from '../../utils/useEventFlag';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from '../../utils/stringHelperMethods';
import clsx from 'clsx';
import { priceCheck } from '../../utils/venue';
import { imageWidth } from '../core/coreStyles';
import EllipsisText from '../core/EllipsisText';

import { ReactComponent as OldSignatureIcon } from '../../assets/icons/signature-icon.svg';
import { ReactComponent as SignatureIcon } from '../../assets/icons/grv-benefits.svg';
import COLORS from '../core/Badge/colorsEnum';
import { VENUE_TYPES } from '../../utils/constants/FilterTypes';
import { combine7RVenueIdAndExperienceId } from '../../utils/availabilityHelper';
import { redirectToReservePage } from '../../pages/reserve/helpers/reserveHelpers';
import { useFeatureFlags } from '../../utils/useFeatureFlags';

const containerPadding = 24;
const gridRowGap = 16;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
    padding: theme.spacing(3, 0),
    width: '100%',
    overflow: 'visible',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 0),
    },
  },
  wrapper: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridRowGap,
    gridTemplateColumns: `${imageWidth}px 1fr`,
    gridTemplateRows: `${imageWidth}px 1fr`,
    paddingLeft: containerPadding,
    [theme.breakpoints.up('xl')]: {
      cursor: 'initial',
      gridColumnGap: 0,
      gridRowGap: '2px',
      gridTemplateColumns: '114px 1fr',
      gridTemplateRows: 'auto 1fr',
    },
    [theme.breakpoints.up('xxl')]: {
      padding: 0,
    },
  },
  wrapperSpecialEvents: {
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '114px 1fr',
      gridTemplateRows: 'calc(114px / 2) 1fr',
    },
  },
  content: {
    cursor: 'pointer',
    gridColumn: 2,
    gridRow: 1,
    flex: 'auto',
    padding: theme.spacing(0, 3, 0, 0),
    '&:last-child': {
      padding: 0,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(6),
    },
  },
  cardContent: {
    maxWidth: ({ cardContentMaxWidth }) => cardContentMaxWidth,
  },
  image: {
    cursor: 'pointer',
    minWidth: imageWidth,
    gridColumn: 1,
    gridRow: '1 / span 2',
    height: imageWidth,
    borderRadius: 4,
    [theme.breakpoints.up('xl')]: {
      height: 114,
      minWidth: 114,
    },
    position: 'relative',
  },
  availTime: {
    gridColumn: '1 / span 2',
    gridRow: 2,
    marginLeft: '-24px',
    [theme.breakpoints.up('xl')]: {
      gridColumn: 2,
      gridRow: 2,
      marginLeft: 0,
      paddingLeft: theme.spacing(6),
    },
  },
  badgeContainer: {
    position: 'relative',
    '& > div': {
      marginTop: theme.spacing(1),
      position: 'static',
      top: 0,
      left: 0,
    },
  },
  description: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  eventBadge: {
    color: COLORS.event.color,
    fontFamily: 'Optimist',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16px',
    letterSpacing: 0,
    textAlign: 'center',
  },
}));

const widthBreakpoint = 960;

const VenueResult = ({ venue, onVenueSelect, ...cardProps }) => {
  const { width } = useViewport();
  // max-width to apply text-overflow
  const cardContentMaxWidth = useMemo(
    () =>
      (width > 800 ? 800 : width) -
      gridRowGap * 2 -
      containerPadding * 2 -
      imageWidth,
    [width]
  );

  const classes = useStyles({ cardContentMaxWidth });
  const dispatch = useDispatch();
  const history = useHistory();
  const { inLocalMarket } = useSelector((state) => state.venues);
  const { user } = useSelector((state) => state.user);
  const availability = useSelector(
    (state) =>
      state.availability.listAvailabilityVenues[
        combine7RVenueIdAndExperienceId(venue)
      ]
  );
  const { submittedGuests } = useSelector((state) => state.availability);
  const { pressProps } = usePress({ onPress: () => onVenueSelect(venue?.id) });
  const eventStatus = useEventFlag(venue);
  const eventText = eventStatus?.venueHasEvent
    ? convertNullToEmptyString(eventStatus?.renderVenueBadge()?.props?.text) +
      ' ' +
      EVENT_TEXT
    : '';

  const getCuisineLabel = () => venue.cuisines.items?.[0]?.cuisine.name || '';

  const getDescription = (labels) =>
    labels.filter((i) => !!i).join(VENUE_DETAILS_JOIN);

  const getLocationLabel = () =>
    [venue.address, venue.neighborhood.name]
      .filter((item) => item)
      .join(COMMA_JOIN);

  const getScreenReaderDescription = () => {
    return generateScreenReaderText(
      ',',
      eventText,
      venue.name,
      getCuisineLabel(),
      priceCheck(venue?.priceRange),
      venue.address,
      venue.neighborhood.name
    );
  };

  const getCardMediaTitle = (venueName) => {
    return generateScreenReaderText(',', VENUE_IMAGE_FROM_TEXT, venueName);
  };

  const handleSelectTimeSlot = (date, time, timeslot) => {
    const draftReservation = {
      venue,
      userId: user.id,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: user.defaultTipAmount,
      allergies: convertUserAllergies(user.allergies),
      diets: convertUserDiets(user.diets),
      notes: user.notes || '',
      emailAddress: user.emailAddress,
      specialOccasions: [],
      phoneNumber: user.phoneNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      ...timeslot,
      date,
      time: time,
      guests: submittedGuests,
    };

    if (timeslot.slotName === '') {
      dispatch(actionSetSearchOverlayVisible(false));
      dispatch(actionSetDraftReservation(draftReservation));
      dispatch(actionGetVenueRequest(draftReservation.venue.id));
      redirectToReservePage(history);
    } else {
      dispatch(actionSetDraftReservation(draftReservation));
      dispatch(actionGetVenueRequest(draftReservation.venue.id));
      dispatch(actionSetOpenSheet());
    }
  };

  const isEvent = venue.venueType === VENUE_TYPES.EVENT;

  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();

  return (
    <Card className={classes.root} {...cardProps}>
      <Box
        className={clsx(
          classes.wrapper,
          isEvent && classes.wrapperSpecialEvents
        )}>
        <CardMedia
          {...pressProps}
          className={classes.image}
          image={venue.images[0] || mediaImg}
          alt="venue image"
          role="link"
          tabIndex={0}
          aria-label={getCardMediaTitle(venue?.name)}
        />
        <CardContent
          {...pressProps}
          className={classes.content}
          role="link"
          aria-label={getScreenReaderDescription()}
          tabIndex={0}>
          <Box aria-hidden>
            {isEvent && (
              <Typography
                variant="small4Semibold"
                className={classes.eventBadge}>
                {VENUE_TYPE_EVENT}
              </Typography>
            )}
            <EllipsisText
              variant={
                width > widthBreakpoint ? 'medium2Normal' : 'medium1Normal'
              }
              className={classes.cardContent}
              text={venue.name}
            />
            <Box className={classes.description}>
              {!refreshLandingPageFlagIsEnabled && venue.isSignatureCollection && (
                <OldSignatureIcon className={classes.icon} />
              )}
              <EllipsisText
                variant="small2Normal"
                className={classes.cardContent}
                text={getDescription([
                  getCuisineLabel(),
                  venue.priceRange,
                  width > widthBreakpoint ? getLocationLabel() : '',
                ])}
              />
            </Box>
            {width <= widthBreakpoint && (
              <EllipsisText
                variant="small2Normal"
                className={classes.cardContent}
                text={getLocationLabel()}
              />
            )}
            {refreshLandingPageFlagIsEnabled && venue.isSignatureCollection && (
              <Box className={classes.description}>
                <SignatureIcon className={classes.icon} />
                <EllipsisText
                  variant="small2Normal"
                  className={classes.cardContent}
                  text={SIGNATURE_COLLECTION_TEXT}
                />
              </Box>
            )}
          </Box>
        </CardContent>
        {inLocalMarket && (
          <VenueResultAvailability
            availability={availability}
            className={classes.availTime}
            handleClick={handleSelectTimeSlot}
            venueId={venue.id}
            venueStartOfDay={venue.startOfDay}
            venueParent={venue}
          />
        )}
      </Box>
    </Card>
  );
};

export default VenueResult;
