import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Backdrop, Fade } from '@material-ui/core';

import Typography from '../../components/overrides/Typography';
import OutlineButton from '../../components/core/OutlineButton';
import GravityButton from '../gravity/button';
import {
  RESERVATION_EDIT_CANCEL_MODAL_DESCRIPTION,
  RESERVATION_EDIT_CANCEL_MODAL_NO_BUTTON_TEXT,
  RESERVATION_EDIT_CANCEL_MODAL_TITLE,
  RESERVATION_CANCEL_MODAL_YES_EXIT_BUTTON_TEXT,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    padding: theme.spacing(4),
    borderRadius: 4,
    position: 'static',
    width: '480px',
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      top: '80px',
    },
  },
  description: {
    margin: theme.spacing(3, 0),
  },
  yesBtn: {
    backgroundColor: theme.palette.red[50],
    color: theme.palette.text.secondary,
    border: `${theme.palette.red[50]} !important`,
    '&:hover': {
      border: `${theme.palette.red[50]} !important`,
    },
    width: 'auto',
  },
  noBtn: {
    color: theme.palette.gray[50],
    border: 'none !important',
    '&:hover': {
      border: 'none !important',
    },
    width: '30%',
    maxWidth: 'initial',
  },
}));

const CancelEditModal = ({
  handleToggleEditModal,
  handleCancelEdit,
  modalOpen,
}) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={modalOpen}
      onClose={handleToggleEditModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={modalOpen}>
        <Box className={classes.paper}>
          <Typography variant="medium3Light">
            {RESERVATION_EDIT_CANCEL_MODAL_TITLE}
          </Typography>
          <Typography variant="medium1Normal" className={classes.description}>
            {RESERVATION_EDIT_CANCEL_MODAL_DESCRIPTION}
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <OutlineButton
              text={RESERVATION_EDIT_CANCEL_MODAL_NO_BUTTON_TEXT}
              onClick={handleToggleEditModal}
              style={classes.noBtn}
            />
            <GravityButton variant="destructive" onClick={handleCancelEdit}>
              {RESERVATION_CANCEL_MODAL_YES_EXIT_BUTTON_TEXT}
            </GravityButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CancelEditModal;
