import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    minWidth: 77,
    minHeight: 40,
    margin: theme.spacing(0.5, 0.9, 0.5, 0),
    backgroundColor: theme.palette.gray[5],
    borderRadius: theme.spacing(0.5),
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

const PlaceholderLoader = ({ length = 10 }) => {
  const classes = useStyles();
  return [...Array(length)].map((el, index) => (
    <Skeleton
      key={`loading=${index}`}
      className={classes.root}
      variant="rect"
    />
  ));
};

export default PlaceholderLoader;
