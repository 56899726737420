import {
  NAVIGATION_DINING_EXPERIENCE,
  NAVIGATION_PREMIER_LOCATIONS,
  NAVIGATION_RESTAURANTS,
  NAVIGATION_VIEW_PROFILE,
  NAVIGATION_VIEW_RESERVATIONS,
  NAVIGATION_VIEW_SIGN_OUT,
} from '../../assets/copy';

const createLink = ({ label, path, variant, dataCy, handler = null }) =>
  Object.freeze({ label, path, variant, dataCy, handler });

export const DESKTOP_NAVIGATION_LINKS = Object.freeze([
  createLink({
    label: NAVIGATION_RESTAURANTS,
    path: '/',
    variant: 'small4Normal',
    dataCy: 'nav-link-restaurants',
  }),
  createLink({
    label: NAVIGATION_PREMIER_LOCATIONS,
    path: '/premier-location',
    variant: 'small4Normal',
    dataCy: 'nav-link-premier-dining',
  }),
  createLink({
    label: NAVIGATION_DINING_EXPERIENCE,
    path: '/dining-experience',
    variant: 'small4Normal',
    dataCy: 'nav-link-dining-experience',
  }),
]);

export const DESKTOP_USER_NAVIGATION_LINKS = Object.freeze([
  createLink({
    label: NAVIGATION_VIEW_RESERVATIONS,
    path: '/reservations',
    variant: 'small4Normal',
    dataCy: 'nav-link-reservations',
  }),
  createLink({
    label: NAVIGATION_VIEW_PROFILE,
    path: '/profile',
    variant: 'small4Normal',
    dataCy: 'nav-link-profile',
  }),
  createLink({
    label: NAVIGATION_VIEW_SIGN_OUT,
    path: '/sign-out',
    variant: 'small4Normal',
    dataCy: 'nav-link-sign-out',
  }),
]);

export const MOBILE_NAVIGATION_LINKS = Object.freeze([
  createLink({
    label: NAVIGATION_RESTAURANTS,
    path: '/',
    dataCy: 'mobile-nav-link-restaurants',
  }),
  createLink({
    label: NAVIGATION_PREMIER_LOCATIONS,
    path: '/premier-location',
    dataCy: 'mobile-nav-link-premier-locations',
  }),
  createLink({
    label: NAVIGATION_DINING_EXPERIENCE,
    path: '/dining-experience',
    dataCy: 'mobile-nav-link-dining-experience',
  }),
  createLink({
    label: NAVIGATION_VIEW_RESERVATIONS,
    path: '/reservations',
    dataCy: 'mobile-nav-link-reservations',
  }),
  createLink({
    label: NAVIGATION_VIEW_PROFILE,
    path: '/profile',
    dataCy: 'mobile-nav-link-profile',
  }),
  createLink({
    label: NAVIGATION_VIEW_SIGN_OUT,
    path: '/sign-out',
    dataCy: 'mobile-nav-link-sign-out',
  }),
]);
