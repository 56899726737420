import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'calc(100% * 696 / 1120) calc(100% * 408 / 1120)',
    gridTemplateRows: 'repeat(2, 204px)',
  },
}));

const VenueImageGrid = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.grid, className)} {...props}>
      {children}
    </Box>
  );
};

export default VenueImageGrid;
