import React from 'react';
import { makeStyles } from '@material-ui/core';
import NoAvailabilityInfo from '../venueAvailability/NoAvailabilityInfo';
import createInflection from '../../utils/inflection/createInflection';
import { useCopy } from '../../utils/useCopy';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { venueAvailabilityStyles } from './helpers/venueAvailabilityStyles';
import AvailabilityTimeslots from './AvailabilityTimeslots';
import {useFeatureFlags} from "../../utils/useFeatureFlags";
import NoCalendarAvailabilityInfo from "../venueAvailability/NoCalendarAvailabilityInfo";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  ...venueAvailabilityStyles(theme),
}));

const VenueAvailability = ({
  hasAvailabilityForSelectedDay,
  hasAvailabilityForNext7Days,
  selectedDateString,
  next7DaysAvailability,
  venue,
  guests,
  type,
  selectedDateAvailabilities,
  selectTimeSlot,
  loadedAvailability,
  action,
  onMount,
  time,
  date,
  showMore,
}) => {
  const classes = useStyles({ type: TIME_AVAILABILITY_TYPES.HORIZONTAL });
  const { getCopy } = useCopy();
  const {refreshAvailabilityCalendarFlagIsEnabled: shouldDisplayCalendar} = useFeatureFlags();
  const calendarVisibleMonth = useSelector((state) => state.availability.calendarVisibleMonth);



  const renderHasNoAvailabilityForDateRangeMessage = () => {
    if (shouldDisplayCalendar) {
      return (
        <NoCalendarAvailabilityInfo
          containerClassName={classes.noAvailabilityInfoContainer}
          contentClassName={classes.noAvailabilityInfoContent}
          date={calendarVisibleMonth}
          useDivider={true}
          dividerClassName={classes.divider}
          type={type}
        />
      );
    } else {
      let text = getCopy('AVAILABILITY_NO_TABLES_ON_SELECTED_DATE', {
        guestsInflection: createInflection('guest', guests),
        selectedDate: selectedDateString,
      });
      return (
        <NoAvailabilityInfo
          containerClassName={classes.noAvailabilityInfoContainer}
          contentClassName={classes.noAvailabilityInfoContent}
          text={text}
          useDivider={true}
          dividerClassName={classes.divider}
          type={type}
        />
      );
    }
  };

  return (
    <AvailabilityTimeslots
      hasAvailabilityForSelectedDay={hasAvailabilityForSelectedDay}
      hasAvailabilityForNext7Days={hasAvailabilityForNext7Days}
      selectedDateString={selectedDateString}
      next7DaysAvailability={next7DaysAvailability}
      venue={venue}
      guests={guests}
      type={type}
      selectedDateAvailabilities={selectedDateAvailabilities}
      selectTimeSlot={selectTimeSlot}
      loadedAvailability={loadedAvailability}
      action={action}
      onMount={onMount}
      time={time}
      date={date}
      showMore={showMore}
      renderHasNoAvailabilityForDateRangeMessage={
        renderHasNoAvailabilityForDateRangeMessage
      }
    />
  );
};

export default VenueAvailability;
