import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import * as TYPES from './AppDataTypes';

const initialState = {
  specialOccasions: [],
  navigationMenuVisible: false,
};

export default function appDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  if (type === TYPES.SPECIAL_OCCASIONS_LIST_SUCCESS) {
    return {
      ...state,
      specialOccasions: payload,
    };
  } else if (type === TYPES.SET_NAVIGATION_MENU_VISIBLE) {
    return {
      ...state,
      navigationMenuVisible: payload,
    };
  } else if (type === SIGNOUT_USER_SUCCESS) {
    return initialState;
  }
  return state;
}
