import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  controlContainer: {
    position: 'relative',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '50%',
      paddingTop: 0,
      width: '100%',
    },
  },
}));

const SearchControlContainer = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.controlContainer, className)} {...props}>
      {children}
    </Box>
  );
};

export default SearchControlContainer;
