
export const swipeSnapGridPrev = (swiperInstance) => {
    snapGrid(swiperInstance);
}

export const swipeSnapGridNext = (swiperInstance) => {
    // Assigning to snapGrid fixes the weird scrollable behavior because of the width calculation of Swiper
    swiperInstance.snapGrid = [...swiperInstance?.slidesGrid];
    swiperInstance.snapGrid[0] = swiperInstance?.slidesGrid[0];
}

export const snapGrid = (swiperInstance) => {
    swiperInstance.snapGrid = [...swiperInstance?.slidesGrid];
}
