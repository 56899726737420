import BigFilledHeartIcon from '../../assets/icons/bigFilledHeart.svg';
import BrunchIcon from '../../assets/icons/brunch.svg';
import OutdoorIcon from '../../assets/icons/outdoor.svg';
import SpecialEventsIcon from '../../assets/icons/specialEvents.svg';
import GroupsIcon from '../../assets/icons/groups.svg';
import ChefIcon from '../../assets/icons/chef.svg';
import {  
    OUTDOOR_LABEL,
    BIG_GROUP_LABEL,
    BRUNCH_LABEL,
    DATE_NIGHT_LABEL,
    CHEFS_PICK_LABEL,
    SPECIAL_EVENTS_LABEL
} from '../../assets/copy';

export const iconLib = Object.freeze({
  date_night: {
    image: (
      <img
        src={BigFilledHeartIcon}
        style={{
          transform: `translateY(20%)`,
          filter:
            'invert(43%) sepia(4%) saturate(80%) hue-rotate(314deg) brightness(44%) contrast(79%)',
        }}
        alt=""
      />
    ),
    name: DATE_NIGHT_LABEL,
  },
  brunch: {
    image: (
      <img
        src={BrunchIcon}
        style={{
          transform: `translateY(20%)`,
          filter: 'brightness(44%)',
        }}
        alt=""
      />
    ),
    name: BRUNCH_LABEL,
  },
  outdoor: {
    image: (
      <img
        src={OutdoorIcon}
        style={{ transform: `translateY(20%)`, filter: 'brightness(44%)' }}
        alt=""
      />
    ),
    name: OUTDOOR_LABEL,
  },
  special_events: {
    image: (
      <img
        src={SpecialEventsIcon}
        style={{ transform: `translateY(20%)`, filter: 'brightness(44%)' }}
        alt=""
      />
    ),
    name: SPECIAL_EVENTS_LABEL,
  },
  big_group: {
    image: (
      <img
        src={GroupsIcon}
        style={{ transform: `translateY(20%)`, filter: 'brightness(44%)' }}
        alt=""
      />
    ),
    name: BIG_GROUP_LABEL,
  },
  chefs_pick: {
    image: (
      <img
        src={ChefIcon}
        style={{ transform: `translateY(20%)`, filter: 'brightness(44%)' }}
        alt=""
      />
    ),
    name: CHEFS_PICK_LABEL,
  },
});
  
export  const filledIconLib = Object.freeze({
    date_night: {
      image: (
        <img
          src={BigFilledHeartIcon}
          style={{ transform: `translateY(20%)` }}
          alt=""
        />
      ),
      name: DATE_NIGHT_LABEL,
    },
    brunch: {
      image: (
        <img
          src={BrunchIcon}
          style={{
            transform: `translateY(20%)`,
            filter:
              'invert(23%) sepia(30%) saturate(5411%) hue-rotate(181deg) brightness(76%) contrast(98%)',
          }}
          alt=""
        />
      ),
      name: BRUNCH_LABEL,
    },
    outdoor: {
      image: (
        <img
          src={OutdoorIcon}
          style={{
            transform: `translateY(20%)`,
            filter:
              'invert(23%) sepia(30%) saturate(5411%) hue-rotate(181deg) brightness(76%) contrast(98%)',
          }}
          alt=""
        />
      ),
      name: OUTDOOR_LABEL,
    },
    special_events: {
      image: (
        <img
          src={SpecialEventsIcon}
          style={{
            transform: `translateY(20%)`,
            filter:
              'invert(23%) sepia(30%) saturate(5411%) hue-rotate(181deg) brightness(76%) contrast(98%)',
          }}
          alt=""
        />
      ),
      name: SPECIAL_EVENTS_LABEL,
    },
    big_group: {
      image: (
        <img
          src={GroupsIcon}
          style={{
            transform: `translateY(20%)`,
            filter:
              'invert(23%) sepia(30%) saturate(5411%) hue-rotate(181deg) brightness(76%) contrast(98%)',
          }}
          alt=""
        />
      ),
      name: BIG_GROUP_LABEL,
    },
    chefs_pick: {
      image: (
        <img
          src={ChefIcon}
          style={{
            transform: `translateY(20%)`,
            filter:
              'invert(23%) sepia(30%) saturate(5411%) hue-rotate(181deg) brightness(76%) contrast(98%)',
          }}
          alt=""
        />
      ),
      name: CHEFS_PICK_LABEL,
    },
  });