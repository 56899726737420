import React, { useCallback, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Welcome from './Welcome';
import Location from './Location';
import LogoSvg from '../../assets/icons/logo-white.svg';
import { actionSetAnimationSlideLeft } from '../../store/Animations/AnimationsAction';
import Transition from '../../components/animation/Transition';
import { useAuth } from '../../utils/useAuth';
import { useHistory, useLocation } from 'react-router';
import { ONBOARDING_LOCATION_INPUT_PLACEHOLDER } from '../../assets/copy';
import OnboardingLoading from '../common/OnboardingLoading';
import { getPermalinkRouteRedirectLocation } from './onboardingHelpers';
import useOnboarding from '../../utils/useOnboarding';
import {
  getPermalinkRouteRedirect,
  clearPermalinkRouteRedirect,
  clearPermalinkSlug,
} from '../../utils/sessionStorageHelper';

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    position: 'fixed',
    left: theme.spacing(3),
    top: 12,
    zIndex: 99,
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(6),
    },
  },
  gradient: {
    background: `linear-gradient(to bottom, rgba(0,0,0,0), ${theme.palette.background.blue} 95%)`,
    position: 'fixed',
    height: '40%',
    top: 0,
    width: '100%',
    zIndex: -1,
    [theme.breakpoints.up('xl')]: {
      background:
        'linear-gradient(to left, rgba(0,0,0,0), rgb(16, 37, 63, 0.7) 20%, rgb(16, 37, 63, 1) 45%)',
      height: '100%',
    },
  },
}));

const Onboarding = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const markets = useSelector((state) => state.markets);
  const [list, setList] = useState(markets.list);
  const [shouldOnboard, setShouldOnboard] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { handleRestaurant } = useOnboarding();

  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user == null) return;
    if (!user.session?.isAuthenticated) return;

    //clear session storage for redirection when user gets to the FTUX
    if (getPermalinkRouteRedirect() === '/onboarding') {
      clearPermalinkRouteRedirect();
    }

    // Skip onboarding on load if user already selected default city
    if (user.marketId != null && activeStep === 0) {
      setShouldOnboard(false);
      history.push({
        pathname: getPermalinkRouteRedirectLocation(),
        search: location.search,
      });
    }
  }, [activeStep, user, history, location]);

  useEffect(() => {
    try {
      const result = {
        id: '0',
        name: ONBOARDING_LOCATION_INPUT_PLACEHOLDER,
      };
      setList([result, ...markets.list]);
    } catch (e) {
      console.error(e);
    }
  }, [markets?.list]);

  const dispatch = useDispatch();
  const totalSteps = 2;

  const handleNext = useCallback(() => {
    dispatch(actionSetAnimationSlideLeft(true));
    setActiveStep((activeStep) => activeStep + 1);
  }, [dispatch, setActiveStep]);

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#10253F';
    return () => {
      document.body.style.backgroundColor = 'white';
      clearPermalinkSlug();
      clearPermalinkRouteRedirect();
    };
  }, []);

  useEffect(() => {
    // Show onboarding loader for 7 seconds before showing FTUX
    setTimeout(() => {
      setIsLoading(false);
    }, 7000);
  }, []);

  if (shouldOnboard && isLoading) {
    return <OnboardingLoading active={isLoading} />;
  }

  return (
    shouldOnboard &&
    user.session.isAuthenticated && (
      <>
        <Box className={classes.gradient} />
        <Transition timeout={0} delay={1350}>
          <img src={LogoSvg} className={classes.img} alt="" />
        </Transition>
        <Transition timeout={2000} active={activeStep === 0}>
          <Welcome
            active={activeStep === 0}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            totalSteps={totalSteps}
          />
        </Transition>
        <Transition timeout={0} active={activeStep === 1}>
          <Location
            active={activeStep === 1}
            handleNext={handleRestaurant}
            handleBack={handleBack}
            list={list}
            activeStep={activeStep}
            totalSteps={totalSteps}
          />
        </Transition>
      </>
    )
  );
};

export default Onboarding;
