import * as types from './UserTypes';

export const actionGetUserByTokenRequest = (payload) => ({
  type: types.GET_USER_BY_TOKEN_REQUEST,
  payload,
});

export const actionGetUserByTokenSuccess = (payload) => ({
  type: types.GET_USER_BY_TOKEN_SUCCESS,
  payload,
});

export const actionGetUserByTokenFail = (payload) => ({
  type: types.GET_USER_BY_TOKEN_FAIL,
  payload,
});

export const actionGetUserByAuthCodeRequest = (payload) => ({
  type: types.GET_USER_BY_AUTH_CODE_REQUEST,
  payload,
});

export const actionGetUserByAuthCodeSuccess = (payload) => ({
  type: types.GET_USER_BY_AUTH_CODE_SUCCESS,
  payload,
});

export const actionGetUserByAuthCodeFail = (payload) => ({
  type: types.GET_USER_BY_AUTH_CODE_FAIL,
  payload,
});

export const actionGetUserRequest = (payload) => ({
  type: types.GET_USER_REQUEST,
  payload,
});

export const actionGetUserSuccess = (payload) => ({
  type: types.GET_USER_SUCCESS,
  payload,
});

export const actionGetUserFail = (payload) => ({
  type: types.GET_USER_FAIL,
  payload,
});

export const actionSetUser = (payload) => ({
  type: types.SET_USER,
  payload,
});

export const actionUpdateUser = (payload) => ({
  type: types.UPDATE_USER,
  payload,
});

export const actionUpdateUserSuccess = (payload) => ({
  type: types.UPDATE_USER_SUCCESS,
  payload,
});

export const actionUpdateUserRequest = (payload) => ({
  type: types.UPDATE_USER_REQUEST,
  payload,
});

export const actionUpdateUserFail = (payload) => ({
  type: types.UPDATE_USER_FAIL,
  payload,
});

export const actionUpdateUserDefaultPaymentAccount = (payload) => ({
  type: types.UPDATE_USER_DEFAULT_PAYMENT_ACCOUNT,
  payload,
});

export const actionSignoutUserRequest = (payload) => ({
  type: types.SIGNOUT_USER_REQUEST,
  payload,
});

export const actionSignoutUserSuccess = (payload) => ({
  type: types.SIGNOUT_USER_SUCCESS,
  payload,
});

export const actionSignoutUserFail = (payload) => ({
  type: types.SIGNOUT_USER_FAIL,
  payload,
});
