const typography = {
  error: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
  },
  tiny1Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '16px',
  },
  tiny1Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  small1Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '18px',
  },
  small2Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '21px',
  },
  small3Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
  },
  small4Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '24px',
  },
  small1Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '24px',
  },
  small2Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
  },
  small3Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '21px',
  },
  small3SemiboldFlex: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 700,
  },
  small4Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
  },
  medium1Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '24px',
  },
  medium1NormalFlex: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  medium1Bold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '24px',
  },
  medium2Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: '30px',
  },
  medium2NormalFlex: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  medium2NormalButtonFlex: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  medium3Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '22px',
  },
  medium1Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '24px',
  },
  medium2Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: '30px',
  },
  medium2Light: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: '28px',
  },
  medium3Semibold: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '22px',
  },
  medium3Light: {
    fontFamily: ['OptimistRight', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '36px',
  },
  medium3LightFlex: {
    fontFamily: ['OptimistRight', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  medium4Normal: {
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '36px',
  },
  large1Light: {
    fontFamily: ['OptimistRight', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 32,
    lineHeight: '42px',
  },
  large1LightFlex: {
    fontFamily: ['OptimistRight', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  formFieldCompact: {
    fontFamily: ['OptimistRight', 'sans-serif'].join(','),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '16px',
  },
};

export default typography;
