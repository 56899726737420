import { createContext, createRef } from 'react';

const containerRef = createRef();
export const INITIAL_SCROLL_REF = { current: null };
export const ScrollContext = createContext(INITIAL_SCROLL_REF);

export const withScrollContext = (WrappedComponent) => (props) =>
  (
    <ScrollContext.Provider value={containerRef}>
      <WrappedComponent {...props} />
    </ScrollContext.Provider>
  );
