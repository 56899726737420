import parsePhoneNumber from 'libphonenumber-js';

export const formatPhone = (phoneNumberString) => {
  try {
    // defaultCountry is required and will be ignored if using international number "+"
    const phoneNumber = parsePhoneNumber(phoneNumberString, {
      defaultCountry: 'US',
    });
    return phoneNumber?.formatNational() || '';
  } catch (error) {
    console.error(error);
    return '';
  }
};
