import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { withScrollToTopOnPress } from '../../helpers';
import { trackClick } from '../../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: 'transparent',
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '100%',
  },
}));

const SearchResultsLinkBase = ({ children, className, ...linkProps }) => {
  const classes = useStyles();

  const handleSearchPaginationClick = (event) => {
    trackClick(event, "search-pagination-link", "Link");
  }

  return (
    <Link
      className={clsx(classes.root, className)}
      onClick={handleSearchPaginationClick}
      data-cy="search-pagination-link"
      {...linkProps}>
      {children}
    </Link>
  );
};

export default withScrollToTopOnPress(SearchResultsLinkBase);
