import React, { useMemo, useRef } from 'react';
import { ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { useOption } from '@react-aria/listbox';
import { getRandomString } from '../../../../utils/useGeneratedKey';
import Divider from '../../../overrides/Divider';
import Typography from '../../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  divider: {
    background: theme.palette.divider,
    width: '100%',
  },
  option: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
  },
  resultType: {
    color: theme.palette.digitalGray[70],
    fontSize: 10,
    lineHeight: '10px',
    marginTop: theme.spacing(0.5),
  },
}));

const SuggestedResultsOption = ({ item, searchQuery, state }) => {
  const classes = useStyles();
  const ref = useRef();
  const { optionProps, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref
  );
  const [resultText, resultType] = useMemo(
    () => item.rendered.split('\n'),
    [item]
  );

  const setHighlightedText = (suggestedItem) =>
    suggestedItem
      .split(new RegExp(`(${searchQuery})`, 'gi'))
      .filter((result) => result)
      .map((splitPart) => (
        <span
          key={getRandomString()}
          style={
            splitPart.toLowerCase() === searchQuery?.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }>
          {splitPart}
        </span>
      ));

  return (
    <ListItem
      {...optionProps}
      aria-label={item.rendered}
      className={classes.option}
      ref={ref}
      button
      selected={isFocused || isSelected}>
      <Divider className={classes.divider} />
      <ListItemText className={classes.content}>
        <Typography>{setHighlightedText(resultText)}</Typography>
        <Typography className={classes.resultType}>{resultType}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export default SuggestedResultsOption;
