import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Collapse } from '@material-ui/core';
import PageSection from '../../components/core/PageSection';
import TextButton from '../../components/core/TextButton';
import ChefItemHorizontal from '../../components/premier/ChefItem';
import VenueGrid from '../../components/premier/VenueGrid';
import Typography from '../../components/overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import {
  PREMIER_LOCATION_ALL_SELECTIONS,
  PREMIER_LOCATION_LOAD_MORE,
} from '../../assets/copy';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: theme.spacing(6, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(7, 5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.blue[50],
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  galleyList: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    rowGap: theme.spacing(3),
  },
  labelContainer: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(6),
    },
  },
}));

const PAGINATION_VALUE = 6;

const AllPremier = () => {
  const classes = useStyles();
  const { width } = useViewport();

  const { list } = useSelector((state) => state.premierLocation);

  const [filteredVenues, setFilteredVenues] = useState([]);
  const [allFilteredVenues, setAllFilteredVenues] = useState([]);
  const [pagination, setPagination] = useState(PAGINATION_VALUE);
  const [more, setMore] = useState(true);

  useEffect(() => {
    if (list) {
      const updatedFilteredVenues = [];
      list.forEach((premierLocation) => {
        premierLocation.venues.items.forEach(
          (item) =>
            item?.venue.specialEventDate == null &&
            updatedFilteredVenues.push(item.venue)
        );
      });
      setAllFilteredVenues(updatedFilteredVenues);
      setFilteredVenues(updatedFilteredVenues.slice(0, pagination));
    }
  }, [list, pagination]);

  useEffect(() => {
    setMore(filteredVenues.length < allFilteredVenues.length);
  }, [filteredVenues, allFilteredVenues]);

  const handleMore = () => {
    setPagination((prevState) => prevState + PAGINATION_VALUE);
  };

  const mobileVenueList = () => (
    <div className={classes.galleyList}>
      {filteredVenues.map((venue, index) => (
        <ChefItemHorizontal
          allSelectionsSection
          chefSection
          large={true}
          venue={venue}
          key={`chefItem-${index}`}
        />
      ))}
    </div>
  );

  return (
    <PageSection className={classes.root}>
      {list && (
        <>
          <Typography
            variant={
              width > WIDTH_BREAKPOINT ? 'medium4Normal' : 'medium2Normal'
            }
            className={classes.title}
            component="h2"
            tabIndex={0}>
            {PREMIER_LOCATION_ALL_SELECTIONS}
          </Typography>
          <Collapse
            in={true}
            collapsedHeight={width > WIDTH_BREAKPOINT ? 80 : 815}>
            {width > WIDTH_BREAKPOINT ? (
              <VenueGrid
                allSelectionsSection
                columns={3}
                venues={filteredVenues}
              />
            ) : (
              mobileVenueList()
            )}
          </Collapse>
          {more === true && (
            <Box className={classes.labelContainer} role="presentation">
              <TextButton onClick={handleMore}>
                {PREMIER_LOCATION_LOAD_MORE}
              </TextButton>
            </Box>
          )}
        </>
      )}
    </PageSection>
  );
};

export default AllPremier;
