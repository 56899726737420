import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { LOADING_TEXT, PROGRESS_BAR_LABEL } from '../../../assets/copy';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    textAlign: 'center',
  },
}));

const CircularLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loadingContainer}>
      <CircularProgress
        size={48}
        role="progressbar"
        aria-label={PROGRESS_BAR_LABEL}
        title={LOADING_TEXT}
        aria-valuenow={0}
      />
    </Box>
  );
};

export default CircularLoader;
