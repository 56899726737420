import React, { useCallback, useState, useEffect } from 'react';
import { Container, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import StepContent from '../../components/onboarding/StepContent';
import StepFooter from '../../components/onboarding/StepFooter';
import StepHeader from '../../components/onboarding/StepHeader';
import SpecialDateInput from '../../components/profile/SpecialDateInput';
import { useViewport } from '../../utils/useViewport';
import Transition from '../../components/animation/Transition';

import {
  ONBOARDING_CONFIRM_INFORMATION,
  ONBOARDING_SPECIAL_DATES_TITLE,
  NEXT_BUTTON_TEXT,
  ONBOARDING_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL,
  ONBOARDING_SPECIAL_DATES_USER_BIRTHDAY_LABEL,
  ONBOARDING_SPECIAL_DATES_USER_ANNIVERSARY_LABEL,
  SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT,
} from '../../assets/copy';
import { onboardingBaseStyles } from './OnboardingStyles';
import Typography from '../../components/overrides/Typography';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { isValidShortSpecialDate } from '../../utils/dateTime';
import FilledButton from '../../components/core/FilledButton';
import TextLink from './TextLink';
import useOnboarding from '../../utils/useOnboarding';

const useStyles = makeStyles((theme) => ({
  ...onboardingBaseStyles(theme),
  wrapper: {
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(7, 4, 0, 6),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  specialDateInputContainer: {
    width: '100%',
  },
  labelStyle: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      width: 135,
      marginTop: 0,
    },
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
  errorColor: {
    color: theme.palette.interactionRed[30],
    '& > input': {
      borderColor: theme.palette.interactionRed[30],
      '&:active, &:focus': {
        borderColor: theme.palette.interactionRed[30],
      },
    },
  },
  specialDateInput: {
    '& > input': {
      color: theme.palette.digitalGray[60],
      '&:active, &:focus': {
        color: theme.palette.secondary[100],
      },
    },
  },
}));

const FTUXSpecialDates = ({ onSave, handleNext, active }) => {
  const [userBirthdayError, setUserBirthdayError] = useState();
  const [partnerBirthdayError, setPartnerBirthdayError] = useState();
  const [anniversaryError, setAnniversaryError] = useState();
  const [isValueUpdated, setIsValueUpdated] = useState(false);
  const classes = useStyles();
  const { width } = useViewport();
  const userBirthdayLabel = ONBOARDING_SPECIAL_DATES_USER_BIRTHDAY_LABEL;
  const partnerBirthdayLabel = ONBOARDING_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL;
  const userAnniversaryLabel = ONBOARDING_SPECIAL_DATES_USER_ANNIVERSARY_LABEL;
  const isMobile = width < WIDTH_BREAKPOINT;
  const { handleRestaurant } = useOnboarding();

  const [currentValue, setCurrentValue] = useState({
    userBirthday: null,
    partnerBirthday: null,
    userAnniversary: null,
  });

  const handleSaveDates = useCallback(() => {
    if (currentValue) {
      onSave(currentValue);
    }
    handleNext();
  }, [currentValue, onSave, handleNext]);

  const getLabel = (label) => {
    return (
      <Typography className={classes.labelStyle} variant="small2Normal">
        {label}
      </Typography>
    );
  };

  useEffect(() => {
    setIsValueUpdated(
      Object.values(currentValue).some(
        (value) => value && isValidShortSpecialDate(value)
      )
    );
  }, [currentValue]);

  return (
    <Box className={classes.root}>
      <StepContent>
        <Transition classNames={classes.slideAnimation} active={active}>
          <Box
            tabIndex={0}
            className={clsx(
              classes.contentWrapper,
              classes.wrapper,
              classes.scrollable
            )}>
            <StepHeader title={ONBOARDING_SPECIAL_DATES_TITLE} />
            <Container maxWidth="sm" className={classes.container}>
              <Typography className={classes.infoStyle} variant="small3Normal">
                {ONBOARDING_CONFIRM_INFORMATION}
              </Typography>
              <Box pb={3} className={classes.specialDateInputContainer}>
                <SpecialDateInput
                  label={getLabel(userBirthdayLabel)}
                  onChange={(val) => {
                    setCurrentValue({ ...currentValue, userBirthday: val });
                  }}
                  inputClass={classes.specialDateInput}
                  errorClass={classes.errorColor}
                  setError={setUserBirthdayError}
                />
              </Box>
              <Box pb={3} className={classes.specialDateInputContainer}>
                <SpecialDateInput
                  label={getLabel(partnerBirthdayLabel)}
                  onChange={(val) => {
                    setCurrentValue({ ...currentValue, partnerBirthday: val });
                  }}
                  inputClass={classes.specialDateInput}
                  errorClass={classes.errorColor}
                  setError={setPartnerBirthdayError}
                />
              </Box>
              <Box
                pb={width > WIDTH_BREAKPOINT ? 3 : 2}
                className={classes.specialDateInputContainer}>
                <SpecialDateInput
                  label={getLabel(userAnniversaryLabel)}
                  onChange={(val) => {
                    setCurrentValue({ ...currentValue, userAnniversary: val });
                  }}
                  inputClass={classes.specialDateInput}
                  errorClass={classes.errorColor}
                  setError={setAnniversaryError}
                />
              </Box>
            </Container>
          </Box>
        </Transition>
        <Transition classNames={classes.slideAnimation} active={active}>
          <StepFooter>
            <Grid
              container
              item
              spacing={isMobile ? 2 : 4}
              className={classes.grid}
              alignItems={isMobile ? 'center' : 'flex-start'}
              direction={isMobile ? 'column' : 'row'}>
              <Grid container={isMobile} item>
                <FilledButton
                  text={NEXT_BUTTON_TEXT}
                  variant="medium2NormalButtonFlex"
                  onClick={handleSaveDates}
                  style={classes.button}
                  disabled={
                    userBirthdayError ||
                    partnerBirthdayError ||
                    anniversaryError ||
                    !isValueUpdated
                  }
                />
              </Grid>
              <Grid container={isMobile} item>
                <TextLink
                  text={SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT}
                  onClickHandler={handleRestaurant}
                  buttonClassName={classes.exploreButton}
                  className={classes.directionalButton}
                />
              </Grid>
            </Grid>
          </StepFooter>
        </Transition>
      </StepContent>
    </Box>
  );
};

export default FTUXSpecialDates;
