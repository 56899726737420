import React from 'react';
import { makeStyles } from '@material-ui/styles';
import GravityButton from '../gravity/button';
import { useViewport } from '../../utils/useViewport';
import { SAVE_BUTTON_HELPER_TEXT, SAVE_BUTTON_TEXT } from '../../assets/copy';

const useStyles = makeStyles({
  accessibleDescription: {
    display: 'none',
  },
});

const widthBreakpoint = 960;

const EditSaveButton = ({
  onSave,
  text = SAVE_BUTTON_TEXT,
  accessibilityHelperText = SAVE_BUTTON_HELPER_TEXT,
  ...other
}) => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <>
      <GravityButton
        aria-describedby="edit-save-button-description"
        onClick={onSave}
        style={{ width: width > widthBreakpoint ? 138 : '100%' }}
        {...other}>
        {text}
      </GravityButton>
      <span
        className={classes.accessibleDescription}
        id="edit-save-button-description">
        {accessibilityHelperText}
      </span>
    </>
  );
};

export default EditSaveButton;
