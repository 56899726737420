import React from 'react';
import { FormControl, makeStyles, Select } from '@material-ui/core';
import clsx from 'clsx';
import DropDownSvg from '../../assets/icons/dropdown.svg';
import ArrowDropDownSvg from '../../assets/icons/blueDropdown.svg';
import MappingSvg from '../../assets/icons/blueMappin.svg';
import { isSafari } from 'react-device-detect';
import { focusVisible } from '../../utils/ada/contrastStyles';
const useStyles = makeStyles((theme) => ({
  locationControl: {
    display: 'flex',
    minHeight: theme.spacing(6),
    flexDirection: 'row',
    backgroundColor: theme.palette.background.white,
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 0),
      width: (props) => (props.labelWidth ? props.labelWidth : 'auto'),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  blueStyle: {
    '& .MuiOutlinedInput-input': {
      ...theme.typography.small2Semibold,
      color: theme.palette.interactionBlue[50],
      backgroundColor: 'white',
      height: 'inherit',
    },
  },
  formControl: {
    minWidth: theme.spacing(15),
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.375, 1.75),
    },
  },
  basicStyle: {
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
    },
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(6),
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  select: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 4,
  },
  homeInputSelect: {
    '&.MuiOutlinedInput-input': {
      paddingLeft: theme.spacing(6),
    },
    '&:focus': {
      backgroundColor: theme.palette.digitalGray[10],
    },
  },
  inputSelect: {
    zIndex: 1,
    ...focusVisible(),
    '&.MuiOutlinedInput-input': {
      paddingRight: theme.spacing(6),
    },
    letterSpacing: isSafari ? '-1px' : 'normal',
  },
  image: {
    marginRight: theme.spacing(2),
    top: ` calc(40%)`,
  },
  location: {
    zIndex: 2,
    height: theme.spacing(1.66625),
    width: theme.spacing(1.33375),
    position: 'relative',
    top: theme.spacing(2.17),
    left: theme.spacing(4),
  },
  text: {
    color: theme.palette.primary.main,
  },
  dropImg: {
    position: 'relative',
    left: theme.spacing(-4.625),
    top: theme.spacing(0.25),
    height: theme.spacing(0.66),
    zIndex: 2,
  },
  hiddenStyles: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}));

export default function NativeSelector({
  items = [],
  selectedItem,
  handleSelect,
  component,
  variant,
  labelWidth,
  ariaLabel,
  idLabel,
  ...otherProps
}) {
  const classes = useStyles({ labelWidth });

  return (
    <FormControl
      variant="outlined"
      className={clsx(
        component === 'location'
          ? classes.locationControl
          : classes.formControl,
        variant === 'home' ? classes.blueStyle : classes.basicStyle
      )}>
      {variant === 'home' ? (
        <img src={MappingSvg} alt="" className={classes.location} />
      ) : (
        ''
      )}
      <label htmlFor={idLabel} className={classes.hiddenStyles}>
        {ariaLabel}
      </label>
      <Select
        id={idLabel}
        data-cy={otherProps['data-cy']}
        native
        value={selectedItem ? selectedItem : undefined}
        onChange={handleSelect}
        displayEmpty
        inputProps={{
          className: clsx(
            classes.inputSelect,
            variant === 'home' && classes.homeInputSelect
          ),
          'aria-label': ariaLabel,
        }}
        IconComponent={
          variant === 'home'
            ? (selectProps) => {
                return component === 'location' ? (
                  <img
                    src={ArrowDropDownSvg}
                    alt=""
                    className={clsx(classes.dropImg, selectProps.className)}
                  />
                ) : (
                  <img
                    src={DropDownSvg}
                    alt=""
                    className={clsx(classes.image, selectProps.className)}
                  />
                );
              }
            : (selectProps) => {
                return (
                  <img
                    src={DropDownSvg}
                    alt=""
                    className={clsx(classes.image, selectProps.className)}
                  />
                );
              }
        }
        className={classes.select}>
        {items &&
          items.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
      </Select>
    </FormControl>
  );
}
