import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  pageNumber: {
    flex: 4,
    marginTop: -6,
    marginBottom: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flex: 5,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
}));

const VenueLightBoxHeader = ({ title, index, images }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="medium2Semibold">
      {title}
      <Typography className={classes.pageNumber} variant="small2Normal">
        {index}/{images.length}
      </Typography>
    </Typography>
  );
};

export default VenueLightBoxHeader;
