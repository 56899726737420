import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PROGRESS_BAR_LABEL } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  circleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  circle: {
    color: theme.palette.primary.main,
  },
}));

const areEqual = (nextProps, prevProps) =>
  nextProps.isLoading === prevProps.isLoading;

const SearchResultsLoader = ({ isLoading = true }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.circleContainer}
      aria-hidden={!isLoading}
      aria-label="Loading"
      pb={isLoading ? 3 : 0}
      aria-live="assertive">
      {isLoading && (
        <CircularProgress
          className={classes.circle}
          color="secondary"
          size={48}
          role="progressbar"
          aria-label={PROGRESS_BAR_LABEL}
          title="Loading"
          aria-valuenow={0}
        />
      )}
    </Box>
  );
};

export default React.memo(SearchResultsLoader, areEqual);
