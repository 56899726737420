import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import COLORS from './colorsEnum';

const StyleChip = withStyles({
  root: ({ type, styleOverrides }) => ({
    fontWeight: 400,
    marginRight: '8px',
    fontFamily: 'Optimist',
    fontStyle: 'normal',
    fontSize: '12px',
    cursor: 'pointer',
    ...COLORS[type],
    ...styleOverrides,
  }),
  icon: {
    marginRight: -12, // Adjust this value to shorten the distance
  },
})(Chip);

const Badge = ({ text, type, size = 'small', icon }) => {
  return (
    <StyleChip
      label={text}
      size={size}
      variant="outlined"
      type={type}
      icon={icon}
    />
  );
};

export default Badge;
