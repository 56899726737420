export const GET_USER_BY_TOKEN_REQUEST = 'GET_USER_BY_TOKEN_REQUEST';
export const GET_USER_BY_TOKEN_SUCCESS = 'GET_USER_BY_TOKEN_SUCCESS';
export const GET_USER_BY_TOKEN_FAIL = 'GET_USER_BY_TOKEN_FAIL';
export const GET_USER_BY_AUTH_CODE_REQUEST = 'GET_USER_BY_AUTH_CODE_REQUEST';
export const GET_USER_BY_AUTH_CODE_SUCCESS = 'GET_USER_BY_AUTH_CODE_SUCCESS';
export const GET_USER_BY_AUTH_CODE_FAIL = 'GET_USER_BY_AUTH_CODE_FAIL';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_DEFAULT_PAYMENT_ACCOUNT =
  'UPDATE_USER_DEFAULT_PAYMENT_ACCOUNT';
export const SIGNOUT_USER_REQUEST = 'SIGNOUT_USER_REQUEST';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SIGNOUT_USER_FAIL = 'SIGNOUT_USER_FAIL';
