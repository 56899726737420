import * as TYPES from './ErrorTypes';

const initialState = {
  errorType: null,
};

export default function errorReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case TYPES.SET_ERROR_TYPE:
      return {
        ...state,
        errorType: payload,
      };
    case TYPES.RESET_ERROR_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
