import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import DirectionalButton from '../../components/buttons/DirectionalButton';
import { HOME_EXPLORE_ALL_BUTTON_TEXT_PREFIX } from '../../assets/copy';
import palette from '../../theme/palette';
import { focusVisible } from '../../utils/ada/contrastStyles';
import { trackClick } from '../../utils/useOneTag';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary[60],
    padding: theme.spacing(1.5, 0),
    textAlign: 'center',
  },
  button: {
    ...focusVisible(),
  },
  exploreButton: {
    color: `${theme.palette.white} !important`,
  },
  customChevronStyle: {
    alignSelf: 'center',
  },
}));

const CallToActionSection = ({ marketName, onClickHandler }) => {
  const classes = useStyles();
  const handleExploreAllClick = (event) => {
    onClickHandler(event);
    trackClick(event, "homepage-explore-all", "Button");
  }
  return (
    <Box className={classes.root}>
      <DirectionalButton
        text={HOME_EXPLORE_ALL_BUTTON_TEXT_PREFIX + marketName}
        customHexColor={palette.text.secondary}
        variant="link"
        role="link"
        linkTextVariant={'medium1Semibold'}
        isRippleDisabled={true}
        onClickHandler={handleExploreAllClick}
        buttonClassName={classes.button}
        data-cy="homepage-explore-all"
      />
    </Box>
  );
};

export default CallToActionSection;
