import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import FilledButton from '../../components/core/FilledButton';
import { ERROR_PAGE_TYPES } from '../../utils/constants/ErrorPageTypes';
import { isExternalURL } from '../../utils/isExternalURL';

const ErrorRedirectButton = ({
  className,
  type = ERROR_PAGE_TYPES.GENERIC.value,
}) => {
  const history = useHistory();
  const location = useLocation();

  const renderButton = useCallback(() => {
    const { buttonText, buttonUrl } = ERROR_PAGE_TYPES[type];

    if (buttonText == null || buttonUrl == null) {
      return null;
    }

    if (type === ERROR_PAGE_TYPES.GENERIC.value && location.pathname === '/') {
      return null;
    }

    const onButtonClick = () => {
      if (isExternalURL(buttonUrl)) {
        window.location.href = buttonUrl;
      } else {
        history.replace(buttonUrl);
      }
    };

    return (
      <FilledButton
        text={buttonText}
        variant="medium2Normal"
        style={className}
        onClick={onButtonClick}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return renderButton();
};

export default ErrorRedirectButton;
