import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EllipsisText from '../core/EllipsisText';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.secondary.main,
  },
  description: {
    color: theme.palette.secondary.main,
  },
}));

const OverflowRestaurantDetails = ({
  large,
  width,
  maxCardWidth,
  venue,
  vertical,
}) => {
  const classes = useStyles({ large, vertical });

  return (
    <>
      <EllipsisText
        aria-hidden="true"
        variant={large ? 'medium2Semibold' : 'medium1Semibold'}
        maxWidth={width > WIDTH_BREAKPOINT && maxCardWidth}
        className={classes.label}
        text={venue.name}
      />

      <EllipsisText
        aria-hidden="true"
        variant={large ? 'medium1Normal' : 'small2Normal'}
        maxWidth={width > WIDTH_BREAKPOINT && maxCardWidth}
        className={classes.description}
        text={[
          venue.cuisines.items[0]?.cuisine.name,
          venue.priceRange,
          venue.city,
          venue.state,
        ]
          .filter((i) => i)
          .join(' • ')}
      />
    </>
  );
};

export default OverflowRestaurantDetails;
