import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  PROFILE_DIETARY_PREFERENCES_SECTION_LABEL,
  PROFILE_MARKET_LABEL,
  PROFILE_SPECIAL_DATES_SECTION_LABEL,
  PROFILE_TITLE,
} from '../../assets/copy';
import Divider from '../../components/overrides/Divider';
import Typography from '../../components/overrides/Typography';
import { PROFILE_SUBROUTES } from '../../components/profile/constants';
import ContactDetail from '../../components/profile/ContactDetail';
import DietPreferenceInfo from '../../components/profile/DietPreferenceInfo';
import EditSection from '../../components/profile/EditSection';
import SpecialDateInfo from '../../components/profile/SpecialDatesInfo';
import { BREAKPOINT_VALUES } from '../../utils/constants/Breakpoints';
import { formatPhone } from '../../utils/formatPhone';
import { useViewport } from '../../utils/useViewport';
import PhoneNumberAlert from '../reserve/PhoneNumberAlert';
import PaymentMethods from './PaymentMethods';
import { useHistory } from 'react-router';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 3, 3),
    '& > *': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      '& > *': {
        marginBottom: theme.spacing(2.5),
        marginTop: theme.spacing(2.5),
      },
    },
  },
  basicInfo: {
    placeContent: 'space-between',
    margin: theme.spacing(1, 0, 0),
  },
  divider: {
    margin: 0,
  },
  phoneNumberAlertBox: {
    margin: 0,
  },
  title: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4, 0, 3),
    },
  },
}));

const widthBreakpoint = BREAKPOINT_VALUES.xl;

const OverviewPage = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const { width } = useViewport();

  const dietPreferences = useMemo(
    () => ({
      restrictions: user.diets?.items.map((item) => item.diet),
      allergies: user.allergies?.items.map((item) => item.allergy),
      additionalNotes: user.notes,
    }),
    [user.allergies, user.diets, user.notes]
  );
  const fullName = useMemo(
    () => `${user.firstName} ${user.lastName}`,
    [user.firstName, user.lastName]
  );
  const phoneNumber = useMemo(
    () => `${formatPhone(user.phoneNumber)}`,
    [user.phoneNumber]
  );

  const onEditPaymentMethod = useCallback(() => {
    history.push(PROFILE_SUBROUTES.PAYMENT_METHODS);
  }, [history]);

  return (
    <Grid className={classes.container} container item xs={12}>
      <Grid item xs={12} className={classes.title} role="status">
        <Typography
          variant={width >= widthBreakpoint ? 'large1Light' : 'medium3Light'}
          component="h1"
          align="center">
          {PROFILE_TITLE}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.basicInfo}>
        <PhoneNumberAlert style={classes.phoneNumberAlertBox} />
        <ContactDetail
          isProfile
          email={user.emailAddress}
          name={fullName}
          phone={phoneNumber}
        />
      </Grid>
      <Divider className={classes.divider} />
      <EditSection
        label={PROFILE_MARKET_LABEL}
        value={user.market?.name}
        to={PROFILE_SUBROUTES.CITY}
      />
      <Divider className={classes.divider} />
      <EditSection
        label={PROFILE_SPECIAL_DATES_SECTION_LABEL}
        value={
          <SpecialDateInfo
            userBirthday={user.userBirthday}
            partnerBirthday={user.partnerBirthday}
            userAnniversary={user.userAnniversary}
          />
        }
        to={PROFILE_SUBROUTES.SPECIAL_DATES}
      />
      <PaymentMethods
        type={PAYMENT_METHOD_COMPONENT_TYPES.PROFILE}
        onEdit={onEditPaymentMethod}
      />
      <Divider className={classes.divider} />
      <EditSection
        label={PROFILE_DIETARY_PREFERENCES_SECTION_LABEL}
        value={<DietPreferenceInfo dietPreferences={dietPreferences} />}
        to={PROFILE_SUBROUTES.DIET_PREFERENCES}
      />
    </Grid>
  );
};

export default OverviewPage;
