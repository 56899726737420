import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SEARCH_PAGINATION_DOTS } from '../../../assets/copy';

/**
 * Returns an array of numbers from a start and end point inclusively
 * @param {number} start Indicates where range should start
 * @param {number} end Indicates where range should end
 * @returns {number[]} Range of numbers from `start` to (and including) `end`
 */
const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

/**
 * Generates array to iterate over for showing pagination. First value will
 * always be `1`, last value will always be the total number of pages. If there
 * are more than `siblingCount` + 5 pages ellipses are added to indicate additional
 * pages, but where the ellipses appear depends on the `currentPage`.
 * @returns {Array<'...'|number>}
 */
export const usePagination = ({ currentPage, siblingCount = 1 }) => {
  const { totalPages } = useSelector((state) => state.venues);
  const paginationRange = useMemo(() => {
    if (currentPage === 0) return [];
    if (siblingCount + 5 >= totalPages) {
      return range(1, totalPages);
    }

    const leftSibling = Math.max(currentPage - siblingCount, 1);
    const rightSibling = Math.min(currentPage + siblingCount, totalPages);
    // Show dots on left side if left-most sibling is greater than `2`
    const shouldShowLeftDots = leftSibling > 2;
    // Show dots on right side if right-most sibling is before third to last page
    const shouldShowRightDots = rightSibling < totalPages - 2;
    /*
      There should always be at minimum 5 numeric values: The first page, the left-most
      sibling, the current page, the right-most sibling, and the last page. This variable
      adds the non-sibling count and the total sibling count (right and left) together.
    */
    const itemCount = 3 + 2 * siblingCount;

    let shownRange = [];

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(1, itemCount);
      shownRange = [...leftRange, SEARCH_PAGINATION_DOTS, totalPages];
    } else if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(totalPages - itemCount + 1, totalPages);
      shownRange = [1, SEARCH_PAGINATION_DOTS, ...rightRange];
    } else if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSibling, rightSibling);
      shownRange = [
        1,
        SEARCH_PAGINATION_DOTS,
        ...middleRange,
        SEARCH_PAGINATION_DOTS,
        totalPages,
      ];
    }

    return shownRange;
  }, [currentPage, totalPages, siblingCount]);

  return paginationRange;
};
