import React from 'react';
import { Backdrop, Box, Fade, makeStyles, Modal } from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  APPLY_BUTTON_TEXT,
  SEARCH_FILTERS_ALT_TEXT,
  SEARCH_FILTER_CLEAR_LABEL,
  SEARCH_FILTER_COUNT_PREFIX,
  SEARCH_FILTER_COUNT_SUFFIX,
  SEARCH_FILTER_INFLECTION,
  SEARCH_FILTER_INFLECTION_SUFFIX,
  SEARCH_FILTER_LABEL,
  SEARCH_FILTER_SECTION_ALT_TEXT,
} from '../../../assets/copy';
import { actionSetFilterOverlayVisible } from '../../../store/Filter/FilterAction';
import createInflection from '../../../utils/inflection/createInflection';
import { useComponentWillUnmount } from '../../../utils/useComponentWillUnmount';
import FilledButton from '../../core/FilledButton';
import OutlineButton from '../../core/OutlineButton';
import ScreenReaderText from '../../core/ScreenReaderText';
import Typography from '../../overrides/Typography';
import CloseButton from '../../core/CloseButton';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    [theme.breakpoints.up('xl')]: {
      maxHeight: `calc(100vh - (73px + 80px + ${theme.spacing(10)}))`,
      overflowY: 'scroll',
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(10),
      overflowY: 'scroll',
    },
  },
  filterHeader: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
  },
  filter: {
    position: 'fixed',
    overflow: 'scroll',
    height: 'calc(100vh - 80px)',
    backgroundColor: theme.palette.background.white,
    [theme.breakpoints.up('xl')]: {
      borderRadius: theme.spacing(0.5),
      height: 'auto',
      left: '50%',
      overflow: 'hidden',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 736,
    },
    [theme.breakpoints.down('lg')]: {
      height: '100%',
    },
  },
  buttons: {
    backgroundColor: theme.palette.background.white,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${theme.palette.secondary[60]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    '-webkit-backface-visibility': 'hidden',
    [theme.breakpoints.up('xl')]: {
      position: 'static',
    },
  },
  button: {
    ...focusVisible(),
    [theme.breakpoints.up('xl')]: {
      width: 150,
    },
  },
  clearBtn: {
    color: theme.palette.digitalGray[60],
    border: 'none',
    '&:hover': {
      border: 'none',
      color: theme.palette.secondary[100],
    },
  },
}));

const FilterModalContainer = ({ children, filtersCount, onApply, onReset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { filterOverlayVisible } = useSelector((state) => state.filters);

  const handleHideModal = () => {
    dispatch(actionSetFilterOverlayVisible(false));
  };

  const handleApply = (event) => {
    trackClick(event, "search-filter-apply", "Button");
    onApply();
    handleHideModal();
  };

  const handleReset = () => {
    onReset();
    handleHideModal();
  };

  // Ensure modal hides when page changes
  useComponentWillUnmount(() => {
    if (filterOverlayVisible) handleHideModal();
  });

  return (
    <Modal
      aria-label={SEARCH_FILTER_SECTION_ALT_TEXT}
      open={filterOverlayVisible}
      onClose={handleHideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={filterOverlayVisible}>
        <Box className={classes.filter}>
          <Box className={classes.filterHeader}>
            <Typography
              aria-label={SEARCH_FILTERS_ALT_TEXT}
              component="h1"
              variant="medium3Light">
              {filtersCount
                ? SEARCH_FILTER_COUNT_PREFIX +
                  filtersCount +
                  SEARCH_FILTER_COUNT_SUFFIX
                : SEARCH_FILTER_LABEL}
            </Typography>
            <ScreenReaderText aria-atomic="true" aria-live="polite">
              {`${createInflection(
                SEARCH_FILTER_INFLECTION,
                filtersCount
              )}${SEARCH_FILTER_INFLECTION_SUFFIX}`}
            </ScreenReaderText>
            <CloseButton
              autoFocus
              fill="#0D74AF"
              height={16}
              close={handleHideModal}
              width={16}
            />
          </Box>
          <Box className={classes.filterContainer}>{children}</Box>
          <Box className={classes.buttons}>
            <OutlineButton
              text={SEARCH_FILTER_CLEAR_LABEL}
              variant="medium2Normal"
              onClick={handleReset}
              style={clsx(classes.button, classes.clearBtn)}
            />
            <FilledButton
              text={APPLY_BUTTON_TEXT}
              variant="medium2Normal"
              onClick={handleApply}
              style={classes.button}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FilterModalContainer;
