import React from 'react';
import PlaceholderLoader from './PlaceholderLoader';
import { Box, GridList } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '-webkit-fill-available',
  },
  skeleton: {
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(2, 0),
    },
    margin: theme.spacing(1, 0),
  },
  skeletonGrid: {
    flexWrap: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* hide scrollbar on IE and Edge */,
    scrollbarWidth: 'none' /* hide scrollbar on Firefox */,
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(2, 0),
    },
    marginLeft: '0 !important',
  },
  line: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.gray[5],
  },
}));

const VenueSkeletonLoader = ({
  length,
  showAvailableTimesHeader = true,
  showLinkPlaceHolder = true,
  className,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={clsx(classes.root, className)}>
        {showAvailableTimesHeader && (
          <Skeleton
            className={clsx(classes.skeleton, classes.line)}
            variant="text"
            component={'div'}
            height={30}
            width={121}
          />
        )}
        <Skeleton
          className={clsx(classes.skeleton, classes.line)}
          variant="text"
          component={'div'}
          height={23.99}
          width={80}
        />
        <GridList
          className={clsx(classes.skeleton, classes.skeletonGrid)}
          mt={16}
          mb={16}>
          <PlaceholderLoader length={length} />
        </GridList>
        {showLinkPlaceHolder && (
          <Skeleton
            className={clsx(classes.skeleton, classes.line)}
            component={'div'}
            variant="text"
            height={23.99}
            width={174.28}
          />
        )}
      </Box>
    </>
  );
};

export default VenueSkeletonLoader;
