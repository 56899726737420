import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Typography from '../overrides/Typography';
import { SEARCH_TITLE } from '../../assets/copy';
import { useFeatureFlags } from '../../utils/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
  container: ({ refreshLandingPageFlagIsEnabled }) => ({
    columnGap: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 928,
    paddingTop: refreshLandingPageFlagIsEnabled ? 0 : theme.spacing(3),
    width: '83.333333%',
  }),
  formContainer: {
    flex: '0 1 80%',
  },
  searchButtonContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexGrow: 1,
  },
  title: {
    flex: '0 0 100%',
    paddingBottom: theme.spacing(2),
  },
}));

const DesktopSearch = ({
  className,
  searchForm,
  searchButton,
  showTitle = false,
}) => {
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();
  const classes = useStyles({ refreshLandingPageFlagIsEnabled });

  return (
    <Box className={clsx(classes.container, className)}>
      {showTitle && (
        <Typography
          className={classes.title}
          component="h1"
          variant="large1Light"
          aria-live="polite">
          {SEARCH_TITLE}
        </Typography>
      )}
      <Box className={classes.formContainer}>{searchForm}</Box>
      <Box className={classes.searchButtonContainer}>{searchButton}</Box>
    </Box>
  );
};

export default DesktopSearch;
