import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Backdrop,
  Modal,
  Select,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import Typography from '../../components/overrides/Typography';
import FilledButton from '../../components/core/FilledButton';
import TimePicker from '../../components/core/TimePicker';
import CloseSvg from '../../components/svg/Close';
import DropDownSvg from '../../assets/icons/dropdown.svg';
import { useViewport } from '../../utils/useViewport';
import createInflection from '../../utils/inflection/createInflection';
import {
  CLOSE_BUTTON_ALT_TEXT,
  EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR,
  EDIT_EXISTING_RESERVATION_FIND_TIMESLOT,
  FAB_SUBMIT_LABEL,
  CALENDAR_INPUT_LABEL,
  CALENDAR_INPUT_SCREENREADER_LABEL,
  SPECIAL_EVENT_CALENDAR_INPUT_LABEL,
  TIME_INPUT_LABEL,
  RESERVATION_GUESTS_SELECT,
  RESERVATION_DATE_SELECT,
} from '../../assets/copy';

import moment from 'moment';
import { isDate } from 'date-fns';

import {
  actionSetFabAvailabilityDate,
  actionSetFabAvailabilityTime,
  actionSetFabAvailabilityGuests,
  actionSetFabDisplayValues,
} from '../../store/Availability/AvailabilityAction';

import { useDispatch, useSelector } from 'react-redux';
import { HOUR_PERIOD_FORMAT } from '../../assets/timeFormats';
import {
  BEGIN_TIME_LIMIT,
  END_TIME_LIMIT,
} from '../../utils/constants/DateTime';
import {
  dateToISOString,
  toISODate,
  isOnOrBeforeDate,
  formatSpecialEventSelectDate,
} from '../../utils/dateTime';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import { actionGetMarketRequest } from '../../store/Markets/MarketsAction';
import MarketSelector from '../../components/venue/FABMarketSelector';
import '../../components/core/Calendar/css/react-datepicker.css';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import DatepickerCalendar from '../../components/core/Calendar/DatepickerCalendar';
import { DateTime } from 'luxon';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  calendar: {
    marginTop: theme.spacing(1.5),
  },
  content: {
    backgroundColor: theme.palette.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '6px 6px 0 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '75%',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      background: 'transparent' /* hide scroll bar on Chrome/Safari/Webkit */,
      width: 0,
    },
    '-ms-overflow-style': 'none' /* hide scroll bar on IE and Edge */,
    scrollbarWidth: 'none' /* hide scroll bar on Firefox */,
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignContent: 'flex-end',
      maxWidth: 448,
      paddingBottom: theme.spacing(6),
      height: 'auto',
      borderRadius: 6,
    },
  },
  contentSection: {
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      display: 'flex',
      width: '100%',
    },
  },
  dateSectionBorder: {
    '& div input': {
      border: `2px solid ${theme.palette.red[50]}!important`,
    },
  },
  contentColumn: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5, 0),
      flex: '1 1 100%',
    },
  },
  title: {
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(5),
      paddingBottom: theme.spacing(1.5),
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4, 0, 1.5),
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.white,
      zIndex: 1,
      height: 36,
    },
  },
  dateSection: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1, 0, 1.5),
      '& .rdt p.MuiTypography-root': {
        marginBottom: 1,
      },
    },
  },
  buttonSection: {
    [theme.breakpoints.up('xl')]: {
      alignItems: 'flex-end',
      display: 'flex',
      padding: theme.spacing(3.5, 0, 0),
    },
  },
  buttonSectionCenter: {
    [theme.breakpoints.up('xl')]: {
      alignItems: 'center',
      display: 'flex',
    },
  },
  button: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('xl')]: {
      margin: 0,
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
      padding: '11px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 48,
      '& fieldset': {
        borderColor: theme.palette.digitalGray[60],
      },
    },
  },
  image: {
    marginRight: theme.spacing(2),
    top: `calc(40%)`,
  },
  close: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  message: {
    textAlign: 'left',
    color: theme.palette.error.main,
    fontSize: 12,

    marginBottom: theme.spacing(0.4),
  },
  modal: {
    display: 'flex',
    alignItems: 'end',
    [theme.breakpoints.up('xl')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  hiddenStyles: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}));

const TableParameter = ({
  isOpen,
  setOpen,
  handleCloseFab,
  handleDone,
  specialEventDate = null,
  showMarket = false,
  buttonText = FAB_SUBMIT_LABEL,
  isMarketLoaded,
  markets,
}) => {
  const classes = useStyles();
  const { width } = useViewport();
  const [marketId, setMarketId] = useState(markets?.market?.id || null);

  useEffect(() => {
    if (showMarket && isMarketLoaded && markets)
      setMarketId(markets?.market?.id);
  }, [showMarket, isMarketLoaded, markets]);

  useEffect(() => {
    if (window.document) {
      const body = window.document.body;

      const observer = new MutationObserver(() => {
        body.style.touchAction = body.style.overflow === 'hidden' ? 'none' : '';
      });

      observer.observe(body, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }
  }, []);

  const midnightToday = new Date(new Date().setHours(0, 0, 0, 0));
  const [message, setMessage] = useState('');
  const [calendarClasses, setCalendarClasses] = useState([
    classes.contentColumn,
    classes.dateSection,
  ]);

  const withRedBorder = [
    classes.contentColumn,
    classes.dateSection,
    classes.dateSectionBorder,
  ];
  const withoutRedBorder = [classes.contentColumn, classes.dateSection];
  const alignEnd = [classes.contentColumn, classes.buttonSection];
  const alignCenter = [classes.contentColumn, classes.buttonSectionCenter];

  const { selectedDate, selectedTime, selectedGuests, submittedDate } =
    useSelector((state) => state.availability);
  const dispatch = useDispatch();
  const [filteredSpecialEventDates] = useState(
    specialEventDate?.filter((date) => isOnOrBeforeDate(DateTime.now(), date))
  );

  const [selectedSpecialEventDate, setSelectedSpecialEventDate] = useState();

  useEffect(() => {
    if (specialEventDate) {
      const date = filteredSpecialEventDates?.find(
        (eventDate) =>
          eventDate === toISODate(new Date(submittedDate).toISOString())
      );
      if (date) {
        setSelectedSpecialEventDate(date);
      } else {
        setSelectedSpecialEventDate(filteredSpecialEventDates[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (specialEventDate) {
      dispatch(
        actionSetFabAvailabilityDate({
          selectedDate: dateToISOString(selectedSpecialEventDate),
        })
      );
    } else {
      dispatch(
        actionSetFabAvailabilityDate({
          selectedDate: dateToISOString(submittedDate),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpecialEventDate, specialEventDate, submittedDate]);

  const resetSelectedDateOnClose = () => {
    if (filteredSpecialEventDates?.length > 0) {
      setSelectedSpecialEventDate(selectedDate);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseFab();
    setMessage('');
    setCalendarClasses(withoutRedBorder);
    resetSelectedDateOnClose();
  };

  const handleCheckAvailability = (e) => {
    trackClick(e, "fab-submit", "Button");
    showMarket && dispatch(actionGetMarketRequest({ id: marketId }));
    dispatch(
      actionSetFabDisplayValues({
        submittedDate: selectedDate,
        submittedTime: selectedTime,
        submittedGuests: selectedGuests,
      })
    );
    handleDone(e);
  };

  const handleDateChange = (date) => {
    const updatedInputValue = date ? new Date(date) : date;
    if (!isDate(updatedInputValue) || updatedInputValue < midnightToday) {
      setMessage(EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR);
      setCalendarClasses(withRedBorder);
    } else {
      setMessage('');
      setCalendarClasses(withoutRedBorder);
      dispatch(
        actionSetFabAvailabilityDate({ selectedDate: updatedInputValue })
      );
    }
  };

  const handleChange = (event) => {
    dispatch(
      actionSetFabAvailabilityGuests({ selectedGuests: event.target.value })
    );
  };

  let [beginLimit, setBeginLimit] = useState(BEGIN_TIME_LIMIT);

  const handleTime = (event) => {
    dispatch(
      actionSetFabAvailabilityTime({ selectedTime: event.target.value })
    );
  };

  const handleSelectMarket = useCallback((event) => {
    const id = event.target.value;
    setMarketId(id);
  }, []);

  useEffect(() => {
    const now = moment();
    const isSelectedDayToday = now.isSame(selectedDate, 'day');
    if (isSelectedDayToday) {
      const start = moment();
      const remainder = 30 - (start.minute() % 30);
      const dateTime = moment(start)
        .add(remainder, 'minutes')
        .format(HOUR_PERIOD_FORMAT);
      setBeginLimit(dateTime);
    } else {
      // the earliest time we allow
      setBeginLimit('6:00 AM');
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!isOpen && marketId !== markets?.market?.id)
      setMarketId(markets?.market?.id);

    return () => {
      if (marketId !== markets?.market?.id) setMarketId(markets?.market?.id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const title = () => (
    <Box
      className={classes.title}
      pb={width > WIDTH_BREAKPOINT ? 3 : 1}
      px={width > WIDTH_BREAKPOINT ? 5 : 0}
      display="flex"
      justifyContent="space-between">
      <Typography
        id="table_parameter_heading"
        variant={
          width > WIDTH_BREAKPOINT ? 'medium2Semibold' : 'medium1Semibold'
        }
        component="h1">
        {EDIT_EXISTING_RESERVATION_FIND_TIMESLOT}
      </Typography>
      <CloseSvg
        width={width > WIDTH_BREAKPOINT ? 21 : 16}
        height={width > WIDTH_BREAKPOINT ? 21 : 16}
        fill="#0D74AF"
        className={classes.close}
        onClick={handleClose}
        onKeyPress={(e) => e.key === 'Enter' && handleClose()}
        tabIndex={0}
        aria-label={CLOSE_BUTTON_ALT_TEXT}
        role="button"
      />
    </Box>
  );

  const onChangeSelectHandler = useCallback((event) => {
    setSelectedSpecialEventDate(event.target.value);
  }, []);

  const renderDateInput = () =>
    specialEventDate ? (
      <>
        <Typography variant="small2Normal" gutterBottom aria-hidden="true">
          {SPECIAL_EVENT_CALENDAR_INPUT_LABEL}
        </Typography>
        <ScreenReaderText>{CALENDAR_INPUT_SCREENREADER_LABEL}</ScreenReaderText>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            id="dateSelect"
            native
            value={toISODate(selectedSpecialEventDate)}
            onChange={onChangeSelectHandler}
            inputProps={{ 'aria-labelledby': 'dateLabel' }}
            IconComponent={(selectProps) => (
              <img
                src={DropDownSvg}
                alt=""
                className={clsx(classes.image, selectProps.className)}
              />
            )}>
            {filteredSpecialEventDates.map((item) => (
              <option value={item} key={`date-${item}`}>
                {formatSpecialEventSelectDate(item)}
              </option>
            ))}
          </Select>
        </FormControl>
      </>
    ) : (
      <>
        <Typography variant="small2Normal" gutterBottom aria-hidden="true">
          {CALENDAR_INPUT_LABEL}
        </Typography>
        <DatepickerCalendar
          calendarClassName={!message && classes.calendar}
          initialValue={selectedDate}
          minDate={midnightToday}
          message={message}
          onChange={(date) => {
            handleDateChange(date);
          }}
          selectedDate={new Date(dateToISOString(selectedDate))}
        />
      </>
    );

  const guestOptions = [];

  for (let i = 1; i <= 10; i++) {
    guestOptions.push({ value: i, label: i });
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      className={classes.modal}>
      <Fade in={isOpen}>
        <Box
          aria-modal
          aria-labelledby="table_parameter_heading"
          role="dialog"
          id="table_parameter"
          className={classes.content}>
          {width > WIDTH_BREAKPOINT && title()}
          <Box
            className={classes.contentSection}
            px={width > WIDTH_BREAKPOINT ? 5 : 3}>
            {width <= WIDTH_BREAKPOINT && title()}
            {showMarket && markets && (
              <MarketSelector
                markets={markets}
                value={marketId}
                handleSelectMarket={handleSelectMarket}
              />
            )}
            <ScreenReaderText>
              {CALENDAR_INPUT_SCREENREADER_LABEL}
            </ScreenReaderText>
            <Box
              className={clsx(calendarClasses)}
              py={width > WIDTH_BREAKPOINT ? 0 : 2}
              aria-label={RESERVATION_DATE_SELECT}>
              {renderDateInput()}
              {!!message && (
                <Typography className={classes.message} variant="medium1Normal">
                  {message}
                </Typography>
              )}
            </Box>
            <Box className={classes.contentColumn}>
              <Typography variant="small2Normal" gutterBottom id="timeLabel">
                {TIME_INPUT_LABEL}
              </Typography>
              <TimePicker
                labelId="timeLabel"
                name="TIME"
                defaultValue={selectedTime}
                beginLimit={beginLimit}
                endLimit={END_TIME_LIMIT}
                onChange={handleTime}
                step={30}
              />
            </Box>
            <Box
              className={classes.contentColumn}
              py={width > WIDTH_BREAKPOINT ? 0 : 2}>
              <Typography variant="small2Normal" gutterBottom>
                Guests
              </Typography>
              <label
                htmlFor="guestsSelect"
                aria-hidden="true"
                className={classes.hiddenStyles}
                id="guestLabel">
                {RESERVATION_GUESTS_SELECT}
              </label>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="guestsSelect"
                  native
                  value={selectedGuests}
                  onChange={handleChange}
                  inputProps={{ 'aria-labelledby': 'guestLabel' }}
                  IconComponent={(selectProps) => (
                    <img
                      src={DropDownSvg}
                      alt=""
                      className={clsx(classes.image, selectProps.className)}
                    />
                  )}>
                  {guestOptions.map((item) => (
                    <option value={item.value} key={`guests-${item.value}`}>
                      {createInflection('guest', item.label)}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {width > WIDTH_BREAKPOINT && (
              <Box className={message ? clsx(alignCenter) : clsx(alignEnd)}>
                <FilledButton
                  text={buttonText}
                  variant="medium2Normal"
                  onClick={handleCheckAvailability}
                  style={classes.button}
                  disabled={
                    !!message || filteredSpecialEventDates?.length === 0
                  }
                  data-cy="fab-submit"
                />
              </Box>
            )}
          </Box>
          {width <= WIDTH_BREAKPOINT && (
            <Box>
              <Box px={3}>
                <FilledButton
                  text={buttonText}
                  variant="medium2Normal"
                  onClick={handleCheckAvailability}
                  style={classes.button}
                  disabled={
                    !!message || filteredSpecialEventDates?.length === 0
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default TableParameter;
