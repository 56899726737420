import { useCallback } from 'react';
import { useScrollContextRef } from './useScrollContextRef';

export const withScrollToTopOnPress =
  (WrappedComponent) =>
  ({ onClick, onKeyUp, ...props }) => {
    const ref = useScrollContextRef();

    const scrollToTop = useCallback(
      () => ref.current && ref.current.scrollTo(0, 0),
      [ref]
    );

    const handleClick = useCallback(
      (e) => {
        if (typeof onClick === 'function') onClick(e);

        scrollToTop();
      },
      [scrollToTop, onClick]
    );

    const handleKeyUp = useCallback(
      (e) => {
        if (typeof onKeyUp === 'function') onKeyUp(e);
        if (e.key === 'Enter') scrollToTop();
      },
      [scrollToTop, onKeyUp]
    );

    return (
      <WrappedComponent
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        {...props}
      />
    );
  };
