import { actionUpdateReservationRequest } from '../../../store/Reservations/ReservationsAction';
import { updateReservationPayload } from '../../../utils/reservationFilters';
import {
  actionSetAnimationPushUp,
  actionSetAnimationSlideRight,
} from '../../../store/Animations/AnimationsAction';
import { getReservationPageBackRoute, setReservationPageBackRoute } from '../../../utils/sessionStorageHelper';

const handleToggleEditModal = (isModalOpen, setModalOpen) => {
  setModalOpen(() => !isModalOpen);
};

const handleEdit = (history) => {
  history.push('/reserve/edit');
};

const getReservationPageBackLocation = () => getReservationPageBackRoute() || '/';

const redirectToReservePage = (history, options) => {
  const path = '/reserve';
  setReservationPageBackRoute(history.location.pathname);
  if (options) {
    history.push(path, options);
  } else {
    history.push(path);
  }
}

const handleCancel = (history) => {
  history.push(getReservationPageBackLocation());
};

const handleUpdated = (history, dispatch, draftReservation, reservation) => {
  dispatch(
    actionUpdateReservationRequest(
      updateReservationPayload({
        ...draftReservation,
        id: reservation.id,
        sevenRoomsReservationId: reservation.sevenRoomsReservationId,
      })
    )
  );
  dispatch(actionSetAnimationPushUp(true));
};

const handleCancelEdit = (history, dispatch, setModalOpen) => {
  setModalOpen(false);
  dispatch(actionSetAnimationSlideRight(true));
  history.push(getReservationPageBackLocation());
};

const handleCancelEditReservation = (
  history,
  dispatch,
  setModalOpen,
  reservation
) => {
  setModalOpen(false);
  history.push(`/reservations/${reservation?.id}`);
};

const handleReserveError = (
  setShowAlert,
  setIsPaymentDisabled,
  isReservationNotAvlbl
) => {
  if (isReservationNotAvlbl) {
    //NO AVAILABILITY
    setShowAlert(true);
    setIsPaymentDisabled(true);
    window.scrollTo(0, 0);
  }
};

export {
  handleToggleEditModal,
  handleEdit,
  handleCancel,
  handleUpdated,
  handleCancelEdit,
  handleCancelEditReservation,
  handleReserveError,
  redirectToReservePage
};
