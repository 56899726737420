import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Backdrop, Fade } from '@material-ui/core';

import Typography from '../../components/overrides/Typography';
import OutlineButton from '../../components/core/OutlineButton';
import {
  RESERVATION_CANCEL_MODAL_DESCRIPTION,
  RESERVATION_CANCEL_MODAL_NO_BUTTON_TEXT,
  RESERVATION_CANCEL_MODAL_TITLE,
  RESERVATION_CANCEL_MODAL_YES_BUTTON_TEXT,
} from '../../assets/copy';
import CancelButton from './CancelButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paper: {
    backgroundColor: theme.palette.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    position: 'static',
    width: 327,
    height: 312,
    left: 'calc(50% - 480px/2)',
    top: 'calc(50% - 230px/2)',
    [theme.breakpoints.up('xl')]: {
      width: '480px',
      height: '230px',
      position: 'absolute',
      top: '80px',
    },
  },
  confirmation: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.up('xl')]: {
      width: 416,
    },
  },
  description: {
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up('xl')]: {
      width: 416,
    },
  },
  noBtn: {
    color: theme.palette.gray[50],
    textTransform: 'none!important',
    border: 'none !important',
    '&:hover': {
      border: 'none !important',
    },
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up('xl')]: {
      width: 128,
      maxWidth: 264,
    },
  },
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(3, 4, 1, 2),
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'end',
      columnGap: theme.spacing(3),
      paddingLeft: theme.spacing(4),
    },
  },
}));

const CancelReserveModal = ({
  handleToggleReserveModal,
  handleCancelReserve,
  modalOpen,
}) => {
  const classes = useStyles();
  const [isCancelling, setIsCancelling] = useState(false);

  const cancel = useCallback(() => {
    if (!isCancelling) {
      setIsCancelling(true);
      handleCancelReserve && handleCancelReserve();
    }
  }, [isCancelling, handleCancelReserve]);

  return (
    <Modal
      className={classes.modal}
      open={modalOpen}
      onClose={handleToggleReserveModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={modalOpen}>
        <Box className={classes.paper} role="dialog">
          <Typography
            variant="medium3Light"
            component="h1"
            className={classes.confirmation}>
            {RESERVATION_CANCEL_MODAL_TITLE}
          </Typography>
          <Typography variant="medium1Normal" className={classes.description}>
            {RESERVATION_CANCEL_MODAL_DESCRIPTION}
          </Typography>
          <Box className={classes.buttonContainer}>
            <OutlineButton
              type="button"
              text={RESERVATION_CANCEL_MODAL_NO_BUTTON_TEXT}
              onClick={handleToggleReserveModal}
              style={classes.noBtn}
            />
            <CancelButton
              isCancelling={isCancelling}
              onCancel={cancel}
              text={RESERVATION_CANCEL_MODAL_YES_BUTTON_TEXT}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CancelReserveModal;
