import * as TYPES from './MarketsTypes';
import {
  updateMarketVenueAvailability,
  clearMarketVenueAvailability,
} from '../../utils/updateMarkets';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';

const initialState = {
  list: [],
  market: null,
  cachedMarketId: null,
};

export default function marketReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.MARKET_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      };
    case TYPES.GET_MARKET_SUCCESS:
      return {
        ...state,
        market: payload,
      };
    case TYPES.CACHE_MARKET_ID:
      return {
        ...state,
        cachedMarketId: payload,
      };
    case TYPES.RESET_CACHED_MARKET_ID:
      return {
        ...state,
        cachedMarketId: initialState.cachedMarketId,
      };
    case TYPES.UPDATE_MARKET_AVAILABILITY:
      return {
        ...state,
        market: updateMarketVenueAvailability(state.market, payload),
      };
    case TYPES.CLEAR_MARKET_AVAILABILITY:
      return {
        ...state,
        market: clearMarketVenueAvailability(state.market),
      };
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
