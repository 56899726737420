import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseButton from '../core/CloseButton';
import Typography from '../overrides/Typography';
import {
  SEARCH_FILTER_REMOVE_PREFIX,
  SEARCH_FILTER_REMOVE_SUFFIX,
} from '../../assets/copy';
import ScreenReaderText from '../core/ScreenReaderText';
import { priceCheck } from '../../utils/venue';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    padding: theme.spacing(0, 1),
    borderRadius: 2,
    background: theme.palette.background.grayLight,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));

const FilterChip = ({ name, onRemove }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} role="listitem">
      <ScreenReaderText>{priceCheck(name)}</ScreenReaderText>
      <Typography
        component="span"
        variant="medium1Normal"
        className={classes.text}
        aria-hidden="true">
        {name}
      </Typography>
      <CloseButton
        close={onRemove}
        fill="#0D74AF"
        height={16}
        label={
          SEARCH_FILTER_REMOVE_PREFIX +
          priceCheck(name) +
          SEARCH_FILTER_REMOVE_SUFFIX
        }
        tabIndex={0}
        variant="svg"
        width={16}
      />
    </Box>
  );
};

export default FilterChip;
