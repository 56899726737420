import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CloseSvg from '../svg/Close';
import { CLOSE_BUTTON_LABEL } from '../../assets/copy';

const useStyles = makeStyles({
  accessibleDescription: {
    display: 'none',
  },
  button: {
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'highlight auto',
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto',
    },
  },
});

const CloseButton = ({
  className,
  close,
  description = null,
  label = CLOSE_BUTTON_LABEL,
  fill = '#525252',
  height = 20,
  role = 'button',
  tabIndex = null,
  width = 20,
  variant = 'button',
}) => {
  const classes = useStyles();

  const svgVariant = () => (
    <CloseSvg
      aria-label={label}
      className={clsx(classes.button, className)}
      fill={fill}
      height={height}
      onClick={close}
      role={role}
      {...(tabIndex != null ? { tabIndex } : {})}
      width={width}
      disableRipple
      disableFocusRipple
      {...(description != null
        ? { 'aria-describedby': 'close-button-description' }
        : {})}
    />
  );

  const buttonVariant = () => (
    <IconButton
      aria-label={label}
      className={clsx(classes.button, className)}
      onClick={close}
      {...(role !== 'button' ? { role } : {})}
      {...(tabIndex != null ? { tabIndex } : {})}
      {...(description != null
        ? { 'aria-describedby': 'close-button-description' }
        : {})}
      disableRipple
      disableFocusRipple>
      <CloseSvg
        aria-hidden="true"
        alt=""
        role="presentation"
        fill={fill}
        height={height}
        width={width}
      />
    </IconButton>
  );

  return (
    <>
      {variant === 'button' && buttonVariant()}
      {variant !== 'button' && svgVariant()}
      {description != null && (
        <span
          className={classes.accessibleDescription}
          id="close-button-description">
          {description}
        </span>
      )}
    </>
  );
};

export default CloseButton;
