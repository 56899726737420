import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '../../overrides/Typography';
import PropTypes from 'prop-types';
import { ReactComponent as BenefitsIcon } from '../../../assets/icons/grv-benefits-small.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { EXCLUSIVE_TABLE_TEXT, GUEST_INFLECTION } from '../../../assets/copy';
import createInflection from '../../../utils/inflection/createInflection';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  flexbox: {
    display: 'flex',
    alignItems: `center`,
  },
  image: {
    maxWidth: '120px',
    minWidth: '80px',
    height: '120px',
    borderRadius: '4px 0 0 4px',
    objectFit: 'cover',
  },
  reservationDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '120px',
    border: '1px solid #E8E5E1',
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '16px',
    height: '16px',
    margin: 'auto',
  },
  insider: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.25),
    marginBottom: '2px',
    '& path': {
      fill: `${theme.palette.yellow[40]} !important`,
    },
  },
  cardholderLabel: {
    color: theme.palette.secondary['65'],
    marginLeft: theme.spacing(0.25),
  },
  twoColGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    rowGap: 0,
    columnGap: theme.spacing(1),
  },
}));

const ReservationCard = ({
  imageUrl,
  venueName,
  reservationDate,
  reservationTime,
  guestCount,
  isCardholderTable,
  classes: customClasses,
}) => {
  const classes = useStyles({ classes: customClasses });
  const guestAltText = createInflection(GUEST_INFLECTION, guestCount);
  return (
    <div className={clsx(classes.root, classes.flexbox)}>
      <img src={imageUrl} className={classes.image} alt="" />
      <div className={classes.reservationDetailsContainer}>
        <Typography variant="medium1Semibold">{venueName}</Typography>
        <div className={classes.twoColGrid}>
          <CalendarIcon className={classes.icon} role="presentation" />
          <Typography variant="small4Normal">{reservationDate}</Typography>

          <ClockIcon className={classes.icon} role="presentation" />
          <div className={classes.flexbox}>
            <Typography variant="small4Normal">{reservationTime}</Typography>
            {isCardholderTable && (
              <>
                <BenefitsIcon className={classes.insider} role="presentation" />
                <Typography
                  variant="tiny1Normal"
                  className={classes.cardholderLabel}>
                  {EXCLUSIVE_TABLE_TEXT}
                </Typography>
              </>
            )}
          </div>

          <UserIcon className={classes.icon} role="presentation" />
          <Typography variant="small4Normal">{guestAltText}</Typography>
        </div>
      </div>
    </div>
  );
};

ReservationCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  venueName: PropTypes.string.isRequired,
  reservationDate: PropTypes.string.isRequired,
  reservationTime: PropTypes.string.isRequired,
  guestCount: PropTypes.number.isRequired,
  isCardholderTable: PropTypes.bool,
};

export default ReservationCard;
