import React from 'react';
import { makeStyles } from '@material-ui/core';
import TextIcon from '../TextIcon';
import signatureCollectionIcon from '../../../assets/icons/grv-benefits.svg';

const useStyles = makeStyles((theme) => ({
    signatureCollectionIcon: {
        height: '24px',
        width: '24px',
        '& path': {
            fill: `${theme.palette.yellow[40]} !important`,
        },
    },
}));



const SignatureCollectionTextIcon = ({ text, variant='small1Semibold', textStyleOverride }) => {
    const classes = useStyles();
    return (
        <TextIcon src={signatureCollectionIcon} variant={variant} iconStyleOverride={classes.signatureCollectionIcon} textStyleOverride={textStyleOverride} text={text} role="presentation"/>
    );

}

export default SignatureCollectionTextIcon;
