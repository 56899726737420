import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { actionPremierLocationListRequest } from '../../store/PremierLocation/PremierLocationsAction';
import TopHero from './TopHero';
import ChefSection from './ChefSection';
import AllPremier from './AllPremier';
import { useAuth } from '../../utils/useAuth';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import CommonLoading from '../common/CommonLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme.palette.digitalGray[10],
  },
  find: {
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: theme.spacing(1),
    width: '100%',
  },
  hiddenStyles: {
    display: 'inline-block',
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
}));

const PremierLocation = () => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const premierLocation = useSelector((state) => state.premierLocation);

  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  const shouldOnboard = useOnboardingRedirect();

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // User needs to be authenticated before dispatching
    if (!user.session.isAuthenticated) return;

    dispatch(actionPremierLocationListRequest());
  }, [user.session.isAuthenticated, dispatch]);

  const isMounted = () => user.session.isAuthenticated && !shouldOnboard;
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  return (
    <>
      <CommonLoading active={!isMounted()} embedded={true} />
      {isMounted() && !navigationMenuVisible && (
        <main className={classes.root}>
          <Box ref={scrollRef}>
            <TopHero />
            <ChefSection data={premierLocation.list} />
            <AllPremier />
          </Box>
        </main>
      )}
    </>
  );
};

export default PremierLocation;
