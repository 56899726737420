import React, { useCallback, useEffect } from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import {
  EDIT_PROFILE_DIETARY_PREFERENCES_SUBTITLE,
  EDIT_PROFILE_DIETARY_PREFERENCES_TITLE,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_NOTES_LABEL,
  RESIZE_TEXT_BOX_ALT_TEXT,
} from '../../assets/copy';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import DietaryPreferences from '../../components/core/DietaryPreferences';
import EditSaveButton from '../../components/core/EditSaveButton';
import Divider from '../../components/overrides/Divider';
import Typography from '../../components/overrides/Typography';
import {
  DIET_PREFERENCE_TYPE,
  useDietPreferences,
  useSaveUserCallback,
} from '../../components/profile/helpers';
import { useIsMounted } from '../../utils/useIsMounted';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  additionalNotesContainer: {
    marginTop: theme.spacing(1),
  },
  description: {
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
    },
  },
  diet: {
    textAlign: 'left',
  },
  divider: {
    width: `calc(100% - ${theme.spacing(2)})`,
    margin: theme.spacing(0, 0, 1, 1),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 0, 3, 1),
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  note: {
    ...theme.typography.medium1Normal,
    border: `1px solid ${theme.palette.digitalGray[60]}`,
    borderRadius: 5,
    width: '100%',
    padding: theme.spacing(1),
  },
  saveButton: {
    marginTop: theme.spacing(3),
    '& button': {
      ...focusVisible(),
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const DietPreferencePage = ({ goBack, user }) => {
  const classes = useStyles();
  const dietsList = useSelector((state) => state.diets.list);
  const allergiesList = useSelector((state) => state.allergies.list);
  const mounted = useIsMounted();

  const {
    storedPreferences: storedDiets,
    currentPreferences: selectedDiets,
    setCurrentPreferences: setSelectedDiets,
  } = useDietPreferences({ type: DIET_PREFERENCE_TYPE.RESTRICTION, user });

  const {
    storedPreferences: storedAllergies,
    currentPreferences: selectedAllergies,
    setCurrentPreferences: setSelectedAllergies,
  } = useDietPreferences({ type: DIET_PREFERENCE_TYPE.ALLERGY, user });

  const {
    storedPreferences: storedAdditionalNotes,
    currentPreferences: currentAdditionalNotes,
    setCurrentPreferences: setCurrentAdditionalNotes,
  } = useDietPreferences({ type: DIET_PREFERENCE_TYPE.ADDITIONAL_NOTES, user });

  const changeNotes = useCallback(
    (e) => {
      if (e.target.value.length <= 250) {
        setCurrentAdditionalNotes(e.target.value);
      }
    },
    [setCurrentAdditionalNotes]
  );

  const saveUser = useSaveUserCallback(user);

  const savePreferences = useCallback(() => {
    saveUser({
      dietIds: selectedDiets.map((diet) => diet.id),
      allergyIds: selectedAllergies.map((allergy) => allergy.id),
      notes: currentAdditionalNotes,
    });
    goBack();
  }, [
    currentAdditionalNotes,
    goBack,
    saveUser,
    selectedAllergies,
    selectedDiets,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BackButtonLayout
      back={goBack}
      title={EDIT_PROFILE_DIETARY_PREFERENCES_TITLE}
      role="alert"
      aria-live="polite">
      <Grid container item className={classes.description}>
        <Typography variant="medium1Normal" className={classes.diet}>
          {EDIT_PROFILE_DIETARY_PREFERENCES_SUBTITLE}
        </Typography>
      </Grid>
      <DietaryPreferences
        text={PROFILE_DIETARY_PREFERENCES_DIETS_LABEL}
        itemMap={dietsList}
        list={selectedDiets}
        setRestrictions={setSelectedDiets}
      />
      <Divider className={classes.divider} />
      <DietaryPreferences
        text={PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL}
        itemMap={allergiesList}
        list={selectedAllergies}
        setRestrictions={setSelectedAllergies}
      />
      <Grid container item className={classes.additionalNotesContainer}>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center">
          <Typography variant="small2Normal" className={classes.label}>
            {PROFILE_DIETARY_PREFERENCES_NOTES_LABEL}
          </Typography>
          <Typography variant="small2Normal" className={classes.label}>
            {currentAdditionalNotes.length}/250
          </Typography>
        </Grid>
        {mounted && (
          <TextareaAutosize
            aria-label={RESIZE_TEXT_BOX_ALT_TEXT}
            rowsMin={6}
            className={classes.note}
            defaultValue={currentAdditionalNotes}
            onChange={changeNotes}
            maxLength={250}
          />
        )}
      </Grid>

      <Grid item xs={12} className={classes.saveButton}>
        <EditSaveButton
          onSave={savePreferences}
          disabled={
            isEqual(selectedDiets, storedDiets) &&
            isEqual(selectedAllergies, storedAllergies) &&
            isEqual(currentAdditionalNotes, storedAdditionalNotes)
          }
        />
      </Grid>
    </BackButtonLayout>
  );
};

export default DietPreferencePage;
