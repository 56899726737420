import { AVAILABLE_TIMES_ALT_TEXT } from '../../assets/copy';
import AvailableTimes from './AvailableTimes';
import { Box, GridList } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import NextAvailabilityList from './NextAvailabilityList';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import PlaceholderLoader from '../../components/loading/PlaceholderLoader';
import { WIDTH_LG_BREAKPOINT } from '../../utils/constants/Breakpoints';
import NoAvailabilityInfo from './NoAvailabilityInfo';
import {
  checkIfHasTimeslots,
  getFormattedLongDateWithWeekday,
} from '../../utils/dateTime';
import clsx from 'clsx';
import { TIME_AVAILABILITY_TYPES } from './timeAvailabilityTypes';
import { useCopy } from '../../utils/useCopy';
import createInflection from '../../utils/inflection/createInflection';

const useStyles = makeStyles((theme) => ({
  subTitle: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.up('lg')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(1, 0);
        return 0;
      },
    },
    [theme.breakpoints.up('xl')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(1, 0);
        return theme.spacing(0, 5);
      },
    },
  },
  gridList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('lg')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(2, 0);
        return theme.spacing(2, 5);
      },
      marginLeft: '0 !important',
    },
  },
  times: {
    flex: 1,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '587px',
    },
    [theme.breakpoints.up('xl')]: {
      boxSizing: 'border-box',
      maxWidth: '672px',
    },
  },
  divider: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(4, 5),
      width: '587px',
    },
    width: '100%',
  },
  dividerInBetween: {
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(4, 0),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4, 5),
    },
  },
  noAvailabilityContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 3, 0),
    [theme.breakpoints.up('lg')]: {
      width: '587px',
      justifyContent: 'center',
      padding: theme.spacing(1, 0, 0),
    },
    [theme.breakpoints.up('xl')]: {
      width: '587px',
      justifyContent: 'center',
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(1, 0, 0);
        return theme.spacing(1, 5, 0);
      },
    },
  },
}));

const AvailabilityList = ({ width, venue, list, type, handleEdit }) => {
  const classes = useStyles({ type });
  const { getCopy } = useCopy();
  const selectedDateAvailability = list?.slice(0, 1);
  const next7DaysAvailability = list?.slice(1);
  const { loadingAvailability, submittedDate, submittedTime, submittedGuests } =
    useSelector((state) => state?.availability);
  const loadedAvailability = !loadingAvailability && list;
  const [showMore, setShowMore] = useState(false);
  const [showMoreLink, setShowMoreLink] = useState(true);
  const [hasAvailabilityForSelectedDay, setHasAvailabilityForSelectedDay] =
    useState(true);
  const [hasAvailabilityForNext7Days, setHasAvailabilityForNext7Days] =
    useState(true);
  const isBetweenLgAndXl = width <= 1072 && width >= WIDTH_LG_BREAKPOINT;

  const handleShowMore = () => {
    setShowMore(true);
    setShowMoreLink(false);
  };

  useEffect(() => {
    if (loadedAvailability) {
      const availabilityForSelectedDay = checkIfHasTimeslots(
        selectedDateAvailability
      );
      const availabilityForNext7Days = checkIfHasTimeslots(
        next7DaysAvailability
      );

      if (!availabilityForSelectedDay && availabilityForNext7Days) {
        setShowMore(true);
        setShowMoreLink(false);
      }
      setHasAvailabilityForSelectedDay(availabilityForSelectedDay);
      setHasAvailabilityForNext7Days(availabilityForNext7Days);
    }
  }, [loadedAvailability, selectedDateAvailability, next7DaysAvailability]);

  useEffect(() => {
    setShowMore(false);
    setShowMoreLink(true);
  }, [submittedDate, submittedTime]);

  const selectedDateString = getFormattedLongDateWithWeekday(submittedDate);
  const hasNoAvailability =
    !hasAvailabilityForSelectedDay && !hasAvailabilityForNext7Days;

  const getNoAvailabilityCopy = () => {
    const copyKey = hasNoAvailability
      ? 'AVAILABILITY_NO_TABLES_ON_SELECTED_DATE_OR_NEXT_7_DAYS'
      : 'AVAILABILITY_NO_TABLES_ON_SELECTED_DATE';
    return getCopy(copyKey, {
      guestsInflection: createInflection('guest', submittedGuests),
      selectedDate: selectedDateString,
    });
  };

  return (
    <Box className={classes.times}>
      <Typography
        variant={
          width > WIDTH_LG_BREAKPOINT ? 'medium2Semibold' : 'medium1Semibold'
        }
        className={classes.subTitle}
        component="h2">
        {AVAILABLE_TIMES_ALT_TEXT}
      </Typography>
      {!loadedAvailability ? (
        <GridList className={classes.gridList}>
          <PlaceholderLoader length={7} />
        </GridList>
      ) : (
        <>
          {(hasNoAvailability || !hasAvailabilityForSelectedDay) && (
            <NoAvailabilityInfo
              text={getNoAvailabilityCopy()}
              dividerClassName={clsx(
                classes.divider,
                isBetweenLgAndXl && classes.dividerInBetween
              )}
              useDivider={false}
              containerClassName={classes.noAvailabilityContainer}
              type={type}
            />
          )}
          {hasAvailabilityForSelectedDay && (
            <AvailableTimes
              width={width}
              venue={venue}
              list={list && selectedDateAvailability}
              type={type}
              handleEdit={handleEdit}
            />
          )}
          <NextAvailabilityList
            hasAvailabilityForSelectedDay={hasAvailabilityForSelectedDay}
            hasAvailabilityForNext7Days={hasAvailabilityForNext7Days}
            list={next7DaysAvailability}
            width={width}
            venue={venue}
            submittedDate={submittedDate}
            submittedTime={submittedTime}
            showMore={showMore}
            showMoreLink={showMoreLink}
            handleShowMore={handleShowMore}
            selectedGuests={submittedGuests}
            selectedDateString={selectedDateString}
            type={type}
            handleEdit={handleEdit}
          />
        </>
      )}
    </Box>
  );
};

export default AvailabilityList;
