import { useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Typography from '../../overrides/Typography';
import { Button } from '../Button';
import {
  RESERVE_EDIT_SHARING_NOTICE,
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  DONE,
  SAVE_BUTTON_HELPER_TEXT,
} from '../../../assets/copy';
import { DietaryPreferences } from './DietaryPreferences';
import { trackClickWithDescription } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  // TODO see if these can be reused with other components in TableBookingModal
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  accessibleDescription: {
    display: 'none',
  },
  ctaContainer: {
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.spoon.white[100],
    padding: theme.spacing(3),
  },
  ctaButton: {
    fontSize: 16,
    lineHeight: '24px',
    padding: theme.spacing(1.5),
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    overflowX: 'hidden',
  },
  dietContainer: {
    marginTop: theme.spacing(0),
  },
  descriptionContainer: {
    marginTop: theme.spacing(1),
  },
}));

export const EditDietPreferences = ({ venue, onSave }) => {
  const classes = useStyles();
  const restrictions = useSelector((state) => state.diets.list);
  const allergies = useSelector((state) => state.allergies.list);
  const { draftReservation } = useSelector((state) => state.reservations);
  const [selectDiets, setSelectDiets] = useState(draftReservation.diets);
  const [selectAllergies, setSelectAllergies] = useState(
    draftReservation.allergies
  );

  const checkUpdated = (userSelect, draft) => {
    if (userSelect?.length !== draft?.length) return true;

    const map = new Map();

    for (const element of draft) {
      map.set(element.id, true);
    }

    for (const element of userSelect) {
      if (map.get(element.id) == null) return true;
    }

    return false;
  };

  const isPrefUpdated =
    checkUpdated(selectAllergies, draftReservation.allergies) ||
    checkUpdated(selectDiets, draftReservation.diets);

  const handleSave = () => {
    trackClickWithDescription(
      'bottom-sheet-edit-dietary-preferences-done',
      'Button',
      venue?.name
    );
    onSave(selectDiets, selectAllergies);
  };

  const handlePreferenceClick = (selection, action) => {
    trackClickWithDescription(
      'bottom-sheet-edit-dietary-preferences-modification',
      'Button',
      `venueName: ${venue?.name}, item: ${selection?.name}, action: ${action}`
    );
  };

  return (
    <div className={classes.root}>
      <Container className={classes.contentContainer}>
        <Grid item className={classes.descriptionContainer}>
          <Typography variant="small4Normal">
            {RESERVE_EDIT_SHARING_NOTICE}
          </Typography>
        </Grid>
        <Grid item className={classes.dietContainer}>
          <DietaryPreferences
            text={PROFILE_DIETARY_PREFERENCES_DIETS_LABEL}
            itemMap={restrictions}
            list={selectDiets}
            setRestrictions={setSelectDiets}
            onClick={handlePreferenceClick}
          />
          <DietaryPreferences
            text={PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL}
            itemMap={allergies}
            list={selectAllergies}
            setRestrictions={setSelectAllergies}
            onClick={handlePreferenceClick}
          />
        </Grid>
        <Container className={classes.ctaContainer}>
          {/* TODO - move accessibility code to src/components/spoon/Button/index.jsx */}
          <Button
            aria-describedby="done-button-description"
            classes={{ root: classes.ctaButton }}
            onClick={handleSave}
            role="link"
            disabled={!isPrefUpdated}>
            {DONE}
            <span
              className={classes.accessibleDescription}
              id="done-button-description">
              {SAVE_BUTTON_HELPER_TEXT}
            </span>
          </Button>
        </Container>
      </Container>
    </div>
  );
};
