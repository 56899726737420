import * as TYPES from './AvailabilityTypes';
import defaultReservation from '../../pages/venue/helpers/defaultReservation';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import { combine7RVenueIdAndExperienceId } from '../../utils/availabilityHelper';
import { DateTime } from 'luxon';
import { YEAR_MONTH_DATE_FORMAT } from '../../assets/dateFormats';

const { defaultDate, defaultTime, defaultGuests } = defaultReservation();

const initialState = {
  list: null,
  availability: null,
  listAvailabilityVenues: {},
  loadingMoreAvailabilities: false,
  loadingAvailability: false,
  selectedDate: defaultDate,
  selectedTime: defaultTime,
  selectedGuests: defaultGuests,
  submittedDate: defaultDate,
  submittedTime: defaultTime,
  submittedGuests: defaultGuests,
  submittedTickets: defaultGuests,
  paymentLoading: false,
  hasLoadedAllAvailabilities: false,
  dateRangeAvailability: null,
  availableDatesList: null,
  initialCalendarSelectedDate: null,
  calendarSelectedDate: null,
  calendarVisibleMonth: null,
};

export default function availabilityReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.GET_AVAILABILITY_REQUEST:
    case TYPES.FIND_AVAILABILITY_REQUEST: {
      return {
        ...state,
        paymentLoading: true,
        loadingAvailability: true,
      };
    }
    case TYPES.LOAD_DATE_AVAILABILITY_REQUEST:
    case TYPES.GET_EVENT_AVAILABILITY_REQUEST:
    case TYPES.GET_MULTIPLE_AVAILABILITIES_REQUEST:
    case TYPES.AVAILABILITY_LIST_REQUEST:
    case TYPES.GET_DATE_RANGE_AVAILABILITY_REQUEST:
    case TYPES.LIST_AVAILABILITY_VENUES_REQUEST:
      return {
        ...state,
        loadingAvailability: true,
      };
    case TYPES.LOAD_MORE_AVAILABILITIES:
      return {
        ...state,
        loadingMoreAvailabilities: true,
      };
    case TYPES.PRELOAD_DATE_RANGE_AVAILABILITY_REQUEST:
      // do nothing, but don't want to update loading availability flags, so not using default state
      return {
        ...state,
      };
    case TYPES.ADD_TIMESLOT_TO_AVAILABILITY:
      const updatedList = state.list?.map((availability) => {
        if (payload.date === availability.date) {
          const availabilityClone = { ...availability };
          const addTimeslots =
            availability.timeslots.length === 0
              ? payload.timeslots.slice(0)
              : payload.timeslots.slice(1); // if there's no availability no elements in array [] add all of the timeslots [0 ~ n] else if there's atleast one [7:00 am] in the timeslots don't include [7:00 am] = [1 ~ n],
          availabilityClone.timeslots = [
            ...availability.timeslots,
            ...addTimeslots,
          ]; //adding the two timeslots availability.timeslots and payload.timeslots. I'm annoyed that I have to do a [7:30, 7:45].slice(1) to get rid of 7:30 since it's being returned by 7R when I use 7:30pm as startTime, I thought we will be given the next time slot
          return availabilityClone;
        }
        return availability;
      });
      return {
        ...state,
        list: updatedList,
        loadingMoreAvailabilities: false,
      };
    case TYPES.SET_AVAILABILITY_LOADING_STATUS:
      return {
        ...state,
        loadingAvailability: payload,
      };
    case TYPES.AVAILABILITY_LIST_SUCCESS:
    case TYPES.LOAD_DATE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        list: payload,
        loadingAvailability: false,
      };
    case TYPES.GET_EVENT_AVAILABILITY_SUCCESS:
    case TYPES.GET_MULTIPLE_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        list: payload,
        loadingAvailability: false,
      };
    case TYPES.GET_DATE_RANGE_AVAILABILITY_SUCCESS: {
      // dedup existing date range availability with new payload
      const uniqueDates = new Set();
      const combinedPayload = [
        ...(state.dateRangeAvailability ?? []),
        ...payload,
      ];
      const dedupedCombinedPayload = combinedPayload.filter((item) => {
        const isDuplicate = uniqueDates.has(item.date);
        uniqueDates.add(item.date);
        return !isDuplicate;
      });
      if (
        state.dateRangeAvailability &&
        !(state.list?.[0].timeslots?.length === 0)
      ) {
        // if data already exist, then this is the loading of a month that is not preloaded
        // also check to make sure we are NOT navigating from a no availability month to a month with availability
        const dateRangeList = dedupedCombinedPayload
          .filter((availability) => availability.timeslots.length > 0)
          .map((availability) =>
            DateTime.fromFormat(
              availability.date,
              YEAR_MONTH_DATE_FORMAT
            ).toJSDate()
          );
        return {
          ...state,
          loadingAvailability: false,
          dateRangeAvailability: dedupedCombinedPayload,
          availableDatesList: dateRangeList,
        };
      } else {
        // if data does not exist, then this is a fresh load of the venue details page or guest count update.
        // or there was no availability in the previously selected month and we want to refresh availability timeslot.
        // find the earliest date and set the availability timeslot to the availability of that date
        let earliestDate = null;
        let earliestResult = null;

        const dateRangeList = dedupedCombinedPayload.reduce(
          (acc, availability) => {
            const date = DateTime.fromFormat(
              availability.date,
              YEAR_MONTH_DATE_FORMAT
            ).toJSDate();
            if (!earliestResult || date < earliestResult) {
              earliestResult = date;
            }

            if (availability.timeslots.length > 0) {
              acc.push(date);
              if (!earliestDate || date < earliestDate) {
                earliestDate = date;
              }
            }
            return acc;
          },
          []
        );
        if (
          earliestResult &&
          earliestDate &&
          earliestResult.getMonth() === earliestDate.getMonth()
        ) {
          // earliestResult and earliestDate are in the same month
          // there is availability in the current displayed month
          // select a date to display
          const selectedDate = DateTime.fromJSDate(earliestDate).toFormat(
            YEAR_MONTH_DATE_FORMAT
          );
          const foundAvailability =
            dedupedCombinedPayload.find((availability) => {
              return availability.date === selectedDate;
            }) || null;

          return {
            ...state,
            loadingAvailability: false,
            dateRangeAvailability: dedupedCombinedPayload,
            availableDatesList: dateRangeList,
            initialCalendarSelectedDate: earliestDate,
            calendarSelectedDate: earliestDate,
            calendarVisibleMonth: earliestDate,
            list: foundAvailability ? [foundAvailability] : null,
          };
        } else {
          // select the earliest result as the default date when no availability
          const selectedDate = DateTime.fromJSDate(earliestResult).toFormat(
            YEAR_MONTH_DATE_FORMAT
          );
          const foundAvailability =
            dedupedCombinedPayload.find((availability) => {
              return availability.date === selectedDate;
            }) || null;
          return {
            ...state,
            loadingAvailability: false,
            dateRangeAvailability: dedupedCombinedPayload,
            availableDatesList: dateRangeList,
            calendarVisibleMonth: state.calendarVisibleMonth
              ? state.calendarVisibleMonth
              : earliestResult,
            list: foundAvailability ? [foundAvailability] : null,
          };
        }
      }
    }
    case TYPES.PRELOAD_DATE_RANGE_AVAILABILITY_SUCCESS:
      // preload only when there is existing data
      // a preload success can return with no existing data if a guest change was triggered while still doing a preload
      if (state.dateRangeAvailability) {
        // dedup existing date range availability with new payload
        const uniqueDates = new Set();
        const combinedPayload = [
          ...(state.dateRangeAvailability ?? []),
          ...payload,
        ];
        const dedupedCombinedPayload = combinedPayload.filter((item) => {
          const isDuplicate = uniqueDates.has(item.date);
          uniqueDates.add(item.date);
          return !isDuplicate;
        });
        const dateRangeList = dedupedCombinedPayload
          .filter((availability) => availability.timeslots.length > 0)
          .map((availability) =>
            DateTime.fromFormat(
              availability.date,
              YEAR_MONTH_DATE_FORMAT
            ).toJSDate()
          );
        return {
          ...state,
          loadingAvailability: false,
          dateRangeAvailability: dedupedCombinedPayload,
          availableDatesList: dateRangeList,
        };
      } else {
        // do nothing
        return {
          ...state,
        };
      }
    case TYPES.LIST_AVAILABILITY_VENUES_CLEAR:
      return {
        ...state,
        listAvailabilityVenues: {},
      };
    case TYPES.LIST_AVAILABILITY_VENUES_PROGRESS:
      return {
        ...state,
        listAvailabilityVenues: payload.reduce(
          (venues, venue) => ({
            ...venues,
            [combine7RVenueIdAndExperienceId(venue)]: venue.availability,
          }),
          { ...state.listAvailabilityVenues }
        ),
      };
    case TYPES.SET_HAS_LOADED_ALL_AVAILABILITIES: {
      return {
        ...state,
        hasLoadedAllAvailabilities: payload,
      };
    }
    case TYPES.LIST_AVAILABILITY_VENUES_FAIL:
    case TYPES.GET_MULTIPLE_AVAILABILITIES_FAIL:
    case TYPES.GET_DATE_RANGE_AVAILABILITY_FAIL:
    case TYPES.PRELOAD_DATE_RANGE_AVAILABILITY_FAIL:
    case TYPES.GET_EVENT_AVAILABILITY_FAIL:
      return {
        ...state,
        loadingAvailability: false,
      };
    case TYPES.GET_AVAILABILITY_SUCCESS:
    case TYPES.FIND_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: payload,
        loadingAvailability: false,
        paymentLoading: false,
        loadingMoreAvailabilities: false,
      };
    case TYPES.SET_FAB_AVAILABILITY_DATE: {
      return {
        ...state,
        selectedDate: payload?.selectedDate || defaultDate,
      };
    }
    case TYPES.SET_FAB_AVAILABILITY_TIME: {
      return {
        ...state,
        selectedTime: payload?.selectedTime || defaultTime,
      };
    }
    case TYPES.SET_FAB_AVAILABILITY_GUESTS: {
      return {
        ...state,
        selectedGuests: payload?.selectedGuests || defaultGuests,
      };
    }
    case TYPES.SET_FAB_DISPLAY_VALUES: {
      sessionStorage.setItem(
        'defaultDate',
        payload?.submittedDate || defaultDate
      );
      sessionStorage.setItem(
        'defaultTime',
        payload?.submittedTime || defaultTime
      );
      sessionStorage.setItem(
        'defaultGuests',
        payload?.submittedGuests || defaultGuests
      );
      return {
        ...state,
        submittedDate: payload?.submittedDate || defaultDate,
        submittedTime: payload?.submittedTime || defaultTime,
        submittedGuests: payload?.submittedGuests || defaultGuests,
      };
    }
    case TYPES.SET_SUBMITTED_TICKETS: {
      return {
        ...state,
        submittedTickets: payload?.submittedTickets || defaultGuests,
      };
    }
    case TYPES.SET_DATE_RANGE_AVAILABILITY_TIMESLOT_LIST: {
      // retrieve the timeslot availability from the stored data for a certain date and store it for display.
      if (state.dateRangeAvailability) {
        const selectedDate = DateTime.fromJSDate(payload).toFormat(
          YEAR_MONTH_DATE_FORMAT
        );
        const foundAvailability =
          state.dateRangeAvailability.find((availability) => {
            return availability.date === selectedDate;
          }) || null;

        return {
          ...state,
          list: foundAvailability ? [foundAvailability] : state.list,
          calendarSelectedDate: payload,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case TYPES.SET_CALENDAR_DISPLAYED_MONTH: {
      return {
        ...state,
        calendarVisibleMonth: payload,
      };
    }
    case TYPES.SET_CALENDAR_SELECTED_DATE: {
      return {
        ...state,
        initialCalendarSelectedDate: payload,
      };
    }
    case TYPES.CLEAR_AVAILABILITY: {
      return {
        ...state,
        availability: null,
      };
    }
    case TYPES.CLEAR_LIST_AVAILABILITIES: {
      return {
        ...state,
        list: null,
      };
    }
    case TYPES.CLEAR_DATE_RANGE_AVAILABILITIES: {
      return {
        ...state,
        dateRangeAvailability: null,
        availableDatesList: null,
        initialCalendarSelectedDate: null,
        calendarVisibleMonth: null,
        calendarSelectedDate: null,
      };
    }
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return {
        ...state,
        loadingAvailability: false,
      };
  }
}
