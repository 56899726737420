import * as types from './DietsTypes';

export const actionDietListRequest = () => ({
  type: types.DIET_LIST_REQUEST,
});

export const actionDietListSuccess = (payload) => ({
  type: types.DIET_LIST_SUCCESS,
  payload,
});

export const actionDietListFail = (payload) => ({
  type: types.DIET_LIST_FAIL,
  payload,
});
