import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import PageSection from '../../components/core/PageSection';
import Video from '../../components/core/Video';
import { useViewport } from '../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import Typography from '../../components/overrides/Typography';
import {
  DINING_EXPERIENCE_VIDEO_TITLE,
  DINING_EXPERIENCE_VIDEO_TRANSCRIPT,
} from '../../assets/copy';
import chefPicture1 from '../../assets/images/chef-jose-andres.png';
import chefPicture2 from '../../assets/images/chef-dominique-crenn.png';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
    marginTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(5),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    margin: '0 auto',
    width: '100%',
    maxWidth: 545,
  },
  placeholderImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto',
  },
  videoSectionContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '90%',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
  },
  chefImage: {
    maxHeight: 375,
    maxWidth: 544,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 197,
      maxWidth: 327,
    },
  },
  chefSectionContainer: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  chefImageContainer: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  videoContainer: {
    maxHeight: 681,
    maxWidth: 544,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 409.356,
      maxWidth: 327,
    },
  },
  video: {
    width: '100%',
    maxHeight: 681,
    maxWidth: 544,
    objectFit: 'fill',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 409.356,
      maxWidth: 327,
    },
  },
}));

const VideoSection = () => {
  const { width } = useViewport();
  const classes = useStyles();
  const isMobile = width < WIDTH_BREAKPOINT;
  const { videoUrl } = useSelector((state) => state.config);

  return (
    <Box className={classes.root}>
      <PageSection className={classes.container} variant="grid">
        <Typography
          variant={isMobile ? 'medium3Light' : 'large1Light'}
          className={classes.title}
          component="h2">
          {DINING_EXPERIENCE_VIDEO_TITLE}
        </Typography>
        <Box className={classes.videoSectionContainer}>
          <Grid container spacing={4} justify="center">
            <Grid
              container
              item
              sm={12}
              lg={12}
              xl={6}
              justify="center"
              spacing={4}>
              <Grid container item justify="center">
                <Video
                  src={videoUrl}
                  alt={DINING_EXPERIENCE_VIDEO_TRANSCRIPT}
                  aria-label={DINING_EXPERIENCE_VIDEO_TITLE}
                  muted
                  disablePictureInPicture
                  controls
                  controlsList="nodownload noplaybackrate noremoteplayback"
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              lg={12}
              xl={6}
              spacing={4}
              justify="center">
              <Grid container item justify="center">
                <img className={classes.chefImage} src={chefPicture1} alt="" />
              </Grid>
              <Grid container item justify="center">
                <img className={classes.chefImage} src={chefPicture2} alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PageSection>
    </Box>
  );
};

export default VideoSection;
