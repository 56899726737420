const convertVenueStructure = (list) =>
  list?.venues.items?.map((item) => ({ ...item.venue }));

const transformVenues = (premierEvents) => {
  const transformedPremierEvents = premierEvents.map((premierEvent) => {
    const premierEventClone = { ...premierEvent };
    const convertedVenues = convertVenueStructure(premierEvent);
    premierEventClone.venues = convertedVenues;
    return premierEventClone;
  });
  return transformedPremierEvents;
};

export const findPremierEvents = (payload) =>
  payload.filter((premierLocation) => premierLocation.spotlight);

export const transformPremierlist = (premierLists) => {
  const premierEvents = findPremierEvents(premierLists);
  const transformedPremierlist = transformVenues(premierEvents);
  return transformedPremierlist;
};
