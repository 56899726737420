/*
  Reservation Confirm is used for confirming new reservations and for confirming edits to existing reservations.
  Reservation data can be fetched from draftReservation.

  Things we should be doing: Waiting for a successful creation, purging draftReservation,
  getting new id, and fetching the reservation for the new id.
*/

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PageSection from '../../components/core/PageSection';
import FilledButton from '../../components/core/FilledButton';
import Typography from '../../components/overrides/Typography';
import SeeMoreRestaurantsLink from '../../components/reserve/SeeMoreRestaurantsLink';
import CheckedSvg from '../../assets/icons/checked.svg';
import CautionSvg from '../../assets/icons/caution.svg';
import LargeCard from '../../components/core/Card/LargeCard';
import CommonLoading from '../common/CommonLoading';
import { BREAKPOINT_VALUES } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionClearReservationCreateStatus,
  actionClearReservationUpdateStatus,
} from '../../store/Reservations/ReservationsAction';
import {
  RESERVATION_CONFIRMED_SUCCESS,
  RESERVATION_CONFIRMED_FAILED,
  RESERVATION_CONFIRMED_NAVIGATE_TO_RESERVATIONS,
  RESERVATIONS_SEE_RESTAURANT,
  RESERVATIONS_SEE_EVENT,
  RESERVATION_ID_LABEL,
} from '../../assets/copy';
import EntertainmentBanner from '../../components/banner/EntertainmentBanner';
import useEventFlag from '../../utils/useEventFlag';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 0),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 'auto'),
      maxWidth: 327,
    },
  },
  confirmedCard: {
    borderRadius: `${theme.spacing(0.5)}`,
    [theme.breakpoints.up('md')]: {
      width: 327,
    },
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      height: 50,
      width: 50,
    },
  },
  link: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2.5),
    },
  },
  button: {
    ...focusVisible(),
    [theme.breakpoints.up('md')]: {
      maxWidth: 327,
    },
  },
  confirmationId: {
    fontWeight: 'bold',
    clear: 'both',
    textAlign: 'center',
    color: theme.palette.secondary[100],
  },
  confirmationLabel: {
    clear: 'both',
    textAlign: 'center',
    color: theme.palette.secondary[100],
    marginRight: theme.spacing(0.5),
  },
  confirmationContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const widthBreakpoint = BREAKPOINT_VALUES.md;

const ReservationConfirm = ({ location }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const history = useHistory();
  const dispatch = useDispatch();
  const { draftReservation, reservation } = useSelector(
    (state) => state.reservations
  );

  const [confirmedReservation, setConfirmedReservation] =
    useState(draftReservation);
  const [status, setStatus] = useState();
  const { navigationMenuVisible } = useSelector((state) => state.appData);
  const eventStatus = useEventFlag(confirmedReservation?.venue);

  const navigateToReservationList = () => {
    history.push('/reservations');
  };

  useEffect(() => {
    if (location.reservationCreateStatus) {
      setStatus(location.reservationCreateStatus);
      dispatch(actionClearReservationCreateStatus());
    } else if (location.reservationUpdateStatus) {
      setStatus(location.reservationUpdateStatus);
      dispatch(actionClearReservationUpdateStatus());
    }
  }, [location, draftReservation, reservation, dispatch]);

  useEffect(() => {
    if (draftReservation && draftReservation.venue) {
      setConfirmedReservation(draftReservation);
      // clear draft as we no longer need it
    }
  }, [dispatch, draftReservation]);

  const navigateToVenueDetails = () => {
    history.push(`/venue/${reservation.venue.id}`);
    // set the reservation create status to blank on navigate away
  };

  const isMounted = () => reservation != null && confirmedReservation && status;

  if (!location.fromReservationPage) {
    history.push('/');
  }

  return (
    <>
      <CommonLoading active={!isMounted()} />
      <PageSection variant={width >= widthBreakpoint ? 'box' : null}>
        {isMounted() && !navigationMenuVisible && (
          <main className={classes.root}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItems="center">
              <img
                alt={status.success ? 'checked' : 'failed'}
                className={classes.icon}
                src={status.success ? CheckedSvg : CautionSvg}
              />
              <Typography
                variant={
                  width >= widthBreakpoint ? 'large1Light' : 'medium3Light'
                }
                className={classes.title}
                component="h1"
                aria-live="polite">
                {status.success
                  ? RESERVATION_CONFIRMED_SUCCESS
                  : RESERVATION_CONFIRMED_FAILED}
              </Typography>
              <Box className={classes.confirmationContainer}>
                <Typography
                  variant={'small4Normal'}
                  className={classes.confirmationLabel}>
                  {RESERVATION_ID_LABEL}
                </Typography>
                <Typography
                  variant={'small4Normal'}
                  className={classes.confirmationId}>
                  {reservation?.referenceCode}
                </Typography>
              </Box>
            </Grid>
            <Box className={classes.cardContainer}>
              {status.success && (
                <LargeCard
                  style={classes.confirmedCard}
                  venue={confirmedReservation.venue}
                  reservation={confirmedReservation}
                  onClick={navigateToVenueDetails}
                  linkText={
                    eventStatus.venueHasEvent
                      ? RESERVATIONS_SEE_EVENT
                      : RESERVATIONS_SEE_RESTAURANT
                  }
                  showActionLinks={!eventStatus.venueHasEvent}
                />
              )}
              {!status.success &&
                status.error.errors.map((error, index) => {
                  return (
                    <Typography variant="medium1Normal" key={`error-${index}`}>
                      {error.message}
                    </Typography>
                  );
                })}
            </Box>
            <Grid
              container
              justify="center"
              direction="column"
              alignItems="center">
              <EntertainmentBanner type="reservation" />
              <FilledButton
                text={RESERVATION_CONFIRMED_NAVIGATE_TO_RESERVATIONS}
                variant="medium2Normal"
                style={classes.button}
                onClick={navigateToReservationList}
              />
              <SeeMoreRestaurantsLink className={classes.link} />
            </Grid>
          </main>
        )}
      </PageSection>
    </>
  );
};

export default ReservationConfirm;
