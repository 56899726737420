import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useViewport } from '../../utils/useViewport';
import StepBackground from '../../components/onboarding/StepBackground';
import StepContent from '../../components/onboarding/StepContent';
import StepFooter from '../../components/onboarding/StepFooter';
import StepHeader from '../../components/onboarding/StepHeader';
import FilledButton from '../../components/core/FilledButton';
import OnboardingPersonalizeBg from '../../assets/images/onboardingPersonalizeBg.png';
import OnboardingPersonalizeBgLarge from '../../assets/images/onboardingPersonalizeBgLarge.png';
import Transition from '../../components/animation/Transition';
import {
  ONBOARDING_PERSONALIZE_TITLE,
  ONBOARDING_EDIT_PROFILE_BUTTON_TEXT,
  SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT,
} from '../../assets/copy';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { onboardingBaseStyles } from './OnboardingStyles';
import clsx from 'clsx';
import TextLink from './TextLink';
import useOnboarding from '../../utils/useOnboarding';

const useStyles = makeStyles((theme) => ({
  ...onboardingBaseStyles(theme),
  bgImg: {
    background: `linear-gradient(to bottom, rgba(0,0,0,0), ${theme.palette.background.blue} 95%), url(${OnboardingPersonalizeBg}) no-repeat`,
    backgroundSize: 'cover',
    zIndex: -1,
    [theme.breakpoints.up('xl')]: {
      background: `linear-gradient(to left, rgba(0,0,0,0), rgb(16, 37, 63, 0.7) 40%, rgb(16, 37, 63, 1) 90%), url(${OnboardingPersonalizeBgLarge}) no-repeat`,
      backgroundSize: 'cover',
    },
  },
  wrapper: {
    padding: theme.spacing(32, 3, 31, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(25, 2, 31, 6),
    },
    [theme.breakpoints.up('1048')]: {
      height: 'calc(100% - 170px)',
    },
  },
  personalizeButton: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 264,
    },
  },
  grid: {
    marginBottom: theme.spacing(3),
  },
}));

const Personalize = ({ handleNext, active }) => {
  const { width } = useViewport();
  const classes = useStyles();
  const isMobile = width < WIDTH_BREAKPOINT;
  const divider = false;
  const { handleRestaurant } = useOnboarding();

  return (
    <Box className={classes.root}>
      <StepBackground
        active={active}
        backgroundStyles={classes.bgImg}
        slideAnimation={classes.slideAnimation}
      />
      <StepContent>
        <Transition classNames={classes.slideAnimation} active={active}>
          <Box
            className={clsx(classes.contentWrapper, classes.wrapper)}
            tabIndex={0}>
            <StepHeader
              title={ONBOARDING_PERSONALIZE_TITLE}
              divider={divider}
            />
          </Box>

          <StepFooter>
            <Grid
              container
              item
              spacing={isMobile ? 2 : 4}
              className={classes.grid}
              alignItems={isMobile ? 'center' : 'flex-start'}
              direction={isMobile ? 'column' : 'row'}>
              <Grid container={isMobile} item>
                <FilledButton
                  text={ONBOARDING_EDIT_PROFILE_BUTTON_TEXT}
                  variant="medium2NormalButtonFlex"
                  onClick={handleNext}
                  style={classes.personalizeButton}
                />
              </Grid>
              <Grid container={isMobile} item>
                <TextLink
                  text={SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT}
                  onClickHandler={handleRestaurant}
                  buttonClassName={classes.exploreButton}
                  className={classes.directionalButton}
                />
              </Grid>
            </Grid>
          </StepFooter>
        </Transition>
      </StepContent>
    </Box>
  );
};

export default Personalize;
