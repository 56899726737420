import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Badge from '../Badge';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
}));

const VenueBadge = ({ text, type, ...props }) => {
  const classes = useStyles();

  if (!text) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Badge type={type} text={text} />
    </Box>
  );
};

export default VenueBadge;
