import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useHistory } from 'react-router';

export const useOnboardingRedirect = () => {
  const { user } = useAuth();
  const [shouldOnboard, setShouldOnboard] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!user?.session?.isAuthenticated) return;

    // If first-time user, marketId will be nullish, so redirect to onboarding
    if (user?.marketId == null) {
      setShouldOnboard(true);
      history.push('/onboarding');
    }
  }, [history, user.session.isAuthenticated, user.marketId, shouldOnboard]);

  return shouldOnboard;
};
