import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import {
  SEARCH_RESULT_INFLECTION,
  SEARCH_RESULT_NO_RESULT_JOIN,
  SEARCH_RESULT_NO_RESULT_JOIN_2,
  SEARCH_RESULT_NO_RESULT_PREFIX,
  SEARCH_RESULT_NO_RESULT_SUFFIX,
} from '../../../assets/copy';
import createInflection from '../../../utils/inflection/createInflection';
import PageSection from '../../core/PageSection';
import ScreenReaderText from '../../core/ScreenReaderText';
import SearchResultsLoader from './SearchResultsLoader';

const useStyles = makeStyles((theme) => ({
  resultsContainer: {
    minHeight: 'calc(100vh - 287px)',
    justifyContent: 'center',
    paddingBottom: 160,
  },
  resultsContent: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      paddingTop: 0,
    },
  },
}));

const SearchResultsContainer = ({ children, isLoading = true }) => {
  const classes = useStyles();
  const { cachedQuery: searchQuery } = useSelector((state) => state.search);
  const { name: marketName } = useSelector((state) => state.markets?.market);
  const { totalCount, inLocalMarket } = useSelector((state) => state.venues);

  const getScreenReaderResultsMessage = useCallback(() => {
    const resultsCount = totalCount ?? 0;
    const resultsFoundText = createInflection(
      SEARCH_RESULT_INFLECTION,
      resultsCount
    );

    if (searchQuery && !inLocalMarket) {
      return (
        SEARCH_RESULT_NO_RESULT_PREFIX +
        searchQuery +
        SEARCH_RESULT_NO_RESULT_JOIN +
        marketName +
        SEARCH_RESULT_NO_RESULT_JOIN_2 +
        resultsFoundText +
        SEARCH_RESULT_NO_RESULT_SUFFIX
      );
    }

    return `${resultsFoundText}.`;
  }, [totalCount, searchQuery, inLocalMarket, marketName]);

  return (
    <PageSection
      aria-busy={isLoading}
      aria-label="Search Results"
      aria-live="polite"
      className={classes.resultsContainer}
      component="section"
      variant="grid">
      <ScreenReaderText component="h2">Search Results</ScreenReaderText>
      <ScreenReaderText aria-atomic="true" aria-live="polite">
        {getScreenReaderResultsMessage()}
      </ScreenReaderText>
      <Grid className={classes.resultsContent} item sm={12} xl={8}>
        {isLoading ? <SearchResultsLoader /> : children}
      </Grid>
    </PageSection>
  );
};

export default SearchResultsContainer;
