import React from 'react';
import PropTypes from 'prop-types';
import HeroCarousel from '../../../components/core/HeroCarousel';
import DateTime from '../../../utils/dateTimeHelpers';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSearchRedirect } from '../../../components/search/helpers';
import { actionSetCurrentFilters } from '../../../store/Filter/FilterAction';
import {
  FILTER_TYPES,
  VENUE_TYPE_FILTERS,
  VENUE_TYPES,
} from '../../../utils/constants/FilterTypes';
import {
  HOME_HERO_CAROUSEL_BOOK_TABLE_TEXT,
  HOME_HERO_CAROUSEL_BROWSE_RESTAURANTS_TEXT,
  HOME_HERO_CAROUSEL_GET_TICKETS_TEXT,
  HOME_HERO_CAROUSEL_BROWSE_EVENTS_TEXT,
  HOME_HERO_CAROUSEL_HIGHLIGHT_LABEL,
  HOME_HERO_CAROUSEL_NEW_EVENT_LABEL,
  HOME_HERO_CAROUSEL_NEW_VENUE_LABEL,
} from '../../../assets/copy';
import { useShortVenueDetails } from '../../../utils/useShortVenueDetails';
import { useEvergreenTile } from '../../../utils/useEvergreenTile';
import { trackClickWithDescription } from '../../../utils/useOneTag';

export const Hero = ({ venues, openBookingModal }) => {
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const dispatch = useDispatch();
  const { getShortVenueDetails } = useShortVenueDetails();
  const { evergreenTile } = useEvergreenTile();
  const venueLabelsMap = {};

  const generateSlideFromVenue = (venue) => {
    const shortVenueDetails = getShortVenueDetails(venue);
    const venueType = venue.venueType;
    let primaryButtonText,
      primaryButtonOnClick,
      secondaryButtonText,
      secondaryButtonOnClick,
      learnMoreOnClick,
      subheading,
      label;
    switch (venueType) {
      case 'EVENT':
        primaryButtonText = HOME_HERO_CAROUSEL_GET_TICKETS_TEXT;
        primaryButtonOnClick = () => {
          trackClickWithDescription(
            'hero-primary-button',
            'Button',
            venue.name
          );
          history.push(`/venue/${venue.id}`);
        };
        secondaryButtonText = HOME_HERO_CAROUSEL_BROWSE_EVENTS_TEXT;
        secondaryButtonOnClick = () => {
          trackClickWithDescription('hero-browse-button', 'Button', venue.name);
          history.push('/dining-experience');
        };
        subheading = shortVenueDetails;
        break;
      case 'REGULAR':
      default:
        primaryButtonText = HOME_HERO_CAROUSEL_BOOK_TABLE_TEXT;
        primaryButtonOnClick = () => {
          trackClickWithDescription(
            'hero-primary-button',
            'Button',
            venue.name
          );
          openBookingModal(venue);
        };
        secondaryButtonText = HOME_HERO_CAROUSEL_BROWSE_RESTAURANTS_TEXT;
        secondaryButtonOnClick = () => {
          dispatch(
            actionSetCurrentFilters({
              [FILTER_TYPES.VENUE_TYPE]: VENUE_TYPE_FILTERS.filter(
                (type) => type.venueType === VENUE_TYPES.REGULAR
              ),
            })
          );
          trackClickWithDescription('hero-browse-button', 'Button', venue.name);
          history.push('/search', { redirectTo });
        };
        learnMoreOnClick = () => {
          trackClickWithDescription(
            'hero-learn-more-button',
            'Button',
            venue.name
          );
          history.push(`/venue/${venue.id}`);
        };
        subheading = shortVenueDetails;
        break;
    }
    if (venue.labels.includes('tier_1') && !venueLabelsMap['tier_1']) {
      venueLabelsMap['tier_1'] = true;
    } else if (
      venue.labels.includes('highlighted_event') &&
      !venueLabelsMap['highlighted_event']
    ) {
      label = HOME_HERO_CAROUSEL_HIGHLIGHT_LABEL;
      venueLabelsMap['highlighted_event'] = true;
    } else if (
      venue.labels.includes('new_event') &&
      !venueLabelsMap['new_event']
    ) {
      label = HOME_HERO_CAROUSEL_NEW_EVENT_LABEL;
      venueLabelsMap['new_event'] = true;
    } else {
      const metricDate = new DateTime().minus({ days: 31 });
      const adjustedMetricISO = new Date(metricDate.toISO()).getTime();
      const dateAdded = new DateTime(venue.liveDate);
      const dateAddedISO = new Date(dateAdded.toISO()).getTime();
      if (adjustedMetricISO <= dateAddedISO) {
        label = HOME_HERO_CAROUSEL_NEW_VENUE_LABEL;
      }
    }
    return {
      imageUrl: venue.imagesFull?.[0],
      heading: venue.name,
      subheading: subheading,
      description: venue.description,
      descriptionShort: venue.descriptionShort,
      primaryButtonText: primaryButtonText,
      primaryButtonOnClick: primaryButtonOnClick,
      label: label,
      learnMoreOnClick: learnMoreOnClick,
      secondaryButtonText: secondaryButtonText,
      secondaryButtonOnClick: secondaryButtonOnClick,
    };
  };

  const slides = venues.map((venue) => generateSlideFromVenue(venue));
  slides.push(evergreenTile);

  return <HeroCarousel pagination slides={slides} />;
};

Hero.propTypes = {
  venues: PropTypes.arrayOf(
    PropTypes.shape({
      venueType: PropTypes.string.isRequired,
      sevenRoomsVenueId: PropTypes.string.isRequired,
      neighborhoodId: PropTypes.string.isRequired,
      cuisineNames: PropTypes.string,
      priceRange: PropTypes.string,
      eventDates: PropTypes.arrayOf(PropTypes.object),
      labels: PropTypes.arrayOf(PropTypes.string),
      imagesFull: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      descriptionShort: PropTypes.string,
    })
  ).isRequired,
  openBookingModal: PropTypes.func.isRequired,
};

export default Hero;
