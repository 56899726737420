import { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ScreenReaderText from './ScreenReaderText';

const InfoIconTooltip = ({ title, tooltipStyle, infoIconStyle }) => {
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [infoIconColor, setInfoIconColor] = useState('secondary');

  const handleToolTipOpen = () => {
    setIsToolTipOpen(true);
    setInfoIconColor('primary');
  };
  const handleToolTipClose = () => {
    setIsToolTipOpen(false);
    setInfoIconColor('secondary');
  };

  return (
    <>
      <ScreenReaderText id={title}>{title}</ScreenReaderText>
      <Tooltip
        tabIndex={0}
        onFocus={handleToolTipOpen}
        onBlur={handleToolTipClose}
        aria-labelledby="tooltipTitle"
        title={title}
        onOpen={handleToolTipOpen}
        onClose={handleToolTipClose}
        placement="top"
        onClick={handleToolTipOpen}
        open={isToolTipOpen}
        disableFocusListener
        disableTouchListener
        classes={{ tooltip: tooltipStyle }}
        PopperProps={{ role: 'tooltip' }}>
        <InfoIcon
          className={infoIconStyle}
          color={infoIconColor}
          aria-hidden="false"
          role='button'
        />
      </Tooltip>
    </>
  );
};

export default InfoIconTooltip;
