import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 24,
    padding: theme.spacing(0, 1),
    borderRadius: 12,
    background: theme.palette.red.light,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    color: theme.palette.red.text,
  },
}));

const Label = ({ text }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="small4Semibold" className={classes.text}>
        {text}
      </Typography>
    </Box>
  );
};

export default Label;
