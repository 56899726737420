import Divider from '../../components/overrides/Divider';
import { Grid } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import { makeStyles } from '@material-ui/styles';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import {
  EDIT_BUTTON_TEXT,
  EDIT_DEFAULT_PAYMENT_BUTTON_LABEL,
  PAYMENT_METHODS_TITLE,
} from '../../assets/copy';
import { useViewport } from '../../utils/useViewport';
import clsx from 'clsx';
import { useAuth } from '../../utils/useAuth';
import { actionUpdateUserRequest } from '../../store/User/UserAction';
import { useDispatch } from 'react-redux';
import CreditCard from './CreditCard';
import { useMemo } from 'react';
import { useGetPaymentMethod } from './useGetPaymentMethod';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';
import PaymentCardInfoBlock from './PaymentCardInfoBlock';
import FirstTimePaymentOptionsSelect from '../reserve/FirstTimePaymentOptionsSelect';

const useStyles = makeStyles((theme) => ({
  paymentDivider: {
    margin: `${theme.spacing(0)} !important`,
  },
  paymentContainer: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 720,
    },
  },
  alert: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
    '& > :first-child': {
      padding: 0,
      margin: 0,
    },
  },
  edit: {
    flex: 1,
    order: ({ isMobile, type }) => {
      if (!isMobile && type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE)
        return 3;
      return 'inherit';
    },
    textAlign: 'end',
  },
  editLabel: {
    cursor: 'pointer',
    color: theme.palette.primary[50],
    [theme.breakpoints.up('xl')]: {
      marginTop: 0,
    },
  },
}));

const PaymentMethods = ({ className, type, onEdit }) => {
  const { width } = useViewport();
  const isMobile = width <= WIDTH_BREAKPOINT;
  const classes = useStyles({ type, isMobile });
  const { user } = useAuth();
  const isSingleCardUser = user?.associatedAccounts?.length === 1;
  const shouldHideUserPaymentMessage = user.hidePaymentMethodAlert;
  const dispatch = useDispatch();
  const handleSaveUserHidePaymentAlert = () => {
    dispatch(
      actionUpdateUserRequest({
        hidePaymentMethodAlert: true,
        id: user.id,
      })
    );
  };

  const hasDefaultPaymentMethod = !!user?.defaultPaymentMethodAccountId;
  const defaultAccount = useGetPaymentMethod(type);

  const gridXLWidth = useMemo(() => {
    if (
      type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE &&
      !isSingleCardUser &&
      defaultAccount
    )
      return 6;
    if (type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE) return 9;
    return 12;
  }, [type, isSingleCardUser, defaultAccount]);

  return (
    <>
      <Divider className={classes.paymentDivider} />
      <Grid
        className={clsx(classes.paymentContainer, className)}
        spacing={isMobile ? 1 : 0}
        container
        justify={isMobile ? 'space-between' : 'flex-start'}>
        <Grid
          item
          xs={9}
          xl={type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE ? 3 : 9}>
          <Typography component="h2" variant="medium1Semibold">
            {PAYMENT_METHODS_TITLE}
          </Typography>
        </Grid>

        {/* Edit button for multi-card users on the profile page only */}
        {!isSingleCardUser && hasDefaultPaymentMethod && (
          <Grid item xs={3} xl={3} className={classes.edit}>
            <Typography
              aria-label={EDIT_DEFAULT_PAYMENT_BUTTON_LABEL}
              align={width > WIDTH_BREAKPOINT ? 'right' : 'inherit'}
              onClick={onEdit}
              variant="medium1Semibold"
              className={classes.editLabel}
              role="button"
              tabIndex={0}>
              {EDIT_BUTTON_TEXT}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} xl={gridXLWidth}>
          <PaymentCardInfoBlock
            onEdit={onEdit}
            type={type}
            isSingleCardUser={isSingleCardUser}
            defaultAccount={defaultAccount}
            shouldHideUserPaymentMessage={shouldHideUserPaymentMessage}
            handleSaveUserHidePaymentAlert={handleSaveUserHidePaymentAlert}
          />
          {(hasDefaultPaymentMethod || isSingleCardUser) && (
            <CreditCard
              account={defaultAccount}
              isDefaultPayment={
                !isSingleCardUser &&
                defaultAccount?.associatedAccountReferenceId ===
                  user?.defaultPaymentMethodAccountId
              }
              type={type}
              onSelect={null}
              isSingleCardUser={isSingleCardUser}
            />
          )}
          {!isSingleCardUser &&
            type === PAYMENT_METHOD_COMPONENT_TYPES.RESERVE &&
            !hasDefaultPaymentMethod && (
              <FirstTimePaymentOptionsSelect user={user} type={type} />
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentMethods;
