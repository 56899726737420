import React, { useCallback, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR } from '../../assets/copy';
import theme from '../../theme';
import { isValidShortSpecialDate } from '../../utils/dateTime';
import TextInput from '../core/TextInput';
import Typography from '../overrides/Typography';
import MaskedDateInput from './MaskedDateInput';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  message: {
    textAlign: 'left',
    fontSize: 12,
  },
  inputField: {
    '& > input::-webkit-contacts-auto-fill-button': {
      visibility: 'hidden',
      display: 'none !important',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
    },
  },
  inputErrorField: {
    '& > input': {
      borderColor: theme.palette.red[50],
      '&:active, &:focus': {
        borderColor: theme.palette.red[50],
      },
    },
  },
}));

const SpecialDateInput = ({
  initialValue,
  onChange = () => {},
  inputClass,
  errorClass,
  setError,
  ...props
}) => {
  const classes = useStyles();

  const [message, setMessage] = useState('');

  const [inputValue, setInputValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const inputErrorClassName = useMemo(
    () => (errorClass == null ? classes.inputErrorField : errorClass),
    [classes, errorClass]
  );

  const handleBlur = (e) => {
    e.target.placeholder = '';
  };
  const handleFocus = (e) => {
    e.target.placeholder = 'MM/DD';
  };

  const handleInputChange = useCallback(
    (event) => {
      const emptyDate = '__/__';
      const updatedInputValue =
        event.target?.value && event.target?.value !== emptyDate
          ? event.target.value
          : null;

      setIsValid(isValidShortSpecialDate(updatedInputValue));

      if (isValidShortSpecialDate(updatedInputValue)) {
        setError(false);
        setMessage('');
      } else {
        setError(true);
        setMessage(EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR);
      }
      onChange(updatedInputValue);
      setInputValue(updatedInputValue);
    },
    [setMessage, setError, onChange, setInputValue]
  );

  const renderErrorMessage = useCallback(
    () =>
      message && (
        <Typography
          className={clsx(classes.message, inputErrorClassName)}
          variant="medium1Normal"
          role="alert">
          {message}
        </Typography>
      ),
    [message, classes.message, inputErrorClassName]
  );

  return (
    <>
      <TextInput
        autoComplete="off"
        inputProps={{
          inputMode: 'numeric',
        }}
        margin="dense"
        onChange={handleInputChange}
        value={inputValue}
        variant="outlined"
        fullWidth
        inputComponent={MaskedDateInput}
        {...props}
        setError={setError}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={clsx(
          classes.inputField,
          inputClass,
          !isValid && inputErrorClassName
        )}
      />
      {renderErrorMessage()}
    </>
  );
};

export default SpecialDateInput;
