import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '../overrides/Typography';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-filled.svg';
import clsx from 'clsx';
import InfoIconTooltip from './InfoIconTooltip';
import { enterKeyPressHandler } from '../../utils/keyPresses';
import {
  LEARN_MORE_BUTTON_TEXT,
  SIGNATURE_COLLECTION_TEXT,
  ABOUT_THE_TEXT,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.background.grayLight,
    border: theme.palette.background.grayLight,
    borderRadius: '12px',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    color: theme.palette.digitalGray[95],
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  leading: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
    width: 15,
    height: 15,
  },
  trailing: {
    margin: theme.spacing(0.75, 1, 0.5, 0.5),
    width: 10,
    height: 10,
    '& path': {
      fill: theme.palette.digitalGray[95],
    },
  },
  blackToolTip: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    lineHeight: '21px',
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    width: 'auto',
    [theme.breakpoints.up('xl')]: {
      width: '256px',
      marginLeft: theme.spacing(4),
    },
  },
}));

const InteractiveTag = ({
  rootStyle,
  text,
  leadingIcon,
  leadingIconStyle,
  onClickHandler,
  contentStyle,
  toolTipContent,
  trailingIconStyle,
}) => {
  const classes = useStyles();
  const onKeyPressHandler = enterKeyPressHandler && onClickHandler;

  const toolTip = () => {
    return (
      <Typography
        variant="tiny1Semibold"
        className={clsx(classes.content, contentStyle)}>
        {text}
        <InfoIconTooltip
          title={toolTipContent}
          infoIconStyle={classes.trailing}
          tooltipStyle={classes.blackToolTip}
        />
      </Typography>
    );
  };

  const getCustomAriaLabel = () => {
    return `${LEARN_MORE_BUTTON_TEXT} ${ABOUT_THE_TEXT} ${SIGNATURE_COLLECTION_TEXT}`;
  };

  const leadingTrailingIconTag = () => {
    return (
      <Typography
        variant="tiny1Semibold"
        className={clsx(classes.content, contentStyle)}
        tabIndex={0}
        aria-label={getCustomAriaLabel()}
        role="button"
        onClick={onClickHandler}
        onKeyPress={onKeyPressHandler}>
        <img
          src={leadingIcon}
          alt=""
          role="presentation"
          className={clsx(classes.leading, leadingIconStyle)}
        />
        {text}
        <InfoIcon className={clsx(classes.trailing, trailingIconStyle)} />
      </Typography>
    );
  };

  return (
    <Box className={clsx(classes.root, rootStyle)}>
      {leadingIcon ? leadingTrailingIconTag() : toolTip()}
    </Box>
  );
};

export default InteractiveTag;
