import React, { useRef } from 'react';
import { ButtonBase, makeStyles, useTheme } from '@material-ui/core';
import { useHover, usePress } from '@react-aria/interactions';
import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: theme.spacing(0.5),
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: 50,
    justifyContent: 'center',
    padding: theme.spacing(0.5, 1),
    textTransform: 'capitalize',
    userSelect: 'none',
    width: 64,
    '-webkit-user-select': 'none',
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
      outline: 0,
    },
  },
}));

const TipChip = ({
  children,
  onPress,
  selected = false,
  variant = 'percent',
}) => {
  const buttonRef = useRef();
  const { hoverProps, isHovered } = useHover({});
  const { pressProps } = usePress({ onPress });
  const classes = useStyles();
  const theme = useTheme();

  const getBorderColor = () => {
    if (selected) {
      return theme.palette.chip.background;
    }

    if (variant === 'custom') {
      return 'transparent';
    }

    if (isHovered) {
      return theme.palette.chip.active;
    }

    return theme.palette.primary.main;
  };

  return (
    <ButtonBase
      {...hoverProps}
      {...pressProps}
      aria-selected={selected}
      className={classes.root}
      ref={buttonRef}
      style={{
        background: selected
          ? theme.palette.chip.background
          : theme.palette.background.white,
        borderColor: getBorderColor(),
      }}>
      <Typography
        variant="medium3Semibold"
        style={{
          color:
            isHovered || selected
              ? theme.palette.chip.active
              : theme.palette.primary.main,
        }}>
        {children}
      </Typography>
    </ButtonBase>
  );
};

export default TipChip;
