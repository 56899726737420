import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    left: 0,
    overflow: 'hidden',
    paddingTop: theme.spacing(7),
    position: 'relative',
    top: 0,
    width: '100vw',
    [theme.breakpoints.up('xl')]: {
      display: 'block',
      paddingTop: 0,
      width: '50vw',
    },
  },
}));

const StepContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Box component="main" className={classes.root}>
      {children}
    </Box>
  );
};

export default StepContent;
