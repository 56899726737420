import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '../../overrides/Typography';
import DropdownSvg from '../../../assets/icons/whiteDropdown.svg';
import createInflection from '../../../utils/inflection/createInflection';
import ScreenReaderText from '../../../components/core/ScreenReaderText';
import { useSelector } from 'react-redux';
import { GUEST_INFLECTION } from '../../../assets/copy.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
    height: '40px',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '48px',
    },
  },
  dateBasic: {
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: 24,
    background: theme.palette.blue[50],
    maxWidth: 320,
    margin: 'auto',
  },
  dateText: {
    flex: 1,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    padding: theme.spacing(1, 0, 1, 2),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5, 0, 1.5, 2),
    },
  },
  image: {
    padding: theme.spacing(0, 2),
  },
}));

const TimeParameter = ({ className, onClick }) => {
  const classes = useStyles();
  const { submittedDate, submittedTime, submittedGuests } = useSelector(
    (state) => state.availability
  );

  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.dateBasic} onClick={onClick}>
        <ScreenReaderText component="time">
          Find a table for,
          {moment(submittedDate).format('MMMM DD')},{submittedTime},
          <span>{createInflection(GUEST_INFLECTION, submittedGuests)}</span>
        </ScreenReaderText>
        <Typography
          variant="medium1Semibold"
          className={classes.dateText}
          aria-hidden="true">
          {moment(submittedDate).format('MMM D')} • {submittedTime} •{' '}
          {createInflection(GUEST_INFLECTION, submittedGuests)}
        </Typography>
        <img src={DropdownSvg} alt="" className={classes.image} />
      </Box>
    </Box>
  );
};

export default TimeParameter;
