import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { store, history, persistor } from './store';
import AppRouter from './router';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { ProvideAuth } from './utils/useAuth';
import { ProvideHeap } from './utils/useHeap';
import { PersistGate } from 'redux-persist/lib/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <ProvideAuth>
              <ProvideHeap>
                <AppRouter />
              </ProvideHeap>
            </ProvideAuth>
          </ConnectedRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
