import safeHtml from 'safe-html';

//config for safeHtml
const config = {
  allowedTags: [
    'div',
    'span',
    'b',
    'i',
    'p',
    'strong',
    'br',
    'em',
    'mark',
    'small',
    'sub',
    'sup',
    'a',
    'u',
  ],
};

const generateSafeHtmlTags = (text) => ({
  __html: safeHtml(text ?? '', config),
});

export default generateSafeHtmlTags;