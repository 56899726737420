import * as types from './CuisinesTypes';

//Cuisine list
export const actionCuisineListRequest = () => ({
  type: types.CUISINE_LIST_REQUEST,
});

export const actionCuisineListSuccess = (payload) => ({
  type: types.CUISINE_LIST_SUCCESS,
  payload,
});

export const actionCuisineListFail = (payload) => ({
  type: types.CUISINE_LIST_FAIL,
  payload,
});
