import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PageSection from '../../components/core/PageSection';
import Typography from '../../components/overrides/Typography';
import { useSearchRedirect } from '../../components/search/helpers';
import { actionSetCurrentFilters } from '../../store/Filter/FilterAction';
import { FILTER_TYPES } from '../../utils/constants/FilterTypes';
import { useViewport } from '../../utils/useViewport';
import { HOME_CUISINES_SUBTITLE } from '../../assets/copy';
import Carousel from '../../components/core/Carousel';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { imageWidth } from '../../components/core/coreStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    overflow: 'hidden',
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 5, 3),
      boxSizing: 'border-box',
    },
  },
  label: {
    padding: theme.spacing(4, 0, 0, 0),
    color: theme.palette.background.white,
    wordBreak: 'break-word',
  },
  gridList: {
    margin: `${theme.spacing(1.75, 3, 0)}!important`,
    flexWrap: 'nowrap',
    gap: theme.spacing(1),
    transform: 'translateZ(0)',
    padding: theme.spacing(0.5, 0),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      margin: `${theme.spacing(2.5, 0, 0)}!important`,
      padding: theme.spacing(0.5, 5),
    },
  },
  paper: {
    minHeight: 70,
    padding: '8px 16px !important',
    display: 'flex',
    alignItems: 'center',
    minWidth: 120,
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
      opacity: 0.93,
      cursor: 'pointer',
    },
  },
  title: {
    marginBottom: 12,
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      marginBottom: 20,
      marginLeft: theme.spacing(0),
    },
  },
  divider: {
    background: theme.palette.gray[30],
    marginTop: theme.spacing(5),
  },
  li: {
    margin: theme.spacing(0, -0.5, 0, 0),
    width: 'auto !important',
    height: 'auto !important',
  },
  gap: {
    [theme.breakpoints.up('xl')]: {
      columnGap: theme.spacing(4),
    },
  },
}));

const Cuisines = ({ hasUpdatedMarket }) => {
  const classes = useStyles();
  const { width } = useViewport();

  const dispatch = useDispatch();
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const cuisines = useSelector((state) => state.cuisines);
  const markets = useSelector((state) => state.markets);
  const listVenues = useSelector((state) => state.venues);

  const [filteredCuisines, setFilteredCuisines] = useState([]);

  const isDesktop = width > WIDTH_BREAKPOINT;

  //TODO: Refactor this later
  useEffect(() => {
    if (cuisines.list) {
      const cuisinesMap = {};
      cuisines.list.forEach((cuisine) => {
        cuisinesMap[cuisine.id] = cuisine;
      });

      setFilteredCuisines(cuisinesMap);
    }
  }, [listVenues, markets, cuisines]);

  const handleClick = (event, cuisine) => {
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_id: event.currentTarget.id,
      ui_interaction_element_name: "homepage-cuisine",
      ui_interaction_element_type: 'Card',
      ui_interaction_element_description: cuisine.name
    });
    dispatch(actionSetCurrentFilters({ [FILTER_TYPES.CUISINES]: [cuisine] }));
    history.push('/search', { redirectTo });
  };

  const renderCarousel = () => {
    const allCuisines = Object.values(filteredCuisines)?.map((cuisine) => (
      <div key={`Cuisine-li-${cuisine.id}`}>
        <div
          key={`Cuisine-div-${cuisine.id}`}
          data-cy="homepage-cuisine"
          className={classes.paper}
          onClick={(e) => handleClick(e, cuisine)}
          onKeyPress={(e) => e.key === 'Enter' && handleClick(e, cuisine)}
          tabIndex={0}
          role="link"
          aria-label={`${cuisine.name} Restaurants`}
          style={{
            backgroundImage: `linear-gradient(rgba(16, 37, 63, 0.73), rgba(16, 37, 63, 0.73)), url(${cuisine.images[0]}) `,
            maxWidth: '100%',
            width: isDesktop ? 160 : 156,
            height: imageWidth,
            borderRadius: 4,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          }}>
          <Typography variant="medium1Semibold" className={classes.label}>
            {cuisine.name}
          </Typography>
        </div>
      </div>
    ));

    return allCuisines.length > 0 ? (
      <div>
        <Carousel
          shadowPadding={4}
          galleryClass={classes.gap}
          defaultItemIndex={hasUpdatedMarket && 0}
          forceUpdatePosition={hasUpdatedMarket}>
          {allCuisines}
        </Carousel>
      </div>
    ) : null;
  };

  return (
    <PageSection className={classes.root}>
      <Typography
        variant={isDesktop ? 'medium4Normal' : 'medium2Semibold'}
        className={classes.title}
        component="h2">
        {HOME_CUISINES_SUBTITLE}
      </Typography>
      {renderCarousel()}
    </PageSection>
  );
};

export default Cuisines;
