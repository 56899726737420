import { PAYMENT_METHOD_COMPONENT_TYPES } from '../profile/paymentComponentTypes';
import CreditCard from '../profile/CreditCard';
import Divider from '../../components/overrides/Divider';
import React, { useEffect, useState } from 'react';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import { makeStyles } from '@material-ui/styles';
import { actionSetPaymentAccount } from '../../store/Reservations/ReservationsAction';
import { useDispatch } from 'react-redux';
import { useGetPaymentMethod } from '../profile/useGetPaymentMethod';

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 720,
    },
  },
  paymentSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1, 0, 1),
  },
  paymentCardsDivider: {
    width: '100%',
    margin: theme.spacing(3, 0, 3),
  },
}));

const FirstTimePaymentOptionsSelect = ({ user, type }) => {
  const { width } = useViewport();
  const isMobile = width <= WIDTH_BREAKPOINT;
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const defaultAccount = useGetPaymentMethod(type);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [selectedDefault, setSelectedDefault] = useState(null);

  const handleSelectPayment = (value) => {
    setSelectedPayment(value);
    if (value.associatedAccountReferenceId !== selectedDefault)
      setSelectedDefault(null);
    value && dispatch(actionSetPaymentAccount(value));
  };

  const handleSelectDefault = (value) => {
    setSelectedDefault(
      value === selectedDefault
        ? // double click un-selects the checkbox
          null
        : value
    );

    // Cloning selectedPayment to prevent Redux bug where it also saves isDefaultPaymentEdited to user state
    const selectedPaymentClone = {
      ...selectedPayment,
      // Only save default payment method when the users select default checkbox
      isDefaultPaymentEdited: value !== selectedDefault,
    };
    dispatch(actionSetPaymentAccount(selectedPaymentClone));
  };

  useEffect(() => {
    defaultAccount && setSelectedPayment(defaultAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.paymentSelectionContainer} role="radiogroup">
      {user.associatedAccounts.map((account, index) => {
        const isDefaultPayment =
          account.associatedAccountReferenceId ===
          user.defaultPaymentMethodAccountId;

        return (
          <div
            key={`first-time-payment-container-${account.associatedAccountReferenceId}`}>
            <div className={classes.paymentContainer}>
              <CreditCard
                selectedPayment={selectedPayment}
                handleSelectPayment={handleSelectPayment}
                account={account}
                isDefaultPayment={isDefaultPayment}
                isSingleCardUser={false}
                checked={
                  selectedDefault === account.associatedAccountReferenceId
                }
                showCheckBox={
                  type === PAYMENT_METHOD_COMPONENT_TYPES.RESERVE &&
                  selectedPayment.associatedAccountReferenceId ===
                    account.associatedAccountReferenceId &&
                  selectedPayment.associatedAccountReferenceId !==
                    user.defaultPaymentMethodAccountId
                }
                onSelect={handleSelectDefault}
                type={PAYMENT_METHOD_COMPONENT_TYPES.EDIT_VIEW}
              />
            </div>

            {index !== user.associatedAccounts.length - 1 && (
              <Divider className={classes.paymentCardsDivider} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FirstTimePaymentOptionsSelect;
