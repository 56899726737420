import { Box } from '@material-ui/core';
import LargeCard from '../../components/core/Card/LargeCard';
import {
  shortScreenReaderVenueDetails,
  shortVenueDetails,
} from '../../utils/venue';
import MediaImg from '../../assets/images/media.png';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import { generateScreenReaderText } from '../../utils/stringHelperMethods';
import Typography from '../../components/overrides/Typography';
import React from 'react';
import { WIDTH_LG_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { imageWidth } from '../../components/core/coreStyles';

const useStyles = makeStyles((theme) => ({
  venue: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      margin: 0,
      width: '336px',
    },
  },
  venueLg: {
    width: '587px',
  },
  card: {
    width: '100%',
  },
  horizontalCard: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: '587px',
      margin: theme.spacing(0, 0, 3),
    },
  },
  horizontalImage: {
    width: 168,
  },
  image: {
    width: imageWidth,
    height: imageWidth,
    borderRadius: 4,
  },
  cardContent: {
    overflow: 'hidden',
  },
}));

const VenueDetail = ({ width, venue }) => {
  const classes = useStyles();
  const isBetweenLgAndXl = width <= 1072 && width >= WIDTH_LG_BREAKPOINT;

  return (
    <Box className={clsx(classes.venue, isBetweenLgAndXl && classes.venueLg)}>
      {width > WIDTH_LG_BREAKPOINT ? (
        <LargeCard
          venue={venue}
          cardType="venue"
          details={shortScreenReaderVenueDetails(venue)}
          style={clsx(classes.card, isBetweenLgAndXl && classes.horizontalCard)}
          cardContentStyle={isBetweenLgAndXl && classes.cardContent}
          imageStyle={isBetweenLgAndXl && classes.horizontalImage}
        />
      ) : (
        <Box display="flex" alignItems="center">
          <img
            src={venue.images[0] || MediaImg}
            alt=""
            className={classes.image}
          />
          <Box px={3}>
            <ScreenReaderText>
              {generateScreenReaderText(
                ',',
                venue.name,
                shortScreenReaderVenueDetails(venue)
              )}
            </ScreenReaderText>
            <Typography variant="medium1Normal" aria-hidden="true">
              {venue.name}
            </Typography>
            <Typography variant="small2Normal" aria-hidden="true">
              {shortVenueDetails(venue)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default VenueDetail;
