import React from 'react';
import { makeStyles } from '@material-ui/styles';
import BackSvg from '../../assets/icons/back-blue.svg';
import { IconButton } from '@material-ui/core';
import {
  ICON_BACK_ARROW_ALT_TEXT,
  CLOSE_BUTTON_DESCRIPTION,
} from '../../assets/copy';

const useStyles = makeStyles({
  accessibleDescription: {
    display: 'none',
  },
  iconButton: {
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'highlight auto',
    },
    '&:focus-visible': {
      outline: '-webkit-focus-ring-color auto',
    },
  },
});

const BlueBackButton = ({
  back,
  height = 'auto',
  width = 'auto',
  label = 'Back',
  role = 'link',
}) => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        onClick={back}
        role={role}
        className={classes.iconButton}
        disableRipple={true}>
        <img
          aria-describedby="back-link-description"
          aria-label={label}
          alt={ICON_BACK_ARROW_ALT_TEXT}
          height={height}
          src={BackSvg}
          width={width}
        />
      </IconButton>
      <span
        className={classes.accessibleDescription}
        id="back-link-description">
        {CLOSE_BUTTON_DESCRIPTION}
      </span>
    </>
  );
};

export default BlueBackButton;
