import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import NavigationLink from '../NavigationLink';
import { DESKTOP_NAVIGATION_LINKS } from '../../../utils/constants/Links';
import { trackClick } from '../../../utils/useOneTag';
import { useFeatureFlags } from '../../../utils/useFeatureFlags';
import { NAVIGATION_PREMIER_LOCATIONS } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('xxl')]: {
      gap: theme.spacing(5),
      padding: theme.spacing(0, 7),
    },
  },
}));

const DesktopMenu = () => {
  const classes = useStyles();
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();
  const handleDesktopNavClick = (event, dataCy) => {
    trackClick(event, dataCy, "Link");
  }
  return (
    <Grid className={classes.root} container item>
      {DESKTOP_NAVIGATION_LINKS.map(({ label, path, variant, dataCy }) => (
        ((!refreshLandingPageFlagIsEnabled || label !== NAVIGATION_PREMIER_LOCATIONS) && (
          <NavigationLink
            key={path}
            label={label}
            path={path}
            variant={variant}
            data-cy={dataCy}
            onClick={(e) => {handleDesktopNavClick(e, dataCy)}}
          />
        ))
      ))}
    </Grid>
  );
};

export default DesktopMenu;
