import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '../overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import ChefListCarousel from './ChefListCarousel';

const useStyles = makeStyles((theme) => ({
  chefName: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 3, 2, 3),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
}));

const ChefList = ({ venues, name, showGetTicketLink = false }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const sectionTitle = name ? name : '';

  return (
    <>
      <Typography
        variant={width > WIDTH_BREAKPOINT ? 'medium3Light' : 'medium1Semibold'}
        className={classes.chefName}
        component="h3"
        aria-label={sectionTitle}>
        {sectionTitle}
      </Typography>
      <ChefListCarousel venues={venues} showGetTicketLink={showGetTicketLink} />
    </>
  );
};

export default ChefList;
