import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    borderBottom: `${theme.palette.secondary[60]} solid 1px`,
    '&:last-child': {
      borderBottom: 'none',
    },
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('xl')]: {},
  },
}));

const ContactInfoBlock = (props) => {
  const classes = useStyles();
  const onKeyPressHandler = useCallback(
    (e) => e.key === 'Enter' && props.onClickHandler(),
    [props]
  );

  return (
    <>
      <Box
        className={classes.item}
        tabIndex={0}
        role="link"
        onClick={props.onClickHandler}
        onKeyPress={onKeyPressHandler}>
        <img
          src={props.imageSrc}
          alt=""
          className={props.additionalImagesClasses}
          width={20}
        />
        <Typography>{props.children}</Typography>
      </Box>
    </>
  );
};

export default ContactInfoBlock;
