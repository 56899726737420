import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '.';
import { makeStyles } from '@material-ui/core';
import { TextButton } from '../../spoon/Button';
import {
  GENERIC_ERROR_DESCRIPTION_TEXT,
  OK_TEXT,
  SOMETHING_WENT_WRONG_TEXT,
} from '../../../assets/copy';
import Typography from '../../overrides/Typography';
import AlertFilled from '../../../assets/icons/alert-filled.svg';

const useStyles = makeStyles((theme) => ({
  alertImage: { width: '64px', height: '64px' },
  content: {
    padding: theme.spacing(0, 3),
  },
  title: {
    color: theme.palette.spoon.black[90],
    margin: theme.spacing(1, 0),
  },
  description: {
    color: theme.palette.secondary[65],
    margin: theme.spacing(1, 0, 2),
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(1),
  },
}));

export const ErrorDialog = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      showHeader={false}
      width={'280px'}
      classes={classes.dialogWrapper}>
      <div className={classes.content}>
        <img src={AlertFilled} className={classes.alertImage} alt="" />
        <Typography variant="medium2Semibold" className={classes.title}>
          {SOMETHING_WENT_WRONG_TEXT}
        </Typography>
        <Typography variant="small2Normal" className={classes.description}>
          {GENERIC_ERROR_DESCRIPTION_TEXT}
        </Typography>
        <div className={classes.ctaContainer}>
          <TextButton onClick={onClose}>{OK_TEXT}</TextButton>
        </div>
      </div>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
