import { put, takeLatest } from 'redux-saga/effects';

import { listCategorys } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './CategoriesAction';
import * as TYPES from './CategoriesTypes';

export function* collectionListRequestHandler() {
  try {
    const variables = {
      filter: { isActive: { eq: true }, status: { eq: true } },
    };
    const result = yield callGraphqlWithToken({
      query: listCategorys,
      variables,
    });
    yield put(
      ACTIONS.actionCategoryListSuccess(result.data.listCategorys.items)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionCategoryListFail(e));
  }
}

export default function* categoriesSaga() {
  yield takeLatest(TYPES.CATEGORY_LIST_REQUEST, collectionListRequestHandler);
}
