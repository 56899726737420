import * as TYPES from './AnimationsTypes';

const initialState = {
  pushUp: false,
  slideLeft: false,
  slideRight: false,
};

export default function animationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.SET_ANIMATION_PUSH_UP:
      return {
        ...state,
        pushUp: payload,
      };
    case TYPES.SET_ANIMATION_SLIDE_LEFT:
      return {
        ...state,
        slideLeft: payload,
      };
    case TYPES.SET_ANIMATION_SLIDE_RIGHT:
      return {
        ...state,
        slideRight: payload,
      };
    case TYPES.RESET_ANIMATIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
