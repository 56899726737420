import * as types from './ConfigTypes';

export const actionGetConfigRequest = (payload) => ({
  type: types.GET_CONFIG_REQUEST,
  payload,
});

export const actionGetConfigSuccess = (payload) => ({
  type: types.GET_CONFIG_SUCCESS,
  payload,
});

export const actionGetConfigFail = (payload) => ({
  type: types.GET_CONFIG_FAIL,
  payload,
});

export const actionSetConfig = (payload) => ({
  type: types.SET_CONFIG,
  payload,
});
