import { Box, makeStyles } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import {
  getFormattedLongDateWithWeekday,
  getFormattedShortDateWithWeekday,
} from '../../utils/dateTime';
import Carousel from '../../components/core/Carousel';
import {
  WIDTH_BREAKPOINT,
  WIDTH_LG_BREAKPOINT,
} from '../../utils/constants/Breakpoints';
import clsx from 'clsx';
import ViewAll from './helpers/viewAll';
import React, { useEffect, useState } from 'react';
import carouselStyles from './carouselStyles';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { TIMESLOT_LABEL } from '../../assets/copy';
import { useFeatureFlags } from '../../utils/useFeatureFlags';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  ...carouselStyles(theme),
  dateText: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    padding: ({ type }) => {
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
        return theme.spacing(2, 3, 1);
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
        return theme.spacing(0, 0, 1);
    },
    [theme.breakpoints.up('lg')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(2, 0, 1);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(1, 0);
      },
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(2, 3, 1);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH)
          return theme.spacing(0, 0, 2);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(2, 0, 1);

        return theme.spacing(2, 0, 1);
      },
    },
  },
  dateContainer: {
    padding: ({ type }) => {
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE) return 0;
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
        return theme.spacing(1, 3, 1);
      return theme.spacing(3, 3, 1);
    },
    [theme.breakpoints.up('lg')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(0, 0, 1);
      },
    },
    [theme.breakpoints.up('xl')]: {
      padding: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(0, 2, 1);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH) return 0;
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return theme.spacing(0, 0, 1);
        return theme.spacing(0, 7, 1);
      },
    },
  },
  noAvailWrapper: {
    display: 'flex',
    width: '100% !important', // Added !important, width was 50% needed override
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
  },
  carouselOuterContainer: {
    width: '100% !important',
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH) return 0;
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(5);
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION)
          return 0;
        return theme.spacing(7);
      },
    },
  },
}));

const HorizontalAvailability = ({
  list,
  type,
  timeslots,
  defaultItemIndex,
  seeMoreAvailability,
  item,
  linkText,
  venueParent,
}) => {
  const classes = useStyles({ type });
  const availability = useSelector((state) => state?.availability);
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(null);

  const { refreshAvailabilityCalendarFlagIsEnabled: shouldDisplayCalendar } =
    useFeatureFlags();
  const formattedDateString = shouldDisplayCalendar
    ? getFormattedLongDateWithWeekday(item?.date)
    : getFormattedShortDateWithWeekday(item?.date);

  useEffect(() => {
    if (shouldDisplayCalendar && availability?.calendarSelectedDate != null) {
      setCalendarSelectedDate(availability.calendarSelectedDate);
    }
  }, [shouldDisplayCalendar, availability?.calendarSelectedDate]);

  return (
    <>
      {type !== TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH && (
        <Box pb={2} className={classes.dateContainer}>
          <Typography
            variant="medium1Semibold"
            className={classes.dateText}
            component="h3">
            {formattedDateString}
          </Typography>
        </Box>
      )}
      <div>
        <div className={classes.carouselOuterContainer}>
          {timeslots && timeslots.length > 0 && (
            <Carousel
              breakpointWidth={
                type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE ||
                type === TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION
                  ? WIDTH_LG_BREAKPOINT
                  : WIDTH_BREAKPOINT
              }
              useDirectionalButton
              containerClass={classes.carouselContainer}
              galleryClass={classes.carousel}
              customGapFactor={1}
              hiddenWidth={16}
              defaultItemIndex={defaultItemIndex}
              buttonClassName={clsx(classes.arrowIcon, classes.arrowButton)}
              leftArrowClassName={clsx(classes.arrowIcon, classes.leftArrow)}
              rightArrowClassName={clsx(classes.arrowIcon, classes.rightArrow)}
              elementName={TIMESLOT_LABEL}
              forceUpdatePosition={calendarSelectedDate}
              forceResetPosition={calendarSelectedDate}
              scrollBehavior={shouldDisplayCalendar ? 'auto' : 'smooth'}>
              {list}
            </Carousel>
          )}
        </div>
      </div>
      {type === TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH && !list[0] && (
        <ViewAll
          linkText={linkText}
          onClick={seeMoreAvailability}
          venueParent={venueParent}
        />
      )}
    </>
  );
};

export default HorizontalAvailability;
