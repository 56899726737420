import { put, takeLatest, select } from 'redux-saga/effects';
import { sortBySortOrder } from '../../utils/sortList';
import { listPremierLocations } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './PremierLocationsAction';
import * as TYPES from './PremierLocationsTypes';
import { getUser } from '../selectors';
import { isVenueAvailable } from '../../utils/eventHelpers';

export function* premierLocationListRequestHandler() {
  try {
    const user = yield select(getUser);
    const isTestUser = user.user.isTest;

    const result = yield callGraphqlWithToken({
      query: listPremierLocations,
      variables: {
        filter: {
          isActive: {
            eq: true,
          },
        },
      },
    });

    const premierLocations = sortBySortOrder(
      result.data.listPremierLocations.items
    );

    /**
     * Ideally we should do this sorting on the DB level but it's not easy to do
     * for a many-to-many relationship where we want to sort PremierLocation
     * venues by the sortOrder field on the Venue.
     *
     * Since there aren't a ton of venues per PremierLocation, it should be okay
     * to sort them on an application level.
     **/
    for (const premierLocation of premierLocations) {
      premierLocation.venues.items = premierLocation.venues.items
        .sort((a, b) =>
          a.venue.sortOrder < b.venue.sortOrder
            ? -1
            : a.venue.sortOrder > b.venue.sortOrder
            ? 1
            : 0
        )
        .filter((item) => {
          if (!isTestUser)
            return (
              !item.venue.isTest &&
              item.venue.isActive &&
              isVenueAvailable(item.venue) &&
              !item.venue.isHidden
            );
          return (
            item.venue.isActive &&
            isVenueAvailable(item.venue) &&
            !item.venue.isHidden
          );
        });
    }
    yield put(ACTIONS.actionPremierLocationListSuccess(premierLocations));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionPremierLocationListFail(e));
  }
}

export default function* premierLocationsSaga() {
  yield takeLatest(
    TYPES.PREMIERLOCATION_LIST_REQUEST,
    premierLocationListRequestHandler
  );
}
