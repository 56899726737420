import React, { useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Typography from '../../components/overrides/Typography';
import OutlinedMapPinSvg from '../../assets/icons/map-pin-outline-dark.svg';
import OutlinedCallSvg from '../../assets/icons/call.svg';
import OutlinedEarthSvg from '../../assets/icons/earth-outline.svg';
import GoogleMap from '../../components/core/GoogleMap/GoogleMap';
import { useViewport } from '../../utils/useViewport';
import {
  VENUE_CONTACT_INFO_TITLE,
  VENUE_CONTACT_INFO_LAUNCH_WEBSITE,
  GET_DIRECTIONS_LINK_TEXT,
} from '../../assets/copy';
import useEventFlag from '../../utils/useEventFlag';
import { useCopy } from '../../utils/useCopy';
import ContactInfoBlock from './ContactInfoBlock';
import ScreenReaderText from '../../components/core/ScreenReaderText';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 0),
      width: '100%',
    },
  },
  contactContainer: {
    padding: theme.spacing(3, 0, 4),
    [theme.breakpoints.up('xl')]: {
      border: `${theme.palette.secondary[60]} solid 1px`,
      borderRadius: '4px',
      padding: theme.spacing(2, 2, 3),
    },
  },
  title: {
    color: theme.palette.secondary[80],
  },
  address: {
    color: theme.palette.primary[100],
    cursor: 'pointer',
    marginLeft: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  infoBlockText: {
    cursor: 'initial',
    marginLeft: theme.spacing(1.5),
  },
  infoBlockTextLine: {
    cursor: 'initial',
    marginLeft: theme.spacing(4),
  },
  mapIcon: {
    alignSelf: 'baseline',
  },
  contact: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('xl')]: {
      margin: 0,
    },
  },
  item: {
    display: 'flex',
    padding: theme.spacing(2, 0, 0),
  },
}));

const widthBreakpoint = 960;

const ContactInfo = ({ venue }) => {
  const { getCopy } = useCopy();
  const classes = useStyles();
  const { width } = useViewport();

  const trackVenueDetails = useCallback(
    (name) => {
      window.oneTag.track('link', {
        event_name: 'ui_interaction',
        ui_interaction_action: 'click',
        ui_interaction_element_name: name,
        ui_interaction_element_type: 'Card',
        ui_interaction_element_description: `venueName: ${venue.name}, venueId: ${venue.sevenRoomsVenueId}`
      });
    },
    [venue.name, venue.sevenRoomsVenueId]
  );

  const handleOpenDirections = useCallback(() => {
    trackVenueDetails("venue-get-directions");
    const baseLink = 'https://maps.google.com/?q=';
    window.open(
      `${baseLink}${venue.address} ${venue.city}, ${venue.state} ${venue.zipcode}`
    );
  }, [
    venue.address,
    venue.city,
    venue.state,
    venue.zipcode,
    trackVenueDetails,
  ]);

  const handlePhoneCall = useCallback(() => {
    trackVenueDetails("venue-phone-number");
    window.open(`tel:${venue.phoneNumber}`);
  }, [
    venue.phoneNumber,
    trackVenueDetails,
  ]);

  const handleOpenWebsite = useCallback(() => {
    trackVenueDetails("venue-website");
    window.open(venue.websiteUrl);
  }, [
    venue.websiteUrl,
    trackVenueDetails,
  ]);

  const handleMapOnClick = useCallback(() => {
    trackVenueDetails("venue-map");
  }, [trackVenueDetails,]);

  const eventStatus = useEventFlag(venue);
  const mapZoomLevel = 11;

  return (
    <Box className={classes.root}>
      <Grid item xs={12} className={classes.contactContainer}>
        <Typography
          variant={
            width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
          }
          className={classes.title}
          component="h2">
          {VENUE_CONTACT_INFO_TITLE}
        </Typography>
        <Box className={classes.contact}>
          {venue.phoneNumber && (
            <>
              <ContactInfoBlock
                imageSrc={OutlinedCallSvg}
                onClickHandler={handlePhoneCall}>
                <ScreenReaderText>{`call ${venue.name} at ${venue.phoneNumber}`}</ScreenReaderText>
                <Typography
                  variant="medium1Normal"
                  className={classes.address}
                  aria-hidden="true"
                  data-cy="venue-phone-number">
                  {venue.phoneNumber}
                </Typography>
              </ContactInfoBlock>
            </>
          )}
          {!eventStatus.venueHasEvent && (
            <ContactInfoBlock
              imageSrc={OutlinedEarthSvg}
              onClickHandler={handleOpenWebsite}>
              <Typography
                variant="medium1Normal"
                className={classes.address}
                data-cy="venue-website">
                {VENUE_CONTACT_INFO_LAUNCH_WEBSITE}
              </Typography>
            </ContactInfoBlock>
          )}
          <Box className={classes.item}>
            <img src={OutlinedMapPinSvg} alt="" width={20} />
            <Typography
              variant="medium1Normal"
              className={classes.infoBlockText}>
              {venue.address}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="medium1Normal"
              className={classes.infoBlockTextLine}>
              {venue.city}, {venue.state} {venue.zipcode}
            </Typography>
          </Box>
          <Box>
            <ContactInfoBlock
              additionalImagesClasses={classes.mapIcon}
              onClickHandler={handleOpenDirections}>
              <Typography
                className={classes.address}
                component="a"
                variant="medium1Normal"
                aria-label={getCopy(
                  'ADA_GET_DIRECTIONS_TO_RESTAURANT_LINK_TEXT',
                  { restaurantName: venue?.name }
                )}>
                {GET_DIRECTIONS_LINK_TEXT}
              </Typography>
            </ContactInfoBlock>
          </Box>
        </Box>
        <GoogleMap
          address={`${venue.address}, ${venue.city}, ${venue.state} ${venue.zipcode}`}
          handleOpenDirections={handleOpenDirections}
          data-cy="venue-map"
          handleMapOnClick={handleMapOnClick}
          zoom={mapZoomLevel}
        />
      </Grid>
    </Box>
  );
};

export default ContactInfo;
