import {
  VENUE_DETAILS_JOIN,
  VENUE_MINIMUM_PRICE_TEXT,
  VENUE_MAXIMUM_PRICE_TEXT,
} from '../assets/copy';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from './stringHelperMethods';
import { PRICE_RANGE_DESCRIPTION } from './constants/PriceRangeDescription';

export const shortVenueDetails = (venue) => {
  let venueDetails = [
    venue?.cuisines?.items[0]?.cuisine?.name,
    venue?.priceRange,
    venue?.neighborhood?.name,
  ];
  return venueDetails.filter(venueDetail => !!venueDetail).join(VENUE_DETAILS_JOIN);
};

// Don't include bullets
export const shortScreenReaderVenueDetails = (venue) => {
  return generateScreenReaderText(
    ',',
    venue?.cuisines?.items[0]?.cuisine?.name || '',
    dollarSymbolText(venue?.priceRange),
    venue?.neighborhood?.name
  );
};

export const dollarSymbolText = (price) => {
  if (price?.length < 1) {
    return VENUE_MINIMUM_PRICE_TEXT;
  } else if (price?.length > 4) {
    return VENUE_MAXIMUM_PRICE_TEXT;
  } else return PRICE_RANGE_DESCRIPTION[price];
};

export const checkForDollarSymbol = (item) => {
  return convertNullToEmptyString(item).indexOf('$') > -1;
};

export const priceCheck = (item) => {
  return checkForDollarSymbol(item) ? dollarSymbolText(item) : item;
};
