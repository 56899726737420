import React from 'react';
import { Box, CardMedia, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: 681,
    maxWidth: 544,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 409.356,
      maxWidth: 327,
    },
  },
}));
const Video = ({ ...options }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CardMedia
        className={classes.root}
        component="video"
        width="100%"
        height="100%"
        {...options}
      />
    </Box>
  );
};

export default Video;
