import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useCopy } from '../../../utils/useCopy';
import OutlineButton from '../../core/OutlineButton';
import ViewGridSVG from '../../svg/ViewGrid';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.white,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    height: theme.spacing(4),
    padding: theme.spacing(0.5),
    textTransform: 'none',
    width: 180,
    '& button': {
      padding: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.white,
      border: `1px solid ${theme.palette.interactionBlue[60]}`,
      color: theme.palette.interactionBlue[60],
    },
    '& .MuiButton-startIcon svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover .MuiButton-startIcon svg path': {
      fill: theme.palette.interactionBlue[60],
    },
  },
}));

const VenueLightBoxButton = ({ className, imageCount, onOpen }) => {
  const classes = useStyles();
  const { getCopy } = useCopy();
  const handleVenueLightboxClick = (event) => {
    onOpen(event);
    trackClick(event, "venue-lightbox-button", "Button");
  }

  return (
    <OutlineButton
      type="button"
      text={getCopy('VENUE_SEE_ALL_PHOTOS', { imageCount })}
      onClick={handleVenueLightboxClick}
      style={clsx(classes.button, className)}
      variant="medium1Semibold"
      startIcon={<ViewGridSVG />}
      data-cy="venue-lightbox-button"
    />
  );
};

export default VenueLightBoxButton;
