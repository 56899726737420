import currency from 'currency.js';

const sanitizeTotalAmount = (num1, num2) => currency(num1).add(num2).value;

const sanitizeAmount = (num) => currency(num).value;

const calculateDifference = (num1, num2) => currency(num1).subtract(num2).value;

const calculateProduct = (num1, num2) => currency(num1).multiply(num2).value;

const calculateQuotient = (num1, num2) => {
  if (num2 === 0) {
    throw new Error("Can't divide by zero");
  }
  return currency(num1).divide(num2).value;
};

const displayUSD = (amount) => currency(amount).format();

const toCents = (amount) => currency(amount).intValue;

const sumTotal = (...allNumbers) =>
  allNumbers.reduce(
    (accumulator, currencyValue) => currency(accumulator).add(currencyValue),
    0
  ).value;

export {
  sanitizeTotalAmount,
  sanitizeAmount,
  calculateDifference,
  calculateProduct,
  calculateQuotient,
  displayUSD,
  sumTotal,
  toCents,
};
