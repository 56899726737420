import React from 'react';

import MultiChoice from './Chips/MultiChoice';
import { Link } from '@material-ui/core';
import { priceCheck } from '../../utils/venue';

const SelectText = ({ item, list, onClick }) => {
  const generateScreenReaderLabel = () => {
    if (item?.name !== null) {
      let name = '';
      name = priceCheck(item.name);
      if (list && list.find((it) => it.id === item.id)) {
        return name;
      } else {
        return name + ' not selected';
      }
    }
  };
  return (
    <Link
      role="button"
      tabIndex="0"
      aria-label={generateScreenReaderLabel()}
      onClick={onClick}
      onKeyPress={(e) => e.key === 'Enter' && onClick()}
      aria-pressed={
        list && list.find((it) => it.id === item.id) ? true : false
      }>
      <MultiChoice
        type={
          list && list.find((it) => it.id === item.id) ? 'active' : 'default'
        }
        text={item.name}
      />
    </Link>
  );
};
export default SelectText;
