import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import * as TYPES from './NeighborhoodsTypes';

const initialState = {
  list: null,
};

export default function neighborhoodReducer(state = initialState, action) {
  const { type, payload } = action;
  if (type === TYPES.NEIGHBORHOOD_LIST_SUCCESS) {
    return {
      ...state,
      list: payload,
    };
  } else if (type === SIGNOUT_USER_SUCCESS) {
    return initialState;
  }
  return state;
}
