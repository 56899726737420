import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, TextareaAutosize } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '../../components/overrides/Typography';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import EditSaveButton from '../../components/core/EditSaveButton';
import SelectText from '../../components/core/SelectText';
import { actionSetAnimationSlideRight } from '../../store/Animations/AnimationsAction';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import { useViewport } from '../../utils/useViewport';
import {
  RESERVE_EDIT_SPECIAL_REQUESTS,
  RESERVE_EDIT_SHARING_NOTICE,
  PROFILE_DIETARY_PREFERENCES_NOTES_LABEL,
  NOTES_PLACEHOLDER,
  SPECIAL_OCCASION_LABEL,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  notes: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
    },
  },
  note: {
    border: '1px solid #A8A8A8',
    borderRadius: '5px',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontSize: '16px',
    lineHeight: '24px',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
}));

const EditSpecialRequests = () => {
  const { specialOccasions } = useSelector((state) => state.appData);
  const { draftReservation } = useSelector((state) => state.reservations);
  const classes = useStyles();
  const widthBreakpoint = 960;
  const { width } = useViewport();
  const history = useHistory();
  const dispatch = useDispatch();
  const [notesText, setNotesText] = useState('');
  const [selectedOccasions, setSelectedOccasions] = useState(
    draftReservation.specialOccasions
  );
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  const handleChange = (e) => {
    if (notesText.length <= 250) {
      setNotesText(e.target.value);
    }
  };

  const handleBack = () => {
    dispatch(actionSetAnimationSlideRight(true));
    history.goBack();
  };

  const handleSave = () => {
    dispatch(actionSetAnimationSlideRight(true));
    const updateDraft = draftReservation;
    updateDraft.notes = notesText;
    updateDraft.specialOccasions = selectedOccasions;
    dispatch(actionSetDraftReservation(updateDraft));
    history.goBack();
  };

  const handleSelectOccasion = (item) => {
    const filter = selectedOccasions.filter(
      (allergy) => allergy.id !== item.id
    );
    if (filter.length === selectedOccasions.length) {
      setSelectedOccasions([...selectedOccasions, item]);
    } else {
      setSelectedOccasions(filter);
    }
  };

  return (
    <BackButtonLayout
      back={handleBack}
      buttonRole="link"
      title={RESERVE_EDIT_SPECIAL_REQUESTS}>
      <Grid container item className={classes.notes}>
        <Grid item mt={width > widthBreakpoint ? 2 : 0}>
          <Typography variant="medium1Normal">
            {RESERVE_EDIT_SHARING_NOTICE}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center">
          <Typography
            variant="small2Normal"
            className={classes.label}
            gutterBottom>
            {PROFILE_DIETARY_PREFERENCES_NOTES_LABEL}
          </Typography>
          <Typography
            variant="small2Normal"
            className={classes.label}
            gutterBottom>
            {notesText.length}/250
          </Typography>
        </Grid>
        {mounted && (
          <TextareaAutosize
            placeholder={NOTES_PLACEHOLDER}
            className={classes.note}
            onChange={handleChange}
            maxLength={250}
          />
        )}
      </Grid>
      <Grid container item>
        <Typography variant="small2Normal">{SPECIAL_OCCASION_LABEL}</Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
          {specialOccasions.map((item) => (
            <SelectText
              key={`key-${item.id}`}
              item={item}
              list={selectedOccasions}
              onClick={() => handleSelectOccasion(item)}
            />
          ))}
        </Box>
      </Grid>
      <Grid className={classes.buttonContainer} item xs={12}>
        <EditSaveButton onSave={handleSave} role="link" />
      </Grid>
    </BackButtonLayout>
  );
};

export default EditSpecialRequests;
