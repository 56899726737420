import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Modal,
  Box,
  Backdrop,
  Fade,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useViewport } from '../../utils/useViewport';
import clsx from 'clsx';
import { actionUpdateUserRequest } from '../../store/User/UserAction';
import StepBackground from '../../components/onboarding/StepBackground';
import StepContent from '../../components/onboarding/StepContent';
import StepFooter from '../../components/onboarding/StepFooter';
import StepHeader from '../../components/onboarding/StepHeader';
import NativeSelector from '../../components/core/NativeSelector';
import Typography from '../../components/overrides/Typography';
import OnboardingLocationBg from '../../assets/images/onboardingLocationBg.png';
import OnboardingLocationBgLarge from '../../assets/images/onboardingLocationBgLarge.png';
import CloseSvg from '../../components/svg/Close';
import gravityPalette from '../../components/gravity/global/gravityPalette';
import Transition from '../../components/animation/Transition';
import { actionGetMarketRequest } from '../../store/Markets/MarketsAction';
import {
  ONBOARDING_LOCATION_TITLE,
  ONBOARDING_LOCATION_INPUT_LABEL,
  ONBOARDING_LOCATION_INPUT_PLACEHOLDER,
  ONBOARDING_LOCATION_TOOLTIP_LABEL,
  CLOSE_BUTTON_ALT_TEXT,
  ONBOARDING_LOCATION_TOOLTIP_TEXT,
  NEXT_BUTTON_TEXT,
} from '../../assets/copy';
import { onboardingBaseStyles } from './OnboardingStyles';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import ProgressButton from './ProgressButton';

const useStyles = makeStyles((theme) => ({
  ...onboardingBaseStyles(theme),
  bgImg: {
    background: `linear-gradient(to bottom, rgba(0,0,0,0), ${theme.palette.background.blue} 95%), url(${OnboardingLocationBg}) no-repeat`,
    backgroundSize: 'cover',
    zIndex: -1,
    [theme.breakpoints.up('xl')]: {
      background: `linear-gradient(to left, rgba(0,0,0,0), rgb(16, 37, 63, 0.7) 40%, rgb(16, 37, 63, 1) 90%), url(${OnboardingLocationBgLarge}) no-repeat`,
      backgroundSize: 'cover',
    },
  },
  wrapper: {
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(30, 4, 15, 6),
    },
    [theme.breakpoints.up('1048')]: {
      height: 'calc(100% - 170px)',
    },
  },
  label: {
    margin: theme.spacing(1, 0),
  },
  card: {
    minHeight: 140,
    backgroundColor: 'transparent',
    boxShadow: `none`,
    borderRadius: 4,
    [theme.breakpoints.up('xl')]: {
      width: 344,
    },
  },
  cardContent: {
    padding: '0px !important',
  },
  description: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      paddingBottom: 0,
    },
  },
  noCityButton: {
    background: 'transparent',
    border: 'none',
    color: 'inherit',
    display: 'block',
    font: 'inherit',
    padding: 0,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('xl')]: {
      display: 'inline',
    },
  },
  close: {
    position: 'absolute',
    right: 8,
    top: 8,
    width: '14px',
    height: '14px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.25)',
    width: '100%',
    height: '100vh',
    zIndex: 999,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 327,
    height: 230,
    [theme.breakpoints.up('xl')]: {
      height: 'auto',
      width: 480,
      margin: 'auto',
    },
    boxSizing: 'border-box',
    padding: theme.spacing(4, 3),
    backgroundColor: theme.palette.digitalGray[10],
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  closeButtonContainer: {
    transform: 'translateY(-10px)',
  },
  tooltipTitle: {
    fontWeight: 300,
    margin: theme.spacing(0.5, 0, 3),
  },
}));

const Location = ({ handleNext, active, list, totalSteps, activeStep }) => {
  const { width } = useViewport();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [marketId, setMarketId] = useState('');
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const isMobile = useMemo(() => width < WIDTH_BREAKPOINT, [width]);
  const isButtonDisabled = useMemo(
    () => marketId === '' || marketId === '0',
    [marketId]
  );
  const isBusy = useMemo(() => user.isRequestPending, [user.isRequestPending]);

  const handleSelectLocation = (event) => {
    setMarketId(event.target.value);
  };

  const handleDispatchLocation = () => {
    dispatch(actionUpdateUserRequest({ id: user.id, marketId }));
    dispatch(actionGetMarketRequest({ id: marketId }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(
    () => marketId && user?.market && handleNext(),
    [user, marketId, handleNext]
  );

  return (
    <Box className={classes.root}>
      <StepBackground
        active={active}
        backgroundStyles={classes.bgImg}
        slideAnimation={classes.slideAnimation}
      />
      <StepContent>
        <Transition
          delay={1400}
          classNames={classes.slideAnimation}
          active={active}>
          <Box
            className={clsx(classes.contentWrapper, classes.wrapper)}
            tabIndex={0}>
            <Box>
              <StepHeader title={ONBOARDING_LOCATION_TITLE} />
            </Box>
            <Card className={classes.card}>
              <Typography
                color="secondary"
                variant="small2Normal"
                aria-hidden="true"
                className={classes.label}>
                {ONBOARDING_LOCATION_INPUT_LABEL}
              </Typography>
              <CardContent className={classes.cardContent}>
                <NativeSelector
                  color="secondary"
                  ariaLabel={ONBOARDING_LOCATION_INPUT_LABEL}
                  placeholder={ONBOARDING_LOCATION_INPUT_PLACEHOLDER}
                  role="combobox"
                  items={list}
                  handleSelect={handleSelectLocation}
                  selectedItem={marketId}
                />
                <Typography
                  color="secondary"
                  variant="small3Normal"
                  className={classes.description}>
                  <button className={classes.noCityButton} onClick={handleOpen}>
                    {ONBOARDING_LOCATION_TOOLTIP_LABEL}
                  </button>
                </Typography>
              </CardContent>
            </Card>
            <Modal
              className={classes.modal}
              open={open}
              onClose={handleClose}
              role="dialog"
              aria-label={ONBOARDING_LOCATION_TOOLTIP_LABEL}
              aria-describedby="dialoglabel dialogdescription"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}>
              <Fade in={open}>
                <Box className={classes.container}>
                  <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    className={classes.closeButtonContainer}>
                    <Box zIndex={2}>
                      <CloseSvg
                        className={classes.close}
                        height={width < WIDTH_BREAKPOINT ? 14 : 20}
                        width={width < WIDTH_BREAKPOINT ? 14 : 20}
                        cursor="pointer"
                        fill={gravityPalette.button.progressive.default}
                        onClick={handleClose}
                        onKeyPress={(e) => e.key === 'Enter' && handleClose()}
                        role="button"
                        tabIndex={0}
                        aria-label={CLOSE_BUTTON_ALT_TEXT}
                      />
                    </Box>
                  </Box>
                  <Box textAlign={'left'} id="dialog-title">
                    <Typography
                      variant="medium3Light"
                      className={classes.tooltipTitle}
                      id="dialoglabel">
                      {ONBOARDING_LOCATION_TOOLTIP_LABEL}
                    </Typography>
                  </Box>
                  <Box textAlign={'left'} id="inactivity-text">
                    <Typography
                      variant="medium1Normal"
                      component="span"
                      id="dialogdescription">
                      {ONBOARDING_LOCATION_TOOLTIP_TEXT}
                    </Typography>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </Box>
          <StepFooter activeStep={++activeStep} totalSteps={totalSteps}>
            <Grid
              container
              item
              spacing={isMobile ? 2 : 4}
              className={classes.grid}
              alignItems={isMobile ? 'center' : 'flex-start'}
              direction={isMobile ? 'column' : 'row'}>
              <Grid container={isMobile} item aria-live="assertive">
                <ProgressButton
                  variant="medium2NormalButtonFlex"
                  isBusy={isBusy}
                  onClick={handleDispatchLocation}
                  disabled={isButtonDisabled}
                  text={NEXT_BUTTON_TEXT}
                />
              </Grid>
            </Grid>
          </StepFooter>
        </Transition>
        <Box className={open ? classes.overlay : null} />
      </StepContent>
    </Box>
  );
};

export default Location;
