import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilledButton from '../core/FilledButton';
import Typography from '../overrides/Typography';
import {
  TEXT_JOIN_OF,
  STEP_FOOTER_PREFIX_TEXT,
  NEXT_BUTTON_TEXT,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.blue,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xl')]: {
      bottom: 0,
      padding: theme.spacing(0, 0, 1, 6),
      position: 'absolute',
    },
  },
  stepper: {
    margin: theme.spacing(3, 0),
    textAlign: 'center',
    '@media (max-height: 550px)': {
      margin: theme.spacing(1, 0),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(2, 0),
      textAlign: 'left',
    },
  },
  button: {
    [theme.breakpoints.up('xl')]: {
      width: '135px!important',
    },
  },
}));

const StepFooter = ({
  activeStep,
  children = null,
  disabled = false,
  onClick,
  text = NEXT_BUTTON_TEXT,
  totalSteps,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <nav>
        {children == null ? (
          <FilledButton
            disabled={disabled}
            onClick={onClick}
            style={classes.button}
            text={text}
            variant="medium2NormalButtonFlex"
          />
        ) : (
          children
        )}
      </nav>
      <footer className={classes.stepper}>
        {activeStep && (
          <Typography color="secondary" variant="small1Normal">
            {STEP_FOOTER_PREFIX_TEXT + activeStep + TEXT_JOIN_OF + totalSteps}
          </Typography>
        )}
      </footer>
    </Box>
  );
};

export default StepFooter;
