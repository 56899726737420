import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import SignatureCollectionTextIcon from '../../core/SignatureCollection/SignatureCollectionTextIcon';
import {
  SIGNATURE_COLLECTION_RESTAURANT_TEXT,
  SIGNATURE_COLLECTION_RESTAURANT_TITLE,
} from '../../../assets/copy';
import Typography from '../../overrides/Typography';
import { BREAKPOINT_VALUES } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';

const useStyles = makeStyles((theme) => ({
  titleText: {
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1),
  },
  signatureCollectionTextContainer: {
    marginTop: theme.spacing(1),
  },
}));

export const SignatureCollectionDescription = ({
  classes: classesOverride,
}) => {
  const classes = useStyles({ classes: classesOverride });
  const { width } = useViewport();
  const isMobile = width <= BREAKPOINT_VALUES.xxl;
  return (
    <Box className={classes.root}>
      <SignatureCollectionTextIcon
        text={SIGNATURE_COLLECTION_RESTAURANT_TITLE}
        variant="small1Semibold"
        textStyleOverride={classes.titleText}
      />
      <Box className={classes.signatureCollectionTextContainer}>
        <Typography variant={isMobile ? 'small2Normal' : 'medium1Normal'}>
          {SIGNATURE_COLLECTION_RESTAURANT_TEXT}
        </Typography>
      </Box>
    </Box>
  );
};

SignatureCollectionDescription.propTypes = {
  classes: PropTypes.object,
};

export default SignatureCollectionDescription;
