import { put, takeLatest } from 'redux-saga/effects';
import { listAtmospheres } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './AtmospheresAction';
import * as TYPES from './AtmospheresTypes';

export function* atmosphereListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listAtmospheres,
      variables: { filter },
    });
    yield put(
      ACTIONS.actionAtmosphereListSuccess(result.data.listAtmospheres.items)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionAtmosphereListFail(e));
  }
}

export default function* atmospheresSaga() {
  yield takeLatest(TYPES.ATMOSPHERE_LIST_REQUEST, atmosphereListRequestHandler);
}
