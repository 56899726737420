export const onboardingBaseStyles = (theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  button: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 264,
    },
  },
  scrollable: {
    overflowY: 'scroll',
  },
  container: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('xl')]: {
      margin: 0,
    },
  },
  infoStyle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  contentWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    scrollBehavior: 'smooth',
    [theme.breakpoints.up('xl')]: {
      bottom: 0,
      left: 0,
      height: 'calc(100% - 180px)',
      position: 'absolute',
      top: 56,
    },
  },
  slideAnimation: {
    '&-enter': {
      opacity: 0,
      transform: 'translateX(100vw)',
      '&-active': {
        opacity: 1,
        transform: 'translateX(0vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    [theme.breakpoints.up('xl')]: {
      '&-enter': {
        transform: 'translateX(50vw)',
      },
    },
  },
  exploreButton: {
    ...theme.typography.medium1Semibold,
    color: theme.palette.interactionBlue[30],
    backgroundColor: 'transparent',
    height: 48,
    width: '100%',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      border: 'none',
      textDecoration: 'underline',
      color: theme.palette.interactionBlue[40],
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.gray[40],
    },
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  directionalButton: {
    alignContent: 'center',
    position: 'absolute',
    paddingLeft: theme.spacing(1.5),
    height: '24px',
    width: '16px',
    '&:hover': {
      cursor: 'pointer',
      border: 'none',
      color: theme.palette.interactionBlue[40],
    },
  },
});
