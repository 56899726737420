import { useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Grid } from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../components/overrides/Typography';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import EditSaveButton from '../../components/core/EditSaveButton';
import { actionSetAnimationSlideRight } from '../../store/Animations/AnimationsAction';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import {
  EDIT_PROFILE_DIETARY_PREFERENCES_TITLE,
  RESERVE_EDIT_SHARING_NOTICE,
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
} from '../../assets/copy';
import DietaryPreferences from '../../components/core/DietaryPreferences';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2),
    },
  },
  divider: {
    margin: theme.spacing(1, 0, 4, 0),
    width: `calc(100% - ${theme.spacing(2)})`,
  },
}));

const EditDietPref = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const restrictions = useSelector((state) => state.diets.list);
  const allergies = useSelector((state) => state.allergies.list);
  const { draftReservation } = useSelector((state) => state.reservations);
  const [selectDiets, setSelectDiets] = useState(draftReservation.diets);
  const [selectAllergies, setSelectAllergies] = useState(
    draftReservation.allergies
  );

  const checkUpdated = (userSelect, draft) => {
    if (userSelect?.length !== draft?.length) return true;

    const map = new Map();

    for (const element of draft) {
      map.set(element.id, true);
    }

    for (const element of userSelect) {
      if (map.get(element.id) == null) return true;
    }

    return false;
  };

  const isPrefUpdated =
    checkUpdated(selectAllergies, draftReservation.allergies) ||
    checkUpdated(selectDiets, draftReservation.diets);

  const handleBack = () => {
    dispatch(actionSetAnimationSlideRight(true));
    history.goBack();
  };

  const handleSave = () => {
    dispatch(actionSetAnimationSlideRight(true));
    const updateDraft = draftReservation;
    updateDraft.diets = selectDiets;
    updateDraft.allergies = selectAllergies;
    dispatch(actionSetDraftReservation(updateDraft));
    history.goBack();
  };

  return (
    <BackButtonLayout
      back={handleBack}
      buttonRole="link"
      title={EDIT_PROFILE_DIETARY_PREFERENCES_TITLE}>
      <Grid item className={classes.container}>
        <Typography variant="medium1Normal">
          {RESERVE_EDIT_SHARING_NOTICE}
        </Typography>
      </Grid>
      <Grid item className={classes.container}>
        <DietaryPreferences
          text={PROFILE_DIETARY_PREFERENCES_DIETS_LABEL}
          itemMap={restrictions}
          list={selectDiets}
          setRestrictions={setSelectDiets}
        />
        <Divider className={classes.divider} />
        <DietaryPreferences
          text={PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL}
          itemMap={allergies}
          list={selectAllergies}
          setRestrictions={setSelectAllergies}
        />
        <Box className={classes.container}>
          <EditSaveButton
            onSave={handleSave}
            role="link"
            disabled={!isPrefUpdated}
          />
        </Box>
      </Grid>
    </BackButtonLayout>
  );
};

export default EditDietPref;
