//RESERVATION list
export const RESERVATION_LIST_BY_USERID_REQUEST =
  'RESERVATION_LIST_BY_USERID_REQUEST';
export const RESERVATION_LIST_BY_USERID_SUCCESS =
  'RESERVATION_LIST_BY_USERID_SUCCESS';
export const RESERVATION_LIST_BY_USERID_FAIL =
  'RESERVATION_LIST_BY_USERID_FAIL';

//set RESERVATION
export const SET_RESERVATION = 'SET_RESERVATION';
export const SET_RESERVATION_SUCCESS = 'SET_RESERVATION_SUCCESS';
export const SET_RESERVATION_FAIL = 'SET_RESERVATION_FAIL';

//get RESERVATION
export const GET_RESERVATION_REQUEST = 'GET_RESERVATION_REQUEST';
export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS';
export const GET_RESERVATION_FAIL = 'GET_RESERVATION_FAIL';

//create RESERVATION
export const CREATE_RESERVATION_REQUEST = 'CREATE_RESERVATION_REQUEST';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_FAIL = 'CREATE_RESERVATION_FAIL';

//update RESERVATION
export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_FAIL = 'UPDATE_RESERVATION_FAIL';

//CANCEL RESERVATION
export const CANCEL_RESERVATION_REQUEST = 'CANCEL_RESERVATION_REQUEST';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_FAIL = 'CANCEL_RESERVATION_FAIL';

// DRAFT RESERVATION
export const SET_DRAFT_RESERVATION = 'SET_DRAFT_RESERVATION';
export const UPDATE_DRAFT_RESERVATION = 'UPDATE_DRAFT_RESERVATION';
export const SET_PAYMENT_ACCOUNT = 'SET_PAYMENT_ACCOUNT';
export const UPDATE_DEFAULT_PAYMENT_SUCCESS = 'UPDATE_DEFAULT_PAYMENT_SUCCESS';

// OPEN/CLOSE SHEET
export const OPEN_RESERVATION_SHEET = 'OPEN_RESERVATION_SHEET';
export const CLOSE_RESERVATION_SHEET = 'CLOSE_RESERVATION_SHEET';

// OPEN/CLOSE BOTTOM SHEET / MODAL
export const OPEN_RESERVATION_MODAL = 'OPEN_RESERVATION_MODAL';
export const CLOSE_RESERVATION_MODAL = 'CLOSE_RESERVATION_MODAL';

export const SET_EDIT_RESERVATION_INFO_BLOCK =
  'SET_EDIT_RESERVATION_INFO_BLOCK';

// STATUS
export const CLEAR_RESERVATION_CREATE_STATUS =
  'CLEAR_RESERVATION_CREATE_STATUS';
export const CLEAR_RESERVATION_UPDATE_STATUS =
  'CLEAR_RESERVATION_UPDATE_STATUS';
export const CLEAR_RESERVATION_CANCEL_STATUS =
  'CLEAR_RESERVATION_CANCEL_STATUS';
export const CLEAR_RESERVATION_LIST_STATUS = 'CLEAR_RESERVATION_LIST_STATUS';
export const CLEAR_RESERVATION_GET_STATUS = 'CLEAR_RESERVATION_GET_STATUS';

export const CLEAR_ALL_RESERVATIONS = 'CLEAR_ALL_RESERVATIONS';

export const SET_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP =
  'SET_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP';
export const CLEAR_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP =
  'CLEAR_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP';
