import { useMemo, useState } from 'react';

export const DIET_PREFERENCE_TYPE = Object.freeze({
  RESTRICTION: 'RESTRICTION',
  ALLERGY: 'ALLERGY',
  ADDITIONAL_NOTES: 'ADDITIONAL_NOTES',
});

export const useDietPreferences = ({ type, user }) => {
  const storedPreferences = useMemo(() => {
    if (type === DIET_PREFERENCE_TYPE.ADDITIONAL_NOTES) {
      return user?.notes ?? '';
    }

    if (type === DIET_PREFERENCE_TYPE.ALLERGY) {
      return user?.allergies?.items?.map((item) => item.allergy) ?? [];
    }

    return user?.diets?.items?.map((item) => item.diet) ?? [];
  }, [type, user]);
  const [currentPreferences, setCurrentPreferences] =
    useState(storedPreferences);

  return {
    storedPreferences,
    currentPreferences,
    setCurrentPreferences,
  };
};
