export const focusVisible = (
  properties = {
    outlineStyle: 'solid',
    outlineWidth: '2px',
    outlineColor: '-webkit-focus-ring-color',
  }
) => ({
  '&:focus-visible': {
    ...properties,
  },
});
