import { Box, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import {
  CLOSE_BUTTON_DESCRIPTION,
  CLOSE_BUTTON_LABEL,
} from '../../assets/copy';
import { useViewport } from '../../utils/useViewport';
import CloseButton from './CloseButton';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  close: {
    float: 'right',
    padding: theme.spacing(0, 3, 0, 0),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5),
    },
  },
  closeButtonContainer: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '83.333333%',
      overflow: 'auto',
      width: '100%',
    },
  },
}));

const ErrorCloseButton = ({ onCloseUrl }) => {
  const classes = useStyles();
  const history = useHistory();
  const { width } = useViewport();
  const widthBreakpoint = 960;
  const closeButtonDimension = width > widthBreakpoint ? 21 : 16;
  const closeButtonContainerMb = width > widthBreakpoint ? 3 : 2;

  const onClose = () => history.replace(onCloseUrl);

  return (
    <Grid className={classes.container} item xs={10} xl={12}>
      <Box
        className={classes.closeButtonContainer}
        mb={closeButtonContainerMb}
        display="flex"
        justifyContent="end">
        <CloseButton
          className={classes.close}
          close={onClose}
          description={CLOSE_BUTTON_DESCRIPTION}
          fill="#0D74AF"
          height={closeButtonDimension}
          label={CLOSE_BUTTON_LABEL}
          role="link"
          width={closeButtonDimension}
        />
      </Box>
    </Grid>
  );
};

export default ErrorCloseButton;
