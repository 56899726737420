import React from 'react';
import ChefItemVertical from './ChefItemVertical';
import Carousel from '../core/Carousel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chefList: {
    display: 'flex',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* hide scrollbar on IE and Edge */,
    scrollbarWidth: 'none' /* hide scrollbar on Firefox */,
    scrollBehavior: 'smooth',
    padding: ({ shadowPadding }) =>
      shadowPadding ? `10px ${shadowPadding}px` : 2,
    margin: ({ shadowPadding }) => shadowPadding && `0 -${shadowPadding}px`,
    columnGap: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      columnGap: `${theme.spacing(3.75)} !important`,
    },
    position: 'relative',
  },
}));
const ChefListCarousel = ({ venues, showGetTicketLink = false }) => {
  const classes = useStyles();
  return (
    <Carousel galleryClass={classes.chefList}>
      {venues &&
        venues.length > 0 &&
        venues.map((venue, index) => {
          return (
            <ChefItemVertical
              large={false}
              venue={venue.venue}
              key={`venue-${index}`}
              showGetTicketLink={showGetTicketLink}
            />
          );
        })}
    </Carousel>
  );
};

export default ChefListCarousel;
