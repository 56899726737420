import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import OnboardingInfoBg from '../../assets/images/onboardingInfoBg.png';
import OnboardingInfoBgLarge from '../../assets/images/onboardingInfoBgLarge.png';
import StepBackground from '../../components/onboarding/StepBackground';
import StepContent from '../../components/onboarding/StepContent';
import StepFooter from '../../components/onboarding/StepFooter';
import StepHeader from '../../components/onboarding/StepHeader';
import Typography from '../../components/overrides/Typography';
import Transition from '../../components/animation/Transition';
import {
  ONBOARDING_CONFIRM_INFO_TITLE,
  ONBOARDING_CONFIRM_INFO_DESCRIPTION,
  NEXT_BUTTON_TEXT,
} from '../../assets/copy';
import { onboardingBaseStyles } from './OnboardingStyles';
import { formatPhone } from '../../utils/formatPhone';

const useStyles = makeStyles((theme) => ({
  ...onboardingBaseStyles(theme),
  bgImgAfterEnter: {
    background: `url(${OnboardingInfoBg}) no-repeat`,
    zIndex: -2,
    [theme.breakpoints.up('xl')]: {
      background: `url(${OnboardingInfoBgLarge}) no-repeat`,
    },
  },
  bgImgBeforeEnter: {
    background: `linear-gradient(to bottom, rgba(0,0,0,0), ${theme.palette.background.blue} 95%), url(${OnboardingInfoBg}) no-repeat`,
    zIndex: -1,
    [theme.breakpoints.up('xl')]: {
      background: `linear-gradient(to left, rgba(0,0,0,0), rgb(16, 37, 63, 0.7) 40%, rgb(16, 37, 63, 1) 90%), url(${OnboardingInfoBgLarge}) no-repeat`,
    },
  },
  bgImg: {
    backgroundSize: 'cover',
    [theme.breakpoints.up('xl')]: {
      backgroundSize: 'cover',
    },
  },
  wrapper: {
    [theme.breakpoints.up('xl')]: {
      height: 'calc(100vh - 180px)',
      left: 0,
      padding: theme.spacing(0, 4, 0, 6),
      position: 'absolute',
      top: 56,
    },
  },
  subTitle: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
  },
  nameEmail: {
    marginBottom: theme.spacing(1),
  },
  phone: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
    '@media (max-height: 550px)': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
  },
  input: {
    maxWidth: 340,
  },
  error: {
    marginTop: theme.spacing(1),
  },
  description: {
    margin: theme.spacing(1, 0, 5, 0),
    color: theme.palette.digitalGray[10],
  },
  slideAnimation: {
    '&-enter': {
      opacity: 0,
      transform: 'translateX(100vw)',
      '&-active': {
        opacity: 1,
        transform: 'translateX(0vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    '&-exit': {
      '&-active': {
        opacity: 0,
        transform: 'translateX(-100vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    [theme.breakpoints.up('xl')]: {
      '&-enter': {
        transform: 'translateX(50vw)',
      },
      '&-exit': {
        '&-active': {
          transform: 'translateX(-50vw)',
        },
      },
    },
  },
  slowSlideOutAnimation: {
    '&-enter': {
      opacity: 0,
      transform: 'translateX(50vw)',
      '&-active': {
        opacity: 1,
        transform: 'translateX(0vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    '&-exit': {
      '&-active': {
        opacity: 0,
        transform: 'translateX(-50vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.77, 0.74)',
      },
    },
  },
}));

const ConfirmInfo = ({ handleNext, active, totalSteps, activeStep }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [hasEntered, setHasEntered] = useState(false);

  const handleOnEntered = () => setHasEntered(true);

  return (
    <Box className={classes.root}>
      <StepBackground
        active={active}
        backgroundStyles={clsx(
          classes.bgImg,
          hasEntered ? classes.bgImgAfterEnter : classes.bgImgBeforeEnter
        )}
        onEntered={handleOnEntered}
        slideAnimation={classes.slowSlideOutAnimation}
      />
      <StepContent>
        <Transition
          delay={1400}
          classNames={classes.slideAnimation}
          active={active}>
          <Box className={clsx(classes.contentWrapper, classes.wrapper)}>
            <StepHeader title={ONBOARDING_CONFIRM_INFO_TITLE}>
              <Typography
                color="secondary"
                variant="medium3LightFlex"
                className={classes.nameEmail}
                redact={true}>
                {`${user.firstName} ${user.lastName}`}
              </Typography>
              <Typography
                color="secondary"
                variant="medium1NormalFlex"
                className={classes.nameEmail}
                redact={true}>
                {user.emailAddress}
              </Typography>
              <Typography
                color="secondary"
                variant="medium1NormalFlex"
                className={classes.phone}
                redact={true}>
                {formatPhone(user.phoneNumber)}
              </Typography>
            </StepHeader>
            <Typography variant="small3Normal" className={classes.description}>
              {ONBOARDING_CONFIRM_INFO_DESCRIPTION}
            </Typography>
          </Box>
          <StepFooter
            activeStep={++activeStep}
            onClick={handleNext}
            text={NEXT_BUTTON_TEXT}
            totalSteps={totalSteps}
          />
        </Transition>
      </StepContent>
    </Box>
  );
};

export default ConfirmInfo;
