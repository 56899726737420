import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DATE_FORMAT_YEAR_FIRST } from '../assets/dateFormats';
import {
  actionGetAvailabilityRequest,
  actionSetFabAvailabilityDate,
  actionSetFabAvailabilityTime,
  actionSetFabAvailabilityGuests,
  actionSetFabDisplayValues,
} from '../store/Availability/AvailabilityAction';
import { generateAvailabilityInput } from './generateAvailabilityInput';

const useFabMethods = () => {
  const dispatch = useDispatch();
  const {
    selectedDate,
    selectedTime,
    selectedGuests,
    submittedDate,
    submittedTime,
    submittedGuests,
  } = useSelector((state) => state?.availability);

  const handleCloseFab = () => {
    if (
      moment(selectedDate).format(DATE_FORMAT_YEAR_FIRST) !==
      moment(submittedDate).format(DATE_FORMAT_YEAR_FIRST)
    ) {
      dispatch(actionSetFabAvailabilityDate({ selectedDate: submittedDate }));
    }

    if (selectedTime !== submittedTime) {
      dispatch(actionSetFabAvailabilityTime({ selectedTime: submittedTime }));
    }

    if (selectedGuests !== submittedGuests) {
      dispatch(
        actionSetFabAvailabilityGuests({ selectedGuests: submittedGuests })
      );
    }
  };

  const callAvailability = ({
    venue,
    date = submittedDate,
    time = submittedTime,
    guests = submittedGuests,
    halo = true,
  }) => {
    const input = generateAvailabilityInput(venue, date, time, guests, halo);
    dispatch(actionGetAvailabilityRequest(input));
  };

  const setFabDisplayValues = ({
    date = submittedDate,
    time = submittedTime,
    guests = submittedGuests,
  }) => {
    dispatch(actionSetFabAvailabilityDate({ selectedDate: date }));
    dispatch(actionSetFabAvailabilityTime({ selectedTime: time }));
    dispatch(actionSetFabAvailabilityGuests({ selectedGuests: guests }));
    dispatch(
      actionSetFabDisplayValues({
        submittedDate: date,
        submittedTime: time,
        submittedGuests: guests,
      })
    );
  };

  return {
    handleCloseFab,
    callAvailability,
    setFabDisplayValues,
  };
};

export default useFabMethods;
