import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actionUpdateUserRequest } from '../../../store/User/UserAction';

export const useSaveUserCallback = (user) => {
  const dispatch = useDispatch();

  const saveUser = useCallback(
    (newUserValues) => {
      dispatch(
        actionUpdateUserRequest({
          ...newUserValues,
          id: user.id,
        })
      );
    },
    [user.id, dispatch]
  );

  return saveUser;
};
