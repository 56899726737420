import React from 'react';
import MaskedInput from 'react-text-mask';

const CPhoneInput = (props) => {
  const { ref, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref}
      guide={false}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

export default CPhoneInput;
