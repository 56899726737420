import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  actionGetMarketRequest,
  actionGetMarketSuccess,
  actionMarketListRequest,
} from '../../store/Markets/MarketsAction';
import { actionCuisineListRequest } from '../../store/Cuisines/CuisinesAction';
import { actionAtmosphereListRequest } from '../../store/Atmospheres/AtmospheresAction';
import { actionCategoryListRequest } from '../../store/Categories/CategoriesAction';
import { actionAccoladeListRequest } from '../../store/Accolades/AccoladesAction';
import { actionSetSearchOverlayVisible } from '../../store/Search/SearchAction';
import { actionPremierLocationListRequest } from '../../store/PremierLocation/PremierLocationsAction';
import { actionDietListRequest } from '../../store/Diets/DietsAction';
import { actionAllergyListRequest } from '../../store/Allergies/AllergiesAction';
import { actionSpecialOccasionsListRequest } from '../../store/AppData/AppDataAction';
import { actionGetConfigRequest } from '../../store/Config/ConfigAction';
import { useCanaryRedirect } from '../../utils/useCanaryRedirect';
import { getPermalinkSlug, setPermalinkLogin, setPermalinkRouteRedirect } from '../../utils/sessionStorageHelper';
import { actionGetVenueBySlugRequest } from '../../store/Venues/VenuesAction';
import { useLocation } from 'react-router';

function AppWrapper({ children }) {
  const { user } = useSelector((state) => state.user);
  const { market } = useSelector((state) => state.markets);
  const { stepUp } = useSelector((state) => state.reservations);
  const config = useSelector((state) => state.config);
  const location = useLocation();
  const dispatch = useDispatch();

  useCanaryRedirect();

  useEffect(() => {
    if (location.pathname?.startsWith('/permalink')) {
      setPermalinkLogin(location.pathname);
    } else if (!user?.marketId && location.pathname !== '/') {
      setPermalinkRouteRedirect(location.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stepUp?.shouldRedirect) return;
    if (market?.id == null && user?.marketId) {
      dispatch(actionGetMarketRequest({ id: user.marketId }));
    }
  }, [user?.marketId, market?.id, stepUp?.shouldRedirect, dispatch]);

  useEffect(() => {
    dispatch(actionSetSearchOverlayVisible(false));
  }, [dispatch]);

  useEffect(() => {
    if (!config.isLoaded) {
      dispatch(actionGetConfigRequest());
    }

    if (stepUp?.shouldRedirect) return;

    if (!user.session.isAuthenticated) {
      dispatch(actionGetMarketSuccess(null));

      // User needs to be authenticated before dispatching subsequent Redux actions
      return;
    }
    dispatch(actionSpecialOccasionsListRequest());
    dispatch(actionMarketListRequest());
    dispatch(actionCuisineListRequest());
    dispatch(actionCategoryListRequest());
    dispatch(actionAccoladeListRequest());
    dispatch(actionAtmosphereListRequest());
    dispatch(actionPremierLocationListRequest());
    dispatch(actionDietListRequest());
    dispatch(actionAllergyListRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.session.isAuthenticated]);

  useEffect(() => {
    if (user.session.isAuthenticated && getPermalinkSlug()) {
      dispatch(actionGetVenueBySlugRequest(getPermalinkSlug()));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.session.isAuthenticated, getPermalinkSlug()]);

  return <>{children}</>;
}

export default AppWrapper;
