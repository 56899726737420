import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
//Components & Assets
import Typography from '../../overrides/Typography';
import InfoSVG from '../../../assets/icons/info-filled.svg';
import CheckedSVG from '../../../assets/icons/checked-filled.svg';
import WarnSVG from '../../../assets/icons/warning.svg';
import CrossSVG from '../../../assets/icons/closs-filled.svg';
//Utils
import { ALERT_VARIANTS } from '../../../utils/constants/AlertVariants';
import theme from '../../../theme';

const mapStyleToVariant = {
  [ALERT_VARIANTS.INFO]: {
    bgColor: '#E1F5FF',
    borderLeftColor: '#0276B1',
    icon: InfoSVG,
  },
  [ALERT_VARIANTS.WARN]: {
    bgColor: '#FFF1D0',
    borderLeftColor: '#F9C606',
    icon: WarnSVG,
  },
  [ALERT_VARIANTS.SUCCESS]: {
    bgColor: '#D9FAD1',
    borderLeftColor: '#25810E',
    icon: CheckedSVG,
  },
  [ALERT_VARIANTS.ERROR]: {
    bgColor: '#FFE5E6',
    borderLeftColor: '#CC2427',
    icon: CrossSVG,
  },
};

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    borderLeft: '4px solid',
    backgroundColor: ({ variant }) => mapStyleToVariant?.[variant]?.bgColor,
    borderLeftColor: ({ variant }) =>
      mapStyleToVariant?.[variant]?.borderLeftColor,
  },
  child: {
    marginLeft: theme.spacing(2),
  },
});

const Alert = ({ variant = ALERT_VARIANTS.INFO, title = '', children }) => {
  const classes = useStyles({ variant });
  return (
    <Box className={classes.container}>
      <img
        aria-hidden="true"
        alt={variant}
        src={mapStyleToVariant?.[variant]?.icon}></img>
      <Box className={classes.child}>{children}</Box>
      {title && <Typography className={classes.child}>{title}</Typography>}
    </Box>
  );
};

Alert.propTypes = {
  variant: PropTypes.oneOf([
    ALERT_VARIANTS.INFO,
    ALERT_VARIANTS.ERROR,
    ALERT_VARIANTS.WARN,
    ALERT_VARIANTS.SUCCESS,
  ]),
  title: PropTypes.string,
  children: PropTypes.node,
};
export default Alert;
