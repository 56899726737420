import React from 'react';
import { useSwiper } from 'swiper/react';
import clsx from 'clsx';
import { ButtonBase, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  UiChevronLeftLined,
  UiChevronRightLined,
} from '@cof/omni-gravity-icons-react';
import palette from '../../../theme/palette';
import {
  trackClick,
  trackClickWithDescription,
} from '../../../utils/useOneTag';
import {
  swipeSnapGridPrev,
  swipeSnapGridNext,
  snapGrid,
} from '../../../utils/swiperHelper';
import { VIEW_NEXT_TEXT, VIEW_PREVIOUS_TEXT } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  paginationDot: {
    '&:hover': {
      cursor: 'pointer',
    },
    borderRadius: '50%',
    width: '6.5px',
    height: '6.5px',
    backgroundColor: theme.palette.background['white'],
    borderStyle: 'solid',
    borderColor: theme.palette.gray[50],
    borderWidth: '1px',
    marginTop: theme.spacing(1),
  },
  paginationDotActive: {
    backgroundColor: theme.palette.secondary[100],
    borderColor: theme.palette.secondary[100],
  },
}));

export const Pagination = ({
  length,
  activeIndex,
  paginationTracker = {},
  isSnapGridEnabled = false,
}) => {
  const classes = useStyles();
  const swiper = useSwiper();
  const handlePrev = (e) => {
    trackClick(e, paginationTracker?.paginationPreviousButtonName, 'Button');
    if (isSnapGridEnabled) {
      swipeSnapGridPrev(swiper);
    }
    swiper.slidePrev();
  };

  const handleNext = (e) => {
    trackClick(e, paginationTracker?.paginationNextButtonName, 'Button');
    if (isSnapGridEnabled) {
      swipeSnapGridNext(swiper);
    }
    swiper.slideNext();
  };

  const handleIndexButton = (index) => {
    trackClickWithDescription(
      paginationTracker?.paginationIndexName,
      'Button',
      index
    );
    if (isSnapGridEnabled) {
      snapGrid(swiper);
    }
    swiper.slideTo(index);
  };

  return (
    <div className={classes.paginationContainer} id="pagination-container">
      <ButtonBase
        tabIndex="0"
        focusRipple={true}
        aria-label={VIEW_PREVIOUS_TEXT}
        onClick={handlePrev}
        disabled={activeIndex === 0}
        aria-disabled={activeIndex === 0}>
        <UiChevronLeftLined
          color={
            activeIndex === 0
              ? palette.gray[40]
              : palette.interactionBlue['main']
          }
        />
      </ButtonBase>
      {[...Array(length)].map((_, index) => (
        <div
          aria-hidden="true"
          key={`slideContent-${index}`}
          className={clsx(
            classes.paginationDot,
            index === activeIndex && classes.paginationDotActive
          )}
          onClick={() => handleIndexButton(index)}
        />
      ))}
      <ButtonBase
        tabIndex="0"
        focusRipple={true}
        aria-label={VIEW_NEXT_TEXT}
        onClick={handleNext}
        disabled={activeIndex === length - 1}
        aria-disabled={activeIndex === length - 1}>
        <UiChevronRightLined
          color={
            activeIndex === length - 1
              ? palette.gray[40]
              : palette.interactionBlue['main']
          }
        />
      </ButtonBase>
    </div>
  );
};

Pagination.propTypes = {
  length: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};
