import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  SEARCH_PAGINATION_HEADING,
  SEARCH_PAGINATION_NAV_LABEL,
} from '../../../../assets/copy';
import ScreenReaderText from '../../../core/ScreenReaderText';

const useStyles = makeStyles((theme) => ({
  paginationList: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    listStyle: 'none',
    margin: theme.spacing(0, 0, 2),
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
  },
}));

const SearchResultsPaginationContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <nav role="navigation" aria-label={SEARCH_PAGINATION_NAV_LABEL}>
      <ScreenReaderText component="h2">
        {SEARCH_PAGINATION_HEADING}
      </ScreenReaderText>
      <ol className={classes.paginationList}>{children}</ol>
    </nav>
  );
};

export default SearchResultsPaginationContainer;
