import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from './useAuth';
import { useMarkets } from './useMarkets';
import { actionGetHomePageRequest } from '../store/HomePage/HomePageAction';
import {
  clearPermalinkRouteRedirect,
  getPermalinkRouteRedirect,
} from './sessionStorageHelper';
import { getPermalinkRouteRedirectLocation } from '../pages/onboarding/onboardingHelpers';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { actionNeighborhoodListRequest } from '../store/Neighborhoods/NeighborhoodsAction';

export const useHomepageData = () => {
  const { stepUp } = useSelector((state) => state.reservations);
  const history = useHistory();
  const {
    user,
    signinWithAuthCode,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();
  const dispatch = useDispatch();
  const { markets } = useMarkets();
  const marketId = markets?.market?.id;

  useEffect(() => {
    if (stepUp?.shouldRedirect) return;
    if (assertion != null && !user.session.isAuthenticated) {
      signinWithJwt(assertion);
    } else {
      signinWithAuthCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stepUp?.shouldRedirect) return;
    if (!user.session.isAuthenticated) return;

    dispatch(actionNeighborhoodListRequest());

    if (user?.marketId && getPermalinkRouteRedirect()) {
      const path = getPermalinkRouteRedirectLocation();
      clearPermalinkRouteRedirect();
      history.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepUp?.shouldRedirect, user.session.isAuthenticated, dispatch]);

  useEffect(() => {
    if (marketId)
      dispatch(actionGetHomePageRequest({ marketId, forceCall: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketId]);

  const {
    homePage: data,
    getHomePageStatus: { isLoading, error: loadingError },
  } = useSelector((state) => state.homePage);

  useEffect(() => {
    const error = loadingError || data?.errors;
    if (!isEmpty(error))
      throw new Error(`error fetching homepage data: ${JSON.stringify(error)}`);
  }, [loadingError, data?.errors]);

  return {
    data,
    isLoading,
    error: loadingError,
  };
};
