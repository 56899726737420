export const config = {
  maintenanceWindowFlag: false,
  FEATURE_FLAG_BANNER_EXPLORE_CTA_BUTTON: true,
  PREMIER_LOCATION_CAROUSEL_DESCRIPTION:
    'Upcoming special events with our partners like the MICHELIN Guide, the James Beard Foundation and more.',
  PREMIER_LOCATION_CAROUSEL_ACTION_PATH: '/premier-location',
  PREMIER_LOCATION_CAROUSEL_ACTION_TEXT: 'Learn More',
  SPECIAL_EVENT_BANNER_FLAG: 'false',
  SPECIAL_EVENT_PATH: '/venue/d66ccea2-0de3-410e-82fb-ee13d30517d0',
  videoUrl:
    'https://d2p9w4ui8rp50l.cloudfront.net/m/17626fb60e5a0c40/mp4-COF-Dining-4x5-FB-Video.mp4',
  venueSortDirection: 'desc',
  permaLinks: {
    'the-charles-restaurant': '/venue/db2c5d86-44a8-4335-8489-3f2b8f49c03c',
    bruto: '/venue/882121ba-f0c2-4325-a497-498544f688a0',
    'bazaar-meat-by-jose-andres': '/venue/7904cfa3-5c7e-4a97-ab1d-16ce78184b7f',
    gemma: '/venue/9c431429-a417-4b29-897b-2f60c9bc96ca',
    'the-bazaar-by-jose-andres': '/venue/778bd671-1ba2-407b-9034-a266355b5a8e',
    'wineaccess-michelin-q1dinner':
      '/venue/1d05e17c-70e7-4be6-b685-45a542161823',
    'jbf-att-la': '/venue/a7045e90-ff71-4ded-892d-0729d192e368',
    'sobewff-dinner1-feb22': '/venue/f610ab74-4913-4259-b9f8-4ab15eded804',
    'bar-volpe': '/venue/6f6a8bc6-d26c-4f84-913e-af23b25a20cd',
    'palo-santo': '/venue/a75afc8a-5b21-410d-bffe-48f4bfa500c7',
    'silver-apricot': '/venue/77db65f0-0b3f-4a7f-aa5e-b6ccf09f03f9',
    'miamiartweek-dominiquecrenn-dinner':
      '/venue/b5fa57ba-bc94-4565-acea-c97a198f01cb',
    'the-wolfs-tailor': '/venue/f42fa0fd-3fe7-43cc-8c1f-8ae5e922e247',
    'sobewff-dinner2-feb23': '/venue/e2178a1b-5665-4d76-af79-05504e6a09b0',
    'the-duck-and-the-peach': '/venue/f0ae3cec-144a-419e-8bfc-710706f6f3ff',
    'miamiartweek-alexisrael': '/venue/6f52dad0-6f45-4d60-8370-07b928f34a15',
    cotogna: '/venue/8ab7695c-bb4b-491c-8490-a6c58322855f',
    'minibar-by-jose-andres': '/venue/f09b8fea-bc70-40c1-a667-1e371722b949',
    'alla-vita': '/venue/dc68a79d-69f9-4530-b1b8-bd1b85f32dba',
    'little-fish': '/venue/a38d5ae8-936e-4e32-913d-062558f9717d',
    xochi: '/venue/2a9daf11-5d98-4bb6-ae66-b69bebdb4d8d',
    oleana: '/venue/174518cf-20f2-4574-890b-7f46b9bbd6a5',
    'sushi-by-scratch-restaurants-seattle':
      '/venue/089d6123-60de-479f-8457-f0a3183725f0',
    'miamiartweek-sunsetsocial': '/venue/486a785b-f74f-4cca-b7e1-403639e03128',
    'atelier-crenn': '/venue/8d1ecdfe-c4a5-4724-8f04-5440cd09d498',
    allelo: '/venue/886af81c-8558-4c82-92e9-dfe8a4a56413',
    gekko: '/venue/e9179b0a-8320-40fa-973d-f45ebd70f155',
    'michelin-guide-ceremony-ny-chi-dc':
      '/venue/e3411b12-f390-4d20-a122-23b642cda984',
    bludorn: '/venue/4c84a8d7-5ef9-4a49-9e5d-ac7b96135d7d',
    hathorne: '/venue/732808d3-3160-4837-8ebd-887970afc43f',
    'amara-at-paraiso': '/venue/38c5440d-c62c-4e9e-a09f-444422d68b3a',
    canlis: '/venue/e10ec1b0-6ed0-4d6b-9b88-442661c6b7e7',
    'miamiartweek-joseandres-dinner':
      '/venue/453d0eb3-77b3-40f8-adfa-01254045bf5e',
    'sobewff-dinner3-feb24': '/venue/8ee6af51-f9d3-436e-b449-01807468dffd',
    'butcher-bee': '/venue/72c7d853-38a5-42e7-8a7e-e28ed6aa8c2b',
    'platform-by-jbf': '/venue/b7277faa-a7c4-45ee-98be-7399fe334dec',
    'pasta-bar-los-angeles': '/venue/f44cfaeb-891e-4b93-b017-b940e8008bac',
    'e-by-jose-andres': '/venue/cac69df8-c04e-4372-89c7-7c9a6939c933',
    'china-poblano': '/venue/201cdcbd-0cbb-4d6d-a90c-3a21d639c7a7',
    zakia: '/venue/16f8a67c-f9af-44d1-8efb-783fb8fec8be',
    'mish-mish': '/venue/a304a09d-ef3c-42a9-a0e7-d067e2b11aae',
    'meat-market-tampa': '/venue/a364c19f-65af-4319-884a-79646b92ad96',
    'michelin-ontheroad-boulder': '/venue/0ccad300-f7cd-40c3-9a66-a21972b14b9d',
    'michelin-ontheroad-dallas': '/venue/1d03506c-5268-480e-981d-cdbdfdb96fe0',
    'michelin-ontheroad-seattle': '/venue/c3cda5c9-5b10-4bc5-abe0-a44b0804841a',
    'michelin-ontheroad-nashville':
      '/venue/3b7cadd4-db6e-4da0-84ae-a488dc663c79',
    'lazy-betty': '/venue/e5b49238-f1b4-4074-976f-870ff8d3ca6f',
    'michaels-genuine-food-drink':
      '/venue/6a533a5d-b0e3-495f-aa44-c469e9eff8ed',
    boka: '/venue/9e37cc0d-b295-4ec9-a7ed-c52562445f6c',
    'mi-tocaya': '/venue/72759f6b-2134-478e-820a-7dc0a0f53a19',
    'mister-oso-rino': '/venue/bc21587d-c10a-479a-aa50-238d2940df3a',
    haisous: '/venue/a0b59b7c-22c2-4731-a807-941cb2ab0839',
    minibar: '/venue/f09b8fea-bc70-40c1-a667-1e371722b949',
    rossoblu: '/venue/c8752319-4f6a-4985-802f-9133d0d545fa',
    ariete: '/venue/ca4bd35a-e95b-469b-a09e-0b2ea4c70396',
    'zitz-sum': '/venue/36229e09-dff5-453c-a6f0-34787b0bac3c',
    8282: '/venue/53080e00-67fc-4724-a510-04e70f33559b',
    'the-modern': '/venue/8ab57eb4-bc94-40ce-ac5e-83ea2c5a5683',
    'rooster-the-till': '/venue/81621613-ab0c-42f1-b8b7-4d3bd6df26aa',
    'lapis-bistro': '/venue/cd130324-5398-449e-915f-e126522aec2a',
    causa: '/venue/110808cd-69c3-43de-a7be-df57e8277151',
    cobis: '/venue/63ea89bf-afe2-4db9-a41f-c72eb992affb',
    'wineaccess-michelin-q2dinner':
      '/venue/e731588e-2e44-4e44-9d22-631d99072bca',
    'michelin-guide-ceremony-2024florida':
      '/venue/1508699d-7605-42fd-9ee6-26165d2918b6',
    'the-dabney': '/venue/cf7d1e4f-2d59-4acb-91e9-fb66853be6b9',
    'ai-fiori': '/venue/6bc46114-c6c4-4b9e-8407-ef8bbae26150',
    sappe: '/venue/6e9cbc59-37f1-480f-9b16-4dc95d06fdd2',
    anju: '/venue/6535cb77-b16b-467c-9e75-4c8752573d2d',
    'bazaar-dc': '/venue/4bbaf0a0-f6ae-4ab2-bc1a-5c0bfeaf2484',
    'villa-yara': '/venue/c94ccc01-7271-426a-8b45-591723808450',
    'uchi-la': '/venue/b8f97653-ae49-432a-aa30-a55876b828c1',
    'anajak-thai': '/venue/79d81a2f-c5f5-4fc3-9a3e-ad3a41990308',
    'uchi-miami': '/venue/72264a8b-0ab7-4171-a1bc-6aa8c93116f6',
    macchialina: '/venue/f0945c9c-7236-4614-9585-7d0d690f3297',
    momotaro: '/venue/648fdc61-9886-445b-8277-68d0336fb83b',
    locust: '/venue/8d17cd29-cb88-43d7-b405-be7b120246d9',
    bastion: '/venue/7628a8fb-04e9-483c-8af6-c8a38f086c0b',
    'the-catbird-seat': '/venue/cc0e52d7-b9af-4d58-a473-ebac8cf9df96',
    'osteria-la-buca': '/venue/baa512af-81ce-484f-801e-67b5a80dd7d6',
    'michelin-guide-star-crawl-dc':
      '/venue/048e5435-69a6-4017-b457-e36d742f3177',
    'DCA-Capital-One-Landing': '/venue/ed31bd62-2708-434f-b6e8-34c0bb25d4e4',
    '2024-NYFW-Dinner': '/venue/c69d4fd4-6579-4302-a3f7-368d1da36cf3',
    '2024michelin-guide-ceremony-ca':
      '/venue/8ae3a5f3-cb4c-4db5-ad0d-89c76a58a0c2',
    saison: '/venue/09ffe61a-130d-4109-a30f-889ef4df3688',
    'knife-steakhouse': '/venue/6b018fbb-ad61-40fb-a3c2-b3e079055500',
    'the-monarch': '/venue/bb2c5259-7b50-4fab-b695-86d86497f236',
    bresca: '/venue/83fdfa4d-59d5-47bb-95ff-bfcbc83428f4',
    'michelin-gucci-dinner2024': '/venue/01e3d73c-902d-47ad-a2f4-1464140eb5f9',
    'NPH-Dinner2024': '/venue/e361998d-aa0c-432d-9820-8dc2932a2781',
    'michelin-sf-star-crawl2024': '/venue/8aefcb34-504b-4dd8-9d4f-4eae87a65070',
    serena: '/venue/a8759c12-2542-4269-ab9f-a53bc3dec4d9',
    adeles: '/venue/06cb2af7-01fd-42a1-8c60-98bb0c39b1c7',
    'the-twelve-thirty-club': '/venue/40b91e81-39e8-407d-8785-2a13bfea9200',
    'jbf-taste-awards-dal-lucia2024':
      '/venue/91244647-0977-45a1-9999-4122fb42b406',
    'jbf-taste-awards-dc-moon2024':
      '/venue/876d0a9e-e5ea-48aa-af1c-7c142b79bc35',
    'jbf-taste-awards-den-wolf2024':
      '/venue/e259f4e4-5ea9-402d-900b-c82dbb3475c7',
    'jbf-taste-awards-la-nnaka2024':
      '/venue/3fff69a2-6a68-467b-9dbc-f467c5880fd8',
    'jbf-taste-awards-nyc-hav2024':
      '/venue/e84ce800-8a68-4425-96a9-9819f3382ec3',
    'jbf-taste-awards-dc-albi2024':
      '/venue/bdf0ea61-0ad5-45b3-9dfb-926136a45916',
    'dca-capital-one-landing': '/venue/6e455960-8250-42a4-856a-1fa678d7a91d',
    'girl-power-crenn-2024': '/venue/17ed5f0f-bd3f-486b-bf55-27959a4f2770',
  },
};
