const PAYMENT_RULE_TYPE = Object.freeze({
  ADVANCED_PAYMENT: 'advanced_payment',
  SAVE_FOR_LATER: 'save_for_later',
  NONE: 'none',
});

const GRATUITY_TYPE = Object.freeze({
  SPECIFIC_GRATUITY: 'SPECIFIC_GRATUITY',
  CLIENT_GRATUITY: 'CLIENT_GRATUITY',
});

const CREDIT_CARD_ERROR_TYPE = Object.freeze({
  INCOMPLETE_NUMBER: 'incomplete_number',
  INCOMPLETE_EXPIRY: 'incomplete_expiry',
  INCOMPLETE_CVC: 'incomplete_cvc',
});

export { PAYMENT_RULE_TYPE, GRATUITY_TYPE, CREDIT_CARD_ERROR_TYPE };
