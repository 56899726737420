import { useMemo } from 'react';
import { RESERVATION_LIST_PARAMS } from '../../../utils/constants/QueryParamWhitelists';
import { useQueryParams } from '../../../utils/useQueryParams';
import { RESERVATIONS_TYPE } from '../constants';

const INVERTED_RESERVATIONS_TYPE = Object.fromEntries(
  Object.entries(RESERVATIONS_TYPE).map(([key, value]) => [value, key])
);

export const useCurrentTab = () => {
  const { [RESERVATION_LIST_PARAMS.TAB]: tab } = useQueryParams();

  const currentTab = useMemo(
    () =>
      !!INVERTED_RESERVATIONS_TYPE[tab] ? tab : RESERVATIONS_TYPE.UPCOMING,
    [tab]
  );

  return currentTab;
};
