import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  PROFILE_CONTACT_DETAILS_INFORMATION,
  PROFILE_CONTACT_DETAILS_SUBTITLE,
} from '../../assets/copy';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import InfoIconTooltip from '../core/InfoIconTooltip';
import Typography from '../overrides/Typography';
import { formatPhone } from '../../utils/formatPhone';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1, 0, 2.5),
    },
  },
  title: {
    display: 'flex',
    placeContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(3, 0, 1),
    },
  },
  edit: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  details: {
    marginBottom: theme.spacing(0.5),
  },
  blackToolTip: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    lineHeight: '21px',
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    width: 'auto',
    [theme.breakpoints.up('xl')]: {
      width: '256px',
      marginLeft: theme.spacing(4),
    },
  },
  informationIcon: {
    margin: theme.spacing(0.5, 0, 0, 1),
    width: '.85rem',
    height: '.85rem',
  },
  contactDetails: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(3, 0),
    marginLeft: ({ isProfile, isMobile }) =>
      isMobile && !isProfile && theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      maxWidth: 720,
    },
  },
}));

const ContactDetail = ({ name, phone, email, isProfile = false }) => {
  const { width } = useViewport();
  const isMobile = width <= WIDTH_BREAKPOINT;
  const classes = useStyles({ isProfile, isMobile });

  const renderInformation = () => {
    return isProfile ? (
      <Grid item xs={12} xl={6}>
        {renderDetails()}
      </Grid>
    ) : (
      renderDetails()
    );
  };
  const renderDetails = () => {
    return (
      <>
        <Grid item xs={12}>
          {renderValue(name)}
        </Grid>
        <Grid item xs={12}>
          {renderValue(email)}
        </Grid>
        {phone && (
          <Grid item xs={12}>
            {renderValue(formatPhone(phone))}
          </Grid>
        )}
      </>
    );
  };
  const renderValue = (value) => {
    return (
      <Typography
        variant="medium1Normal"
        className={classes.details}
        redact={true}>
        {value}
      </Typography>
    );
  };

  return (
    <Grid
      className={classes.container}
      container
      justify={width > WIDTH_BREAKPOINT ? 'initial' : 'space-between'}>
      <Grid item xs={9} xl={isProfile ? 3 : 4}>
        <Typography
          component="h2"
          variant="medium1Semibold"
          className={classes.contactDetails}>
          {PROFILE_CONTACT_DETAILS_SUBTITLE}
          <InfoIconTooltip
            title={PROFILE_CONTACT_DETAILS_INFORMATION}
            tooltipStyle={classes.blackToolTip}
            infoIconStyle={classes.informationIcon}
          />
        </Typography>
      </Grid>
      {renderInformation()}
    </Grid>
  );
};

export default ContactDetail;
