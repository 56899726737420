import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  section: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1200,
    width: '100%',
  },
});

const PageSection = ({
  className,
  component = 'div',
  style = {},
  variant = 'box',
  children,
  ...other
}) => {
  const classes = useStyles();

  if (variant === 'box') {
    return (
      <Box
        className={clsx(classes.section, className)}
        component={component}
        style={style}
        {...other}>
        {children}
      </Box>
    );
  }

  if (variant === 'grid') {
    return (
      <Grid
        container
        component={component}
        className={clsx(classes.section, className)}
        style={style}
        {...other}>
        {children}
      </Grid>
    );
  }

  return <>{children}</>;
};

PageSection.propTypes = {
  children: PropTypes.any,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(['box', 'grid', null]),
};

export default PageSection;
