import Typography from '../../components/overrides/Typography';
import CalendarSvg from '../../components/svg/Calendar';
import UserSvg from '../../components/svg/UserSvg';
import createInflection from '../../utils/inflection/createInflection';
import {
  GUEST_INFLECTION,
  HOME_FIND_RESTAURANT_TEXT,
  SEARCH_ACTION_BUTTON_TEXT,
} from '../../assets/copy';
import FilledButton from '../../components/core/FilledButton';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import defaultReservation from '../venue/helpers/defaultReservation';
import { formatDate } from '../../utils/dateTime';
import { useCopy } from '../../utils/useCopy';
import TextButton from '../../components/core/TextButton';
import { focusVisible } from '../../utils/ada/contrastStyles';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  defaultDate: {
    width: 'calc(100% - 42px)',
    backgroundColor: theme.palette.background.white,
    flex: '1 1 40%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.up('xl')]: {
      maxWidth: '414px',
    },
  },
  suggestedDateContainer: {
    margin: theme.spacing(1, 0, 3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(4),
    },
  },
  flexCenter: {
    alignItems: 'center',
    display: 'flex',
  },
  svg: {
    marginRight: theme.spacing(1),
  },
  underlinedButton: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
    color: theme.palette.interactionBlue.main,
    textUnderlineOffset: theme.spacing(0.5),
  },
  button: {
    ...focusVisible(),
  },
}));

const FindRestaurantSection = ({
  handleSearchOverlay,
  handleNavigateToSearchPage,
}) => {
  const classes = useStyles();
  const { defaultDate, defaultTime, defaultGuests } = defaultReservation();
  const formattedSuggestedDate = formatDate(defaultDate);
  const { getCopy } = useCopy();
  const guestsAltText = getCopy('HOME_RESERVATION_GUESTS_ALT_TEXT', {
    guestsInflection: createInflection(GUEST_INFLECTION, defaultGuests),
  });
  const onKeyPressHandler = useCallback(
    (e) => e.key === 'Enter' && handleSearchOverlay(),
    [handleSearchOverlay]
  );

  const handleSearchClick = (event) => {
    trackClick(event, "homepage-search", "Button");
    handleNavigateToSearchPage();
  }

  return (
    <div className={classes.defaultDate}>
      <Typography
        variant="medium2Semibold"
        className={classes.searchBoxTitle}
        component="h2">
        {HOME_FIND_RESTAURANT_TEXT}
      </Typography>
      <div className={classes.suggestedDateContainer}>
        <div
          className={classes.flexCenter}
          onClick={handleSearchOverlay}
          onKeyPress={onKeyPressHandler}>
          <CalendarSvg className={classes.svg} />
          <TextButton
            className={classes.underlinedButton}
            variant="medium1Normal"
            aria-label={getCopy('HOME_RESTAURANT_TIMESLOT_ALT_TEXT', {
              formattedSuggestedDate,
              defaultTime,
            })}>
            {getCopy('HOME_RESTAURANT_TIMESLOT_TEXT', {
              formattedSuggestedDate,
              defaultTime,
            })}
          </TextButton>
        </div>
        <div
          className={classes.flexCenter}
          onClick={handleSearchOverlay}
          onKeyPress={onKeyPressHandler}>
          <UserSvg className={classes.svg} />
          <TextButton
            className={classes.underlinedButton}
            variant="medium1Normal"
            aria-label={guestsAltText}>
            {createInflection(GUEST_INFLECTION, defaultGuests)}
          </TextButton>
        </div>
      </div>
      <FilledButton
        text={SEARCH_ACTION_BUTTON_TEXT}
        role="link"
        variant="medium2Normal"
        onClick={handleSearchClick}
        style={classes.button}
      />
    </div>
  );
};

export default FindRestaurantSection;
