import React, { useEffect, useMemo } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  pushUpAnimation: {
    '&-exit': {
      transition: `all .6s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateY(0vh)',
      '&-active': {
        transform: 'translateY(-100vh)',
      },
    },
    '&-enter': {
      transition: `all .6s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateY(100vh)',
      '&-active': {
        transform: 'translateY(0vh)',
      },
    },
    '&-appear': {
      transition: `all .6s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateY(100vh)',
      '&-active': {
        transform: 'translateY(0vh)',
      },
    },
  },
  slideRightAnimation: {
    '&-exit': {
      transition: `all .4s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateX(0%)',
      '&-active': {
        transform: 'translateX(100%)',
      },
    },
    '&-enter': {
      transition: `all .4s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateX(-100%)',
      '&-active': {
        transform: 'translateX(0%)',
      },
    },
  },
  slideLeftAnimation: {
    '&-exit': {
      transition: `all .4s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateX(0vw)',
      '&-active': {
        transform: 'translateX(-100vw)',
      },
    },
    '&-enter': {
      transition: `all .4s cubic-bezier(0.33, 1, 0.68, 1)`,
      transform: 'translateX(100vw)',
      '&-active': {
        transform: 'translateX(0vw)',
      },
    },
  },
}));

const AnimatedSwitch = withRouter(({ location, children }) => {
  const classes = useStyles();
  const { slideLeft, slideRight, pushUp } = useSelector(
    (state) => state.animations
  );

  const animationType = useMemo(() => {
    if (pushUp) return 'pushUpAnimation';
    if (slideLeft) return 'slideLeftAnimation';
    if (slideRight) return 'slideRightAnimation';

    return null;
  }, [pushUp, slideLeft, slideRight]);

  const duration =
    animationType && animationType === 'pushUpAnimation' ? 600 : 400;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <TransitionGroup
      appear
      childFactory={(child) =>
        React.cloneElement(child, {
          classNames: classes[animationType],
          timeout: duration,
        })
      }>
      <CSSTransition
        key={location.pathname}
        classNames={classes[animationType]}
        unmountOnExit
        exit={false}
        timeout={duration}>
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default AnimatedSwitch;
