import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Typography from '../overrides/Typography';
import {
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_NOTES_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED,
  PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  PROFILE_DIETARY_PREFERENCES_NOTES_NONE_SELECTED,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  noText: {
    color: 'secondary',
    paddingBottom: theme.spacing(2),
  },
  label: {
    color: '#141414',
  },
  value: {
    color: 'secondary',
    paddingBottom: theme.spacing(2),
    overflowWrap: 'break-word',
  },
}));

const DietPreferenceInfo = ({ dietPreferences }) => {
  // util
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="small4Normal" className={classes.label}>
          {PROFILE_DIETARY_PREFERENCES_DIETS_LABEL}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {dietPreferences?.restrictions?.length > 0 ? (
          <Typography className={classes.value} variant="medium1Normal">
            {dietPreferences?.restrictions.map((rest) => rest.name).join(', ')}
          </Typography>
        ) : (
          <Typography variant="medium1Normal" className={classes.noText}>
            {PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="small4Normal" className={classes.label}>
          {PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {dietPreferences?.allergies?.length > 0 ? (
          <Typography className={classes.value} variant="medium1Normal">
            {dietPreferences?.allergies.map((rest) => rest.name).join(', ')}
          </Typography>
        ) : (
          <Typography variant="medium1Normal" className={classes.noText}>
            {PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="small4Normal" className={classes.label}>
          {PROFILE_DIETARY_PREFERENCES_NOTES_LABEL}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {dietPreferences?.additionalNotes?.length > 0 ? (
          <Typography className={classes.value} variant="medium1Normal">
            {dietPreferences?.additionalNotes}
          </Typography>
        ) : (
          <Typography variant="medium1Normal" className={classes.noText}>
            {PROFILE_DIETARY_PREFERENCES_NOTES_NONE_SELECTED}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default DietPreferenceInfo;
