import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '../../overrides/Typography';
import {
  PRIVACY_POLICY_LINK_URL,
  RESERVATION_COMPLETE_CONFIRM_NOTICE,
  RESERVATION_TERMS_PRIVACY_POLICY_LABEL,
  RESERVATION_TERMS_RESERVE_LINE_TWO,
  RESERVATION_TERMS_TERMS_OF_SERVICE_LABEL,
  SENTENCE_END,
  TERMS_OF_SERVICE_LINK_URL,
  TERMS_PT1,
  TERMS_PT2,
} from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {},
  link: {},
}));

export const TermsOfService = ({
  classes: classesOverride,
  quotedText,
  variant,
}) => {
  const classes = useStyles({ classes: classesOverride });
  return (
    <Typography variant={variant} className={classes.root}>
      {TERMS_PT1}
      {quotedText}
      {TERMS_PT2}
      <a
        href={TERMS_OF_SERVICE_LINK_URL}
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer">
        {RESERVATION_TERMS_TERMS_OF_SERVICE_LABEL}
      </a>
      {RESERVATION_TERMS_RESERVE_LINE_TWO}
      <a
        href={PRIVACY_POLICY_LINK_URL}
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer">
        {RESERVATION_TERMS_PRIVACY_POLICY_LABEL}
      </a>
      {`${SENTENCE_END}${RESERVATION_COMPLETE_CONFIRM_NOTICE}`}
    </Typography>
  );
};

TermsOfService.propTypes = {
  quotedText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  classes: PropTypes.object,
};

export default TermsOfService;
