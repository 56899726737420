import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  link: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textDecoration: 'none',
  },
}));

const NavigationLink = ({
  label,
  onClick = () => {},
  path = (location) => location.pathname,
  style,
  variant = 'medium1Normal',
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Link
      to={path}
      className={clsx(classes.link, style)}
      onClick={onClick}
      {...otherProps}>
      <Typography variant={variant}>{label}</Typography>
    </Link>
  );
};

NavigationLink.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  path: PropTypes.string,
  variant: PropTypes.string,
};

export default NavigationLink;
