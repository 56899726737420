import { focusVisible } from '../../utils/ada/contrastStyles';

const carouselStyles = (theme) => ({
  carouselContainer: {
    marginBottom: 0,
    [theme.breakpoints.up('xl')]: {
      width: '587px',
    },
  },
  carousel: {
    columnGap: theme.spacing(1),
  },
  arrowIcon: {
    height: '32px',
    width: '32px',
  },
  leftArrow: {
    left: '-40px',
  },
  rightArrow: {
    right: '-40px',
  },
  arrowButton: {
    padding: 0,
    minWidth: '32px',
    borderRadius: '50%',
    ...focusVisible(),
    '&:hover': {
      background: theme.palette.background.grayLight,
      opacity: 0.93,
    },
  },
});

export default carouselStyles;
