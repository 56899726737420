import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Map } from 'immutable';

import PageSection from '../../components/core/PageSection';
import Typography from '../../components/overrides/Typography';
import { useSearchRedirect } from '../../components/search/helpers';
import { actionSetCurrentFilters } from '../../store/Filter/FilterAction';
import { FILTER_TYPES } from '../../utils/constants/FilterTypes';
import { useViewport } from '../../utils/useViewport';
import { HOME_ACCOLADES_SUBTITLE } from '../../assets/copy';
import { cardHoverStyles } from '../../utils/constants/hoverStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: theme.spacing(3),
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(9, 5, 3),
    },
  },
  media: {
    maxWidth: '100%',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(2),
      width: 31,
    },
  },
  label: {
    padding: theme.spacing(0, 0, 0, 1.5),
    color: theme.palette.primary.dark,
    wordBreak: 'break-word',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 2),
    },
  },
  paper: {
    height: 44,
    width: 327,
    display: 'flex',
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    ...cardHoverStyles,
    alignItems: 'center',
    [theme.breakpoints.up('xl')]: {
      borderRadius: theme.spacing(0.25),
      height: 96,
      width: 256,
    },
  },
  title: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    background: theme.palette.gray[30],
    marginTop: theme.spacing(5),
  },
}));

const Accolades = () => {
  const widthBreakpoint = 960;
  const classes = useStyles();
  const { width } = useViewport();

  const dispatch = useDispatch();
  const history = useHistory();
  const redirectTo = useSearchRedirect();

  const markets = useSelector((state) => state.markets);
  const accolades = useSelector((state) => state.accolades);
  const [accoladesObj, setAccolades] = useState({});

  const awardsInMarket = useMemo(() => {
    if (markets.market && markets.market.venues) {
      const venues = markets.market.venues;
      const awardsFromVenues = venues.items.reduce(
        (acc, venue) =>
          acc.concat(
            venue.awards.items.map((item) => [item.award.id, item.award])
          ),
        Map()
      );

      return awardsFromVenues
        .valueSeq()
        .sortBy(({ name }) => name)
        .toArray();
    } else return [];
  }, [markets.market]);

  useEffect(() => {
    if (markets.market && markets.market.venues) {
      const awardsInMarketMap = {}; // build a map of awards with awardsId as the key
      Object.values(awardsInMarket).forEach((awardInMarket) => {
        if (!awardsInMarketMap[awardInMarket.awardId]) {
          awardsInMarketMap[awardInMarket.id] = awardInMarket;
        }
      });

      const accoladesMap = {}; // build map of accolades with accolade the id as the key. Iterate through all of the accolades
      accolades?.list?.forEach((accolade) => {
        accoladesMap[accolade.id] = accolade;
      });
      setAccolades(accoladesMap);
    }
  }, [accolades, markets.market, awardsInMarket]);

  const handleClick = (accolade) => {
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_name: 'homepage-accolade',
      ui_interaction_element_type: 'Card',
      ui_interaction_element_description: accolade.name,
    });
    dispatch(actionSetCurrentFilters({ [FILTER_TYPES.ACCOLADES]: [accolade] }));
    history.push('/search', { redirectTo });
  };

  return (
    <PageSection className={classes.root}>
      <Typography
        variant={width > widthBreakpoint ? 'medium4Normal' : 'medium2Semibold'}
        className={classes.title}
        component="h2">
        {HOME_ACCOLADES_SUBTITLE}
      </Typography>
      <Grid container spacing={4}>
        {Object.values(accoladesObj).map((accolade) => (
          <Grid item xs={10} xl={'auto'} key={accolade.id}>
            <div
              className={classes.paper}
              onClick={() => handleClick(accolade)}
              onKeyPress={(e) => e.key === 'Enter' && handleClick(accolade)}
              tabIndex={0}
              role="link"
              aria-label={`${accolade.name} Restaurants`}
              data-cy="homepage-accolade">
              <img
                className={classes.media}
                src={accolade.icon}
                alt={`${accolade.name} img`}
              />
              <Typography variant="medium1Normal" className={classes.label}>
                {accolade.name}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </PageSection>
  );
};

export default Accolades;
