import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../overrides/Typography';

const useStyles = makeStyles({
  visiblyHidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: 1,
    height: 1,
    overflow: 'hidden',
    opacity: 0,
  },
});

const ScreenReaderText = ({
  children,
  className,
  component = 'p',
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.visiblyHidden, className)}
      component={component}
      variant="medium2Semibold"
      {...otherProps}>
      {children}
    </Typography>
  );
};

export default ScreenReaderText;
