import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useMarkets = () => {
  const markets = useSelector((state) => state.markets);
  const [categories, setCategories] = useState({});
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (markets.market && markets.market.venues) {
      setMounted(true);
      const venues = markets.market.venues.items;
      const categories = {};
      venues.forEach((venue) => {
        venue.categories.items?.forEach((category) => {
          if (categories[category.category.sortOrder]) {
            categories[category.category.sortOrder].venues.push(venue);
          } else {
            categories[category.category.sortOrder] = {
              venues: [venue],
              category: category.category,
            };
          }
        });
      });
      setCategories(categories);
    }
  }, [markets]);

  return {
    markets,
    categories,
    mounted,
  };
};
