import React, { useCallback, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  EDIT_PROFILIE_CITY_TITLE,
  HOME_SELECT_CITY,
  ONBOARDING_LOCATION_INPUT_LABEL,
} from '../../assets/copy';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import EditSaveButton from '../../components/core/EditSaveButton';
import NativeSelector from '../../components/core/NativeSelector';
import Typography from '../../components/overrides/Typography';
import { useSaveUserCallback } from '../../components/profile/helpers';
import { actionGetMarketRequest } from '../../store/Markets/MarketsAction';
import { useConfig } from '../../utils/useConfig';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: theme.spacing(4),
    '& button': {
      ...focusVisible(),
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
    },
  },
  item: {
    margin: theme.spacing(0, 0, 1),
  },
}));

const PreferredCityPage = ({ goBack, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isDebug } = useConfig();
  const { markets: storedMarkets } = useSelector((state) => state);
  const storedMarketId = useMemo(
    () => (user?.market?.id ? user.market.id : ''),
    [user]
  );
  const [selectedMarketId, setSelectedMarketId] = useState(
    storedMarketId ? storedMarketId : ''
  );
  /**
   * Add option to clear market if debug mode.
   * NOTE: id must be empty string for option value in dropdown to be set (it cannot be null)
   */
  const markets = useMemo(
    () =>
      isDebug
        ? [{ id: '', name: 'None' }, ...storedMarkets.list]
        : storedMarkets.list,
    [isDebug, storedMarkets?.list]
  );

  const saveUser = useSaveUserCallback(user);

  const selectCity = useCallback(
    (e) => setSelectedMarketId(e.target.value),
    [setSelectedMarketId]
  );

  const saveSelectedCity = useCallback(() => {
    saveUser({ marketId: selectedMarketId === '' ? null : selectedMarketId });
    if (selectedMarketId != null) {
      dispatch(actionGetMarketRequest({ id: selectedMarketId }));
    }
    goBack();
  }, [dispatch, goBack, saveUser, selectedMarketId]);

  return (
    <BackButtonLayout back={goBack} title={EDIT_PROFILIE_CITY_TITLE}>
      <Grid item xs={12} xl={8}>
        <Typography variant="medium1Normal" className={classes.item}>
          {ONBOARDING_LOCATION_INPUT_LABEL}
        </Typography>
        <NativeSelector
          ariaLabel={HOME_SELECT_CITY}
          items={markets}
          handleSelect={selectCity}
          selectedItem={selectedMarketId}
        />
      </Grid>
      <Grid item xs={12} className={classes.saveButton}>
        <EditSaveButton
          onSave={saveSelectedCity}
          disabled={selectedMarketId === storedMarketId}
        />
      </Grid>
    </BackButtonLayout>
  );
};

export default PreferredCityPage;
