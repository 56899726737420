import React, { useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import EventCard from '../Card/EventCard';
import EventHorizontalCard from '../Card/EventHorizontalCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard } from 'swiper/modules';
import { Pagination } from '../HeroCarousel/Pagination';
import { BREAKPOINT_VALUES } from '../../../utils/constants/Breakpoints';
import { useViewport } from '../../../utils/useViewport';
import PageSection from '../PageSection';
import clsx from 'clsx';
import ChevronButton from '../../buttons/ChevronButton';
import { trackClick } from '../../../utils/useOneTag';
import {
  swipeSnapGridPrev,
  swipeSnapGridNext,
  snapGrid,
} from '../../../utils/swiperHelper';
import {
  CAROUSEL_TEXT,
  DINING_EVENTS_TITLE,
  SLIDE_TEXT,
} from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 'auto', theme.spacing(2)),
    position: 'relative',
  },
  leftArrowContainer: {
    position: 'absolute',
    left: -25,
  },
  rightArrowContainer: {
    position: 'absolute',
    right: -25,
  },
  slide: {
    width: 'auto !important',
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 3),
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 5),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(1, 0),
    },
  },
  mobileSlide: {
    width: '320px',
  },
  arrowSectionContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 1,
    transform: 'translate(-50%, 0)',
    padding: 0,
    margin: 0,
    width: '90%',
  },
  arrowsContainer: {
    display: 'flex',
    '& .swiper-button-disabled': {
      display: 'none',
    },
  },
}));

const EventCarousel = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useViewport();
  const isMobile = width < BREAKPOINT_VALUES.xxl;
  const classes = useStyles();
  const swiperRef = useRef();

  let slidesOffset = 0;
  if (width < BREAKPOINT_VALUES.md) {
    slidesOffset = 24;
  } else if (width < BREAKPOINT_VALUES.xxl) {
    slidesOffset = 40;
  }
  const handleSlideSwipe = (e) => {
    if (e.activeIndex > e.previousIndex) {
      swipeSnapGridPrev(swiperRef?.current);
    } else {
      swipeSnapGridNext(swiperRef?.current);
    }
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'swipe',
      ui_interaction_element_name: 'event-carousel-slide',
      ui_interaction_element_type: 'Swiper',
    });
  };

  const handlePrev = (event) => {
    trackClick(event, 'event-carousel-previous-navigation-button', 'Button');
    swiperRef.current.slidePrev();
  };

  const handleNext = (event) => {
    trackClick(event, 'event-carousel-next-navigation-button', 'Button');
    swiperRef.current.slideNext();
  };

  return (
    <Box className={classes.root} width={'100%'}>
      <Swiper
        modules={[Keyboard]}
        keyboard={{ enabled: true }}
        role="region"
        aria-roledescription={CAROUSEL_TEXT}
        aria-label={DINING_EVENTS_TITLE}
        allowSlidePrev={false}
        allowSlideNext={false}
        slidesPerView={'auto'}
        centeredSlides={!isMobile}
        slidesOffsetBefore={slidesOffset}
        slidesOffsetAfter={slidesOffset}
        spaceBetween={isMobile ? 10 : 16}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          swiperRef.current.allowSlidePrev = true;
          swiperRef.current.allowSlideNext = true;
        }}
        onTouchEnd={handleSlideSwipe}
        onSlideChange={(e) => {
          if (isMobile) {
            snapGrid(swiperRef.current);
          }
          setActiveIndex(e.activeIndex);
        }}>
        <Box className={classes.swiperContainer}>
          {slides?.map((item, index) => (
            <SwiperSlide
              className={classes.slide}
              key={`${item.id}_${index}`}
              role="group"
              aria-roledescription={SLIDE_TEXT}
              aria-label={`${item.name} ${index + 1} of ${slides.length}`}>
              {isMobile ? (
                <EventCard
                  key={`${item.id}_event_card`}
                  item={item}
                  classes={{
                    root: width < BREAKPOINT_VALUES.md && classes.mobileSlide,
                  }}
                />
              ) : (
                <EventHorizontalCard
                  key={`${item.id}_event_horizontal_card`}
                  item={item}
                />
              )}
            </SwiperSlide>
          ))}
        </Box>
        {isMobile && slides?.length > 1 && (
          <PageSection className={classes.paginationContainer}>
            <Pagination
              length={slides.length}
              isSnapGridEnabled={true}
              activeIndex={activeIndex}
              paginationTracker={{
                paginationPreviousButtonName: 'event-carousel-prev-button',
                paginationNextButtonName: 'event-carousel-next-button',
                paginationIndexName: 'event-carousel-index-button',
              }}
            />
          </PageSection>
        )}
        {!isMobile && slides?.length > 1 && (
          <PageSection
            className={clsx(
              classes.paginationContainer,
              classes.arrowSectionContainer
            )}>
            <Box className={classes.arrowsContainer}>
              {activeIndex > 0 && (
                <Box className={classes.leftArrowContainer}>
                  <ChevronButton onClickHandler={handlePrev} />
                </Box>
              )}
              {activeIndex < slides.length - 1 && (
                <Box className={classes.rightArrowContainer}>
                  <ChevronButton
                    direction="right"
                    onClickHandler={handleNext}
                  />
                </Box>
              )}
            </Box>
          </PageSection>
        )}
      </Swiper>
    </Box>
  );
};

export default EventCarousel;
