import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '../overrides/Divider';
import Typography from '../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  header: {
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)':
      {
        marginTop: '15%',
      },
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(3),
    },
    '@media (max-height: 550px)': {
      marginBottom: 0,
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
  },
  divider: {
    background: theme.palette.red[50],
    '@media (max-height: 550px)': {
      display: 'none',
    },
  },
}));

const StepHeader = ({
  title,
  children = null,
  divider = true,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.header} aria-live="polite">
      <Typography
        className={classes.title}
        color="secondary"
        component="h1"
        variant="large1LightFlex"
        {...otherProps}>
        {title}
      </Typography>
      {children != null && children}
      {divider && <Divider className={classes.divider} />}
    </Box>
  );
};

export default StepHeader;
