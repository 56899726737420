import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import PageSection from '../../components/core/PageSection';
import ChefList from '../../components/premier/ChefList';
import DesktopChefHeader from '../../components/premier/DesktopChefHeader';
import MobileChefHeader from '../../components/premier/MobileChefHeader';
import { useViewport } from '../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme.palette.charcoal[60],
  },
  lastChefSection: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  content: {
    boxSizing: 'border-box',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 5, 6),
    },
  },
  divider: {
    background: theme.palette.error.main,
    marginTop: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(100% - (${theme.spacing(3)} * 2))`,
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(6),
      width: '100%',
    },
  },
}));

const ChefSection = ({ data }) => {
  const classes = useStyles();
  const { width } = useViewport();

  const itemListOrGrid = (item) => {
    if (
      item.venues == null ||
      item.venues.items == null ||
      item.venues.items.length === 0
    ) {
      return;
    }

    return (
      <ChefList
        venues={item.venues.items}
        name={item.name}
        showGetTicketLink={item?.spotlight}
      />
    );
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((item, index) => (
          <div
            className={clsx(
              classes.root,
              ...[index === data.length - 1 && classes.lastChefSection]
            )}
            key={`chef-${index}`}>
            {width > WIDTH_BREAKPOINT ? (
              <DesktopChefHeader item={item} index={index} />
            ) : (
              <MobileChefHeader item={item} />
            )}

            <PageSection className={classes.content}>
              {itemListOrGrid(item)}
              {index !== data.length - 1 && (
                <Divider className={classes.divider} />
              )}
            </PageSection>
          </div>
        ))}
    </>
  );
};

export default ChefSection;
