import { useState, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { InventoryCard } from '../../../components/spoon/InventoryCard';
import DateTime from '../../../utils/dateTimeHelpers';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import Typography from '../../../components/overrides/Typography';
import { cardHoverStyles } from '../../../utils/constants/hoverStyles';
import gravityPalette from '../../../components/gravity/global/gravityPalette';
import { ReactComponent as TinyHeartIcon } from '../../../assets/icons/tinyHeart.svg';
import { CtaLink } from '../../../components/spoon/Button';
import { SectionTitle } from '../../../components/spoon/SectionTitle';
import { useShortVenueDetails } from '../../../utils/useShortVenueDetails';
import {
  INVENTORY_TEXT,
  INVENTORY_SUBTEXT,
  MOBILE_NAVIGATION_VIEW_ALL,
} from '../../../assets/copy';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { filledIconLib, iconLib } from '../../../utils/constants/VenueLabels';
import { actionSetCurrentFilters } from '../../../store/Filter/FilterAction';
import { trackClickWithDescription } from '../../../utils/useOneTag';
import {
  FILTER_TYPES,
  VENUE_TYPE_FILTERS,
  VENUE_TYPES,
} from '../../../utils/constants/FilterTypes';
import { useDispatch } from 'react-redux';
import PageSection from '../../../components/core/PageSection';
import clsx from 'clsx';
import { useViewport } from '../../../utils/useViewport';
import { BREAKPOINT_VALUES } from '../../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 5),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(1, 0),
    },
  },
  cardRoot: {
    flexGrow: 1,
  },
  media: {
    maxWidth: '100%',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(2),
      width: 31,
    },
  },
  label: {
    padding: theme.spacing(0, 0, 0, 1.5),
    color: theme.palette.primary.dark,
    wordBreak: 'break-word',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 2),
    },
  },
  cardUpperText: {
    paddingBottom: 5,
  },
  cardLowerText: {
    color: theme.palette.gray[40],
  },
  paper: {
    height: 44,
    width: 327,
    display: 'flex',
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    ...cardHoverStyles,
    alignItems: 'center',
    [theme.breakpoints.up('xl')]: {
      borderRadius: theme.spacing(0.25),
      height: 96,
      width: 256,
    },
  },
  title: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.black,
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    background: theme.palette.gray[30],
    marginTop: theme.spacing(5),
  },
  sectionTitleContainer: {
    position: `absolute`,
    backgroundColor: theme.palette.blue[70],
    width: '150px',
    height: '50px',
    textAlign: 'center',
    borderBottomRightRadius: '16px',
    zIndex: 10,
    [theme.breakpoints.up('xl')]: {
      width: '200px',
      borderRadius: '16px',
    },
  },
  titleTextFont: {
    ...theme.typography.tiny1Semibold,
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '.5px',
    paddingTop: '17px',
    [theme.breakpoints.up('xl')]: {
      letterSpacing: '.25px',
      paddingTop: '13px',
      fontStyle: 'normal',
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  slideImage: {
    width: '369px',
    height: '182px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '369px',
      height: '182px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '624px',
      height: '279px',
      padding: theme.spacing(3, 2),
    },
  },
  redirLabel: {
    textDecoration: 'underline',
  },
  card: ({ onCardClick }) => ({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(1.5),
    width: '100%',
    ...(onCardClick && cardHoverStyles),
    [theme.breakpoints.down('lg')]: {
      borderRadius: theme.spacing(0.5),
      width: '100%',
    },
  }),

  ctaLink: {
    paddingTop: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '40px',
    },
    paddingBottom: theme.spacing(5),
  },

  blue: {
    color: theme.palette.spoon.blue[40],
  },
  tabLabel: {
    marginTop: theme.spacing(1.5),
  },
}));

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 2,
    '& > span': {
      width: '100%',
      backgroundColor: '#0276B1',
    },
    '&:hover > span': {
      backgroundColor: gravityPalette.tabs.hover,
    },
  },
  flexContainer: {
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(6),
    },
  },
}))((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span /> }}
    scrollButtons="off"
    variant="scrollable"
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    textEmphasis: gravityPalette.tabs.hover,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: '1px',
    minWidth: '1px',
    padding: theme.spacing(1, 0),
    '&:focus': {
      opacity: 1,
    },
    '&.Mui-selected .MuiTab-wrapper': {
      color: '#0276B1',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const processInventoryDatetime = (curatedInventoryData) => {
  const metricDate = new DateTime().minus({ months: 2 });
  const adjustedMetricISO = new Date(metricDate.toISO()).getTime();
  const curatedVenueList = Object.keys(curatedInventoryData).map(
    (inventoryData) => {
      var curatedVenues = {};
      var venueList = [];
      curatedInventoryData[inventoryData].map((venue) => {
        if (!venue.liveDate) {
          return venueList;
        }
        const dateAdded = new DateTime(venue.liveDate);
        const dateAddedISO = new Date(dateAdded.toISO()).getTime();
        if (
          adjustedMetricISO <= dateAddedISO &&
          !venue.labels.includes('New')
        ) {
          venue.labels.push('New');
        }
        venueList.push(venue);
        return venueList;
      });
      curatedVenues[inventoryData] = venueList;
      return curatedVenues;
    }
  );
  return curatedVenueList;
};

const processCuratedInventory = (curatedInventoryData) => {
  var curatedInventory = {};
  for (var key in curatedInventoryData) {
    if (
      curatedInventoryData.hasOwnProperty(key) &&
      curatedInventoryData[key].length > 1
    ) {
      curatedInventory[key] = curatedInventoryData[key];
    }
  }
  return curatedInventory;
};

export function CuratedPicks({ curatedVenues }) {
  processInventoryDatetime(curatedVenues); // Adds "new" label to code
  const curatedInventory = processCuratedInventory(curatedVenues);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const fullVenueList = curatedInventory[Object.keys(curatedInventory)[value]];
  const venueData = fullVenueList ? fullVenueList.slice(0, 4) : [];
  const { width } = useViewport();
  const isMobile = width < BREAKPOINT_VALUES.xl;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xxl'));

  const types = Object.keys(curatedInventory);
  const { getShortVenueDetails } = useShortVenueDetails();

  useEffect(() => {
    trackClickWithDescription(
      'curated-inventory-occasion-filter',
      'Button',
      Object.keys(curatedInventory)[value]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSearchSelect = () => {
    dispatch(
      actionSetCurrentFilters({
        [FILTER_TYPES.VENUE_TYPE]: VENUE_TYPE_FILTERS.filter(
          (type) => type.venueType === VENUE_TYPES.REGULAR
        ),
      })
    );
    trackClickWithDescription(
      'curated-inventory-search-redirect',
      'Link',
      `Search select: restaurants`
    );
    history.push(`/search`);
  };

  return (
    <>
      {Object.keys(curatedInventory).length > 0 && (
        <PageSection className={classes.root}>
          <div style={{ transform: `translateY(15%)`, paddingBottom: '20px' }}>
            <SectionTitle
              size={isDesktop ? 'large' : 'normal'}
              text={INVENTORY_TEXT}
              color="dark"
              icon={<TinyHeartIcon />}
            />
          </div>
          <br />
          <Typography
            variant={isMobile ? 'medium2Light' : 'large1Light'}
            className={classes.title}
            component="h2">
            {INVENTORY_SUBTEXT}
          </Typography>

          <div
            style={{
              paddingBottom: '20px',
            }}>
            <StyledTabs value={value} indicatorColor="primary">
              {types.map((tab, index) => (
                <StyledTab
                  key={tab}
                  disabled={tab.disabled}
                  onClick={() => setValue(index)}
                  value={index}
                  label={
                    <>
                      <div style={{ height: '32px' }}>
                        {index === value
                          ? filledIconLib[tab]?.image
                          : iconLib[tab]?.image}
                      </div>
                      <Typography
                        variant="small1Semibold"
                        className={clsx(classes.tabLabel, {
                          [classes.blue]: index === value,
                        })}>
                        {filledIconLib[tab]?.name}
                      </Typography>
                    </>
                  }
                />
              ))}
            </StyledTabs>
          </div>
          <div className={classes.cardRoot}>
            <Grid container spacing={3}>
              {venueData.map((venue, index) => {
                return (
                  <Grid item xs={12} md={6} key={index}>
                    <InventoryCard
                      venue={venue}
                      subheading={getShortVenueDetails(venue)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <CtaLink
            classes={{
              root: classes.ctaLink,
            }}
            size={isMobile ? 'normal' : 'large'}
            onClick={() => handleSearchSelect()}>
            {MOBILE_NAVIGATION_VIEW_ALL}
          </CtaLink>
        </PageSection>
      )}
    </>
  );
}

export default CuratedPicks;
