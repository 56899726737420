import { CircularProgress, makeStyles } from '@material-ui/core';
import { LOADING_TEXT, PROGRESS_BAR_LABEL } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loader: {
    margin: '64px auto',
  },
}));

export const ModalLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        size={48}
        className={classes.loader}
        role="progressbar"
        aria-label={PROGRESS_BAR_LABEL}
        title={LOADING_TEXT}
        aria-valuenow={0}
      />
    </div>
  );
};
