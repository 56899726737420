import * as TYPES from './UserTypes';
import { AUTH_STATUS } from '../../utils/useAuth';

const initialState = {
  user: {
    session: {
      isAuthenticated: false,
      status: null,
    },
    isRequestPending: false,
  },
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.GET_USER_BY_TOKEN_REQUEST:
    case TYPES.GET_USER_BY_AUTH_CODE_REQUEST:
      return {
        ...state,
        user: {
          session: {
            ...state.user.session,
            isAuthenticated: false,
            status: AUTH_STATUS.PENDING,
          },
          isRequestPending: true,
        },
      };
    case TYPES.GET_USER_REQUEST:
    case TYPES.UPDATE_USER_REQUEST:
    case TYPES.UPDATE_USER_DEFAULT_PAYMENT_ACCOUNT:
      return {
        ...state,
        user: {
          ...state.user,
          // only update isRequestPending when not updating hidePaymentMethodAlert to prevent whole page reload
          ...(payload?.hidePaymentMethodAlert == null && {
            isRequestPending: true,
          }),
        },
      };
    case TYPES.GET_USER_BY_TOKEN_SUCCESS:
    case TYPES.GET_USER_BY_AUTH_CODE_SUCCESS:
      return {
        ...state,
        user: {
          ...payload,
          session: {
            ccid: state.user.session.ccid,
            isAuthenticated: true,
            status: AUTH_STATUS.SUCCESS,
          },
          isRequestPending: false,
        },
      };
    case TYPES.SET_USER:
      return { ...state, user: payload };
    case TYPES.GET_USER_SUCCESS:
    case TYPES.UPDATE_USER:
    case TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
          isRequestPending: false,
        },
      };
    case TYPES.UPDATE_USER_FAIL:
      console.error(`Error updating user: ${JSON.stringify(payload.errors)}`);
      return {
        ...state,
        user: {
          ...state.user,
          isRequestPending: false,
        },
      };
    case TYPES.GET_USER_BY_TOKEN_FAIL:
    case TYPES.GET_USER_BY_AUTH_CODE_FAIL:
      const errorMessage = payload.response?.data?.message;
      console.error(`Error calling getUser: ${errorMessage}`);

      return {
        ...state,
        user: {
          session: {
            isAuthenticated: false,
            status: AUTH_STATUS.FAILED,
          },
          isRequestPending: false,
        },
      };
    case TYPES.SIGNOUT_USER_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
}
