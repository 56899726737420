import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { BASE_SEARCH_PATHNAME, DEFAULT_REDIRECT } from '../constants';

export const useSearchRedirect = () => {
  const location = useLocation();

  return useMemo(
    () =>
      location.pathname === BASE_SEARCH_PATHNAME ? DEFAULT_REDIRECT : location,
    [location]
  );
};
