import moment from 'moment';
import { DATE_FORMAT_YEAR_FIRST } from '../assets/dateFormats';
import { addDays } from './dateTime';
import { combine7RVenueIdAndExperienceId } from './availabilityHelper';

export const generateListAvailabilityInput = (
  venue,
  selectedDate,
  time,
  guests
) => {
  const { sevenRoomsVenueId, experienceId } = venue;
  const END_DATE = 8;
  const selectedDateObj = moment(selectedDate);
  const currentDateTime = moment();
  const isSameDate = selectedDateObj.isSame(currentDateTime, 'day');
  const selectedDateAvailability = moment(time, ['h:mm A']);
  selectedDateAvailability.set('month', selectedDateObj.month());
  selectedDateAvailability.set('date', selectedDateObj.date());
  selectedDateAvailability.set('year', selectedDateObj.year());

  const endAvailability = selectedDateAvailability
    .clone()
    .add(END_DATE, 'days'); // max number of days from selectedDate

  const startOfTime = isSameDate
    ? currentDateTime
    : selectedDateAvailability.clone().set({ hour: 6, minute: 0 });
  const endOfTime = selectedDateAvailability
    .clone()
    .set({ hour: 5, minute: 45 });
  const time24Format = 'HH:mm';
  const dateFormat = DATE_FORMAT_YEAR_FIRST;

  return {
    sevenRoomsVenueId,
    startDate: selectedDateAvailability.format(dateFormat),
    endDate: endAvailability.format(dateFormat),
    startTime: startOfTime.format(time24Format),
    endTime: endOfTime.format(time24Format),
    partySize: parseInt(guests),
    experienceId,
  };
};

export const generateGetDateRangeAvailabilityInput = (
  venue,
  selectedDate,
  guests,
  daysOut
) => {
  const { sevenRoomsVenueId, experienceId } = venue;
  const selectedDateObj = moment(selectedDate);

  const startOfTime = selectedDateObj.clone().set({ hour: 6, minute: 0 });
  const endOfTime = selectedDateObj.clone().set({ hour: 5, minute: 45 });
  const time24Format = 'HH:mm';
  const dateFormat = DATE_FORMAT_YEAR_FIRST;

  return {
    sevenRoomsVenueId,
    date: selectedDateObj.format(dateFormat),
    startTime: startOfTime.format(time24Format),
    endTime: endOfTime.format(time24Format),
    partySize: parseInt(guests),
    daysOut: daysOut,
    experienceId,
  };
};

export const generateAvailabilityInput = (
  venue,
  date,
  time,
  guests,
  halo = true,
  allAvailabilities = false
) => {
  const { sevenRoomsVenueId, experienceId } = venue;
  const HALO = 2;
  const currentDate = moment(date);
  const currentAvailability = moment(time, ['h:mm A']);
  currentAvailability.set('month', currentDate.month());
  currentAvailability.set('date', currentDate.date());
  currentAvailability.set('year', currentDate.year());

  let startOfTime;
  let endOfTime;
  if (halo && !allAvailabilities) {
    startOfTime = currentAvailability.clone().subtract(HALO, 'hours');
    endOfTime = currentAvailability.clone().add(HALO, 'hours');
  } else if (allAvailabilities) {
    startOfTime = currentAvailability.clone().set({ hour: 6, minute: 0 });
    endOfTime = currentAvailability.clone().set({ hour: 5, minute: 45 });
  } else {
    // Backward compatible. Might not need anymore
    startOfTime = currentAvailability.clone();
    endOfTime = moment(venue.startOfDay || '23:59', ['h:mm A']).subtract(
      1,
      'minutes'
    );
  }

  const time24Format = 'HH:mm';
  const dateFormat = DATE_FORMAT_YEAR_FIRST;

  return {
    sevenRoomsVenueId,
    date: currentAvailability.format(dateFormat),
    startTime: startOfTime.format(time24Format),
    endTime: endOfTime.format(time24Format),
    partySize: parseInt(guests),
    experienceId,
  };
};

export const generateAvailabilityInputForSpecialEvent = (
  venue,
  date,
  guests
) => {
  const { sevenRoomsVenueId, experienceId, eventDates } = venue;
  const lastEventDate = moment(eventDates[eventDates.length - 1]);
  const specialEventDateObj = moment(date);
  const END_DATE = lastEventDate.diff(specialEventDateObj, 'days') + 1;
  const endAvailability = specialEventDateObj.clone().add(END_DATE, 'days');
  const startOfTime = specialEventDateObj.clone().set({ hour: 6, minute: 0 });
  const endOfTime = specialEventDateObj.clone().set({ hour: 5, minute: 45 });
  const time24Format = 'HH:mm';
  const dateFormat = DATE_FORMAT_YEAR_FIRST;

  return {
    sevenRoomsVenueId,
    startDate: specialEventDateObj.format(dateFormat),
    endDate: endAvailability.format(dateFormat),
    startTime: startOfTime.format(time24Format),
    endTime: endOfTime.format(time24Format),
    partySize: parseInt(guests),
    experienceId,
    eventDates,
  };
};

export const generateFindAvailabilityInput = (venue, date, time, guests) => {
  const { sevenRoomsVenueId, experienceId } = venue;
  const currentDate = moment(date);
  const currentAvailability = moment(time, ['h:mm A']);
  currentAvailability.set('month', currentDate.month());
  currentAvailability.set('date', currentDate.date());
  currentAvailability.set('year', currentDate.year());
  let startOfTime = currentAvailability.clone();

  const time24Format = 'HH:mm';
  const dateFormat = DATE_FORMAT_YEAR_FIRST;

  return {
    sevenRoomsVenueId,
    date: currentAvailability.format(dateFormat),
    time: startOfTime.format(time24Format),
    partySize: parseInt(guests),
    experienceId,
  };
};

export const generateAvailabilityListInput = (
  venues,
  date,
  time,
  guests,
  halo,
  page
) => {
  // speeding up QA venue data by deduping the sevenRoomsVenueIds
  const uniqueVenueIds = new Set();

  const venueObjs = (venues ?? []).reduce((uniqueVenues, venue) => {
    const uniqueKey = combine7RVenueIdAndExperienceId(venue);
    if (!uniqueVenueIds.has(uniqueKey)) {
      uniqueVenueIds.add(uniqueKey);
      return [
        ...uniqueVenues,
        {
          sevenRoomsVenueId: venue.sevenRoomsVenueId,
          experienceId: venue.experienceId,
        },
      ];
    }
    return [...uniqueVenues];
  }, []);

  const currentDate = moment(date);
  const currentAvailability = moment(time, ['h:mm A']);
  currentAvailability.set('month', currentDate.month());
  currentAvailability.set('date', currentDate.date());
  currentAvailability.set('year', currentDate.year());
  const time24Format = 'HH:mm';
  const dateFormat = DATE_FORMAT_YEAR_FIRST;

  return {
    venues: venueObjs,
    sevenRoomsVenueIds: null,
    date: currentDate.format(dateFormat),
    time: currentAvailability.format(time24Format),
    partySize: parseInt(guests),
    halo: halo,
    page,
  };
};

/** Set time for a specific date object.
 * @param {Date} date - Date object for which being set time
 * @param {string} time - exact time to be set for the date
 * @returns {Date} Date object with new time
 * */
export const setDateTime = function (date, time) {
  const arrayOfTimeParts = time.split(':');
  date.setHours(parseInt(arrayOfTimeParts[0], 10));
  date.setMinutes(parseInt(arrayOfTimeParts[1], 10));
  return date;
};

/**
 * @typedef {Object} DateParts
 * @property {number} year - year in integer
 * @property {number} month - index of the month.
 * @property {number} day - day in integer
 * */

/** Get year, month, day parts from the dateString param
 * @param {string} dateString - dateString in YYYY-mm-dd format
 * @returns {DateParts}
 * */
export const getDateParts = (dateString) => {
  const dateParts = dateString.split('-');

  return {
    year: parseInt(dateParts[0]),
    month: parseInt(dateParts[1]) - 1,
    day: parseInt(dateParts[2]),
  };
};

/**
 * @typedef {Object} TimeParts
 * @property {number} hour - hour in integer
 * @property {number} min - min in integer
 * */

/** Get hour, min parts from the timeString param
 * @param {string} timeString - timeString in HH:MM XM format (Ex: "07:00 PM")
 * @returns {TimeParts}
 * */
export const getTimeParts = (timeString) => {
  const time = timeString.split(' ');
  const timeParts = time[0].split(':');
  const isSelectedTimeAfterNoon = time[1] === 'PM';

  let hour = parseInt(timeParts[0]);
  if (hour === 12 && !isSelectedTimeAfterNoon) {
    hour = 0; // Set to 0 for 12:00 AM
  } else if (isSelectedTimeAfterNoon && hour !== 12) {
    hour += 12; // Add 12 for PM times (1 PM to 11 PM), excluding 12 PM
  }

  return {
    hour: hour,
    min: parseInt(timeParts[1]),
  };
};

/** Get index of the selected date time compared to the array of timeslots.
 *  If no index of selected date time is found return the closest index.
 * @param {string} selectedDate - Selected Date (no time). Can be in one of the following formats:
 * - "YYYY-mm-dd" string.
 * - "Weekday MM DD YYYY" string (ex: Monday Sept 20 2022).
 * - Date object.
 * @param {string} selectedTime - Selected time string (no date)
 * @param {Object[]} timeslots - array of timeslots object
 * @returns {number} Index of the timeslot's item that matches day and time
 * */
export const checkIndexOfSelectedDate = (
  selectedDate,
  selectedTime,
  timeslots
) => {
  if (!timeslots || !selectedDate || !selectedTime) return 0;
  const extractedSelectedDateString = getDateString(selectedDate);
  const { year, month, day } = getDateParts(extractedSelectedDateString);
  const { hour, min } = getTimeParts(selectedTime);

  const selectedDateObj = new Date(year, month, day, hour, min);
  const copyOfSelectedDate = new Date(selectedDateObj);
  const midnight = setDateTime(copyOfSelectedDate, '00:00');
  const midnightClone = new Date(midnight);
  const lastBusinessHour = setDateTime(midnightClone, '05:45');

  const isBetweenMidnightAndLastBusinessHr =
    selectedDateObj >= midnight && selectedDateObj <= lastBusinessHour;

  const adjustedSelectedDate = isBetweenMidnightAndLastBusinessHr
    ? addDays(selectedDateObj, 1)
    : selectedDateObj;

  let from = 0,
    until = timeslots.length - 1;
  if (timeslots.length < 1) return 0;

  // Binary Search to reduce load time
  while (from <= until) {
    const middle = Math.floor((from + until) / 2);

    if (middle === from) {
      const diff1 =
        +adjustedSelectedDate -
        +new Date(timeslots[from].realDateTime.replace(/\s/, 'T'));
      const diff2 =
        +new Date(timeslots[until].realDateTime.replace(/\s/, 'T')) -
        +adjustedSelectedDate;
      return diff1 <= diff2 ? from : until;
    }

    const current = new Date(timeslots[middle].realDateTime.replace(/\s/, 'T'));

    if (+current === +adjustedSelectedDate) {
      return middle;
    }

    if (current > adjustedSelectedDate) {
      until = middle;
    } else {
      from = middle;
    }
  }
};

/**
 * Get Date string from selected date
 * @param {string|Date} selectedDate - Selected Date (no time). Can be in one of the following formats:
 * - "YYYY-mm-dd" string (ex: '2022-12-20').
 * - "Weekday MM DD YYYY" string (ex: 'Monday Sept 20 2022').
 * - Stringified format of Date object:
 *    "Weekday MM DD YYYY 00:00:00 local time" (Ex: 'Sat Jan 07 2023 00:00:00 GMT-0800 (Pacific Standard Time)')
 * - Date object.
 * @return {string} date part of the selected date.
 **/
export const getDateString = (selectedDate) => {
  if (selectedDate instanceof Date) {
    return selectedDate.toISOString().slice(0, 10);
  }

  /**
   * Test for string format "YYYY-mm-dd" i.e. "2022-12-20" (hence check for more than 1 occurrence of "-") but NOT
   * format such as "Sat Jan 07 2023 00:00:00 GMT-0800 (Pacific Standard Time)" (which only has 1 occurrence of "-").
   * This happens due to inconsistency of the use of date value across the app.
   * **/
  const isDateStringFormatted =
    (selectedDate.match(/-/g) || []).length > 1 &&
    selectedDate.indexOf(':') < 0;

  return new Date(
    isDateStringFormatted ? `${selectedDate}T00:00` : selectedDate
  )
    .toISOString()
    .slice(0, 10);
};

/** Checks to see if date1 is before, the same or after date2. Return the index to be added to startIndex
 * from function sanitizeAvailability to grab more timeslots
 * @param {string|Date} date1 - array of availabilities time slots object
 * @param {string|Date} date2 - array of availabilities time slots object
 * @returns {number} index
 * */
export const checkIsPriorToSelectedDate = (date1, date2) => {
  const dateTime1 = new Date(date1);
  const dateTime2 = new Date(date2);
  if (dateTime1 > dateTime2) {
    return 3;
  }
  return 2;
};

/** - Grabs 2 timeslots prior to the selected date time, the selected date time and additional slots after the
 * selected date time. Max slots is 7 (including the selected date time slot).
 *  - If not enough slots prior to the selected date time, more slots will be grabbed from the latter slots
 * @param {Object[]} timeslots - array of availabilities time slots object
 * @param {string|Date} tentativeSelectedDate - Selected time string (no date)
 * @param {string} tentativeSelectedTime - array of timeslots object
 * @returns {Object[]} array of sanitized availabilities time slots object
 *  */
export const sanitizeAvailability = (
  timeslots,
  tentativeSelectedDate,
  tentativeSelectedTime,
  maxSlots = 7
) => {
  if (!timeslots || !timeslots.length) return [];

  let priorSlots = 3;
  let remainingSlots = maxSlots - priorSlots;
  const closestIndexToSelectedDate = checkIndexOfSelectedDate(
    new Date(tentativeSelectedDate).toISOString().slice(0, 19),
    tentativeSelectedTime,
    timeslots
  );

  const startIndex =
    closestIndexToSelectedDate -
    checkIsPriorToSelectedDate(
      tentativeSelectedDate + ' ' + tentativeSelectedTime,
      timeslots[closestIndexToSelectedDate].realDateTime
    );

  const result = [];
  for (let j = startIndex; j <= closestIndexToSelectedDate; j++) {
    if (timeslots[j]) {
      result.push(timeslots[j]);
    } else {
      remainingSlots++;
    }
  }

  result.push(
    ...timeslots.slice(
      closestIndexToSelectedDate + 1,
      closestIndexToSelectedDate + 1 + remainingSlots
    )
  );

  return result;
};
