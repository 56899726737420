import React from 'react';
import PropTypes from 'prop-types';
import Select from '.';
import {
  GUEST_SELECTOR_DROPDOWN_LABEL,
  GUEST_INFLECTION,
} from '../../../assets/copy';
import createInflection from '../../../utils/inflection/createInflection';

export const GuestSelector = ({ minGuests = 1, maxGuests = 8, dropdownLabel=GUEST_SELECTOR_DROPDOWN_LABEL, ...rest }) => {
  if (minGuests > maxGuests) {
    throw new Error('minGuests cannot be greater than maxGuests');
  }

  const options = [];
  for (let i = minGuests; i <= maxGuests; i++) {
    options.push(
      <option key={i} value={i}>
        {createInflection(GUEST_INFLECTION, i)}
      </option>
    );
  }

  return (
    <Select
      label={dropdownLabel}
      ariaLabel="Number of Guests"
      {...rest}>
      {options}
    </Select>
  );
};

export default GuestSelector;

GuestSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  minGuests: PropTypes.number,
  maxGuests: PropTypes.number,
};
