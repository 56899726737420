import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper/modules';

export const TimeButtonGroup = withStyles(() => ({
  button: {
    width: 'auto',
    userSelect: 'none',
  },
  buffer: {
    width: ({ bufferWidth }) => bufferWidth || 0,
    height: '1px',
  },
}))(({ classes, children, bufferWidth, swiperProps }) => {
  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={8}
      className={classes.root}
      modules={[FreeMode]}
      freeMode={true}
      {...swiperProps}>
      {bufferWidth && <SwiperSlide className={classes.buffer} />}
      {children?.map((child, i) => (
        <SwiperSlide className={classes.button} key={`swiperSlide_${i}`}>
          {child}
        </SwiperSlide>
      ))}
      {bufferWidth && <SwiperSlide className={classes.buffer} />}
    </Swiper>
  );
});

export default TimeButtonGroup;

TimeButtonGroup.propTypes = {
  children: PropTypes.node, // TimeButton components
  bufferWidth: PropTypes.string, // width of left and right buffers
  swiperProps: PropTypes.object, // props to pass to Swiper
};
