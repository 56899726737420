import { put, takeLatest } from 'redux-saga/effects';
import { listNeighborhoods } from '../../graphql/queries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './NeighborhoodsAction';
import * as TYPES from './NeighborhoodsTypes';

export function* neighborhoodListRequestHandler() {
  try {
    const neighborhoods = [];
    const filter = { isActive: { eq: true } };
    let nextToken;

    do {
      const result = yield callGraphqlWithToken({
        query: listNeighborhoods,
        variables: { filter, nextToken },
      });
      neighborhoods.push(...result.data.listNeighborhoods.items);
      nextToken = result.data.listNeighborhoods.nextToken;
    } while (nextToken != null);

    yield put(ACTIONS.actionNeighborhoodListSuccess(neighborhoods));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionNeighborhoodListFail(e));
  }
}

export default function* neighborhoodsSaga() {
  yield takeLatest(
    TYPES.NEIGHBORHOOD_LIST_REQUEST,
    neighborhoodListRequestHandler
  );
}
