import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ENTERTAINMENT_BANNER_BUTTON_TEXT } from '../../../assets/copy';
import OutlineButton from '../../core/OutlineButton';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  bannerButton: {
    ...focusVisible(),
    [theme.breakpoints.up('xl')]: {
      padding: 9,
      maxWidth: 208,
    },
    [theme.breakpoints.down('lg')]: {
      padding: 4,
      maxWidth: 177,
      height: 32,
    },
  },
}));

const BannerButton = ({ handleClick, variant }) => {
  const classes = useStyles();

  const handleBannerButtonClick = (event) => {
    handleClick(event);
    trackClick(event, "entertainment-banner-explore-experiences", "Button");
  }

  return (
    <OutlineButton
      variant={variant}
      text={ENTERTAINMENT_BANNER_BUTTON_TEXT}
      role="link"
      style={classes.bannerButton}
      onClick={handleBannerButtonClick}
      data-cy="entertainment-banner-explore-experiences"
    />
  );
};

export default BannerButton;
