import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useScrollContextRef } from './useScrollContextRef';

export const withScrollToTopOnBack = (WrappedComponent) => (props) => {
  const history = useHistory();
  const ref = useScrollContextRef();

  // Scroll container to top of page when using back/forward browser buttons
  useEffect(() => {
    return history.block((_, action) => {
      if (action === 'POP' && ref.current != null) {
        ref.current.scrollTo(0, 0);
      }

      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return <WrappedComponent {...props} />;
};
