import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Typography from '../../components/overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import { VENUE_ATMOSPHERE_TITLE, VENUE_GREAT_FOR_LABEL } from '../../assets/copy';
import { iconLib } from '../../utils/constants/VenueLabels';
import { useFeatureFlags } from '../../utils/useFeatureFlags';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 4),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 0, 4, 7),
      width: '100%',
    },
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      alignItems: 'flex-start',
    },
  },
  subTitle: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.secondary[80],
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(1),
    },
    fontSize: '16px',
    lineHeight: '24px',
  },
  description: {
    color: theme.palette.secondary[80],
    fontSize: '14px',
    lineHeight: '20px',
  },
  atmospheres: {
    margin: 0,
  },
  labelsContainer: {
    marginTop: theme.spacing(3)
  },
  labels: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  labelImageContainer: {
    '& img': {
      transform: 'translateY(15%) !important',
      filter: 'brightness(0%) !important',
      width: '16px',
      height: '16px',
    }
  },
  labelTextContainer: {
    marginLeft: theme.spacing(1)
  },

}));

const widthBreakpoint = 960;


const Details = ({ venue }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const [labels] = useState(venue?.labels?.filter(label => iconLib[label]));
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();

  return (
    <Box className={classes.root}>
      {venue.atmospheres.items.length > 0 && (
      <Grid container className={classes.container}>
        <Grid item xs={12} xl={6}>
          <Typography
            variant={
              width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
            }
            className={clsx(classes.subTitle)}
            component="h2">
            {VENUE_ATMOSPHERE_TITLE}
          </Typography>
          <Grid item container spacing={3} className={classes.atmospheres}>
            {venue.atmospheres.items.length > 0 &&
              venue.atmospheres.items.map((item, index) => (
                <>
                  <Typography
                    variant="medium1Normal"
                    className={classes.description}
                    key={`atmosphere-${item.atmosphere.id}`}>
                    <>
                      {item.atmosphere.name}
                      {index < venue.atmospheres.items.length - 1 && (
                        <span>,&nbsp;</span>
                      )}
                    </>
                  </Typography>
                </>
              ))}
          </Grid>
        </Grid>
      </Grid>
      )}
      {refreshLandingPageFlagIsEnabled && labels?.length > 0 && (
      <Grid container className={clsx(classes.container, classes.labelsContainer)}>
        <Grid item xs={12} xl={6}>
          <Typography
            variant={
              width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
            }
            className={clsx(classes.subTitle)}
            component="h2">
            {VENUE_GREAT_FOR_LABEL}
          </Typography>
          <Grid item container >
            {
              labels.map((label, index) => (
                  <Box
                    variant="medium1Normal"
                    className={classes.labels}
                    key={`label-${label}-${index}`}>
                      <Box className={classes.labelImageContainer}>
                        {iconLib[label]?.image} 
                      </Box>
                      <Box>
                      <Typography
                      variant="medium1Normal"
                      className={clsx(classes.description, classes.labelTextContainer)}
                      >
                        {iconLib[label]?.name}
                    </Typography>
                    </Box>
                  </Box>
              ))}
          </Grid>
        </Grid>
      </Grid>
      )}
    </Box>
  );
};

export default Details;
