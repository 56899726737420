import React, { useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Typography from '../../overrides/Typography';
import SelectText from '../../core/SelectText';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0, 1),
  },
  fieldset: {
    border: 'none',
    paddingInline: 'unset',
    paddingBlock: 'unset',
    margin: 'none'
  },
  legend: {
    paddingBottom: 'none',
    paddingBlock: 'unset',
    paddingInline: 'unset',
  },
}));

export const DietaryPreferences = ({
  setRestrictions,
  text,
  itemMap,
  list,
  style,
  onClick,
}) => {
  const classes = useStyles();
  const handleSelections = useCallback(
    (item) => {
      const filter = list.filter((selection) => selection.id !== item.id);
      if (filter.length === list.length) {
        onClick?.(item, 'selected');
        setRestrictions((prevState) => [...prevState, item]);
      } else {
        onClick?.(item, 'deselected');
        setRestrictions(filter);
      }
    },
    [list, setRestrictions, onClick]
  );

  return (
    <>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          <Typography variant="small4Normal">{text}</Typography>
        </legend>
        <Box className={classes.root}>
          {itemMap.map((item) => (
            <SelectText
              role="listbox"
              key={`key-${item.id}`}
              item={item}
              list={list}
              aria-multiselectable="true"
              onClick={() => handleSelections(item)}
            />
          ))}
        </Box>
      </fieldset>
    </>
  );
};
