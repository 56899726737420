import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cn from 'clsx';

import Typography from '../../overrides/Typography';
import CloseSvg from '../../svg/Close';

const getVariant = Object.freeze({
  small: 'small4Semibold',
  medium: 'medium3Semibold',
  large: 'medium1Semibold',
});

const getWidth = {
  small: 71,
  medium: 80,
  large: 105,
};

const getHeight = {
  small: 24,
  medium: 32,
  large: 40,
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.white,
    border: `1px solid ${(props) =>
      props.outlined ? theme.palette.secondary.main : theme.palette.gray[5]}`,
    borderRadius: (props) =>
      props.radius === 'card' ? theme.spacing(0.5) : theme.spacing(2),
    width: (props) => (props.type === 'chips' ? getWidth[props.size] : null),
    height: (props) => (props.type === 'chips' ? getHeight[props.size] : null),
  },
  item: {
    color: theme.palette.chip.default,
    margin: theme.spacing(0.5, 2),
  },
  startAdornment: {
    padding: theme.spacing(0, 0, 0, 2),
  },
}));

const Chips = ({
  text,
  size,
  outlined,
  radius,
  rootStyle,
  itemStyle,
  onClick,
  type,
  startAdornment,
  endAdornment,
}) => {
  const classes = useStyles({ text, size, outlined, radius, type });
  // size : 'small', 'medium', 'large', 'small4Semibold',  'small2Semibold', 'medium1Semibold'
  // radius : 'card', 'chip' '4px', '16px'
  // 'outlined'
  // 'startAdornment', 'endAdornment'
  return (
    <Box
      className={cn(classes.root, rootStyle ? rootStyle : null)}
      onClick={onClick}>
      {startAdornment && (
        <img src={startAdornment} alt="" className={classes.startAdornment} />
      )}
      <Typography
        variant={getVariant[size]}
        className={cn(classes.item, itemStyle ? itemStyle : null)}>
        {text}
      </Typography>
      {endAdornment && (
        <CloseSvg
          width={16}
          height={16}
          fill="#0D74AF"
          className={classes.close}
        />
      )}
    </Box>
  );
};

export default Chips;
