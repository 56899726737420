import React, { useEffect } from 'react';
import { makeStyles, Grid, CircularProgress, Box } from '@material-ui/core';

import LogoSvg from '../../assets/icons/logo-white.svg';
import OnboardingLoadingBg from '../../assets/images/onboardingLoadingBg.png';
import OnboardingLoadingBgLarge from '../../assets/images/onboardingLoadingBgLarge.png';
import Typography from '../../components/overrides/Typography';
import Divider from '../../components/overrides/Divider';
import Transition from '../../components/animation/Transition';
import {
  COMMON_ONBOARDING_LOADING_TITLE,
  COMMON_ONBOARDING_LOADING_SUBTITLE,
  PROGRESS_BAR_LABEL,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bgImg: {
    position: 'fixed',
    zIndex: -1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(to bottom, rgba(0,0,0,0), rgb(16, 37, 63, 1) 84%), url(${OnboardingLoadingBg}) no-repeat`,
    backgroundSize: 'cover',
    [theme.breakpoints.up('xl')]: {
      left: '50%',
      width: '50%',
      height: '100%',
      background: `linear-gradient(to left, rgba(0,0,0,0), rgb(16, 37, 63, 0.7) 40%, rgb(16, 37, 63, 1) 90%), url(${OnboardingLoadingBgLarge}) no-repeat`,
      backgroundSize: 'cover',
    },
  },
  content: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 360,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xs')]: {
      height: 300,
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      height: 360,
      padding: theme.spacing(0, 6),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
  },
  divider: {
    background: theme.palette.red[50],
  },
  subTitle: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4, 0),
    },
  },
  circle: {
    color: '#0276B1',
    margin: theme.spacing(3, 0, 0, 0),
  },
  logo: {
    position: 'fixed',
    left: theme.spacing(3),
    // calculate posY based on content height
    top: 'calc(100% - 360px - 48px)',
    [theme.breakpoints.up('xs')]: {
      top: 'calc(100% - 300px - 48px)',
    },
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(6),
      top: 'calc(100% - 360px - 48px)',
    },
  },
  logoAnimation: {
    '&-exit': {
      '&-active': {
        top: 12,
        transition: 'all 1.4s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
  },
  contentAnimation: {
    '&-exit': {
      opacity: 1,
      '&-active': {
        opacity: 0,
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
  },
}));

const OnboardingLoading = ({ active }) => {
  const classes = useStyles();

  useEffect(() => {
    document.body.style.backgroundColor = '#10253F';
  }, []);

  return (
    <Transition timeout={2000} active={active}>
      <Grid container alignContent="flex-end" className={classes.root}>
        <Transition
          timeout={2000}
          classNames={classes.logoAnimation}
          active={active}>
          <img src={LogoSvg} alt="logo" className={classes.logo} />
        </Transition>
        <Transition classNames={classes.contentAnimation} active={active}>
          <Box className={classes.bgImg} aria-live="assertive" />
          <Grid
            container
            direction="column"
            justify="flex-start"
            className={classes.content}>
            <Typography
              variant="medium2NormalFlex"
              className={classes.title}
              color="secondary"
              align="left">
              {COMMON_ONBOARDING_LOADING_TITLE}
            </Typography>
            <Divider className={classes.divider} />
            <Typography
              variant="medium1NormalFlex"
              className={classes.subTitle}
              color="secondary"
              align="left">
              {COMMON_ONBOARDING_LOADING_SUBTITLE}
            </Typography>
            <CircularProgress
              size={48}
              className={classes.circle}
              role="progressbar"
              aria-label={PROGRESS_BAR_LABEL}
              title="Loading"
              aria-valuenow={0}
            />
          </Grid>
        </Transition>
      </Grid>
    </Transition>
  );
};

export default OnboardingLoading;
