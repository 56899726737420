import { TIME_AVAILABILITY_TYPES } from '../../venueAvailability/timeAvailabilityTypes';

export const venueAvailabilityStyles = (theme) => ({
  noAvailabilityInfoContainer: {
    padding: theme.spacing(2, 3, 0),
    justifyContent: 'normal',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 0, 0, 7),
      justifyContent: 'normal',
    },
  },
  noAvailabilityInfoContent: {
    width: '100%',
    maxWidth: '100%',
  },
  divider: {
    width: '100%',
    margin: theme.spacing(4, 0, 0),
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% - 56px)',
      margin: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL)
          return theme.spacing(5, 7, 0);
      },
    },
  },
  availTime: {
    width: '100%',
    '& *::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
