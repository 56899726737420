import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      alignItems: 'center',
      columnGap: theme.spacing(4),
      display: 'flex',
      maxWidth: '100%',
      padding: 0,
    },
  },
}));

const SearchFormContainer = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.root, className)} {...props}>
      {children}
    </Container>
  );
};

export default SearchFormContainer;
