import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RESERVATIONS_TITLE } from '../../assets/copy';
import PageSection from '../../components/core/PageSection';
import Typography from '../../components/overrides/Typography';
import { RESERVATIONS_TYPE } from '../../components/reservations/constants';
import { mapToReservationsLink } from '../../components/reservations/helpers';
import PastReservations from '../../components/reservations/PastReservations';
import ReservationsTabPanel from '../../components/reservations/ReservationsTabPanel';
import ReservationsTabs from '../../components/reservations/ReservationsTabs';
import UpcomingReservations from '../../components/reservations/UpcomingReservations';
import CancelReserveModal from '../../components/reserve/CancelReserveModal';
import CommonLoading from '../../pages/common/CommonLoading';
import { actionReservationListByUserIdRequest } from '../../store/Reservations/ReservationsAction';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { partitionArray } from '../../utils/partitionArray';
import { isReservationUpcoming } from '../../utils/reservationFilters';
import { sortByDateAsc, sortByDateDesc } from '../../utils/sortList';
import { useAuth } from '../../utils/useAuth';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import { useViewport } from '../../utils/useViewport';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 0),
    },
  },
  title: {
    textAlign: 'center',
    color: theme.palette.blue[70],
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

const ReservationsList = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const { list, reservationListStatus } = useSelector(
    (state) => state.reservations
  );
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [pastReservations, setPastReservations] = useState([]);
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  const shouldOnboard = useOnboardingRedirect();

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // get userId and pass forward to ReservationsByUserId
    if (user.id) {
      dispatch(actionReservationListByUserIdRequest({ userId: user.id }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!location.search) {
      const link = mapToReservationsLink();
      history.replace(link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reservationListStatus?.success && list.length > 0) {
      const [upcoming, past] = partitionArray(list, isReservationUpcoming);
      setUpcomingReservations(upcoming);
      setPastReservations(past);
      sortByDateDesc(past);
      sortByDateAsc(upcoming);
    }
  }, [list, reservationListStatus]);

  const handleToggleReserveModal = (e) => {
    e.stopPropagation();
    setModalOpen((isOpen) => !isOpen);
  };

  const handleCancelReserve = () => {
    setModalOpen(false);
    history.push('/reserve/cancel/confirmed');
  };

  const goToPastReservation = useCallback(
    (reservation) =>
      history.push(`/reservations/${reservation.id}`, {
        reservationType: RESERVATIONS_TYPE.PAST,
      }),
    [history]
  );

  const goToUpcomingReservation = useCallback(
    (reservation) =>
      history.push(`/reservations/${reservation.id}`, {
        reservationType: RESERVATIONS_TYPE.UPCOMING,
      }),
    [history]
  );

  const isMounted = () =>
    user.session.isAuthenticated &&
    !shouldOnboard &&
    reservationListStatus?.success;

  return (
    <>
      <CommonLoading active={!isMounted()} embedded={true} />
      {isMounted() && !navigationMenuVisible && (
        <main>
          <PageSection className={classes.root}>
            <Box>
              <Typography
                variant={
                  width > WIDTH_BREAKPOINT ? 'large1Light' : 'medium3Light'
                }
                className={classes.title}
                component="h1"
                aria-live="polite">
                {RESERVATIONS_TITLE}
              </Typography>
            </Box>
            <Box>
              <ReservationsTabs />
              <ReservationsTabPanel>
                <UpcomingReservations
                  selectReservation={goToUpcomingReservation}
                  upcomingReservations={upcomingReservations}
                />
              </ReservationsTabPanel>
              <ReservationsTabPanel type={RESERVATIONS_TYPE.PAST}>
                <PastReservations
                  pastReservations={pastReservations}
                  selectReservation={goToPastReservation}
                />
              </ReservationsTabPanel>
            </Box>
            <CancelReserveModal
              modalOpen={modalOpen}
              handleCancelReserve={handleCancelReserve}
              handleToggleReserveModal={handleToggleReserveModal}
            />
          </PageSection>
        </main>
      )}
    </>
  );
};

export default ReservationsList;
