import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getCookie, setCookie, removeCookie } from './cookies';

const CANARY_UUID_COOKIE_KEY = 'CANARY_UUID';
const C1_DOMAIN = '.capitalone.com';

const getCanaryCookie = () => getCookie(CANARY_UUID_COOKIE_KEY);

const setCanaryCookie = (uuid) => {
  const cookieConfig = {
    domain: process.env.REACT_APP_C1_COOKIE_DOMAIN || C1_DOMAIN,
    maxAge: 400 * 24 * 60 * 60, // maximum possible age for a cookie is 400 days
    secure: true,
  };

  if (window.location.hostname === 'localhost') {
    // For localhost, the domain and secure flag on the cookie must not be set
    delete cookieConfig.domain;
    delete cookieConfig.secure;
  }
  setCookie(CANARY_UUID_COOKIE_KEY, uuid, cookieConfig);
};

const removeCanaryCookie = () => {
  if (window.location.hostname === 'localhost') {
    removeCookie(CANARY_UUID_COOKIE_KEY);
  } else {
    removeCookie(CANARY_UUID_COOKIE_KEY, {
      domain: process.env.REACT_APP_C1_COOKIE_DOMAIN || C1_DOMAIN,
    });
  }
};

export const useCanaryRedirect = () => {
  const config = useSelector((state) => state.config);
  const location = useLocation();

  useEffect(() => {
    if (!config.isLoaded) return;

    const { isCanary, canaryURL, canaryUUID } = config;

    if (canaryURL == null || canaryUUID == null) return;

    const canaryCookie = getCanaryCookie();

    if (isCanary) {
      if (canaryCookie !== canaryUUID) {
        setCanaryCookie(canaryUUID);
      }
    } else {
      // if on active env and canaryCookie matches canaryUUID, redirect to canary
      if (canaryCookie === canaryUUID) {
        window.location = encodeURI(`${canaryURL}${location.search}`);
      } else {
        removeCanaryCookie();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.isLoaded]);
};
