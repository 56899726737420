import { Set } from 'immutable';

/**
 * @param {Object.<string, {id: string, name: string}[]>} filters
 * @param {Object[]} results array of venues to filter on
 * @returns
 */
const filterResults = (filters, results) => {
  if (!results) return [];

  // venue has a field (categories / cuisines / etc) with an `items` field in it with an array of objects
  // each of those objects has one field (category / cuisine / etc) with an object
  // each of those objects has an `id` field
  // so if filterItems is empty the venue passes the filter
  // if it's not empty, make sure that the venue has one of the items we're looking for
  const venueHasOneOfDesired = (venue, filterItems, itemsField, itemField) => {
    const wantedItemIds = new Set(filterItems.map(({ id }) => id));
    if (wantedItemIds.size === 0) return true;
    if (!venue[itemsField]?.items) return false;

    const venueContainedIds = () =>
      venue[itemsField].items.map((item) => item[itemField].id);
    return venueContainedIds().some((id) => wantedItemIds.has(id));
  };

  // pull an array of wanted items from the filters object, and if it exists then check to make sure the venue
  // passes the filter. if it doesn't exist then the venue passes by default because we're not filtering on this type
  const venueHasOneOfDesiredIfFiltered =
    (filterField, itemsField, itemField) => (venue) => {
      const filterItems = filters[filterField];
      return filterItems
        ? venueHasOneOfDesired(venue, filterItems, itemsField, itemField)
        : true;
    };

  // this one's a little different because the price range id is 1/2/3/4, but name is `$`, `$$`, etc, and the venue
  // has the field with the dollar signs
  const passesPriceRange = (venue) => {
    const wantedPrices = filters.priceRange;
    return wantedPrices
      ? !wantedPrices.length ||
          wantedPrices.map(({ name }) => name).includes(venue.priceRange)
      : true;
  };
  const passesCategories = venueHasOneOfDesiredIfFiltered(
    'categories',
    'categories',
    'category'
  );

  // so accolades are fun
  // accolades are kind of like categories of accolades, and they have individual different awards or tags within them
  // so for instance the michelin star accolade can have eg 1 star, 2 star, etc.
  // from the main page (at least), individual awards are listed, and clicking one searches for venues that got that
  //  specific award
  // from the filters editor, whole categories are listed, and selecting one should pull up all venues that has any
  //  associated award/tag
  // so, if we have accolades in this set of filters that we're searching for, go through it once and create a set
  //  of all the award / tag IDs that we want to find. then for every individual venue, we can see if any of its
  //  awards or tags are contained in the set that we built once.
  const createAccoladeAwardAndTagIds = () => {
    // so either we have an accolade category with multiple awards/tags, or we have an individual item
    // currently the only way (I believe) to get an individual item is clicking from the Explore Accolades section
    //  which makes sure there's a "type: accolade" field on it. if that isn't there return an empty array, but
    //  this would need updating if there's some other thing that can happen
    const idsFromAccolade = (accolade) =>
      'awards' in accolade && 'tags' in accolade
        ? accolade.awards.items
            .map((item) => item.awardId)
            .concat(accolade.tags.items.map((item) => item.tagId))
        : accolade.type === 'accolade'
        ? [accolade.id]
        : [];

    return Set(
      filters.accolades?.reduce(
        (acc, accolade) => acc.concat(idsFromAccolade(accolade)),
        []
      ) ?? []
    );
  };
  const allWantedAccoladeIds = createAccoladeAwardAndTagIds();
  const passesAccolades = (venue) => {
    if (allWantedAccoladeIds.isEmpty()) return true;
    else {
      const awardIds = venue.awards.items.map((item) => item.award.id);
      const tagIds = venue.tags.items.map((item) => item.tag.id);
      const venueItems = Set(awardIds.concat(tagIds));
      return !venueItems.intersect(allWantedAccoladeIds).isEmpty();
    }
  };

  const passesCuisines = venueHasOneOfDesiredIfFiltered(
    'cuisines',
    'cuisines',
    'cuisine'
  );
  const passesAtmospheres = venueHasOneOfDesiredIfFiltered(
    'atmospheres',
    'atmospheres',
    'atmosphere'
  );

  const passesVenueTypes = (venue) => {
    const venueTypeFilters = filters.venueType;
    if (venueTypeFilters?.length) {
      const wantedVenueTypes = new Set(
        venueTypeFilters.map(({ venueType }) => venueType)
      );
      return venue.venueType && wantedVenueTypes.has(venue.venueType);
    } else return true;
  };

  const passesNeighborhoods = (venue) => {
    const wantedNeighborhoods = filters.neighborhoods;
    if (wantedNeighborhoods?.length) {
      const wantedIds = new Set(wantedNeighborhoods.map(({ id }) => id));
      return venue.neighborhoodId && wantedIds.has(venue.neighborhoodId);
    } else return true;
  };

  /**
   * Determines whether a venue passes the occasions filter
   * @param {Object} venue venue to check
   * @param {string[] | null} venue.labels snake_case strings which correspond to occasions, e.g., ['date_night', 'brunch']
   * @returns {boolean} whether the venue passes the occasions filter
   */
  const passesOccasions = (venue) => {
    const wantedOccasionLabels = new Set(
      filters.occasions.map((occasion) => occasion.id)
    );

    if (wantedOccasionLabels.size) {
      if (!venue.labels) return false;
      return venue.labels.some((label) => wantedOccasionLabels.has(label));
    } else return true;
  };

  // check for all of the possible filters. each one will short-circuit quickly with true if we're not filtering by
  // that type. first false returned will short-circuit the statement and not run the rest
  const venueFilter = (venue) =>
    passesPriceRange(venue) &&
    passesCategories(venue) &&
    passesAccolades(venue) &&
    passesCuisines(venue) &&
    passesAtmospheres(venue) &&
    passesNeighborhoods(venue) &&
    passesVenueTypes(venue) &&
    passesOccasions(venue);

  return results.filter(venueFilter);
};

export default filterResults;
