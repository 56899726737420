import React from 'react';
import { makeStyles, TextareaAutosize } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '../../overrides/Typography';
import ScreenReaderText from '../../core/ScreenReaderText';
import { generateScreenReaderText } from '../../../utils/stringHelperMethods';
import {
  SPECIAL_REQUESTS_OUT_OF,
  SPECIAL_REQUESTS_CHARACTERS_USED,
} from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {},
  textarea: {
    border: '1px solid #A8A8A8',
    borderRadius: '5px',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    minHeight: 50,
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}));

export const TextArea = ({
  classes: classesOverride,
  value,
  onChange,
  label,
  placeholderText,
  maxLength,
}) => {
  const classes = useStyles({ classes: classesOverride });
  return (
    <div className={classes.root}>
      {label && (
        <div className={classes.flexRow}>
          <Typography>{label}</Typography>
          <ScreenReaderText
            variant="small2Normal"
            className={classes.label}
            gutterBottom
            role="status"
            aria-live="polite"
            aria-atomic="true">
            {generateScreenReaderText(
              ' ',
              value.length,
              SPECIAL_REQUESTS_OUT_OF,
              maxLength,
              SPECIAL_REQUESTS_CHARACTERS_USED
            )}
          </ScreenReaderText>
          <Typography
            variant="small2Normal"
            className={classes.label}
            gutterBottom
            aria-hidden="true">
            {value.length}/{maxLength}
          </Typography>
        </div>
      )}
      <TextareaAutosize
        placeholder={placeholderText}
        value={value}
        maxLength={maxLength}
        className={classes.textarea}
        onChange={onChange}
      />
    </div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  maxLength: PropTypes.number,
  classes: PropTypes.object,
};

export default TextArea;
