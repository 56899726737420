import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  makeStyles,
  CardActionArea,
  ButtonBase,
} from '@material-ui/core';
import Typography from '../../overrides/Typography';
import { removeHtmlTags } from '../../../utils/stringHelperMethods';
import Badge from '../Badge';
import {
  displayCardEventDates,
  displayCardEventTimes,
  displayLocationText,
  joinText,
} from '../../../utils/eventHelpers';
import { useHistory } from 'react-router';
import {
  trackClickWithDescription,
  trackPageImpression,
} from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    width: 356,
    height: 320,
    borderRadius: 6,
    border: 'none',
    backgroundColor: theme.palette.blue[70],
  },
  content: {
    color: theme.palette.text.secondary,
  },
  eventDateText: {
    letterSpacing: 0.5,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  eventNameText: {
    fontWeight: 300,
    lineHeight: '28px',
    padding: theme.spacing(1, 0),
    fontStyle: 'normal',
    color: theme.palette.text.secondary,
    'overflow': 'hidden',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'width': '100%',
    'text-align': 'left',
  },
  eventDescriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: theme.palette.text.secondary,
  },
}));

const EventCard = ({ item, classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride });
  const relatedVenues = item?.relatedVenues?.items;
  const locationText = displayLocationText(item, relatedVenues);
  const history = useHistory();
  const redirectToVenueDetailsPage = () => {
    trackClickWithDescription('event-card', 'Button', item?.name);
    history.push(`/venue/${item?.id}`);
  };

  useEffect(() => {
    if (item.name) {
      trackPageImpression('events', 'event-card', item.name);
    }
  }, [item?.name]);

  return (
    <Card
      className={classes.root}
      variant="outlined"
      onClick={redirectToVenueDetailsPage}>
      <CardActionArea
        className={classes.actionArea}
        tabIndex="-1"
        disableRipple>
        <Box
          position="absolute"
          top="16px"
          left="16px"
          overflow="hidden"
          borderRadius={4}>
          {locationText && <Badge text={locationText} type="eventCard" />}
        </Box>
        <CardMedia
          component="img"
          height="182"
          image={item?.imagesFull[0]}
          alt=""></CardMedia>
        <CardContent className={classes.content} component="div">
          <Box component="div" minHeight={18}>
            <Typography
              className={classes.eventDateText}
              variant="small3Normal">
              {joinText(
                [
                  displayCardEventDates(item.eventDates),
                  displayCardEventTimes(item.eventTimes),
                ],
                ' | '
              )}
            </Typography>
          </Box>
          <Box component="div" minHeight={52}>
            <ButtonBase
              style={{width: "100%"}}
              focusRipple
              tabIndex="0"
              aria-label={item.name}
              onKeyPress={redirectToVenueDetailsPage}>
              <Typography
                className={classes.eventNameText}
                variant="medium2Normal">
                {item.name}
              </Typography>
            </ButtonBase>
          </Box>
          <Box component="div" minHeight={36}>
            <Typography
              variant="small3Normal"
              className={classes.eventDescriptionText}>
              {removeHtmlTags(item.description.replaceAll(/((<br>)|(<br\/>))/g, ' '))}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default EventCard;
