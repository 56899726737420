import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import WelcomeItem from '../../components/onboarding/WelcomeItem';
import StepBackground from '../../components/onboarding/StepBackground';
import StepContent from '../../components/onboarding/StepContent';
import StepFooter from '../../components/onboarding/StepFooter';
import StepHeader from '../../components/onboarding/StepHeader';
import BulletOneSvg from '../../assets/icons/dining-white.svg';
import BulletTwoSvg from '../../assets/icons/redeem-rewards.svg';
import BulletThreeSvg from '../../assets/icons/phone-checked-white.svg';
import OnboardingWelcomeBg from '../../assets/images/onboardingWelcomeBg.png';
import OnboardingWelcomeBgLarge from '../../assets/images/onboardingWelcomeBgLarge.png';
import Typography from '../../components/overrides/Typography';
import Transition from '../../components/animation/Transition';
import {
  ONBOARDING_BULLET_ONE_TEXT,
  ONBOARDING_BULLET_TWO_TEXT,
  ONBOARDING_BULLET_THREE_TEXT,
  ONBOARDING_WELCOME_SUBTITLE,
  ONBOARDING_WELCOME_TITLE_PREFIX,
  ONBOARDING_WELCOME_TITLE_SUFFIX,
  NEXT_BUTTON_TEXT,
} from '../../assets/copy';
import { onboardingBaseStyles } from './OnboardingStyles';

const useStyles = makeStyles((theme) => ({
  ...onboardingBaseStyles(theme),
  bgImg: {
    background: `url(${OnboardingWelcomeBg}) no-repeat`,
    backgroundSize: 'cover',
    zIndex: -2,
    [theme.breakpoints.up('xl')]: {
      background: `url(${OnboardingWelcomeBgLarge}) no-repeat`,
      backgroundSize: 'cover',
    },
  },
  scrollable: {
    overflowY: 'scroll',
  },
  wrapper: {
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 4, 9.5, 6),
    },
  },
  subTitle: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
  diningBenefits: {
    height: 360,
    [theme.breakpoints.up('xl')]: {
      height: 340,
    },
  },
  list: {
    paddingBottom: theme.spacing(1),
  },
  slideAnimation: {
    '&-enter': {
      opacity: 0,
      transform: 'translateY(200px)',
      '&-active': {
        opacity: 1,
        transform: 'translateY(0px)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    '&-exit': {
      transform: 'translateX(0vw)',
      '&-active': {
        opacity: 0,
        transform: 'translateX(-100vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    [theme.breakpoints.up('xl')]: {
      '&-exit': {
        '&-active': {
          transform: 'translateX(-50vw)',
        },
      },
    },
  },
  slowSlideOutAnimation: {
    '&-enter': {
      opacity: 0,
      transform: 'translateY(200px)',
      '&-active': {
        opacity: 1,
        transform: 'translateY(0px)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    '&-exit': {
      '&-active': {
        opacity: 0,
        transform: 'translateX(-50vw)',
        transition: 'all 1s cubic-bezier(0.33, 1, 0.77, 0.74)',
      },
    },
  },
}));

const Welcome = ({ handleNext, active, totalSteps, activeStep }) => {
  const classes = useStyles();
  const [scrollable, setScrollable] = useState(false);
  const contents = [
    {
      icon: BulletOneSvg,
      content: ONBOARDING_BULLET_ONE_TEXT,
    },
    {
      icon: BulletTwoSvg,
      content: ONBOARDING_BULLET_TWO_TEXT,
    },
    {
      icon: BulletThreeSvg,
      content: ONBOARDING_BULLET_THREE_TEXT,
    },
  ];
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    // add scroll to wrapper after enter animation to avoid animation clipping
    setTimeout(() => {
      setScrollable(true);
    }, 3000);
  }, []);

  return (
    <Box className={classes.root}>
      <StepBackground
        active={active}
        backgroundStyles={classes.bgImg}
        slideAnimation={classes.slowSlideOutAnimation}
      />
      <StepContent>
        <Box
          tabIndex={0}
          className={clsx(
            classes.contentWrapper,
            classes.wrapper,
            scrollable && classes.scrollable
          )}>
          <Transition
            delay={1400}
            classNames={classes.slideAnimation}
            active={active}>
            <Box mb={1}>
              <StepHeader
                title={`${ONBOARDING_WELCOME_TITLE_PREFIX}${user.firstName}${ONBOARDING_WELCOME_TITLE_SUFFIX}`}
                redact={true}
              />
            </Box>
          </Transition>
          <Box className={classes.diningBenefits}>
            <Transition
              delay={1600}
              classNames={classes.slideAnimation}
              active={active}>
              <Typography
                variant="medium2NormalFlex"
                color="secondary"
                component="h2"
                className={classes.subTitle}>
                {ONBOARDING_WELCOME_SUBTITLE}
              </Typography>
            </Transition>
            <List className={classes.list}>
              {contents.map((item, index) => (
                <Transition
                  delay={1600 + (index + 1) * 120}
                  classNames={classes.slideAnimation}
                  active={active}>
                  <WelcomeItem item={item} key={index} />
                </Transition>
              ))}
            </List>
          </Box>
        </Box>
        <Transition
          delay={2000}
          classNames={classes.slideAnimation}
          active={active}>
          <StepFooter
            activeStep={++activeStep}
            onClick={handleNext}
            text={NEXT_BUTTON_TEXT}
            totalSteps={totalSteps}
          />
        </Transition>
      </StepContent>
    </Box>
  );
};

export default Welcome;
