import * as TYPES from './HomePageTypes';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';

const initialState = {
  homePage: null,
  getHomePageStatus: {
    error: null,
    isLoading: null,
  },
};

export default function homePageReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case TYPES.GET_HOME_PAGE_REQUEST: {
      return {
        ...state,
        getHomePageStatus: {
          error: null,
          isLoading: payload.forceCall,
        },
      };
    }
    case TYPES.GET_HOME_PAGE_SUCCESS: {
      return {
        ...state,
        getHomePageStatus: {
          error: null,
          isLoading: false,
        },
        homePage: payload,
      };
    }
    case TYPES.GET_HOME_PAGE_FAIL: {
      return {
        ...state,
        getHomePageStatus: {
          error: payload,
          isLoading: false,
        },
      };
    }
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
