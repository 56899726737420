import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { BREAKPOINT_VALUES } from '../utils/constants/Breakpoints';

export const breakpoints = createBreakpoints({
  values: { ...BREAKPOINT_VALUES },
  keys: Object.keys(BREAKPOINT_VALUES),
});

export const applyFontBreakpoints = (theme) => {
  theme.typography.small2SemiboldFlex = {
    ...theme.typography.small2Semibold,
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
      lineHeight: '28px',
    },
  };
  theme.typography.small3SemiboldFlex = {
    ...theme.typography.small3SemiboldFlex,
    fontSize: 10,
    lineHeight: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  };
  theme.typography.medium1NormalFlex = {
    ...theme.typography.medium1NormalFlex,
    fontSize: 14,
    lineHeight: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
      lineHeight: '30px',
    },
  };
  theme.typography.medium2NormalFlex = {
    ...theme.typography.medium2NormalFlex,
    fontSize: 16,
    lineHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      lineHeight: '30px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 24,
      lineHeight: '36px',
    },
  };
  theme.typography.medium2NormalButtonFlex = {
    ...theme.typography.medium2NormalButtonFlex,
    fontSize: 16,
    lineHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      lineHeight: '30px',
    },
  };
  theme.typography.medium3LightFlex = {
    ...theme.typography.medium3LightFlex,
    fontSize: 16,
    lineHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
      lineHeight: '36px',
    },
  };
  theme.typography.large1LightFlex = {
    ...theme.typography.large1LightFlex,
    fontSize: 24,
    lineHeight: '32px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
      lineHeight: '42px',
    },
  };
};
