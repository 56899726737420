import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '../../overrides/Typography';
import { Button } from '../Button';
import {
  RESERVATION_SHEET_PRICE_UNIT,
  SELECT_BUTTON_LABEL,
} from '../../../assets/copy';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: ({ isCompact }) => isCompact && 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inline: {
    display: 'inline',
  },
  button: {
    display: 'block',
    margin: ({ isCompact }) => (isCompact ? '' : theme.spacing(2.5, 0, 1)),
    width: ({ isCompact }) => (isCompact ? theme.spacing(10.5) : '100%'),
  },
  diningOptionName: {
    marginBottom: ({ isCompact }) => !isCompact && theme.spacing(0.5),
    letterSpacing: ({ isCompact }) => isCompact && '0.3px',
  },
}));

const currencyFormat = (amount) => {
  return amount?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const DiningOptionSelection = ({
  classes: classesOverride,
  isCompact,
  onConfirm,
}) => {
  const classes = useStyles({ classes: classesOverride, isCompact });
  const { draftReservation } = useSelector((state) => state.reservations);

  return (
    <Container className={classes.root}>
      <div>
        <Typography
          variant={isCompact ? 'small2Semibold' : 'medium2Semibold'}
          className={classes.diningOptionName}>
          {draftReservation.slotName}
        </Typography>
        {!!draftReservation.payment?.cost && (
          <>
            <Typography
              variant={isCompact ? 'small2Semibold' : 'medium2Semibold'}
              className={classes.inline}>
              {currencyFormat(draftReservation.payment?.cost)}
            </Typography>
            <Typography
              variant={isCompact ? 'small1Normal' : 'medium2Normal'}
              className={classes.inline}>
              {RESERVATION_SHEET_PRICE_UNIT}
            </Typography>
          </>
        )}
      </div>
      {onConfirm && (
        <Button classes={{ root: classes.button }} onClick={onConfirm}>
          {SELECT_BUTTON_LABEL}
        </Button>
      )}
    </Container>
  );
};

DiningOptionSelection.propTypes = {
  classes: PropTypes.object,
  isCompact: PropTypes.bool,
  onConfirm: PropTypes.func,
};

export default DiningOptionSelection;
