import { FILTER_TYPES } from './constants/FilterTypes';

export const getEmptyFilters = () =>
  Object.values(FILTER_TYPES).reduce(
    (filters, filter) => ({ ...filters, [filter]: [] }),
    {}
  );

export const getFiltersCount = (filters) =>
  Object.values(filters).reduce(
    (count, selectedFilters) => count + selectedFilters.length,
    0
  );

export const removeFilterItem = (currentFilters, { id, type }) => {
  const updatedFilters = { ...currentFilters };

  updatedFilters[type] =
    updatedFilters[type].filter((item) => item.id !== id) ?? [];

  return updatedFilters;
};

export const updateFilterByType = (updatedFilterType, updatedFilter) => {
  if (!(updatedFilter?.length > 0)) return [];

  return updatedFilter.map(
    ({ id, name, timestamp, awards, tags, venueType }) => ({
      id,
      name,
      timestamp: timestamp || new Date(),
      type: updatedFilterType,
      ...(updatedFilterType === FILTER_TYPES.ACCOLADES ? { awards, tags } : {}),
      ...(venueType && { venueType }),
    })
  );
};

export const updateFiltersByType = (updatedFilters) =>
  Object.values(FILTER_TYPES).reduce(
    (filters, filterType) => ({
      ...filters,
      [filterType]: updateFilterByType(filterType, updatedFilters[filterType]),
    }),
    {}
  );
