//Market list
export const MARKET_LIST_REQUEST = 'MARKET_LIST_REQUEST';
export const MARKET_LIST_SUCCESS = 'MARKET_LIST_SUCCESS';
export const MARKET_LIST_FAIL = 'MARKET_LIST_FAIL';

//get Market
export const GET_MARKET_REQUEST = 'GET_MARKET_REQUEST';
export const GET_MARKET_SUCCESS = 'GET_MARKET_SUCCESS';
export const GET_MARKET_FAIL = 'GET_MARKET_FAIL';

//marketId cache
export const CACHE_MARKET_ID = 'CACHE_MARKET_ID';
export const RESET_CACHED_MARKET_ID = 'RESET_CACHED_MARKET_ID';

export const UPDATE_MARKET_AVAILABILITY = 'UPDATE_MARKET_AVAILABILITY';
export const CLEAR_MARKET_AVAILABILITY = 'CLEAR_MARKET_AVAILABILITY';
export const NAVIGATE_TO_SEARCH = 'NAVIGATE_TO_SEARCH';
