import React from 'react';
import { List, makeStyles } from '@material-ui/core';
import { useListBox } from '@react-aria/listbox';
import SuggestedResultsOption from './SuggestedResultsOption';

const useStyles = makeStyles({
  listBox: {
    paddingBottom: 0,
    paddingTop: 0,
  },
});

const SuggestedResultsListBox = ({
  listBoxRef,
  searchQuery,
  state,
  ...props
}) => {
  const classes = useStyles();
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <List {...listBoxProps} ref={listBoxRef} className={classes.listBox}>
      {[...state.collection].map((item) => (
        <SuggestedResultsOption
          item={item}
          key={item.key}
          searchQuery={searchQuery}
          state={state}
        />
      ))}
    </List>
  );
};

export default SuggestedResultsListBox;
