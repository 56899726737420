import ContactInfo from './ContactInfo';
import Divider from '../../components/overrides/Divider';
import Information from './Information';
import DetailedLayout from './DetailedLayout';

// import { BOOKING_POLICY_LABEL } from '../../assets/copy'; /* Commented out per COD-1548 */
// import Policy from '../../components/core/Policy'; /* Commented out per COD-1548 */
// import { makeStyles } from '@material-ui/styles'; /* Commented out per COD-1548 */

/* Commented out per COD-1548 */
// const useStyles = makeStyles((theme) => ({
//   policy: {
//     padding: theme.spacing(3),
//     margin: 0,
//     [theme.breakpoints.up('xl')]: {
//       padding: theme.spacing(4, 7),
//       width: '100%',
//     },
//   },
// }));

const MobileVenueLayout = ({
  venue,
  navigationMenuVisible,
  handleClick,
  date,
  time,
  user,
  guests,
  list,
  loadingAvailability,
  hasRelatedEvents = false,
  eventList,
}) => {
  // const classes = useStyles(); /* Commented out per COD-1548 */

  return (
    !navigationMenuVisible && (
      <>
        <Information venue={venue} />
        <Divider />
        <DetailedLayout
          list={list}
          venue={venue}
          guests={guests}
          user={user}
          time={time}
          date={date}
          loadingAvailability={loadingAvailability}
          handleClick={handleClick}
          showPolicy={false}
          eventList={hasRelatedEvents && eventList}
        />
        <div>
          <ContactInfo venue={venue} />
        </div>
        <Divider />
        {/* Commented out per ticket COD-1548 */}
        {/*<Policy*/}
        {/*  header={BOOKING_POLICY_LABEL}*/}
        {/*  venuePolicy={venue?.bookingPolicy}*/}
        {/*  className={classes.policy}*/}
        {/*/>*/}
      </>
    )
  );
};

export default MobileVenueLayout;
