import React from 'react';

import { Box, Divider, makeStyles } from '@material-ui/core';
import { venueAvailabilityStyles } from './helpers/venueAvailabilityStyles';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import TimeAvailability from '../../components/core/TimesButton/TimeAvailability';
import NextAvailabilityList from '../venueAvailability/NextAvailabilityList';
import { useViewport } from '../../utils/useViewport';
import useEventFlag from '../../utils/useEventFlag';
import Typography from '../../components/overrides/Typography';
import { NO_TIMESLOT_FOR_NUMBER_OF_TICKET } from '../../assets/copy';
import { getFormattedShortDateWithWeekday } from '../../utils/dateTime';

const useStyles = makeStyles((theme) => ({
  ...venueAvailabilityStyles(theme),
  noAvailabilityContainer: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 7),
    },
  },
  dateContainer: {
    padding: theme.spacing(3, 3, 1),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 7, 1),
    },
  },
}));

const AvailabilityTimeslots = ({
  venue,
  date,
  time,
  hasAvailabilityForSelectedDay,
  hasAvailabilityForNext7Days,
  next7DaysAvailability,
  selectTimeSlot,
  selectedDateAvailabilities,
  loadedAvailability,
  action,
  onMount,
  showMore,
  showMoreLink = false,
  handleShowMore = null,
  guests,
  selectedDateString,
  type,
  renderHasNoAvailabilityForDateRangeMessage = null,
}) => {
  const classes = useStyles({ type: TIME_AVAILABILITY_TYPES.HORIZONTAL });
  const { width } = useViewport();
  const { venueHasEvent: isEvent } = useEventFlag(venue);
  const shouldShowEventAvailability =
    isEvent && selectedDateAvailabilities.length > 1;

  const renderEventAvailability = () => {
    return selectedDateAvailabilities.map((item, index) => {
      return item.timeslots.length > 0 ? (
        <>
          <TimeAvailability
            type={type}
            item={item}
            handleClick={selectTimeSlot}
            loader={loadedAvailability}
            propsAction={action}
            onMount={onMount}
          />
          <Divider className={classes.divider} />
        </>
      ) : (
        <>
          <Box pb={2} className={classes.dateContainer}>
            <Typography
              variant="medium1Semibold"
              className={classes.dateText}
              component="h3">
              {getFormattedShortDateWithWeekday(item?.date)}
            </Typography>
          </Box>
          <Box pb={2} className={classes.noAvailabilityContainer}>
            <Typography variant="medium1Normal" component="h3">
              {NO_TIMESLOT_FOR_NUMBER_OF_TICKET}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
        </>
      );
    });
  };

  return (
    <>
      {hasAvailabilityForSelectedDay ? (
        <Box className={classes.availTime}>
          {shouldShowEventAvailability ? (
            renderEventAvailability()
          ) : (
            <TimeAvailability
              type={type}
              item={selectedDateAvailabilities?.[0]}
              handleClick={selectTimeSlot}
              loader={loadedAvailability}
              propsAction={action}
              onMount={onMount}
            />
          )}
        </Box>
      ) : (
        renderHasNoAvailabilityForDateRangeMessage()
      )}
      {!hasAvailabilityForSelectedDay && hasAvailabilityForNext7Days && (
        <NextAvailabilityList
          hasAvailabilityForSelectedDay={hasAvailabilityForSelectedDay}
          hasAvailabilityForNext7Days={hasAvailabilityForNext7Days}
          list={next7DaysAvailability}
          width={width}
          venue={venue}
          submittedDate={date}
          submittedTime={time}
          showMore={showMore}
          showMoreLink={showMoreLink}
          handleShowMore={handleShowMore}
          selectedGuests={guests}
          selectedDateString={selectedDateString}
          type={type}
        />
      )}
    </>
  );
};

export default AvailabilityTimeslots;
