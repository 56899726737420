import Mustache from 'mustache';

import * as COPY from '../assets/copy';

export const useCopy = () => {
  const getCopy = (key, templateParameters = null) => {
    const template = COPY[key];

    if (template == null) {
      return '';
    }

    if (templateParameters != null) {
      return Mustache.render(template, templateParameters);
    }

    return template;
  };

  return {
    getCopy,
  };
};
