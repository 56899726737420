import { Box } from '@material-ui/core';
import Typography from '../../components/overrides/Typography';
import {
  CLOSE_BUTTON_ALT_TEXT,
  VENUE_AVAILABILITY_TITLE,
} from '../../assets/copy';
import CloseSvg from '../../components/svg/Close';
import React from 'react';
import {
  WIDTH_BREAKPOINT,
  WIDTH_LG_BREAKPOINT,
} from '../../utils/constants/Breakpoints';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    padding: theme.spacing(2, 3, 0),
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 0,
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  headerText: {
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '587px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
  },
  headerLg: {
    justifyContent: 'space-between',
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: '587px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  lgContainer: {
    width: '587px',
    justifyContent: 'center',
    marginLeft: 0,
  },
}));

const VenueHeader = ({ width }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
  };

  const onKeyPressHandler = (e) => e.key === 'Enter' && handleClose();
  const isBetweenLgAndXl = width > WIDTH_LG_BREAKPOINT && width <= 1072;

  return (
    <Box
      className={clsx(classes.header, isBetweenLgAndXl && classes.lgContainer)}>
      <Box
        className={clsx(
          classes.container,
          isBetweenLgAndXl && classes.lgContainer
        )}>
        <Box
          mb={2.5}
          className={clsx(
            classes.headerText,
            isBetweenLgAndXl && classes.headerTextLg
          )}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          aria-live="polite">
          <Typography
            component="h1"
            aria-live="polite"
            variant={width > WIDTH_BREAKPOINT ? 'large1Light' : 'medium3Light'}>
            {VENUE_AVAILABILITY_TITLE}
          </Typography>
          <CloseSvg
            width={width > WIDTH_BREAKPOINT ? 21 : 16}
            height={width > WIDTH_BREAKPOINT ? 21 : 16}
            fill="#0D74AF"
            className={classes.close}
            onClick={handleClose}
            onKeyPress={onKeyPressHandler}
            aria-label={CLOSE_BUTTON_ALT_TEXT}
            role="button"
            tabIndex={0}
            cursor="pointer"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VenueHeader;
