import React, { useState } from 'react';
import { Box, GridList } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import TimeAvailability from '../../components/core/TimesButton/TimeAvailability';
import { AVAILABLE_TIMES_ALT_TEXT } from '../../assets/copy';
import PlaceholderLoader from '../loading/PlaceholderLoader';
import { TIME_AVAILABILITY_TYPES } from '../../pages/venueAvailability/timeAvailabilityTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fadeIn: {
    transition: 'opacity 1s ease',
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity 1s ease',
  },
  noAvail: {
    margin: `0 ${theme.spacing(1)}`,
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    padding: theme.spacing(0, 3),
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      padding: 0,
      marginLeft: '0 !important',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const VenueResultAvailability = ({ availability, className, ...props }) => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(true);
  const onMount = () => {
    setShowLoader(false);
  };

  return (
    <Box
      aria-label={AVAILABLE_TIMES_ALT_TEXT}
      className={clsx(classes.root, className)}>
      {!availability ? (
        <GridList cellHeight={'auto'} className={classes.gridList}>
          <PlaceholderLoader length={7} />
        </GridList>
      ) : (
        <>
          {showLoader && (
            <GridList cellHeight={'auto'} className={classes.gridList}>
              <PlaceholderLoader length={7} />
            </GridList>
          )}
          <TimeAvailability
            type={TIME_AVAILABILITY_TYPES.HORIZONTAL_SEARCH}
            item={availability[0] || availability}
            onMount={onMount}
            {...props}
          />
        </>
      )}
    </Box>
  );
};

export default VenueResultAvailability;
