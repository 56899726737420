import { makeStyles } from '@material-ui/styles';
import InfoBlock from '../../components/core/InfoBlock';
import {
  PROFILE_PAYMENT_METHOD_INFO,
  RESERVE_MULTI_CARDS_ACTION_MESSAGE,
  RESERVE_MULTI_CARDS_PAYMENT_METHOD_INFO,
  RESERVE_PAYMENT_METHOD_INFO,
  INFORMATION_BANNER,
} from '../../assets/copy';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';
import { generateScreenReaderText } from '../../utils/stringHelperMethods';

const useStyles = makeStyles((theme) => ({
  alert: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
    '& > :first-child': {
      padding: 0,
      margin: 0,
    },
  },
  infoContainer: {
    [theme.breakpoints.up('xl')]: {
      margin: ({ type, isSingleCardUser }) => {
        if (type !== PAYMENT_METHOD_COMPONENT_TYPES.PROFILE)
          return theme.spacing(2, 0, 1);
        if (isSingleCardUser) return theme.spacing(0.5, 0, 3);
        return theme.spacing(1, 0);
      },
    },
    margin: ({ type, isSingleCardUser, defaultAccount }) => {
      if (type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE && isSingleCardUser)
        return theme.spacing(0, 0, 2);
      if (type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE && !isSingleCardUser)
        return theme.spacing(1, 0);
      if (type !== PAYMENT_METHOD_COMPONENT_TYPES.PROFILE && defaultAccount)
        return theme.spacing(1, 0, 3);
      return theme.spacing(1, 0, 1);
    },
    padding: 0,
    alignItems: 'flex-start',
  },
  policyText: {
    order: 'inherit',
    margin: theme.spacing(2, 0),
  },
  closeButton: {
    margin: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  actionText: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const PaymentCardInfoBlock = ({
  onEdit,
  type,
  isSingleCardUser,
  defaultAccount,
  shouldHideUserPaymentMessage,
  handleSaveUserHidePaymentAlert,
}) => {
  const classes = useStyles({ type, isSingleCardUser, defaultAccount });

  // Info block for single card user
  if (!shouldHideUserPaymentMessage && isSingleCardUser) {
    return (
      <InfoBlock
        showCloseButton={true}
        containerClassName={classes.infoContainer}
        alertClassName={classes.alert}
        policyTextClassName={classes.policyText}
        closeButtonClassName={classes.closeButton}
        closeButtonHandler={handleSaveUserHidePaymentAlert}
        text={
          type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE
            ? PROFILE_PAYMENT_METHOD_INFO
            : RESERVE_PAYMENT_METHOD_INFO
        }
      />
    );
  }

  // Info block for multi-cards user on reserve page
  if (
    !shouldHideUserPaymentMessage &&
    !isSingleCardUser &&
    type === PAYMENT_METHOD_COMPONENT_TYPES.RESERVE
  ) {
    return (
      <InfoBlock
        showCloseButton={true}
        containerClassName={classes.infoContainer}
        alertClassName={classes.alert}
        policyTextClassName={classes.policyText}
        closeButtonClassName={classes.closeButton}
        closeButtonHandler={handleSaveUserHidePaymentAlert}
        text={RESERVE_MULTI_CARDS_PAYMENT_METHOD_INFO}
      />
    );
  }

  // Info block for multi-cards user on profile page
  if (
    type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE &&
    !defaultAccount &&
    !isSingleCardUser
  ) {
    return (
      <InfoBlock
        showCloseButton={false}
        containerClassName={classes.infoContainer}
        alertClassName={classes.alert}
        policyTextClassName={classes.policyText}
        closeButtonHandler={handleSaveUserHidePaymentAlert}
        children={
          <div>
            {RESERVE_MULTI_CARDS_PAYMENT_METHOD_INFO}
            <span
              onClick={onEdit}
              onKeyPress={(e) => e.key === 'Enter' && onEdit()}
              className={classes.actionText}
              role="link"
              tabIndex={0}>
              {RESERVE_MULTI_CARDS_ACTION_MESSAGE}
            </span>
          </div>
        }
        screenReaderText={generateScreenReaderText(
          ',',
          INFORMATION_BANNER,
          RESERVE_MULTI_CARDS_PAYMENT_METHOD_INFO,
          RESERVE_MULTI_CARDS_ACTION_MESSAGE
        )}
      />
    );
  }

  return null;
};

export default PaymentCardInfoBlock;
