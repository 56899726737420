import * as types from './FeatureToggleTypes';

/**
 * Creates dispatch object for when feature toggles are requested
 * @returns action object
 */
export const actionGetFeatureTogglesRequest = () => ({
  type: types.GET_FEATURE_TOGGLES_REQUEST,
});

/**
 * Creates dispatch object for when feature toggles are successfully retrieved
 * @param {Map<string, boolean>} payload - map of feature toggles
 * @returns action object
 */
export const actionGetFeatureTogglesSuccess = (payload) => ({
  type: types.GET_FEATURE_TOGGLES_SUCCESS,
  payload,
});

/**
 * Creates dispatch object for when fetching feature toggles results in an error
 * @param {string | undefined} payload - error message
 * @returns action object
 */
export const actionGetFeatureTogglesFailure = (payload) => ({
  type: types.GET_FEATURE_TOGGLES_FAILURE,
  payload,
});
