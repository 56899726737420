import * as TYPES from './ErrorTypes';

export const actionSetErrorType = (payload) => ({
  type: TYPES.SET_ERROR_TYPE,
  payload,
});

export const actionClearErrorType = (payload) => ({
  type: TYPES.CLEAR_ERROR_TYPE,
  payload,
});

export const actionResetErrorState = () => ({
  type: TYPES.RESET_ERROR_STATE,
});
