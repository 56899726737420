import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useComboBox } from '@react-aria/combobox';
import { mergeProps } from '@react-aria/utils';
import { useComboBoxState } from '@react-stately/combobox';
import { useSelector } from 'react-redux';
import SearchControlContainer from './SearchControlContainer';
import SearchInputBase from './SearchInputBase';
import SuggestedResultsListBox from './SuggestedResults/SuggestedResultsListBox';
import SuggestedResultsPopover from './SuggestedResults/SuggestedResultsPopover';

const useStyles = makeStyles({
  container: {
    zIndex: 10,
  },
});

const SearchComboBox = ({
  autoFocus = true,
  customListBoxProps = {},
  handleChange,
  handleSearch,
  handleReset,
  ...otherProps
}) => {
  const classes = useStyles();
  const { currentSearchInputValue } = useSelector((state) => state.search);
  const comboBoxState = useComboBoxState({ ...otherProps });
  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);

  const {
    inputProps: generatedInputProps,
    listBoxProps: generatedListBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...otherProps,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    comboBoxState
  );

  return (
    <SearchControlContainer className={classes.container}>
      <SearchInputBase
        autoFocus={autoFocus}
        handleChange={handleChange}
        handleReset={handleReset}
        handleSearch={handleSearch}
        id={generatedInputProps?.id}
        inputProps={generatedInputProps}
        inputRef={inputRef}
        labelProps={labelProps}
        value={currentSearchInputValue}
      />
      {comboBoxState?.isOpen && (
        <SuggestedResultsPopover
          aria-label="suggestedResultsPopover"
          label="label"
          isOpen={comboBoxState?.isOpen}
          onClose={comboBoxState.close}
          popoverRef={popoverRef}>
          <SuggestedResultsListBox
            {...mergeProps(generatedListBoxProps, customListBoxProps)}
            // Use virtual focus to get aria-activedescendant tracking and
            // ensure focus doesn't leave the input field
            shouldUseVirtualFocus
            listBoxRef={listBoxRef}
            state={comboBoxState}
            searchQuery={currentSearchInputValue}
            id="listBox"
          />
        </SuggestedResultsPopover>
      )}
    </SearchControlContainer>
  );
};

export default SearchComboBox;
