import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  startAdornment: {
    position: 'absolute',
    paddingLeft: theme.spacing(1),
    zIndex: 1,
  },
}));

const SearchStartAdornment = ({ alt, ...props }) => {
  const classes = useStyles();

  return (
    <InputAdornment className={classes.startAdornment}>
      <img alt="" {...props} />
    </InputAdornment>
  );
};

export default SearchStartAdornment;
