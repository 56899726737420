import React, { useState, useMemo } from 'react';
import SelectedDateAvailability from './SelectedDayAvailability';
import Details from './Details';
import { Box, makeStyles } from '@material-ui/core';
// import Policy from '../../components/core/Policy'; /* Commented out per COD-1548 */
// import { BOOKING_POLICY_LABEL } from '../../assets/copy'; /* Commented out per COD-1548 */
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import Divider from '../../components/overrides/Divider';
import {
  EVENT_CAROUSEL_TITLE,
  SIGNATURE_COLLECTION_RESTAURANT_TEXT,
  SIGNATURE_COLLECTION_RESTAURANT_TITLE,
  EVENT_CTA_TEXT,
  BOOK_A_TABLE_TEXT,
  GUEST_SELECTOR_DROPDOWN_LABEL,
} from '../../assets/copy';
import EventsCarousel from '../home/EventsCarousel';
import EventTicketDropdown from './EventTicketDropdown';
import useEventFlag from '../../utils/useEventFlag';
import { useFeatureFlags } from '../../utils/useFeatureFlags';
import CalendarSelector from '../../components/core/CalendarSelector';
import { showDivider } from './helpers';
import SignatureCollectionTextIcon from '../../components/core/SignatureCollection/SignatureCollectionTextIcon';
import Typography from '../../components/overrides/Typography';
import { isVenueAlwaysOn } from '../../utils/eventHelpers';
import { iconLib } from '../../utils/constants/VenueLabels';

const useStyles = makeStyles((theme) => ({
  desktopDivider: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(7),
      width: 'calc(100% - 56px)',
    },
  },
  calendarContainer: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(7, 7, 0),
    },
  },
  policy: {
    padding: theme.spacing(3),
    margin: 0,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 7),
      width: '100%',
    },
  },
  hiddenStyles: {
    display: 'inline-block',
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
  eventsCarouselContainer: {
    width: '100%',
    '& *::-webkit-scrollbar': {
      display: 'none',
    },
  },
  content: {
    padding: theme.spacing(3, 3, 0),
    margin: 0,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 7, 0),
      width: '100%',
    },
  },
  signatureCollectionTextContainer: {
    marginTop: theme.spacing(2),
  },
}));

const DetailedLayout = ({
  list,
  venue,
  guests,
  user,
  time,
  date,
  loadingAvailability,
  eventList,
}) => {
  const { width } = useViewport();
  const classes = useStyles();
  const {
    venueHasEvent: isEvent,
    isEventOnSale,
    isEventNoLongerAvailable,
  } = useEventFlag(venue);
  const eventIsAvailable = useMemo(() => {
    return isEventOnSale && isEvent && !isEventNoLongerAvailable;
  }, [isEvent, isEventNoLongerAvailable, isEventOnSale]);

  const isAlwaysOn = isVenueAlwaysOn(venue);

  const { refreshAvailabilityCalendarFlagIsEnabled: shouldDisplayCalendar } = useFeatureFlags();
  const { refreshLandingPageFlagIsEnabled } = useFeatureFlags();
  const calendarEligibleEvent = refreshLandingPageFlagIsEnabled && isAlwaysOn;
  const [labels] = useState(venue?.labels?.filter((label) => iconLib[label]));

  const showEventTicketDropdown = eventIsAvailable && (!refreshLandingPageFlagIsEnabled || !isAlwaysOn)

  return (
    <>
      {venue.isActive && (
        <>
          {refreshLandingPageFlagIsEnabled && venue?.isSignatureCollection && (
            <Box className={classes.content}>
              <SignatureCollectionTextIcon
                text={SIGNATURE_COLLECTION_RESTAURANT_TITLE}
                variant="small1Semibold"
              />
              <Box className={classes.signatureCollectionTextContainer}>
                <Typography variant="medium1Normal">
                  {SIGNATURE_COLLECTION_RESTAURANT_TEXT}
                </Typography>
              </Box>
            </Box>
          )}
          {shouldDisplayCalendar && (!isEvent || calendarEligibleEvent) && (
            <div className={classes.calendarContainer}>
              <CalendarSelector
                venue={venue}
                guests={guests}
                loadingAvailability={loadingAvailability}
                showBookTableText={true}
                dateSelectionCTA={isEvent ? EVENT_CTA_TEXT : BOOK_A_TABLE_TEXT}
                ticketSelectionLabel={isEvent ? 'Quantity' : GUEST_SELECTOR_DROPDOWN_LABEL}
              />
            </div>
          )}
          {showEventTicketDropdown && <EventTicketDropdown venue={venue} />}
          <SelectedDateAvailability
            list={list}
            venue={venue}
            guests={guests}
            user={user}
            time={time}
            date={date}
            loadingAvailability={loadingAvailability}
            type={TIME_AVAILABILITY_TYPES.HORIZONTAL}
          />
        </>
      )}
      {eventList && (
        <Box className={classes.eventsCarouselContainer}>
          <EventsCarousel
            venue={venue}
            list={eventList}
            title={EVENT_CAROUSEL_TITLE}
            isEventCarousel={true}
            isEventsDetailPage={true}
          />
          <Divider className={classes.desktopDivider} />
        </Box>
      )}

      {refreshLandingPageFlagIsEnabled && WIDTH_BREAKPOINT < width &&
        showDivider(venue?.atmospheres?.items?.length > 0 || labels.length > 0)}
      <Details venue={venue} />
      {refreshLandingPageFlagIsEnabled && showDivider(venue?.atmospheres?.items?.length > 0 || labels.length > 0)}

      {width < WIDTH_BREAKPOINT && <Divider />}
      {/* Commented out per ticket COD-1548 */}
      {/*{showPolicy && (*/}
      {/*  <Policy*/}
      {/*    header={BOOKING_POLICY_LABEL}*/}
      {/*    venuePolicy={venue?.bookingPolicy}*/}
      {/*    className={classes.policy}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default DetailedLayout;
