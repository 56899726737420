import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '../overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import {
  // Not using RESERVATION_UPDATE_TITLE as of now
  RESERVATION_CREATE_TITLE,
  RESERVATION_UPCOMING_TITLE,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: '-25px',
    marginRight: 'auto',
    clear: 'both',
    textAlign: 'center',
    color: theme.palette.blue[70],
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(0),
    },
  },
}));

const widthBreakpoint = 960;

const Title = ({ type }) => {
  const classes = useStyles();
  const { width } = useViewport();
  return (
    <Typography
      className={classes.title}
      component="h1"
      aria-live="polite"
      variant={width > widthBreakpoint ? 'large1Light' : 'medium3Light'}>
      {type === 'Create' && RESERVATION_CREATE_TITLE}
      {type === 'Update' && RESERVATION_CREATE_TITLE}
      {type === 'Upcoming' && RESERVATION_UPCOMING_TITLE}
      { type !== 'Create' && type !== 'Update' && 
        type !== 'Upcoming' && RESERVATION_CREATE_TITLE }
    </Typography>
  );
};

export default Title;
