import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionListAvailabilityVenuesClear,
  actionListAvailabilityVenuesRequest,
} from '../../../store/Availability/AvailabilityAction';
import { generateAvailabilityListInput } from '../../../utils/generateAvailabilityInput';
import { useLocation } from 'react-router';

export default function useAvailabilityCallback(page = 0, resetList = true) {
  const dispatch = useDispatch();
  const { list: venues } = useSelector((state) => state.venues);
  const { loadingAvailability, submittedDate, submittedTime, submittedGuests } =
    useSelector((state) => state?.availability);
  const location = useLocation();

  // Extract page from the URL
  const params = new URLSearchParams(location.search);
  const pageParam = params.get('page');
  
  const fetchAvailability = useCallback(() => {
    let updatedPage = page;
    if (!loadingAvailability && !!venues?.length) {
      // Only update the page value if it exists in the URL
      if (pageParam !== null) {
        const index = parseInt(pageParam, 10);

        if (!isNaN(index)) {
          updatedPage = index;
        }
      }
      console.log('fetchAvailability was called, no halo');
      const input = generateAvailabilityListInput(
        venues,
        submittedDate,
        submittedTime,
        submittedGuests,
        false,
        updatedPage
      );
      resetList && dispatch(actionListAvailabilityVenuesClear());
      dispatch(actionListAvailabilityVenuesRequest(input));
    }
  }, [
    venues,
    submittedDate,
    submittedTime,
    submittedGuests,
    dispatch,
    loadingAvailability,
    page,
    resetList,
    pageParam,
  ]);

  return fetchAvailability;
}
