import Typography from '../../../components/overrides/Typography';
import React, { useState } from 'react';
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, TextField } from '@material-ui/core';
import {
  CREDIT_CARD_VERIFICATION_CODE_LABEL,
  PAYMENT_CARD_NUMBER_LABEL,
  PAYMENT_INFORMATION_SECTION_TITLE,
  PAYMENT_NAME_ON_CARD_LABEL,
  RESERVATION_REQUIRES_PAYMENT_NOTICE,
} from '../../../assets/copy';
import Alert from '../Alert';
import { ALERT_VARIANTS } from '../../../utils/constants/AlertVariants';
import { useViewport } from '../../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  root: ({ isModal }) => ({
    padding: isModal ? 0 : theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: isModal ? 0 : theme.spacing(3, 0),
    },
  }),
  title: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    color: theme.palette.red[50],
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  label: {
    fontFamily: 'PT Sans, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0px',
  },
  name: {
    background: '#FFFFFF',
    border: '1px solid #A8A8A8',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontFamily: 'PT Sans, sans-serif',
    fontSize: '16px',
    left: '0px',
    margin: '8px 0px',
    padding: '10px 14px',
    width: '100%',
    '& .MuiInput-input': {
      padding: 0,
      height: '24px',
      fontFamily: 'PT Sans, sans-serif',
    },
    '&.active': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
  },
  cardNumberContainer: {
    '& .StripeElement': {
      fontSize: '16px',
      width: '100%',
      left: '0px',
      top: '29px',
      background: '#FFFFFF',
      border: '1px solid #A8A8A8',
      boxSizing: 'border-box',
      borderRadius: '4px',
      flex: 'none',
      order: '1',
      alignSelf: 'stretch',
      flexGrow: '0',
      margin: '8px 0px',
      padding: '10px 14px',
      fontFamily: 'PT Sans, sans-serif',
    },
    '& .StripeElement--focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
    '& .StripeElement--invalid': {
      borderColor: theme.palette.red[50],
    },
  },
  cardDetailsContainer: {
    display: 'flex',
  },
  cardContainerExpiration: {
    marginRight: '16px',
  },
  cardContainer: {
    flex: 0.5,
    '& .StripeElement': {
      fontSize: '16px',
      width: '100%',
      left: '0px',
      top: '29px',
      background: '#FFFFFF',
      border: '1px solid #A8A8A8',
      boxSizing: 'border-box',
      borderRadius: '4px',
      flex: 'none',
      order: '1',
      alignSelf: 'stretch',
      flexGrow: '0',
      margin: '8px 0px',
      padding: '10px 14px',
      fontFamily: 'PT Sans, sans-serif',
    },
    '& .StripeElement--focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
    '& .StripeElement--invalid': {
      borderColor: theme.palette.red[50],
    },
    alertBox: {
      margin: theme.spacing(3),
    },
  },
}));

const StripePaymentForm = ({
  handleSetName,
  cardNumberError,
  cardCvcError,
  cardCardExpiryError,
  stripeAlertMessage,
  isModal,
}) => {
  const classes = useStyles({ isModal });
  const [isFocused, setIsFocused] = useState(false);

  const cardElementStyle = {
    style: {
      base: {
        fontSize: '16px',
        fontFamily: 'PT Sans, sans-serif',
        fontStyle: 'normal',
        lineHeight: '24px',
        '::placeholder': {
          color: '#8B939E',
          fontFamily: 'PT Sans, sans-serif',
          fontStyle: 'normal',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const { width } = useViewport();
  const alertBoxClasses = width > WIDTH_BREAKPOINT ? '' : classes.alertBox;

  return (
    <>
      <Container className={classes.root}>
        <Box className={classes.title}>
          <Typography variant="medium1Semibold">
            {PAYMENT_INFORMATION_SECTION_TITLE}
          </Typography>
        </Box>
        <Box className={alertBoxClasses}>
          <Alert variant={ALERT_VARIANTS.ERROR} title={stripeAlertMessage} />
        </Box>
        <Box>
          <Typography
            variant="small2Normal"
            className={classes.subTitle}
            gutterBottom>
            {RESERVATION_REQUIRES_PAYMENT_NOTICE}
          </Typography>
        </Box>
        <Box>
          <Box>
            <Box className={classes.label} id="nameLabel">
              {PAYMENT_NAME_ON_CARD_LABEL}
            </Box>
            <Box>
              <TextField
                type="text"
                variant="standard"
                className={`${classes.name} ${isFocused ? 'active' : ''}`}
                onChange={handleSetName}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
                InputProps={{ disableUnderline: true }}
                // note lowercase, necessary for ADA label
                inputProps={{
                  'aria-labelledby': 'nameLabel',
                }}
              />
            </Box>
          </Box>
          <Box className={classes.cardNumberContainer}>
            <Box className={classes.label}>{PAYMENT_CARD_NUMBER_LABEL}</Box>
            <CardNumberElement
              options={{
                showIcon: true,
                iconStyle: 'solid',
                ...cardElementStyle,
              }}
            />
            <Box>
              <Typography
                variant="small2Normal"
                className={classes.errorMessage}
                gutterBottom>
                {cardNumberError}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.cardDetailsContainer}>
            <Box
              className={`${classes.cardContainer} ${classes.cardContainerExpiration}`}>
              <Box className={classes.label}>Expiration</Box>
              <CardExpiryElement
                options={{
                  ...cardElementStyle,
                }}
              />
              <Box>
                <Typography
                  variant="small2Normal"
                  className={classes.errorMessage}
                  gutterBottom>
                  {cardCardExpiryError}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.cardContainer}>
              <Box className={classes.label}>
                {CREDIT_CARD_VERIFICATION_CODE_LABEL}
              </Box>
              <CardCvcElement
                options={{
                  ...cardElementStyle,
                }}
              />
              <Box>
                <Typography
                  variant="small2Normal"
                  className={classes.errorMessage}
                  gutterBottom>
                  {cardCvcError}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default StripePaymentForm;
