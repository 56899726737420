const ROUTES_TO_HIDE = new Set(['/onboarding', '/search']);
const ROUTES_TO_HIDE_FAB = [
  '/premier-location',
  '/profile',
  '/reservations',
  '/reserve',
];
const ROUTES_TO_SHOW_FAB = ['/reserve/edit/check-availability'];

export const shouldNotShowFooter = (pathName) => ROUTES_TO_HIDE.has(pathName);
export const shouldNotShowFAB = (pathName) =>
  ROUTES_TO_HIDE_FAB.find((e) => pathName === '/' || pathName.includes(e)) &&
  !ROUTES_TO_SHOW_FAB.find((e) => pathName.includes(e));
