import React, { useMemo } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../assets/icons/filter.svg';
import createInflection from '../../utils/inflection/createInflection';
import OutlineButton from '../core/OutlineButton';
import PageSection from '../core/PageSection';
import ScreenReaderText from '../core/ScreenReaderText';
import FilterChip from './FilterChip';
import {
  SEARCH_FILTERS_ALT_TEXT,
  SEARCH_FILTER_LABEL,
  SEARCH_FILTER_INFLECTION,
  SEARCH_FILTER_INFLECTION_SUFFIX,
} from '../../assets/copy';
import {
  actionRemoveCurrentFilterItem,
  actionSetFilterOverlayVisible,
} from '../../store/Filter/FilterAction';
import { getFiltersCount } from '../../utils/searchFilters';
import { focusVisible } from '../../utils/ada/contrastStyles';
import { trackClick } from '../../utils/useOneTag';
const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(8),
    justifyContent: 'center',
    [theme.breakpoints.up('xl')]: {
      height: 'auto',
    },
  },
  container: {
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
    height: '100%',
    paddingLeft: theme.spacing(3),
    flexFlow: 'row',
    [theme.breakpoints.up('xl')]: {
      height: 'auto',
      padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(2, 0),
    },
  },
  filterButton: {
    width: 'auto',
    color: theme.palette.primary.main,
    height: theme.spacing(4),
    backgroundColor: theme.palette.chip.background,
    borderRadius: 24,
    [theme.breakpoints.up('xl')]: {
      alignSelf: 'flex-start',
    },
  },
  filterButtonExpanded: {
    width: 'auto',
    color: theme.palette.primary.main,
    padding: `0 ${theme.spacing(2)}`,
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 24,
    ...focusVisible(),
  },
  filterChipList: {
    display: 'flex',
    gap: theme.spacing(1),
    width: 'calc(100vw - 96px)',
    overflowX: 'scroll',
    paddingRight: 24,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      paddingRight: 0,
      width: 'auto',
    },
  },
}));

const FilterActions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentFilters } = useSelector((state) => state.filters);

  const activeFiltersCount = useMemo(
    () => getFiltersCount(currentFilters),
    [currentFilters]
  );

  const handleRemoveFilter = (id, type) => {
    dispatch(actionRemoveCurrentFilterItem({ id, type }));
  };

  const handleShowFilter = (event) => {
    trackClick(event, "search-filter", "Button");
    dispatch(actionSetFilterOverlayVisible(true));
  };

  const renderChips = () =>
    Object.values(currentFilters)
      .flat()
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .map((item) => (
        <FilterChip
          key={item.id}
          name={item.name}
          onRemove={() => handleRemoveFilter(item.id, item.type)}
          id={item.id}
          type={item.type}
        />
      ));

  const filterIcon = () => <img src={Filter} alt="" />;

  return (
    <PageSection
      aria-label={SEARCH_FILTERS_ALT_TEXT}
      className={classes.root}
      component="section"
      variant="grid">
      <ScreenReaderText component="h2">
        {SEARCH_FILTERS_ALT_TEXT}
      </ScreenReaderText>
      <ScreenReaderText aria-atomic="true" aria-live="polite">
        {createInflection(SEARCH_FILTER_INFLECTION, activeFiltersCount) +
          SEARCH_FILTER_INFLECTION_SUFFIX}
      </ScreenReaderText>
      <Grid className={classes.container} container item xl={8}>
        {activeFiltersCount ? (
          <>
            <IconButton
              aria-label={SEARCH_FILTER_LABEL}
              color="primary"
              className={classes.filterButton}
              onClick={handleShowFilter}>
              {filterIcon()}
            </IconButton>
            <Box
              aria-label="Selected Filters"
              className={classes.filterChipList}
              role="list">
              {renderChips()}
            </Box>
          </>
        ) : (
          <OutlineButton
            variant="small2Semibold"
            text={SEARCH_FILTER_LABEL}
            style={classes.filterButtonExpanded}
            onClick={handleShowFilter}
            startIcon={filterIcon()}
          />
        )}
      </Grid>
    </PageSection>
  );
};
export default FilterActions;
