export const WIDTH_BREAKPOINT = 960;
export const WIDTH_LG_BREAKPOINT = 768;

export const BREAKPOINT_VALUES = {
  xxs: 0,
  xs: 320,
  sm: 375,
  md: 600,
  lg: 768,
  xl: 960,
  xxl: 1200,
};
