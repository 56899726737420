import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ExtraLargeCard from '../../components/core/Card/ExtraLargeCard';
import { Grid, makeStyles, Box } from '@material-ui/core';
import wineGlasses from '../../assets/icons/wine-glasses-white.svg';
import { NO_EVENTS_TEXT, NO_EVENTS_TITLE } from '../../assets/copy';
import palette from '../../theme/palette';
import NoContent from '../../components/core/NoContent';
import { actionSetAnimationPushUp } from '../../store/Animations/AnimationsAction';

const useStyles = makeStyles((theme) => ({
  grid: {
    justifyContent: 'center',
  },
  emptyEventsContainer: {
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(0, 3),
    },
  },
}));

const EventSectionContent = () => {
  const history = useHistory();
  const classes = useStyles();
  const { events } = useSelector((state) => state.venues);
  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(actionSetAnimationPushUp(true));
    history.push('/');
  };
  return events?.length > 0 ? (
    <Grid container spacing={4} className={classes.grid}>
      {events.map((venue) => (
        <Grid item key={venue.id}>
          <ExtraLargeCard venue={venue} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Box className={classes.emptyEventsContainer}>
      <NoContent
        customTextColor={palette.text.secondary}
        imageSrc={wineGlasses}
        title={NO_EVENTS_TITLE}
        message={NO_EVENTS_TEXT}
        onClickHandler={onClickHandler}
      />
    </Box>
  );
};

export default EventSectionContent;
