import { cardHoverStyles } from '../../utils/constants/hoverStyles';
export const chefItemBaseStyles = (theme) => ({
  root: {
    width: (props) => (props.large ? '100%' : 240),
    borderRadius: 4,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.up('xl')]: {
      width: (props) => (props.large ? '100%' : 256),
    },
  },
  card: {
    width: (props) => (props.large ? '100%' : 240),
    boxShadow: 'none',
    borderRadius: 4,
    ...cardHoverStyles,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      width: (props) => (props.large ? '100%' : 256),
    },
    // control height based on whether card is vertical
    height: ({ vertical }) => vertical && 256,
  },
});
