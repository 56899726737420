import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useFabMethods from '../../utils/useFABMethods';
import Typography from '../../components/overrides/Typography';
import TableParameter from '../venue/TableParameter';
import FixedBottomButtonWrapper from '../../components/core/FAB/FixedBottomButtonWrapper';
import TimeParameter from '../../components/core//FAB/TimeParameter';
import CancelEditModal from '../../components/reserve/CancelEditModal';
import { useViewport } from '../../../src/utils/useViewport';
import CloseButton from '../../components/core/CloseButton';
import LargeCard from '../../components/core/Card/LargeCard';
import SmallCard from '../../components/core/Card/SmallCard';
import useReservationMethods from '../../utils/useReservationMethods';
import AvailabilityContainer from '../../components/core/AvailabilityContainer';
import {
  actionGetMultipleAvailabilitiesRequest,
  actionSetHasLoadedAllAvailabilities,
} from '../../store/Availability/AvailabilityAction';
import {
  RESERVATION_EDIT_TITLE,
  RESERVATION_EDIT_CURRENT_LABEL,
  RESERVATION_EDIT_NEW_RESERVATION_LABEL,
  CLOSE_BUTTON_DESCRIPTION,
  CLOSE_BUTTON_LABEL,
} from '../../assets/copy';
import PageSection from '../../components/core/PageSection';
import ReservationSheet from '../../components/core/Sheet/ReservationSheet';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';
import clsx from 'clsx';
import { BREAKPOINT_VALUES } from '../../utils/constants/Breakpoints';
import { generateListAvailabilityInput } from '../../utils/generateAvailabilityInput';
import { imageWidth } from '../../components/core/coreStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 6, 0, 6),
    },
  },
  content: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      columnGap: '48px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      maxWidth: '1040px',
      justifyContent: 'space-between',
    },
  },
  header: {
    padding: theme.spacing(3, 3, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 0, 2),
    },
    [theme.breakpoints.up('xl')]: {
      flexGrow: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      padding: theme.spacing(0, 0, 2),
    },
  },
  close: {
    padding: 0,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5),
    },
  },
  venue: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
    [theme.breakpoints.up('xl')]: {
      width: '336px',
    },
  },
  titleContainer: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  title: {
    [theme.breakpoints.up('lg')]: {
      width: '587px',
      marginLeft: 0,
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
  },
  subTitle: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cardContainer: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  card: {
    width: '100%',
    margin: 0,
    borderRadius: 0,
    flex: '0 0 350px',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  horizontalCard: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: '587px',
      margin: theme.spacing(0, 0, 3),
    },
    [theme.breakpoints.up('xl')]: {
      display: 'block',
      width: '336px',
    },
  },
  horizontalImage: {
    width: 168,
    height: 'auto',
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
  },
  image: {
    width: imageWidth,
    height: imageWidth,
    borderRadius: 4,
  },
  cardContent: {
    overflow: 'hidden',
  },
}));

const widthBreakpoint = BREAKPOINT_VALUES.lg;

const ReservationAvailability = () => {
  const { width } = useViewport();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const fabMethods = useFabMethods();
  const { selectTimeSlotEdit } = useReservationMethods();
  const {
    selectedDate,
    selectedTime,
    selectedGuests,
    list,
    loadingAvailability,
  } = useSelector((state) => state?.availability);
  const { draftReservation, reservation } = useSelector(
    (state) => state?.reservations
  );

  const scrollRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const isBetweenLgAndXl = width <= 1072 && width >= BREAKPOINT_VALUES.lg;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleToggleEditModal = () => {
    setModalOpen((isOpen) => !isOpen);
  };

  const handleDone = () => {
    setOpen(false);
    const input = generateListAvailabilityInput(
      reservation.venue,
      selectedDate,
      selectedTime,
      selectedGuests
    );
    dispatch(actionGetMultipleAvailabilitiesRequest(input));
    fabMethods.setFabDisplayValues({
      date: selectedDate,
      time: selectedTime,
      guests: selectedGuests,
    });
    dispatch(actionSetHasLoadedAllAvailabilities(false));
  };

  const handleCancelEdit = () => {
    setModalOpen(false);
    history.push(`/reservations/${reservation?.id}`);
  };

  const handleCloseFab = () => {
    fabMethods.handleCloseFab();
  };

  useEffect(() => {
    //clean up
    return () => {
      dispatch(actionSetHasLoadedAllAvailabilities(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isMounted) {
      const { date, time, guests } = location?.state;
      const input = generateListAvailabilityInput(
        draftReservation.venue,
        date,
        time,
        guests
      );
      dispatch(actionGetMultipleAvailabilitiesRequest(input));
      fabMethods.setFabDisplayValues({ date, time, guests });
    }
  }, [location.state, dispatch, draftReservation.venue, isMounted, fabMethods]);

  return (
    <>
      <PageSection className={classes.root}>
        <Box ref={scrollRef} mb={width >= widthBreakpoint ? 3 : 0}>
          <Box className={classes.content}>
            <Box className={classes.header}>
              <Box
                mb={width >= widthBreakpoint ? 4 : 1}
                display="flex"
                justifyContent="flex-end">
                <CloseButton
                  className={classes.close}
                  close={handleToggleEditModal}
                  description={CLOSE_BUTTON_DESCRIPTION}
                  fill="#0D74AF"
                  label={CLOSE_BUTTON_LABEL}
                  width={width >= widthBreakpoint ? 21 : 16}
                  height={width >= widthBreakpoint ? 21 : 16}
                />
              </Box>
              <div className={classes.titleContainer}>
                <Box
                  className={classes.title}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center">
                  <Typography
                    component="h1"
                    variant={
                      width >= widthBreakpoint ? 'large1Light' : 'medium3Light'
                    }>
                    {RESERVATION_EDIT_TITLE}
                  </Typography>
                </Box>
              </div>
            </Box>
            <Box className={classes.venue}>
              <Typography
                variant={
                  width >= widthBreakpoint
                    ? 'medium2Semibold'
                    : 'medium1Semibold'
                }
                className={classes.subTitle}
                component="h2">
                {RESERVATION_EDIT_CURRENT_LABEL}
              </Typography>
              {width >= widthBreakpoint ? (
                <LargeCard
                  reservation={reservation}
                  venue={reservation.venue}
                  showActionLinks={false}
                  style={clsx(
                    classes.card,
                    isBetweenLgAndXl && classes.horizontalCard
                  )}
                  cardContentStyle={isBetweenLgAndXl && classes.cardContent}
                  imageStyle={isBetweenLgAndXl && classes.horizontalImage}
                />
              ) : (
                <>
                  <Box className={classes.cardContainer} alignItems="center">
                    <SmallCard
                      reservation={reservation}
                      venue={reservation.venue}
                    />
                  </Box>
                </>
              )}
            </Box>
            <AvailabilityContainer
              title={RESERVATION_EDIT_NEW_RESERVATION_LABEL}
              list={list}
              loader={loadingAvailability}
              handleClick={selectTimeSlotEdit}
            />
          </Box>
        </Box>
        <ReservationSheet type={RESERVATION_TYPE.UPDATE} />
        <FixedBottomButtonWrapper tabIndex={0} onClick={handleClick}>
          <TimeParameter
            date={location?.state?.date}
            time={location?.state?.time}
            guests={location?.state?.guests}
          />
        </FixedBottomButtonWrapper>
        {isMounted && (
          <TableParameter
            isOpen={isOpen}
            setOpen={setOpen}
            handleDone={handleDone}
            handleCloseFab={handleCloseFab}
          />
        )}
        <CancelEditModal
          modalOpen={modalOpen}
          handleCancelEdit={handleCancelEdit}
          handleToggleEditModal={handleToggleEditModal}
        />
      </PageSection>
    </>
  );
};

export default ReservationAvailability;
