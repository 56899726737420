import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SEARCH_PAGINATION_DOTS } from '../../../../assets/copy';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/constants/Pagination';
import { getRandomString } from '../../../../utils/useGeneratedKey';
import { usePagination, useSearchParams } from '../../helpers';
import ArrowLink, { ARROW_LINK_VARIANT } from './SearchResultsArrowLink';
import PageLink from './SearchResultsPageLink';
import PaginationContainer from './SearchResultsPaginationContainer';
import PaginationItem from './SearchResultsPaginationItem';

const SearchResultsPagination = () => {
  const { totalPages, totalCount } = useSelector((state) => state.venues);
  const { pageNumber: currentPage } = useSearchParams();
  const paginatedRange = usePagination({ currentPage });
  const shouldShowPagination = useMemo(
    () => currentPage > 0 && totalCount > DEFAULT_PAGE_SIZE * 2,
    [currentPage, totalCount]
  );

  if (!shouldShowPagination) return null;
  return (
    <PaginationContainer>
      {currentPage > 1 && (
        <PaginationItem>
          <ArrowLink variant={ARROW_LINK_VARIANT.PREVIOUS} />
        </PaginationItem>
      )}
      {paginatedRange.map((pageNumber) => {
        if (pageNumber === SEARCH_PAGINATION_DOTS) {
          return <PaginationItem key={getRandomString()} isDots />;
        }

        return (
          <PaginationItem key={getRandomString()}>
            <PageLink pageNumber={pageNumber} />
          </PaginationItem>
        );
      })}
      {currentPage < totalPages && (
        <PaginationItem>
          <ArrowLink />
        </PaginationItem>
      )}
    </PaginationContainer>
  );
};

export default SearchResultsPagination;
