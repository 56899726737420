import safeHtml from 'safe-html';
import { unescape } from 'lodash';

export const convertNullToEmptyString = (text) => text ?? '';

export const ellipsify = (text, maxLength) =>
  text != null && text.length > maxLength
    ? `${text.slice(0, maxLength)}...`
    : text;

export const generateScreenReaderText = (delimiter, ...args) =>
  args?.length > 0
    ? args.filter((a) => !!a).join(convertNullToEmptyString(delimiter))
    : '';

const config = {
  allowedTags: [],
};
export const removeHtmlTags = (text) => unescape(safeHtml(text, config));
