import { all } from 'redux-saga/effects';
import appDataSaga from '../AppData/AppDataSaga';
import marketsSaga from '../Markets/MarketsSagas';
import atmospheresSaga from '../Atmospheres/AtmospheresSagas';
import seatingOptionsSaga from '../SeatingOptions/SeatingOptionsSagas';
import neighborhoodsSaga from '../Neighborhoods/NeighborhoodsSagas';
import categoriesSaga from '../Categories/CategoriesSagas';
import accoladesSaga from '../Accolades/AccoladesSagas';
import venuesSaga from '../Venues/VenuesSagas';
import cuisinesSaga from '../Cuisines/CuisinesSagas';
import animationsSaga from '../Animations/AnimationsSagas';
import awardsSaga from '../Awards/AwardsSagas';
import tagsSaga from '../Tags/TagsSagas';
import dietsSaga from '../Diets/DietsSagas';
import allergiesSaga from '../Allergies/AllergiesSagas';
import premierLocationsSaga from '../PremierLocation/PremierLocationsSagas';
import availabilitySaga from '../Availability/AvailabilitySagas';
import reservationsSaga from '../Reservations/ReservationsSagas';
import userSaga from '../User/UserSaga';
import configSaga from '../Config/ConfigSaga';
import homePageSaga from '../HomePage/HomePageSagas';

export default function* root() {
  yield all([
    appDataSaga(),
    marketsSaga(),
    atmospheresSaga(),
    seatingOptionsSaga(),
    neighborhoodsSaga(),
    categoriesSaga(),
    accoladesSaga(),
    animationsSaga(),
    awardsSaga(),
    tagsSaga(),
    dietsSaga(),
    allergiesSaga(),
    premierLocationsSaga(),
    venuesSaga(),
    availabilitySaga(),
    cuisinesSaga(),
    reservationsSaga(),
    userSaga(),
    configSaga(),
    homePageSaga()
  ]);
}
