import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';

import Typography from '../../components/overrides/Typography';
import {
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED,
  PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED,
} from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)}`,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  title: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    paddingBottom: theme.spacing(0.5),
  },
  details: {
    color: theme.palette.secondary[80],
  },
  edit: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  additional: {
    color: theme.palette.digitalGray[95],
  },
}));

const FormSection = ({
  title,
  subTitle1,
  subTitle2,
  data1,
  data2,
  handleEdit,
  editable = true,
}) => {
  const classes = useStyles();

  const renderText = (data, dietaryPreferencesNoneSelectedText = '') => {
    return (
      <Typography variant="medium1Normal" className={classes.additional}>
        {data || dietaryPreferencesNoneSelectedText}
      </Typography>
    );
  };

  return (
    <Container className={classes.root}>
      <Box className={classes.title}>
        <Typography component="h2" variant="medium1Semibold">
          {title}
        </Typography>
        {editable && (
          <Typography
            aria-label={`Edit ${title}`}
            variant="medium1Semibold"
            className={classes.edit}
            onClick={handleEdit}
            role="button"
            tabIndex="0">
            Edit
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="small4Normal" className={classes.subTitle}>
          {subTitle1}
        </Typography>
        {renderText(data1, PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED)}
      </Box>
      <Box mt={2}>
        <Typography variant="small4Normal" className={classes.subTitle}>
          {subTitle2}
        </Typography>
        {renderText(data2, PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED)}
      </Box>
    </Container>
  );
};

export default FormSection;
