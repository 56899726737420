import * as types from './AvailabilityTypes';

//availability list
export const actionAvailabilityListRequest = (payload) => ({
  type: types.AVAILABILITY_LIST_REQUEST,
  payload,
});

export const actionAvailabilityListSuccess = (payload) => ({
  type: types.AVAILABILITY_LIST_SUCCESS,
  payload,
});

export const actionAvailabilityListFail = (payload) => ({
  type: types.AVAILABILITY_LIST_FAIL,
  payload,
});

//get availability
export const actionGetAvailabilityRequest = (payload) => ({
  type: types.GET_AVAILABILITY_REQUEST,
  payload,
});

export const actionGetAvailabilitySuccess = (payload) => ({
  type: types.GET_AVAILABILITY_SUCCESS,
  payload,
});

export const actionGetAvailabilityFail = (payload) => ({
  type: types.GET_AVAILABILITY_FAIL,
  payload,
});

export const actionGetMultipleAvailabilitiesRequest = (payload) => ({
  type: types.GET_MULTIPLE_AVAILABILITIES_REQUEST,
  payload,
});

export const actionGetMultipleAvailabilitiesSuccess = (payload) => ({
  type: types.GET_MULTIPLE_AVAILABILITIES_SUCCESS,
  payload,
});

export const actionGetMultipleAvailabilitiesFail = (payload) => ({
  type: types.GET_MULTIPLE_AVAILABILITIES_FAIL,
  payload,
});

//find availability
export const actionFindAvailabilityRequest = (payload) => ({
  type: types.FIND_AVAILABILITY_REQUEST,
  payload,
});

export const actionFindAvailabilitySuccess = (payload) => ({
  type: types.FIND_AVAILABILITY_SUCCESS,
  payload,
});

export const actionFindAvailabilityFail = (payload) => ({
  type: types.FIND_AVAILABILITY_FAIL,
  payload,
});

//get more availabilities
export const actionLoadMoreAvailabilityRequest = (payload) => ({
  type: types.LOAD_MORE_AVAILABILITIES,
  payload,
});

// clear availability
export const actionClearAvailability = () => ({
  type: types.CLEAR_AVAILABILITY,
});

export const actionClearListAvailabilities = () => ({
  type: types.CLEAR_LIST_AVAILABILITIES,
});

export const actionClearDateRangeAvailabilities = () => ({
  type: types.CLEAR_DATE_RANGE_AVAILABILITIES,
});
// set loading status for availability actions
export const actionSetAvailabilityLoadingStatus = (payload) => ({
  type: types.SET_AVAILABILITY_LOADING_STATUS,
  payload,
});

// load more availability
export const actionLoadDateAvailabilityRequest = (payload) => ({
  type: types.LOAD_DATE_AVAILABILITY_REQUEST,
  payload,
});

export const actionLoadDateAvailabilitySuccess = (payload) => ({
  type: types.LOAD_DATE_AVAILABILITY_SUCCESS,
  payload,
});

export const actionLoadDateAvailabilityFail = (payload) => ({
  type: types.LOAD_DATE_AVAILABILITY_FAIL,
  payload,
});

// FAB
export const actionSetFabAvailabilityDate = (payload) => ({
  type: types.SET_FAB_AVAILABILITY_DATE,
  payload,
});

export const actionSetFabAvailabilityTime = (payload) => ({
  type: types.SET_FAB_AVAILABILITY_TIME,
  payload,
});

export const actionSetFabAvailabilityGuests = (payload) => ({
  type: types.SET_FAB_AVAILABILITY_GUESTS,
  payload,
});

export const actionSetFabDisplayValues = (payload) => ({
  type: types.SET_FAB_DISPLAY_VALUES,
  payload,
});

//DROPDOWN
export const actionSetSubmittedTickets = (payload) => ({
  type: types.SET_SUBMITTED_TICKETS,
  payload,
});
export const actionGetEventAvailabilityRequest = (payload) => ({
  type: types.GET_EVENT_AVAILABILITY_REQUEST,
  payload,
});

export const actionGetEventAvailabilitySuccess = (payload) => ({
  type: types.GET_EVENT_AVAILABILITY_SUCCESS,
  payload,
});

export const actionGetEventAvailabilityFail = (payload) => ({
  type: types.GET_EVENT_AVAILABILITY_FAIL,
  payload,
});

// load more for list
export const actionListAvailabilityVenuesRequest = (payload) => ({
  type: types.LIST_AVAILABILITY_VENUES_REQUEST,
  payload,
});

export const actionListAvailabilityVenuesClear = () => ({
  type: types.LIST_AVAILABILITY_VENUES_CLEAR,
});

export const actionListAvailabilityVenuesProgress = (payload) => ({
  type: types.LIST_AVAILABILITY_VENUES_PROGRESS,
  payload,
});

export const actionListAvailabilityVenuesFail = (payload) => ({
  type: types.LIST_AVAILABILITY_VENUES_FAIL,
  payload,
});

export const actionAddTimeslotToAvailabilityList = (payload) => ({
  type: types.ADD_TIMESLOT_TO_AVAILABILITY,
  payload,
});

export const actionSetHasLoadedAllAvailabilities = (payload) => ({
  type: types.SET_HAS_LOADED_ALL_AVAILABILITIES,
  payload,
});

// get date range availability
export const actionGetDateRangeAvailabilityRequest = (payload) => ({
  type: types.GET_DATE_RANGE_AVAILABILITY_REQUEST,
  payload
});

export const actionGetDateRangeAvailabilitySuccess = (payload) => ({
  type: types.GET_DATE_RANGE_AVAILABILITY_SUCCESS,
  payload
});

export const actionGetDateRangeAvailabilityFail = (payload) => ({
  type: types.GET_DATE_RANGE_AVAILABILITY_FAIL,
  payload
});

export const actionPreloadDateRangeAvailabilityRequest = (payload) => ({
  type: types.PRELOAD_DATE_RANGE_AVAILABILITY_REQUEST,
  payload
});

export const actionPreloadDateRangeAvailabilitySuccess = (payload) => ({
  type: types.PRELOAD_DATE_RANGE_AVAILABILITY_SUCCESS,
  payload
});

export const actionPreloadDateRangeAvailabilityFail = (payload) => ({
  type: types.PRELOAD_DATE_RANGE_AVAILABILITY_FAIL,
  payload
});

export const actionSetDateRangeAvailabilityTimeslotList = (payload) => ({
  type: types.SET_DATE_RANGE_AVAILABILITY_TIMESLOT_LIST,
  payload
});
export const actionSetCalendarSelectedDate = (payload) => ({
  type: types.SET_CALENDAR_SELECTED_DATE,
  payload
});
export const actionSetCalendarDisplayedMonth = (payload) => ({
  type: types.SET_CALENDAR_DISPLAYED_MONTH,
  payload
});
