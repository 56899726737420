import React from 'react';
import { makeStyles } from '@material-ui/styles';
import cn from 'clsx';

import Chips from './Chips';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.white,
    border: `1px solid ${theme.palette.chip.default}`,
    margin: theme.spacing(0, 1, 1, 0),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selected: {
    color: `${theme.palette.chip.active} !important`,
    background: theme.palette.chip.background,
    border: `1px solid ${theme.palette.chip.background}`,
  },
  item: {
    color: theme.palette.chip.default,
    margin: theme.spacing(0.5, 2),
  },
  itemSelected: {
    color: `${theme.palette.chip.active} !important`,
  },
}));

const MultiChoice = ({ text, type, onClick }) => {
  const classes = useStyles();
  // default, active
  return (
    <Chips
      text={text}
      size="medium"
      radius="card"
      outlined
      rootStyle={cn(classes.root, type === 'default' ? null : classes.selected)}
      itemStyle={cn(
        classes.item,
        type === 'default' ? null : classes.itemSelected
      )}
    />
  );
};

export default MultiChoice;
