import { useMemo } from 'react';
import { QUERY_PARAM_WHITELISTS } from './constants/QueryParamWhitelists';
import { useQueryParams } from './useQueryParams';

const validateParam = (param) =>
  !!param && typeof param === 'object' ? param : {};

export const validateByWhitelist = (givenQueryParams, givenWhitelist) => {
  const queryParams = validateParam(givenQueryParams);
  const whitelist = validateParam(givenWhitelist);

  return Object.fromEntries(
    Object.entries({ ...queryParams }).filter(([key]) => !!whitelist[key])
  );
};

/**
 * Hook to create validated search string from query parameters
 * @param {function} pathname Pathname to check for valid query parameters
 * @returns {string|null}
 */
export const useValidatedQueryParamString = ({ pathname }) => {
  const queryParams = useQueryParams();
  const whitelist = useMemo(
    () => QUERY_PARAM_WHITELISTS[pathname] ?? null,
    [pathname]
  );
  const validatedParams = useMemo(() => {
    const params = validateByWhitelist(queryParams, whitelist);

    return Object.keys(params).length > 0 ? params : null;
  }, [queryParams, whitelist]);

  return useMemo(
    () =>
      validatedParams != null
        ? `?${new URLSearchParams(validatedParams).toString()}`
        : null,
    [validatedParams]
  );
};
