import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import LogoSvg from '../../assets/icons/logo.svg';
import CloseButton from './CloseButton';
import { HOMEPAGE_LINK_ALT_TEXT, CLOSE_BUTTON_LABEL } from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.gray[30]}`,
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      height: 64,
      paddingTop: 0,
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('xxl')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  logo: {
    position: 'absolute',
    left: 16,
    top: 12,
    [theme.breakpoints.up('xl')]: {
      position: 'static',
    },
  },
  close: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1.5),
    position: 'relative',
    bottom: 32,
    '& svg': {
      fill: theme.palette.digitalGray[100],
      [theme.breakpoints.up('xl')]: {
        fill: theme.palette.digitalGray[80],
      },
    },
    [theme.breakpoints.up('xl')]: {
      margin: 'initial',
      position: 'static',
    },
  },
}));

const AppBarClose = ({ close, isMobile = true }) => {
  const classes = useStyles();
  return (
    <Grid
      role="banner"
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}>
      <Grid item className={classes.logo}>
        <Link aria-label={HOMEPAGE_LINK_ALT_TEXT} to="/">
          <img
            src={LogoSvg}
            aria-hidden="true"
            alt={''}
            className={classes.img}
          />
        </Link>
      </Grid>
      <Grid item className={classes.close}>
        <CloseButton
          autoFocus
          fill="inherit"
          height={isMobile ? 20 : 21}
          label={CLOSE_BUTTON_LABEL}
          close={close}
          width={isMobile ? 20 : 21}
        />
      </Grid>
    </Grid>
  );
};

export default AppBarClose;
