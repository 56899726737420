import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Modal,
  Backdrop,
  Fade,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import Divider from '../../overrides/Divider';

import OutlineButton from '../../../components/core/OutlineButton';
import Typography from '../../../components/overrides/Typography';
import GravityButton from '../../gravity/button';
import {
  sanitizeTotalAmount,
  sanitizeAmount,
} from '../../../utils/currencyHelper/';
import {
  PAYMENT_CUSTOM_TIP_AMOUNT_LABEL,
  PAYMENT_CUSTOM_TIP_LABEL,
  PAYMENT_CUSTOM_TIP_SAVE_BUTTON_TEXT,
} from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(3),
    borderRadius: 4,
    width: '480px',
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      top: '80px',
    },
  },
  titleContainer: {
    display: 'flex',
  },
  description: {
    margin: theme.spacing(3, 0),
  },
  input: {
    border: '1px solid #A8A8A8',
    borderRadius: '5px',
    fontFamily: ['Optimist', 'sans-serif'].join(','),
  },
  currency: {
    color: theme.palette.text.primary,
  },
  yesBtn: {
    backgroundColor: theme.palette.red[50],
    color: theme.palette.text.secondary,
    border: `${theme.palette.red[50]} !important`,
    '&:hover': {
      border: `${theme.palette.red[50]} !important`,
    },
    width: 'auto',
  },
  noBtn: {
    color: theme.palette.gray[50],
    border: 'none !important',
    '&:hover': {
      border: 'none !important',
    },
    width: 'auto',
  },
  divider: {
    margin: '15px 0',
  },
}));

const TipModal = ({
  modalOpen,
  setModalOpen,
  setCustomTip,
  setTotalAmount,
  customTip,
  originalAmount,
  tipProperties,
  setTipProperties,
  setPaymentGratuity,
}) => {
  const classes = useStyles();

  const calculateTip = () => {
    let tip = customTip;

    if (isNaN(tip) || tip === '') {
      tip = 0;
    }

    // no negative number
    if (tip < 0) {
      return;
    }

    setCustomTip(parseFloat(tip));
    const total = sanitizeTotalAmount(originalAmount, tip);
    const gratuityAmount = sanitizeAmount(tip);
    setTotalAmount(total);
    setPaymentGratuity(gratuityAmount);

    const tipPropertiesClone = { ...tipProperties };
    Object.keys(tipPropertiesClone).forEach(
      (tip) => (tipPropertiesClone[tip].isSelected = false)
    );
    setTipProperties({ ...tipPropertiesClone, custom: { isSelected: true } });
    setModalOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={modalOpen}>
        <Box className={classes.paper}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="medium3Light">
              {PAYMENT_CUSTOM_TIP_LABEL}
            </Typography>
            <OutlineButton
              text="X"
              onClick={() => setModalOpen(false)}
              style={classes.noBtn}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="medium1Normal" className={classes.description}>
              {PAYMENT_CUSTOM_TIP_AMOUNT_LABEL}
            </Typography>
          </Box>
          <Box display="flex">
            <OutlinedInput
              className={classes.input}
              fullWidth
              value={customTip}
              placeholder={'0.00'}
              inputProps={{ maxLength: 7 }}
              onChange={(e) => {
                const amount = e.target.value;
                const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
                if (!regexp.test(amount)) {
                  return;
                } else {
                  setCustomTip(amount);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Typography className={classes.currency}>$</Typography>
                </InputAdornment>
              }
            />
          </Box>
          <Box className={classes.divider}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <GravityButton
              variant="progressive"
              fullWidth
              onClick={calculateTip}>
              {PAYMENT_CUSTOM_TIP_SAVE_BUTTON_TEXT}
            </GravityButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TipModal;
