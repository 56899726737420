import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import { useDispatch, useSelector } from 'react-redux';
import Policy from '../../components/core/Policy';
import ContactDetail from '../../components/profile/ContactDetail';
import FormSection from './FormSection';
import Typography from '../../components/overrides/Typography';
import CloseButton from '../../components/core/CloseButton';
import FilledButton from '../../components/core/FilledButton';
import OutlineButton from '../../components/core/OutlineButton';

import PageSection from '../../components/core/PageSection';
import CommonLoading from '../../pages/common/CommonLoading';
import LargeCard from '../../components/core/Card/LargeCard';
import { mapToReservationsLink } from '../../components/reservations/helpers';
import { RESERVATIONS_TYPE } from '../../components/reservations/constants';
import CancelReserveModal from '../../components/reserve/CancelReserveModal';
import { useViewport } from '../../utils/useViewport';
import { isReservationPast } from '../../utils/reservationFilters';
import { formatPhone } from '../../utils/formatPhone';
import {
  actionCancelReservationRequest,
  actionGetReservationRequest,
  actionClearReservationCancelStatus,
  actionSetReservation,
} from '../../store/Reservations/ReservationsAction';

import InfoBlock from '../../components/core/InfoBlock';

import { getTypeFromStatus } from '../../utils/reservationFilters';
import gravityPalette from '../../components/gravity/global/gravityPalette';
import clsx from 'clsx';
import {
  PAST_RESERVATION_TITLE,
  UPCOMING_RESERVATION_TITLE,
  CLOSE_BUTTON_DESCRIPTION,
  BACK_BUTTON_LABEL,
  RESERVATIONS_SEE_RESTAURANT,
  PROFILE_DIETARY_PREFERENCES_SECTION_LABEL,
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  SPECIAL_REQUESTS_SECTION_LABEL,
  PROFILE_DIETARY_PREFERENCES_NOTES_LABEL,
  SPECIAL_OCCASION_LABEL,
  PAYMENT_SECTION_LABEL,
  RESERVATION_SERVICE_CHARGE_TOOLTIP,
  RESERVATION_TITLE,
  RESERVATION_RECEIPT_SERVICE_FEE_LABEL,
  RESERVATION_RECEIPT_TITLE_ALT_TEXT,
  PAYMENT_TIP_LABEL,
  PAYMENT_TIP_LABEL_ALT_TEXT,
  PAYMENT_TAX_LABEL,
  PAYMENT_TAX_LABEL_ALT_TEXT,
  PAYMENT_TOTAL_LABEL,
  PAYMENT_POLICY_LABEL,
  BOOKING_POLICY_LABEL,
  CANCEL_RESERVATION_BUTTON_TEXT,
  EDIT_RESERVATION_BUTTON_TEXT,
  PAYMENT_TOTAL_LABEL_ALT_TEXT,
  RESERVATION_TITLE_ALT_TEXT,
  EDIT_RESERVATION_WARNING_TEXT,
  RESERVATION_ID_LABEL,
  DINING_OPTION_TITLE,
  RESERVATIONS_SEE_EVENT,
} from '../../assets/copy';
import { PAYMENT_RULE_TYPE } from './helpers/paymentAvailabilityTypes';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import { generateScreenReaderText } from '../../utils/stringHelperMethods';
import useEventFlag from '../../utils/useEventFlag';
import InfoIconTooltip from '../../components/core/InfoIconTooltip';
import {
  calculateQuotient,
  displayUSD,
  sumTotal,
} from '../../utils/currencyHelper/';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },

  header: {
    flexGrow: 0,
    flexBasis: '100%',
  },
  aside: {
    flex: '0 0 350px',
  },
  details: {
    [theme.breakpoints.up('xl')]: {
      boxSizing: 'border-box',
      maxWidth: `calc(531px + ${theme.spacing(3)})`,
      minWidth: `calc(526px)`,
    },
  },
  contactDetails: {
    [theme.breakpoints.up('xl')]: {
      paddingTop: 0,
    },
  },
  buttonContainer: {
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
    },
  },
  button: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 1),
    height: theme.spacing(6),
    borderRadius: 4,
    display: 'flex',
    textTransform: 'none',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(4),
      width: '196px',
    },
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    backgroundColor: gravityPalette.button.destructive.default,
    '&:hover': {
      backgroundColor: gravityPalette.button.destructive.hover,
    },
    '&:active': {
      backgroundColor: gravityPalette.button.destructive.active,
    },
  },
  editButton: {
    backgroundColor: gravityPalette.button.destructive.default,
    '&:hover': {
      backgroundColor: gravityPalette.button.destructive.hover,
    },
    '&:active': {
      backgroundColor: gravityPalette.button.destructive.active,
    },
  },
  cancelButtonSecondary: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '600px',
    },
    maxWidth: 'none',
    color: gravityPalette.button.destructive.default,
    backgroundColor: gravityPalette.button.text.default,
    border: 0,
    '&:hover': {
      backgroundColor: gravityPalette.button.text.hover,
      border: '0',
    },
    '&:active': {
      backgroundColor: gravityPalette.button.text.active,
      border: '0',
    },
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  close: {
    float: 'right',
    padding: theme.spacing(0, 3, 0, 0),
    marginBottom: theme.spacing(1.25),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5),
      marginBottom: 0,
    },
  },
  closeButtonContainer: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '83.333333%',
      width: '100%',
    },
  },
  card: {
    margin: 0,
    borderRadius: 0,
    flex: '0 0 350px',
    [theme.breakpoints.down('lg')]: {
      boxShadow: 'none',
    },
  },
  slotOptionsContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  slotNameLabel: {
    marginTop: theme.spacing(1),
    color: theme.palette.secondary[80],
  },
  paymentContainer: {
    padding: theme.spacing(3, 3, 0, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 2, 0, 0),
    },
  },
  paymentInnerContainer: {
    padding: theme.spacing(1, 3, 1, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1, 2, 1, 0),
    },
  },

  reservationCharges: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(0),
  },

  reservationChargesText: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(2),
  },

  reservationTotal: {
    display: 'flex',
    placeContent: 'space-between',
    padding: theme.spacing(0.5, 0, 1.5, 0),
  },
  cardContentStyle: {
    padding: theme.spacing(3),
  },
  serviceChargeText: {
    padding: theme.spacing(1, 0, 0, 0),
  },
  blackToolTip: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    lineHeight: '21px',
    fontSize: '14px',
    width: 'auto',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(8),
      width: '256px',
    },
  },
  informationIcon: {
    margin: theme.spacing(0.5, 0, 0, 1),
    width: '.85rem',
    height: '.85rem',
  },

  title: {
    clear: 'both',
    textAlign: 'center',
    color: theme.palette.blue[70],
  },
  confirmationId: {
    fontWeight: 'bold',
    clear: 'both',
    textAlign: 'center',
    color: theme.palette.secondary[100],
  },
  confirmationLabel: {
    clear: 'both',
    textAlign: 'center',
    color: theme.palette.secondary[100],
    marginRight: theme.spacing(0.5),
  },
  subTitle: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    color: theme.palette.red[50],
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  policyContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  paymentPolicy: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  bookingPolicy: {
    padding: theme.spacing(0),
  },
  confirmationContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const widthBreakpoint = 960;

const ReservationDetails = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const reservationType = useMemo(
    () => location?.state?.reservationType ?? RESERVATIONS_TYPE.UPCOMING,
    [location?.state]
  );
  const { reservation, reservationCancelStatus } = useSelector(
    (state) => state.reservations
  );

  const eventStatus = useEventFlag(reservation?.venue);

  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('Upcoming reservation');
  const [mounted, setMounted] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [cancelStatus, setCancelStatus] = useState();
  const [isClosing, setIsClosing] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentGratuity, setPaymentGratuity] = useState(0);
  const [paymentSubTotal, setPaymentSubTotal] = useState(0);
  const [paymentServiceCharge, setPaymentServiceCharge] = useState(0);
  const [paymentTax, setPaymentTax] = useState(0);
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  const dispatch = useDispatch();

  const clearReservationState = useCallback(() => {
    setMounted(false);
    dispatch(actionSetReservation(null));
  }, [dispatch]);

  const setCalculationTotalAmount = useCallback(() => {
    const total = sumTotal(
      paymentSubTotal,
      paymentServiceCharge,
      paymentGratuity,
      paymentTax
    );
    setTotalAmount(displayUSD(total));
  }, [paymentSubTotal, paymentGratuity, paymentServiceCharge, paymentTax]);

  useEffect(() => {
    dispatch(actionGetReservationRequest({ id: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionGetReservationRequest, id, dispatch]);

  useEffect(() => {
    // Prevent useEffect hook from re-running when close button is clicked
    if (isClosing) return;

    if (
      !reservation ||
      (reservation.id && reservation.id !== id) ||
      !reservation.venue
    ) {
      clearReservationState();
    }
    if (reservation) {
      setTitle(
        isReservationPast(reservation) ||
          getTypeFromStatus(reservation.status) === 'Canceled'
          ? PAST_RESERVATION_TITLE
          : UPCOMING_RESERVATION_TITLE
      );

      if (reservation?.paymentSubTotal > 0) {
        setShowPayment(true);
        setPaymentSubTotal(calculateQuotient(reservation.paymentSubTotal, 100));
        setPaymentGratuity(calculateQuotient(reservation.paymentGratuity, 100));
        setPaymentServiceCharge(
          calculateQuotient(reservation.paymentServiceCharge, 100)
        );
        setPaymentTax(calculateQuotient(reservation.paymentTax, 100));
        setCalculationTotalAmount();
      } else {
        setShowPayment(false);
      }
      setMounted(true);
    }
  }, [
    reservation,
    id,
    dispatch,
    clearReservationState,
    isClosing,
    setCalculationTotalAmount,
  ]);

  useEffect(() => {
    if (reservationCancelStatus) {
      setCancelStatus(reservationCancelStatus);
      if (reservationCancelStatus.success) {
        setModalOpen(false);
        history.push('/reserve/cancel/confirmed');
      }
      dispatch(actionClearReservationCancelStatus());
    }
  }, [
    cancelStatus,
    setCancelStatus,
    reservationCancelStatus,
    dispatch,
    history,
    setCalculationTotalAmount,
  ]);

  const handleClose = useCallback(() => {
    const link = mapToReservationsLink(reservationType);
    clearReservationState();
    setIsClosing(true);
    history.push(link);
  }, [clearReservationState, history, reservationType]);

  //Listen for Browser back button action
  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        handleClose();
      }
    });
  }, [history, handleClose]);

  const handleToggleCancelReservationModal = () => {
    setModalOpen((isOpen) => !isOpen);
  };

  const handleToggleEditReservationModal = () => {
    history.push(`/reserve/edit/${id}`);
  };

  const handleCancelReservation = () => {
    dispatch(actionCancelReservationRequest({ id: reservation.id }));
  };

  const isMounted = () => mounted && reservation != null;
  const serviceChargeTooltipTitle = RESERVATION_SERVICE_CHARGE_TOOLTIP;
  const closeButtonDimension = width >= widthBreakpoint ? 21 : 16;

  const renderBookingPolicy = () => {
    if (reservation?.bookingPolicy) {
      return (
        <Policy
          header={BOOKING_POLICY_LABEL}
          venuePolicy={reservation?.bookingPolicy}
          className={classes.bookingPolicy}
        />
      );
    } else if (reservation?.venue?.bookingPolicy) {
      return (
        <Policy
          header={BOOKING_POLICY_LABEL}
          venuePolicy={reservation?.venue?.bookingPolicy}
          className={classes.bookingPolicy}
        />
      );
    }
    return null;
  };

  const getReservationIdText = () => {
    return generateScreenReaderText(
      ',',
      RESERVATION_ID_LABEL,
      reservation?.referenceCode
    );
  };

  const renderInfo = () => {
    if (eventStatus.venueHasEvent) {
      return null;
    }

    return (
      reservation?.paymentRule !== PAYMENT_RULE_TYPE.NONE && (
        <InfoBlock text={EDIT_RESERVATION_WARNING_TEXT} />
      )
    );
  };

  const renderButtons = () => {
    if (eventStatus.venueHasEvent) {
      return null;
    }
    return (
      <Box className={classes.buttonContainer}>
        {reservation?.paymentRule === PAYMENT_RULE_TYPE.NONE ? (
          <>
            <FilledButton
              text={EDIT_RESERVATION_BUTTON_TEXT}
              onClick={handleToggleEditReservationModal}
              role="link"
              style={`${classes.button}`}
            />
            <OutlineButton
              type="button"
              text={CANCEL_RESERVATION_BUTTON_TEXT}
              onClick={handleToggleCancelReservationModal}
              style={`${classes.button} ${classes.cancelButtonSecondary}`}
            />
          </>
        ) : (
          <>
            <FilledButton
              text={CANCEL_RESERVATION_BUTTON_TEXT}
              onClick={handleToggleCancelReservationModal}
              role="link"
              style={`${classes.button} ${classes.cancelButton}`}
            />
          </>
        )}
      </Box>
    );
  };

  const renderCancelReserveModal = () => {
    if (eventStatus.venueHasEvent) {
      return null;
    }
    return (
      <CancelReserveModal
        modalOpen={modalOpen}
        handleCancelReserve={handleCancelReservation}
        handleToggleReserveModal={handleToggleCancelReservationModal}
      />
    );
  };

  return (
    <>
      <CommonLoading active={!isMounted()} />
      {isMounted() && !navigationMenuVisible && (
        <main>
          <PageSection className={classes.root}>
            <Box
              className={classes.header}
              mt={width >= widthBreakpoint ? 0.5 : 0}
              mb={width >= widthBreakpoint ? 4 : 2.5}>
              <Box className={classes.closeButtonContainer}>
                <CloseButton
                  className={classes.close}
                  close={handleClose}
                  description={CLOSE_BUTTON_DESCRIPTION}
                  fill="#0D74AF"
                  height={closeButtonDimension}
                  label={BACK_BUTTON_LABEL}
                  role="link"
                  width={closeButtonDimension}
                />
              </Box>
              <Typography
                variant={
                  width >= widthBreakpoint ? 'large1Light' : 'medium3Light'
                }
                className={classes.title}
                component="h1"
                role="status">
                {title}
              </Typography>
              <Box className={classes.confirmationContainer}>
                <ScreenReaderText>{getReservationIdText()}</ScreenReaderText>
                <Typography
                  variant={'small4Normal'}
                  className={classes.confirmationLabel}
                  aria-hidden="true">
                  {RESERVATION_ID_LABEL}
                </Typography>
                <Typography
                  variant={'small4Normal'}
                  className={classes.confirmationId}
                  aria-hidden="true">
                  {reservation?.referenceCode}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.aside}>
              <LargeCard
                style={classes.card}
                cardContentStyle={classes.cardContentStyle}
                venue={reservation.venue}
                reservation={reservation}
                linkText={
                  eventStatus.venueHasEvent
                    ? RESERVATIONS_SEE_EVENT
                    : RESERVATIONS_SEE_RESTAURANT
                }
                showActionLinks={!eventStatus.venueHasEvent}
              />
            </Box>
            <Box
              pl={width >= widthBreakpoint ? 7 : 0}
              className={classes.details}>
              {width < widthBreakpoint && <Divider />}
              <ContactDetail
                className={classes.contactDetails}
                name={`${reservation.firstName} ${reservation.lastName}`}
                phone={formatPhone(reservation.phone)}
                email={reservation.email}
                editable={false}
                isReservation={true}
              />
              <Divider />
              <FormSection
                title={PROFILE_DIETARY_PREFERENCES_SECTION_LABEL}
                subTitle1={PROFILE_DIETARY_PREFERENCES_DIETS_LABEL}
                data1={reservation.diets?.join(', ')}
                subTitle2={PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL}
                data2={reservation.allergies?.join(', ')}
                editable={false}
              />
              <Divider />
              <FormSection
                title={SPECIAL_REQUESTS_SECTION_LABEL}
                subTitle1={PROFILE_DIETARY_PREFERENCES_NOTES_LABEL}
                data1={reservation.notes}
                subTitle2={SPECIAL_OCCASION_LABEL}
                data2={reservation.specialOccasions?.join(', ')}
                editable={false}
              />
              {reservation.slotName && (
                <>
                  <Divider />
                  <Container className={classes.slotOptionsContainer}>
                    <Typography variant="medium1Semibold">
                      {DINING_OPTION_TITLE}
                    </Typography>
                    <Typography
                      variant="medium1Normal"
                      className={classes.slotNameLabel}>
                      {reservation?.slotName}
                    </Typography>
                  </Container>
                </>
              )}

              {showPayment && (
                <>
                  <Divider />
                  <Container className={classes.paymentContainer}>
                    <Box className={classes.reservationChargesText}>
                      <Typography variant="medium1Semibold" component="h2">
                        {PAYMENT_SECTION_LABEL}
                      </Typography>
                    </Box>
                    <Box className={classes.reservationCharges}>
                      <Typography variant="small2Normal">
                        {RESERVATION_TITLE}
                      </Typography>

                      <Typography
                        aria-label={`${RESERVATION_TITLE_ALT_TEXT} ${displayUSD(
                          paymentSubTotal
                        )}`}
                        variant="small2Normal"
                        className={classes.reservationCharges}>
                        {displayUSD(paymentSubTotal)}
                      </Typography>
                    </Box>
                    {paymentServiceCharge > 0 && (
                      <Box className={classes.subTitle}>
                        <Typography
                          variant="small2Normal"
                          component="h3"
                          className={clsx(
                            classes.reservationCharges,
                            classes.serviceChargeText
                          )}>
                          {RESERVATION_RECEIPT_SERVICE_FEE_LABEL}
                          <ScreenReaderText>
                            {serviceChargeTooltipTitle}
                          </ScreenReaderText>
                          <InfoIconTooltip
                            title={serviceChargeTooltipTitle}
                            tooltipStyle={classes.blackToolTip}
                            infoIconStyle={classes.informationIcon}
                          />
                        </Typography>
                        <Typography
                          className={clsx(
                            classes.reservationCharges,
                            classes.serviceChargeText
                          )}
                          aria-label={`${RESERVATION_RECEIPT_TITLE_ALT_TEXT} ${displayUSD(
                            paymentSubTotal
                          )}`}
                          variant="small2Normal">
                          {displayUSD(paymentServiceCharge)}
                        </Typography>
                      </Box>
                    )}
                  </Container>
                  {paymentGratuity > 0 && (
                    <>
                      <Container className={classes.paymentInnerContainer}>
                        <Divider className={classes.divider} />
                        <Box className={classes.reservationCharges}>
                          <Typography variant="small2Semibold">
                            {PAYMENT_TIP_LABEL}
                          </Typography>

                          <Typography
                            aria-label={`${PAYMENT_TIP_LABEL_ALT_TEXT} ${displayUSD(
                              paymentGratuity
                            )}`}
                            variant="small2Normal">
                            {displayUSD(paymentGratuity)}
                          </Typography>
                        </Box>
                      </Container>
                    </>
                  )}
                  {paymentTax > 0 && (
                    <>
                      <Container className={classes.paymentInnerContainer}>
                        <Divider className={classes.divider} />
                        <Box className={classes.reservationCharges}>
                          <Typography variant="small2Semibold">
                            {PAYMENT_TAX_LABEL}
                          </Typography>

                          <Typography
                            aria-label={`${PAYMENT_TAX_LABEL_ALT_TEXT} ${displayUSD(
                              paymentTax
                            )}`}
                            variant="small2Normal">
                            {displayUSD(paymentTax)}
                          </Typography>
                        </Box>
                      </Container>
                    </>
                  )}

                  <Container className={classes.paymentInnerContainer}>
                    <Divider className={classes.divider} />
                    <Box className={classes.reservationTotal}>
                      <Typography variant="small2Semibold">
                        {PAYMENT_TOTAL_LABEL}
                      </Typography>

                      <Typography
                        aria-label={`${PAYMENT_TOTAL_LABEL_ALT_TEXT} ${totalAmount}`}
                        variant="small2Normal">
                        {totalAmount}
                      </Typography>
                    </Box>
                  </Container>
                </>
              )}
              {getTypeFromStatus(reservation.status) !== 'Canceled' &&
                !isReservationPast(reservation) && (
                  <>
                    <Divider />
                    <Container className={classes.policyContainer}>
                      {reservation.paymentPolicy?.trim() && (
                        <Policy
                          header={PAYMENT_POLICY_LABEL}
                          venuePolicy={reservation?.paymentPolicy}
                          className={classes.paymentPolicy}
                        />
                      )}
                      {renderBookingPolicy()}
                    </Container>
                    <Box px={width >= widthBreakpoint ? 0 : 3}>
                      {renderInfo()}
                      {renderButtons()}
                    </Box>
                  </>
                )}
            </Box>
            {renderCancelReserveModal()}
          </PageSection>
        </main>
      )}
    </>
  );
};

export default ReservationDetails;
