import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Typography from '../../overrides/Typography';
import SelectText from '../../core/SelectText';
import DownArrow from '../../../assets/icons/dropdown.svg';
import { focusVisible } from '../../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    ...focusVisible(),
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
    width: '100vw',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.secondary[60]}`,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
  },
  accordionSummary: {
    marginBottom: -1,
    margin: 0,
    padding: theme.spacing(0, 3),
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  accordionDetails: {
    padding: theme.spacing(0, 3, 3),
    width: 'calc(100% - 48px)',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(-1),
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}));

const FilterSection = ({
  id,
  list = [],
  title,
  activeList,
  onChange,
  defaultExpanded = false,
  hideIcon = false,
}) => {
  const classes = useStyles();

  const renderList = () =>
    list
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((item, index) => (
        <SelectText
          key={`filterSelect-${index}`}
          item={item}
          list={activeList}
          onClick={() => onChange(item, id)}
        />
      ));

  if (!list.length) return null;

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      classes={{ root: classes.accordion, expanded: classes.expanded }}>
      <AccordionSummary
        id={id}
        expandIcon={
          !hideIcon && (
            <img src={DownArrow} alt="expand" className={classes.image} />
          )
        }
        classes={{
          root: classes.accordionSummary,
          content: classes.content,
          expanded: classes.expanded,
        }}>
        <Typography variant="medium1Semibold">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {renderList()}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterSection;
