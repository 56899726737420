import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionSetSearchOverlayVisible } from '../../../store/Search/SearchAction';
import { useSearchParams } from '../helpers/useSearchParams';
import VenueResult from '../VenueResult';

const SearchResultsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageNumber } = useSearchParams();
  const currentPage = useSelector((state) => state.venues.pages?.[pageNumber]);

  const handleVenueSelect = useCallback(
    (id) => {
      dispatch(actionSetSearchOverlayVisible(false));
      history.push(`/venue/${id}`, { isSearchResult: true });
    },
    [dispatch, history]
  );

  if (!currentPage) return null;
  return currentPage.map((venue) => (
    <VenueResult
      venue={venue}
      key={venue.id}
      onVenueSelect={handleVenueSelect}
    />
  ));
};

export default SearchResultsList;
