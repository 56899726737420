import React, { useEffect, useState, Children } from 'react';
import { CSSTransition } from 'react-transition-group';

const Transition = ({
  children,
  classNames = {},
  timeout = 1000,
  unmountOnExit = true,
  delay = 0,
  appear = true,
  active = true,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) setTimeout(() => setVisible(true), delay);
  }, [delay, visible]);

  return Children.map(children, (child) => (
    <CSSTransition
      timeout={timeout}
      in={active && visible}
      classNames={classNames}
      unmountOnExit={unmountOnExit}
      appear={appear}
      {...props}>
      {child}
    </CSSTransition>
  ));
};

export default Transition;
