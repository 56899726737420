import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';
import { useViewport } from '../../utils/useViewport';
import Typography from '../overrides/Typography';
import BlueBackButton from './BlueBackButton';
import PageSection from './PageSection';

const useStyles = makeStyles((theme) => ({
  desktopGrid: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  layout: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      paddingTop: 0,
    },
  },
  buttonContainer: {
    marginBottom: 10,
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },
  title: {
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const widthBreakpoint = 960;

const BackButtonLayout = ({
  back,
  buttonRole = 'button',
  className,
  container = true,
  spacing = 2,
  title = '',
  xl = 6,
  children,
  ...contentProps
}) => {
  const classes = useStyles();
  const { width } = useViewport();

  const titleExists = Boolean(title);

  return (
    <PageSection
      className={classes.desktopGrid}
      variant={width > widthBreakpoint ? 'grid' : null}>
      {width > widthBreakpoint && (
        <Grid item container xs={10} className={classes.buttonContainer}>
          <BlueBackButton
            back={back}
            height={17}
            role={buttonRole}
            width={24}
          />
        </Grid>
      )}
      <Grid
        item
        container={container}
        className={clsx(classes.layout, className)}
        spacing={spacing}
        xl={xl}
        {...contentProps}>
        {width <= widthBreakpoint && (
          <Grid xs={12} item>
            <BlueBackButton back={back} role={buttonRole} />
          </Grid>
        )}
        {titleExists && (
          <Grid item xs={12} className={classes.title}>
            <Typography
              component="h1"
              variant={
                width > widthBreakpoint ? 'large1Light' : 'medium3Light'
              }>
              {title}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </PageSection>
  );
};

export default BackButtonLayout;
