import { getDefaultDateTimeForFab } from './defaultDateTimeHelper';

const defaultReservation = () => {
  const { defaultDate, defaultTime } = getDefaultDateTimeForFab();
  const defaultGuests = sessionStorage.getItem('defaultGuests') || 2;

  return {
    defaultDate,
    defaultTime,
    defaultGuests,
  };
};

export default defaultReservation;
