import React from 'react';
import { Container, Box } from '@material-ui/core';
import Typography from '../overrides/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '../../components/overrides/Divider';
import PhoneField from '../../pages/reserve/PhoneField';
import {
  CONTACT_INFO_NO_PHONE_NUMBER,
  CONTACT_INFO_TITLE,
} from '../../assets/copy';

const useStyle = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0),
  },
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3, 0),
    },
  },
  title: {
    display: 'flex',
    placeContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  phoneContainer: {
    marginTop: theme.spacing(2),
  },
}));

const PhoneNumberInfo = ({ isPhoneNumberFieldDisplayed = false }) => {
  const classes = useStyle();

  if (!isPhoneNumberFieldDisplayed) {
    return null;
  }

  return (
    <>
      <Divider />
      <Container className={classes.root}>
        <Box className={classes.title}>
          <Typography component="h2" variant="medium1Semibold">
            {CONTACT_INFO_TITLE}
          </Typography>
        </Box>
        <Box>
          <Typography variant="medium1Normal">
            {CONTACT_INFO_NO_PHONE_NUMBER}
          </Typography>
        </Box>
        <Box className={classes.phoneContainer}>
          <PhoneField />
        </Box>
      </Container>
    </>
  );
};

export default PhoneNumberInfo;
