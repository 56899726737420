import { useViewport } from '../../utils/useViewport';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import {
  handleCancel,
  handleCancelEdit,
  handleCancelEditReservation,
  handleEdit,
  handleToggleEditModal,
  handleUpdated,
} from './helpers/reserveHelpers';
import PageSection from '../../components/core/PageSection';
import { Box } from '@material-ui/core';
import CloseButton from '../../components/core/CloseButton';
import {
  CLOSE_BUTTON_DESCRIPTION,
  CLOSE_BUTTON_LABEL,
} from '../../assets/copy';
import Title from '../../components/reserve/Title';
import ReservationCard from '../../components/reserve/ReservationCard';
import FormFooter from '../../components/reserve/FormFooter';
import CloseButtonForm from '../../components/reserve/CloseButtonForm';
import CancelEditModal from '../../components/reserve/CancelEditModal';
import { makeStyles } from '@material-ui/core/styles';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import ReservationDetails from './ReservationDetailsComponent';
import CommonLoading from '../common/CommonLoading';
import { Redirect } from 'react-router-dom';
import { useReserve } from '../../utils/useReserve';
import SignatureCollectionDescription from '../../components/spoon/SignatureCollectionDescription';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  close: {},
  closeButtonContainer: {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    overflow: 'hidden',
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '100%',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '25px',
      marginRight: 'auto',
      maxWidth: 'calc(100% - 25px)',
    },
  },
  desktopLayoutCard: {
    flex: '0 0 350px',
    gap: theme.spacing(3.5),
    display: 'flex',
    flexDirection: 'column',
  },
  desktopLayoutContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  desktopLayoutContent: {
    boxSizing: 'border-box',
    maxWidth: `calc(531px + ${theme.spacing(3)})`,
  },
  desktopLayoutTitleContainer: {
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));

const Reserve = ({ type, onEditPayment }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    draftReservation,
    handleReserve,
    isPaymentDisabled,
    mounted,
    navigationMenuVisible,
    paymentRule,
    redirectToConfirmPage,
    reservation,
    reservationChargesProperties,
    reservationCreateStatus,
    reservationUpdateStatus,
    shouldShowLoader,
    shouldShowStripeForm,
    showAlert,
    showInfoBlock,
    stripeAlertMessage,
    stripeProperties,
    venuePaymentPolicy,
  } = useReserve();

  const [modalOpen, setModalOpen] = useState(false);

  const updateNotes = (notes) => {
    const updatedDraftReservation = draftReservation;
    updatedDraftReservation.notes = notes;
    dispatch(actionSetDraftReservation(updatedDraftReservation));
  };

  const updateSelectOccasions = (list) => {
    const updatedDraftReservation = draftReservation;
    updatedDraftReservation.specialOccasions = list;
    dispatch(actionSetDraftReservation(updatedDraftReservation));
  };

  const modalCancelAction = () =>
    history?.location?.state?.type === RESERVATION_TYPE.UPDATE
      ? handleCancelEditReservation(
          history,
          dispatch,
          setModalOpen,
          reservation
        )
      : handleCancelEdit(history, dispatch, setModalOpen);

  const handleClose = () => {
    history?.location?.state?.type === RESERVATION_TYPE.UPDATE
      ? handleToggleEditModal(modalOpen, setModalOpen)
      : modalCancelAction();
  };

  const closeButtonContainerMb = width > WIDTH_BREAKPOINT ? 3 : 2;

  const closeButtonWidth = width > WIDTH_BREAKPOINT ? 225 : 'auto';

  /*
    Don't show the loader if using Stripe form, since Stripe requires card form
    element to be visible in DOM, and the loader would remove the form from the DOM
  */
  if (shouldShowLoader && !shouldShowStripeForm) {
    return <CommonLoading active={shouldShowLoader} />;
  }

  if (redirectToConfirmPage) {
    return (
      <Redirect
        to={{
          pathname: '/reserve/confirmed',
          fromReservationPage: true,
          reservationCreateStatus,
          reservationUpdateStatus,
        }}
      />
    );
  }

  return (
    <PageSection className={classes.root}>
      {mounted && width > WIDTH_BREAKPOINT ? (
        <>
          <main className={classes.desktopLayoutContainer}>
            <Box mb={5} className={classes.desktopLayoutTitleContainer}>
              <Box
                className={classes.closeButtonContainer}
                mb={closeButtonContainerMb}
                display="flex"
                justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="center"
                  width={closeButtonWidth}>
                  <CloseButton
                    className={classes.close}
                    close={handleClose}
                    description={CLOSE_BUTTON_DESCRIPTION}
                    fill="#0D74AF"
                    label={CLOSE_BUTTON_LABEL}
                    role="link"
                  />
                </Box>
              </Box>
              <Box aria-hidden="true">
                <Title type={type} />
              </Box>
            </Box>
            <Box className={classes.desktopLayoutCard}>
              <ReservationCard
                type={type}
                reservation={draftReservation}
                showActionLinks={false}
                showSeeVenueLink={false}
              />
              {draftReservation.venue?.isSignatureCollection && (
                <SignatureCollectionDescription />
              )}
            </Box>
            <Box className={classes.desktopLayoutContent} pl={4}>
              <ReservationDetails
                type={type}
                paymentRule={paymentRule}
                stripeProperties={stripeProperties}
                reservationChargesProperties={reservationChargesProperties}
                reservation={draftReservation}
                updateNotes={updateNotes}
                updateSelectOccasions={updateSelectOccasions}
                showErrorAlert={showAlert}
                diningOptionsSlotName={draftReservation?.slotName}
                paymentPolicy={venuePaymentPolicy}
                bookingPolicy={draftReservation?.bookingPolicy}
                showInfoBlock={showInfoBlock}
                onEditPayment={onEditPayment}
                shouldShowStripeForm={shouldShowStripeForm}
                stripeAlertMessage={stripeAlertMessage}
              />
              <FormFooter
                type={type}
                isPaymentDisabled={isPaymentDisabled}
                handleReserve={handleReserve}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
                handleUpdated={() =>
                  handleUpdated(
                    history,
                    dispatch,
                    draftReservation,
                    reservation
                  )
                }
              />
            </Box>
          </main>
        </>
      ) : (
        !navigationMenuVisible && (
          <>
            <main mb={2} className={classes.closeButtonContainer}>
              <CloseButtonForm
                handleClose={handleClose}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                toggleModal={handleToggleEditModal}
              />
              <Title type={type} aria-live="polite" />
              <ReservationCard type={type} reservation={draftReservation} />
              <Box m={3}>
                {draftReservation.venue?.isSignatureCollection && (
                  <SignatureCollectionDescription />
                )}
              </Box>
              <ReservationDetails
                onEditPayment={onEditPayment}
                type={type}
                reservation={draftReservation}
                paymentRule={paymentRule}
                stripeProperties={stripeProperties}
                reservationChargesProperties={reservationChargesProperties}
                updateNotes={updateNotes}
                updateSelectOccasions={updateSelectOccasions}
                showErrorAlert={showAlert}
                diningOptionsSlotName={draftReservation?.slotName}
                paymentPolicy={venuePaymentPolicy}
                bookingPolicy={draftReservation?.bookingPolicy}
                showInfoBlock={showInfoBlock}
                shouldShowStripeForm={shouldShowStripeForm}
                stripeAlertMessage={stripeAlertMessage}
              />
              <FormFooter
                type={type}
                isPaymentDisabled={isPaymentDisabled}
                handleReserve={handleReserve}
                handleEdit={handleEdit}
                handleCancel={() => () => handleCancel(history)}
                handleUpdated={() =>
                  handleUpdated(
                    history,
                    dispatch,
                    draftReservation,
                    reservation
                  )
                }
              />
            </main>
          </>
        )
      )}
      <CancelEditModal
        modalOpen={modalOpen}
        handleCancelEdit={modalCancelAction}
        handleToggleEditModal={() =>
          handleToggleEditModal(modalOpen, setModalOpen)
        }
      />
    </PageSection>
  );
};

export default Reserve;
