import Divider from '../../components/overrides/Divider';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { TIME_AVAILABILITY_TYPES } from './timeAvailabilityTypes';
import Typography from '../../components/overrides/Typography';
import { ReactComponent as CancelIcon } from '../../assets/icons/grv-cancel.svg';
import TextButton from '../../components/core/TextButton';
import { UiChevronRightLined } from '@cof/omni-gravity-icons-react';
import palette from '../../theme/palette';
import { useCopy } from '../../utils/useCopy';
import { DateTime } from 'luxon';
import { actionSetCalendarSelectedDate } from '../../store/Availability/AvailabilityAction';
import { useDispatch } from 'react-redux';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  noAvailabilityDivider: {
    margin: theme.spacing(2, 0, 0),
    [theme.breakpoints.up('xl')]: {
      margin: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE)
          return theme.spacing(3, 5, 0);
        return theme.spacing(2, 7, 2);
      },
    },
  },
  noAvailabilityContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noAvailabilityContent: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '587px',
    },
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: '6px',
    backgroundColor: theme.palette.background.grayLight,
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '12px',
    marginLeft: '8px',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const NoCalendarAvailabilityInfo = ({
  date,
  containerClassName,
  contentClassName,
  useDivider = true,
  dividerClassName,
  type,
}) => {
  const classes = useStyles({ type });
  const { getCopy } = useCopy();
  const dispatch = useDispatch();

  const currentMonthName = date.toLocaleString('default', { month: 'long' });
  const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const nextMonthName = nextMonthDate.toLocaleString('default', {
    month: 'long',
  });

  const noAvailabilityMessage = getCopy('CALENDAR_NO_AVAILABILITY_TEXT', {
    month: currentMonthName,
  });
  const noAvailabilityCTA = getCopy('CALENDAR_SEARCH_AVAILABILITY_TEXT', {
    month: nextMonthName,
  });

  const handleClick = (e) => {
    trackClick(e, 'calendar-no-availability-cta', 'Link');
    const oneMonthAfterStartDate = DateTime.fromJSDate(date)
      .plus({ months: 1 })
      .startOf('month')
      .toJSDate();
    dispatch(actionSetCalendarSelectedDate(oneMonthAfterStartDate));
  };

  return (
    <>
      <div
        className={clsx(classes.noAvailabilityContainer, containerClassName)}>
        <div className={clsx(classes.noAvailabilityContent, contentClassName)}>
          <div className={classes.alertContainer}>
            <CancelIcon className={classes.icon} role="presentation" />
            <Typography variant="small2Normal" className={classes.child}>
              {noAvailabilityMessage}
            </Typography>
          </div>
          <div className={classes.ctaContainer}>
            <TextButton variant="medium1Semibold" onClick={handleClick}>
              {noAvailabilityCTA}
            </TextButton>
            <UiChevronRightLined
              className={clsx(classes.pointer, classes.icon)}
              color={palette.interactionBlue['main']}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
      {useDivider && (
        <Divider
          className={clsx(classes.noAvailabilityDivider, dividerClassName)}
        />
      )}
    </>
  );
};

export default NoCalendarAvailabilityInfo;
