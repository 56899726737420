export const DATE_FORMAT_YEAR_FIRST = 'YYYY-MM-DD';
export const DATE_FORMAT_MONTH_FIRST = 'MM/DD/YYYY';
export const SHORT_DATE = 'MMM D';
export const SHORT_DATE_WITH_WEEKDAY = 'ddd, MMM D';
export const MEDIUM_DATE = 'MMMM D';
export const LONG_DATE_WITH_WEEKDAY = 'dddd, MMMM D';
export const DATE_TIME = 'YYYY-MM-DD hh:mm:ss';
export const DAY_MONTH_DATE = 'ddd, MMM D';
export const SPECIAL_EVENT_FORMAT = 'dddd, MMMM D';
export const SPECIAL_DATE_LINK = 'MMM D';

export const SPECIAL_EVENT_MONTH_DATE = 'LLL dd';
export const SPECIAL_EVENT_DATE = 'd';
export const SPECIAL_EVENT_MONTH_DATE_YEAR = 'LLL dd, yyyy';
export const SPECIAL_EVENT_DATE_SELECT_FORMAT = 'ccc, LLL dd, yyyy';
export const YEAR_MONTH_DATE_FORMAT = 'yyyy-MM-dd';
export const FULL_MONTH_ONLY_FORMAT = 'LLLL';

export const YEAR_MONTH_FORMAT = 'yyyy-LL';
export const DATE_FORMAT = 'dd';
export const FULL_MONTH_NAME_AND_DATE_FORMAT = 'MMMM d';
export const FULL_MONTH_NAME_FORMAT = 'MMMM';
export const FULL_DAY_FORMAT = 'CCCC';