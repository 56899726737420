import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import PageSection from '../core/PageSection';
import GalleryHeader from './GalleryHeader';
import GalleryItem from './GalleryItem';
import { actionSetCurrentFilters } from '../../store/Filter/FilterAction';
import { FILTER_TYPES, VENUE_TYPES } from '../../utils/constants/FilterTypes';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from '../../utils/stringHelperMethods';
import { dollarSymbolText } from '../../utils/venue';
import Carousel from '../core/Carousel';
import { useSearchRedirect } from '../search/helpers';
import { RESTAURANTS_TEXT, EVENTS_TEXT } from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  galleyList: {
    display: 'flex',
    overflowX: 'scroll',
    paddingBottom: theme.spacing(2),
    columnGap: theme.spacing(2),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      columnGap: theme.spacing(4),
      padding: theme.spacing(0, 5, 4),
      margin: theme.spacing(0),
    },
  },
  eventsCarouselRoot: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(2),
      marginRight: 0,
    },
  },
  eventsCarouselContainer: {
    maxWidth: '736px',
  },
}));

const GalleryList = ({
  category,
  title,
  description,
  list,
  showHeaderLink = true,
  isGetTicketLinkShown = false,
  customLink = null,
  hasUpdatedMarket,
  isEventCarousel = false,
  isEventsDetailPage = false,
  isCompact = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const dispatch = useDispatch();

  const handleVenueSelect = (item) => {
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_name: `homepage-venue-card`,
      ui_interaction_element_type: 'Card',
      ui_interaction_element_description: `venueName: ${item.name}, carouselHeader: ${title}`
    });
    history.push(`/venue/${item.id}`);
  };
  const handleSectionViewAll = () => {
    if (isEventCarousel) {
      history.push('/dining-experience');
    } else {
      dispatch(
        actionSetCurrentFilters({
          [FILTER_TYPES.CATEGORIES]: [category.category],
        })
      );
      history.push('/search', { redirectTo });
    }
  };
  const getScreenReaderDescription = (item) => {
    const eventStatus = item?.venueType === VENUE_TYPES.EVENT;
    const cuisine = convertNullToEmptyString(
      item.cuisines?.items[0]?.cuisine?.name
    );
    const price = item.priceRange ? dollarSymbolText(item.priceRange) : '';
    const city = eventStatus ? convertNullToEmptyString(item.city) : '';
    const location = eventStatus
      ? convertNullToEmptyString(item.state)
      : convertNullToEmptyString(item.neighborhood?.name);
    return generateScreenReaderText(
      ',',
      item.name,
      cuisine,
      price,
      city,
      location
    );
  };

  const getDescription = (item) => {
    const eventStatus = item?.venueType === VENUE_TYPES.EVENT;
    const cuisine = convertNullToEmptyString(
      item.cuisines?.items[0]?.cuisine?.name
    );
    const price = convertNullToEmptyString(item.priceRange);
    const city = eventStatus ? convertNullToEmptyString(item.city) : '';
    const location = eventStatus
      ? convertNullToEmptyString(item.state)
      : convertNullToEmptyString(item.neighborhood?.name);
    return [cuisine, price, city, location]
      .filter((item) => !!item)
      .join(' • ');
  };

  return (
    <PageSection className={isEventsDetailPage && classes.eventsCarouselRoot}>
      <GalleryHeader
        category={category}
        title={title}
        description={description}
        list={list}
        onClick={() => {
          handleSectionViewAll();
        }}
        showHeaderLink={showHeaderLink}
        customLink={customLink}
        isCompact={isCompact}
        isEventCarousel={isEventCarousel}
        isEventsDetailPage={isEventsDetailPage}
      />
      <div className={classes.galleyList}>
        {list && list.length > 0 && (
          <Carousel
            containerClass={
              isEventsDetailPage && classes.eventsCarouselContainer
            }
            shadowPadding={4}
            defaultItemIndex={hasUpdatedMarket && 0}
            forceUpdatePosition={hasUpdatedMarket}
            description={description}
            elementName={isEventCarousel ? EVENTS_TEXT : RESTAURANTS_TEXT}>
            {list &&
              list.map((item, index) => {
                return (
                  <GalleryItem
                    image={item.images[0]}
                    label={item.name}
                    description={getDescription(item)}
                    screenReaderText={getScreenReaderDescription(item)}
                    key={`Category-Venue-${index}`}
                    onClick={()=>handleVenueSelect(item)}
                    venue={item}
                    isGetTicketLinkShown={isGetTicketLinkShown}
                    isEventCarousel={isEventCarousel}
                    isCompact={isCompact}
                  />
                );
              })}
          </Carousel>
        )}
      </div>
    </PageSection>
  );
};

export default GalleryList;
