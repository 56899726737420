import { makeStyles } from '@material-ui/core';
import GalleryList from '../../components/home/GalleryList';
import { EVENT_CAROUSEL_DESCRIPTION } from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  otherSection: ({ isEventsDetailPage }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('xl')]: {
      marginTop: isEventsDetailPage ? theme.spacing(0) : theme.spacing(7),
    },
    width: '100%',
  }),
}));

const EventsCarousel = ({
  list,
  isCompact = false,
  hasUpdatedMarket,
  title,
  isEventCarousel,
  shouldShowDescription = false,
  isEventsDetailPage = false,
}) => {
  const classes = useStyles({ isEventsDetailPage });

  return (
    <div key={'events'} className={classes.otherSection}>
      <GalleryList
        title={title}
        description={shouldShowDescription && EVENT_CAROUSEL_DESCRIPTION}
        list={list}
        role="link"
        hasUpdatedMarket={hasUpdatedMarket}
        isGetTicketLinkShown={false}
        isCompact={isCompact}
        isEventCarousel={isEventCarousel}
        isEventsDetailPage={isEventsDetailPage}
      />
    </div>
  );
};

export default EventsCarousel;
