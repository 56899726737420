import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '../overrides/Typography';
import ChefItemHorizontal from './ChefItem';
import ChefItemVertical from './ChefItemVertical';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
  },
  fourColumnGrid: {
    gap: theme.spacing(4),
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
  threeColumnGrid: {
    gap: theme.spacing(4),
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
  twoColumnGrid: {
    gap: theme.spacing(6),
    gridTemplateColumns: `repeat(2, 1fr)`,
  },
  oneColumnGrid: {
    gap: theme.spacing(6),
    gridTemplateColumns: '1fr',
  },
  chefName: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
}));

const VenueGrid = ({
  columns = 'auto',
  venues,
  name,
  allSelectionsSection,
}) => {
  const classes = useStyles();

  const getGridClasses = () => {
    const gridClasses = [classes.grid];

    if (isColumnMatch(4)) {
      gridClasses.push(classes.fourColumnGrid);
    } else if (isColumnMatch(3)) {
      gridClasses.push(classes.threeColumnGrid);
    } else {
      // 1 or 2 items will use twoColumnGrid
      gridClasses.push(classes.twoColumnGrid);
    }
    return gridClasses;
  };

  const isColumnMatch = (numberOfColumns) =>
    columns === numberOfColumns ||
    (columns === 'auto' && venues.length === numberOfColumns);

  const sectionTitle = name ? name : '';

  return venues && venues.length > 0 ? (
    <>
      {sectionTitle && (
        <Typography
          variant="medium3Light"
          className={classes.chefName}
          component="h3"
          aria-label={sectionTitle}>
          {sectionTitle}
        </Typography>
      )}
      <Box className={clsx(getGridClasses())}>
        {venues?.map((venue, index) =>
          venues.length < 3 || allSelectionsSection ? (
            <ChefItemHorizontal
              large={!isColumnMatch(4)}
              venue={venue}
              key={`venue-${index}`}
              allSelectionsSection={allSelectionsSection}
            />
          ) : (
            <ChefItemVertical
              large={!isColumnMatch(4)}
              venue={venue}
              key={`venue-${index}`}
            />
          )
        )}
      </Box>
    </>
  ) : null;
};

VenueGrid.propTypes = {
  columns: PropTypes.oneOf(['auto', 1, 2, 3, 4]),
  venues: PropTypes.array,
};

export default VenueGrid;
