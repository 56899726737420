import React from 'react';
import Typography from '../overrides/Typography';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    image: {
        marginRight: theme.spacing(1),
        height: 16,
        width: 16
    },
}));



const TextIcon = ({ text, variant='medium1Normal', src, iconStyleOverride = {}, textStyleOverride = {}, role="presentation" }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box display='flex' flexDirection='column' justifyContent='center'>
                <Box component="img" className={clsx(classes.image, iconStyleOverride)} src={src} role={role}></Box>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='center'>
                <Typography variant={variant} className={clsx(classes.text, textStyleOverride)}>{text}</Typography>
            </Box>
        </Box>
    );
}

export default TextIcon;
