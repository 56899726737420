import * as TYPES from './FilterTypes';
import {
  getEmptyFilters,
  getFiltersCount,
  removeFilterItem,
  updateFiltersByType,
} from '../../utils/searchFilters';

const initialState = {
  filterOverlayVisible: false,
  cachedFilters: null,
  currentFilters: getEmptyFilters(),
};

export default function searchReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.CACHE_CURRENT_FILTERS:
      if (getFiltersCount(state.currentFilters) === 0) return state;
      return {
        ...state,
        cachedFilters: state.currentFilters,
        currentFilters: getEmptyFilters(),
      };
    case TYPES.REMOVE_CURRENT_FILTER_ITEM:
      return {
        ...state,
        currentFilters: removeFilterItem(state.currentFilters, payload),
      };
    case TYPES.RESET_CACHED_FILTERS:
      return {
        ...state,
        cachedFilters: null,
      };
    case TYPES.RESET_CURRENT_FILTER:
      if (state.currentFilters[payload].length === 0) return state;
      return {
        ...state,
        currentFilters: { ...state.currentFilters, [payload]: [] },
      };
    case TYPES.RESET_CURRENT_FILTERS:
      return {
        ...state,
        currentFilters: getEmptyFilters(),
      };
    case TYPES.SET_CURRENT_FILTERS:
      return {
        ...state,
        currentFilters: updateFiltersByType(payload),
      };
    case TYPES.SET_CURRENT_FILTERS_FROM_CACHE:
      if (state.cachedFilters == null) return state;
      return {
        ...state,
        cachedFilters: null,
        currentFilters: state.cachedFilters,
      };
    case TYPES.SET_FILTER_OVERLAY_VISIBLE:
      return {
        ...state,
        filterOverlayVisible: payload,
      };
    default:
      return state;
  }
}
