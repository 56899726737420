import React, { useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  RESERVATIONS_NO_UPCOMING_SUBTITLE,
  RESERVATIONS_NO_UPCOMING_TITLE,
  RESERVATIONS_SEE_DETAILS,
  SEE_MORE_RESTAURANTS_BUTTON_TEXT,
} from '../../assets/copy';
import calendarImg from '../../assets/icons/drawn-calendar.svg';
import LargeCard from '../core/Card/LargeCard';
import Typography from '../overrides/Typography';
import NoReservations from './NoReservations';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  discoverText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  linkContainer: {
    marginTop: theme.spacing(4),
    textDecoration: 'none',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(6),
    },
  },
}));

const UpcomingReservation = ({ reservation, history, selectReservation }) => {
  const handleGridClick = useCallback(
    () => selectReservation(reservation),
    [selectReservation, reservation]
  );
  const handleCardClick = useCallback(
    () => history.push(`/reservations/${reservation.id}`),
    [history, reservation]
  );
  
  return (
    <Grid
      item
      key={`upcoming_reservation_${reservation.id}`}
      onClick={handleGridClick}
      xl={4}
      xs={12}>
      <LargeCard
        linkText={RESERVATIONS_SEE_DETAILS}
        onCardClick={handleCardClick}
        showActionLinks={false}
        reservation={reservation}
        venue={reservation.venue}
        focusable={false}
      />
    </Grid>
  );
};

const UpcomingReservations = ({ upcomingReservations, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToHome = useCallback(() => {
    history.push('/');
  }, [history]);

  if (!upcomingReservations?.length) {
    return (
      <NoReservations
        imageSrc={calendarImg}
        message={RESERVATIONS_NO_UPCOMING_SUBTITLE}
        title={RESERVATIONS_NO_UPCOMING_TITLE}
      />
    );
  }

  const handleSeeMoreClick = (event) => {
    trackClick(event, "upcoming:see-more-restaurants", "Button");
  }

  return (
    <>
      <Grid container spacing={4}>
        {upcomingReservations.map((reservation) => (
          <UpcomingReservation
            history={history}
            reservation={reservation}
            {...props}
          />
        ))}
      </Grid>
      <Box
        className={classes.linkContainer}
        onClick={goToHome}
        onKeyPress={goToHome}>
        <Typography
          align="center"
          tabindex={0}
          className={classes.discoverText}
          role="link"
          onClick={handleSeeMoreClick}
          data-cy="upcoming:see-more-restaurants"
          variant="medium2Normal">
          {SEE_MORE_RESTAURANTS_BUTTON_TEXT}
        </Typography>
      </Box>
    </>
  );
};

export default UpcomingReservations;
