import * as types from './SearchTypes';

export const actionAddRecentSearch = (payload) => ({
  type: types.ADD_RECENT_SEARCH,
  payload,
});

export const actionSetSearchOverlayVisible = (payload) => ({
  type: types.SET_SEARCH_OVERLAY_VISIBLE,
  payload,
});

export const actionSetSearchCachedQuery = (payload) => ({
  type: types.SET_SEARCH_CACHED_QUERY,
  payload,
});

export const actionSetSearchCurrentQuery = (payload) => ({
  type: types.SET_SEARCH_CURRENT_QUERY,
  payload,
});

export const actionSetSearchCurrentInputValue = (payload) => ({
  type: types.SET_SEARCH_CURRENT_INPUT_VALUE,
  payload,
});

export const actionCacheSearchSessionId = () => ({
  type: types.CACHE_SEARCH_SESSION_ID,
});

export const actionResetSearchSessionIdCache = () => ({
  type: types.RESET_SEARCH_SESSION_ID_CACHE,
});

export const actionSetCurrentSearchSessionIdFromCache = () => ({
  type: types.SET_CURRENT_SEARCH_SESSION_ID_FROM_CACHE,
});

export const actionSetSearchCurrentSessionId = (payload) => ({
  type: types.SET_SEARCH_CURRENT_SESSION_ID,
  payload,
});
