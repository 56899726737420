import React from 'react';
import MaskedInput from 'react-text-mask';

const CalendarInput = (props) => {
  const { ref, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref}
      guide={false}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

export default CalendarInput;
