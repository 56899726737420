/**
 * Generates a random string of specified length. This will output a
 * cryptographic random string, safe for use as a state parameter in OAuth2
 * flow.
 *
 * @see [Generating Cryptographic Random State in JavaScript](https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50)
 *
 * @param {int} length Optional; defaults to `40`. Length of random string.
 * @returns {string} cryptographic random string of specified length
 */
export const generateRandomString = (length = 40) => {
  const validChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(length);
  crypto.getRandomValues(array);
  array = array.map((x) => validChars.charCodeAt(x % validChars.length));
  return String.fromCharCode.apply(null, array);
};

export const generateRandomUUID = () => crypto.randomUUID();
