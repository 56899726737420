import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
//ui
import Typography from '../../overrides/Typography';
import { getCheckboxColor } from './helpers';

const GravityCheckbox = ({
  error = false,
  compact = false,
  label = '',
  checked = false,
  indeterminate = false,
  disabled = false,
  onChange,
  labelClassName,
  ...other
}) => {
  return (
    <FormControlLabel
      label={
        <Typography variant={compact ? 'formFieldCompact' : 'medium1Normal'}>
          {label}
        </Typography>
      }
      className={labelClassName}
      control={
        <Checkbox
          style={{
            color: getCheckboxColor(disabled, error, checked),
            transform: compact ? 'scale(0.75)' : '',
          }}
          color="primary"
          indeterminate={indeterminate}
          aria-checked={checked}
          checked={checked}
          onChange={onChange}
          {...other}
        />
      }
    />
  );
};

export default GravityCheckbox;
