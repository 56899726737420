import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';

const useStyles = makeStyles(() => ({
  open: {
    '&-enter': {
      opacity: 0,
      '&-active': {
        opacity: 1,
        transition: 'all .4s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
    '&-exit': {
      opacity: 1,
      '&-active': {
        opacity: 0,
        transition: 'all .4s cubic-bezier(0.33, 1, 0.68, 1)',
      },
    },
  },
}));

const MenuAnimation = ({ children, isOpen = false }) => {
  const classes = useStyles();
  return (
    <CSSTransition
      timeout={400}
      unmountOnExit
      in={isOpen}
      classNames={classes.open}>
      {children}
    </CSSTransition>
  );
};

export default MenuAnimation;
