import { put, takeLatest } from 'redux-saga/effects';
import { listDiets } from '../../graphql/queries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './DietsAction';
import * as TYPES from './DietsTypes';

export function* dietListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listDiets,
      variables: { filter },
    });
    yield put(ACTIONS.actionDietListSuccess(result.data.listDiets.items));
  } catch (error) {
    console.error(error);
    yield dispatchError(error.message);
    yield put(ACTIONS.actionDietListFail(error));
  }
}

export default function* dietsSaga() {
  yield takeLatest(TYPES.DIET_LIST_REQUEST, dietListRequestHandler);
}
