export const updateMarketVenueAvailability = (market, payload) => {
  const draftMarket = { ...market };
  const draftVenues = draftMarket.venues.items.map((venue) => {
    const draftVenue = { ...venue };
    const foundVenue = payload.find(
      (item) => item.sevenRoomsVenueId === venue.sevenRoomsVenueId
    );
    if (foundVenue) draftVenue.availability = foundVenue.availability;
    return draftVenue;
  });
  draftMarket.venues.items = draftVenues;
  return draftMarket;
};

export const clearMarketVenueAvailability = (market) => {
  const draftMarket = { ...market };
  const draftVenues = draftMarket.venues.items.map((venue) => {
    const draftVenue = { ...venue };
    draftVenue.availability = null;
    return draftVenue;
  });
  draftMarket.venues.items = draftVenues;
  return draftMarket;
};
