import gravityPalette from '../global/gravityPalette';

export const CHECKBOX_COLOR = Object.freeze({
  DISABLED: gravityPalette.checkbox.unchecked.disabled,
  ERROR: gravityPalette.checkbox.unchecked.error,
  CHECKED: gravityPalette.checkbox.checked.active,
  DEFAULT: gravityPalette.checkbox.unchecked.active,
});

export const getCheckboxColor = (disabled, error, checked) => {
  if (disabled) return CHECKBOX_COLOR.DISABLED;
  if (error) return CHECKBOX_COLOR.ERROR;
  if (checked) return CHECKBOX_COLOR.CHECKED;
  return CHECKBOX_COLOR.DEFAULT;
};
