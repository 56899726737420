import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  actionResetCurrentFilters,
  actionSetCurrentFilters,
} from '../../../store/Filter/FilterAction';
import {
  actionAddRecentSearch,
  actionSetSearchCurrentInputValue,
  actionSetSearchCurrentQuery,
} from '../../../store/Search/SearchAction';
import { FILTER_TYPES } from '../../../utils/constants/FilterTypes';
import { mapSearches } from '../helpers/mapSearches';

const updateMap = (items = []) => {
  if (!items?.length) return {};

  return items.reduce((map, item) => {
    map[item.id] = item;

    return map;
  }, {});
};

const isFunction = (handler) =>
  handler != null && typeof handler === 'function';

export const handleCuisineSelect = (cuisine, dispatch) => {
  dispatch(actionSetSearchCurrentQuery(''));
  dispatch(actionSetSearchCurrentInputValue(''));
  dispatch(actionSetCurrentFilters({ [FILTER_TYPES.CUISINES]: [cuisine] }));
};

export const handleVenueSelect = (item, history) => {
  history.push(`/venue/${item.id}`);
};

export const handleSearchSelect = (query, dispatch) => {
  if (!query) return;
  const recentSearch = query.id ? query.id : query;
  dispatch(actionSetSearchCurrentInputValue(recentSearch));
  dispatch(actionSetSearchCurrentQuery(recentSearch));
  dispatch(actionResetCurrentFilters());
  dispatch(actionAddRecentSearch(recentSearch));
};

export const useSelectionHandler = ({
  selectCuisine,
  selectSearch,
  selectVenue,
  suggestedCuisines,
  suggestedRestaurants,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { recentSearches } = useSelector((state) => state.search);
  const cuisineMap = useMemo(
    () => updateMap(suggestedCuisines),
    [suggestedCuisines]
  );
  const restaurantMap = useMemo(
    () => updateMap(suggestedRestaurants),
    [suggestedRestaurants]
  );
  const searchMap = useMemo(
    () => updateMap(mapSearches(recentSearches)),
    [recentSearches]
  );

  const cuisineSelectionCallback = useCallback(
    (cuisine) =>
      isFunction(selectCuisine)
        ? selectCuisine(cuisine)
        : handleCuisineSelect(cuisine, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectCuisine]
  );

  const restaurantSelectionCallback = useCallback(
    (restaurant) =>
      isFunction(selectVenue)
        ? selectVenue(restaurant)
        : handleVenueSelect(restaurant, history),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectVenue]
  );

  const searchSelectionCallback = useCallback(
    (search) =>
      isFunction(selectSearch)
        ? selectSearch(search)
        : handleSearchSelect(search, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectSearch]
  );

  const handleSelect = useCallback(
    (item) => {
      const id = item.split('\n')[2];

      if (cuisineMap[id] != null) {
        return cuisineSelectionCallback(cuisineMap[id]);
      }

      if (restaurantMap[id] != null) {
        return restaurantSelectionCallback(restaurantMap[id]);
      }

      if (searchMap[id] != null) {
        return searchSelectionCallback(searchMap[id]);
      }
    },
    [
      cuisineMap,
      cuisineSelectionCallback,
      restaurantMap,
      restaurantSelectionCallback,
      searchMap,
      searchSelectionCallback,
    ]
  );

  return handleSelect;
};
