import { VENUE_TYPE_EVENT, VENUE_TYPE_REGULAR } from '../../assets/copy';

export const FILTER_TYPES = Object.freeze({
  ACCOLADES: 'accolades',
  ATMOSPHERES: 'atmospheres',
  CATEGORIES: 'categories',
  CUISINES: 'cuisines',
  NEIGHBORHOODS: 'neighborhoods',
  OCCASIONS: 'occasions',
  PRICE_RANGE: 'priceRange',
  VENUE_TYPE: 'venueType',
});

export const VENUE_TYPES = {
  EVENT: 'EVENT',
  REGULAR: 'REGULAR',
};

export const EVENT_VENUE_TYPE = {
  venueType: VENUE_TYPES.EVENT,
  name: VENUE_TYPE_EVENT,
};

export const DINING_VENUE_TYPE = {
  venueType: VENUE_TYPES.REGULAR,
  name: VENUE_TYPE_REGULAR,
};

export const VENUE_TYPE_VALUES = [
  EVENT_VENUE_TYPE,
  DINING_VENUE_TYPE,
];

export const VENUE_TYPE_FILTERS = Object.freeze(
  VENUE_TYPE_VALUES.map(({ venueType, name }, index) =>
    Object.freeze({ id: index + 1, name, venueType })
  )
);

/**
 * Values to appear in the occasions filter
 */
export const OCCASIONS_FILTER_VALUES = Object.freeze({
  DATE_NIGHT: 'Date Night',
  BRUNCH: 'Brunch',
  OUTDOOR: 'Outdoor',
  SPECIAL_EVENTS: 'Special Events',
  BIG_GROUPS: 'Big Groups',
  CHEF_PICKS: 'Chef Picks',
});

/**
 * Possible values for venue `labels` field
 */
export const VENUE_LABELS = Object.freeze({
  DATE_NIGHT: 'date_night',
  BRUNCH: 'brunch',
  OUTDOOR: 'outdoor',
  SPECIAL_EVENTS: 'special_events',
  BIG_GROUPS: 'big_group',
  CHEF_PICKS: 'chefs_pick',
});

export const VENUE_LABELS_TO_OCCASIONS_MAP = Object.freeze(
  new Map([
    [VENUE_LABELS.DATE_NIGHT, OCCASIONS_FILTER_VALUES.DATE_NIGHT],
    [VENUE_LABELS.BRUNCH, OCCASIONS_FILTER_VALUES.BRUNCH],
    [VENUE_LABELS.OUTDOOR, OCCASIONS_FILTER_VALUES.OUTDOOR],
    [VENUE_LABELS.SPECIAL_EVENTS, OCCASIONS_FILTER_VALUES.SPECIAL_EVENTS],
    [VENUE_LABELS.BIG_GROUPS, OCCASIONS_FILTER_VALUES.BIG_GROUPS],
    [VENUE_LABELS.CHEF_PICKS, OCCASIONS_FILTER_VALUES.CHEF_PICKS],
  ])
);
