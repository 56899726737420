import React from 'react';
import { makeStyles } from '@material-ui/core';
import NoAvailabilityInfo from '../venueAvailability/NoAvailabilityInfo';
import createInflection from '../../utils/inflection/createInflection';
import { useCopy } from '../../utils/useCopy';
import useEventFlag from '../../utils/useEventFlag';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { AVAILABILITY_NO_TICKETS_SPECIAL_EVENTS_WITHOUT_DATES } from '../../assets/copy';
import { venueAvailabilityStyles } from './helpers/venueAvailabilityStyles';
import AvailabilityTimeslots from './AvailabilityTimeslots';

const useStyles = makeStyles((theme) => ({
  ...venueAvailabilityStyles(theme),
}));

const EventAvailability = ({
  hasAvailabilityForSelectedDay,
  hasAvailabilityForNext7Days,
  selectedDateString,
  next7DaysAvailability,
  venue,
  guests,
  type,
  selectedDateAvailabilities,
  selectTimeSlot,
  loadedAvailability,
  action,
  onMount,
  time,
  date,
  showMore,
}) => {
  const classes = useStyles({ type: TIME_AVAILABILITY_TYPES.HORIZONTAL });
  const { getCopy } = useCopy();
  const { isEventSoldOut, isPast, isComingSoon, isEventNoLongerAvailable } =
    useEventFlag(venue);

  const renderUnavailableEventMessage = () => {
    let noAvailabilityText = getCopy('AVAILABILITY_NO_TICKETS_SPECIAL_EVENTS', {
      guestsInflection: createInflection('guest', guests),
      selectedDate: selectedDateString,
    });
    if (isEventSoldOut) {
      noAvailabilityText = getCopy('SOLD_OUT_TEXT', {
        venueName: venue?.name,
      });
    } else if (isComingSoon) {
      noAvailabilityText = getCopy('COMING_SOON_TEXT', {
        venueName: venue?.name,
      });
    } else if (isPast) {
      noAvailabilityText = getCopy('PAST_EVENT_TEXT', {
        venueName: venue?.name,
      });
    }
    return (
      <NoAvailabilityInfo
        containerClassName={classes.noAvailabilityInfoContainer}
        contentClassName={classes.noAvailabilityInfoContent}
        text={noAvailabilityText}
        useDivider={true}
        dividerClassName={classes.divider}
        type={type}
      />
    );
  };

  const renderHasNoAvailabilityForDateRangeMessage = () => {
    let text = '';
    const length = venue?.eventDates?.length;
    const eventDateLastElement = venue?.eventDates[length - 1];
    if (!hasAvailabilityForSelectedDay) {
      text = getCopy('AVAILABILITY_NO_TICKETS_SPECIAL_EVENTS', {
        guestsInflection: createInflection('guest', guests),
        selectedDate: selectedDateString,
      });
    }
    if (eventDateLastElement !== undefined && !hasAvailabilityForNext7Days) {
      text = AVAILABILITY_NO_TICKETS_SPECIAL_EVENTS_WITHOUT_DATES;
    }
    return (
      <NoAvailabilityInfo
        containerClassName={classes.noAvailabilityInfoContainer}
        contentClassName={classes.noAvailabilityInfoContent}
        text={text}
        useDivider={true}
        dividerClassName={classes.divider}
        type={type}
      />
    );
  };

  return isEventNoLongerAvailable ? (
    renderUnavailableEventMessage()
  ) : (
    <AvailabilityTimeslots
      hasAvailabilityForSelectedDay={hasAvailabilityForSelectedDay}
      hasAvailabilityForNext7Days={hasAvailabilityForNext7Days}
      selectedDateString={selectedDateString}
      next7DaysAvailability={next7DaysAvailability}
      venue={venue}
      guests={guests}
      type={type}
      selectedDateAvailabilities={selectedDateAvailabilities}
      selectTimeSlot={selectTimeSlot}
      loadedAvailability={loadedAvailability}
      action={action}
      onMount={onMount}
      time={time}
      date={date}
      showMore={showMore}
      renderHasNoAvailabilityForDateRangeMessage={
        renderHasNoAvailabilityForDateRangeMessage
      }
    />
  );
};

export default EventAvailability;
