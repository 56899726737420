import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RESERVATION_CANCEL_CONFIRMED_TITLE } from '../../assets/copy';
import CheckedSvg from '../../assets/icons/checked.svg';
import LargeCard from '../../components/core/Card/LargeCard';
import PageSection from '../../components/core/PageSection';
import Typography from '../../components/overrides/Typography';
import SeeMoreRestaurantsLink from '../../components/reserve/SeeMoreRestaurantsLink';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 0),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(2),
    },
  },
  cardContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4, 'auto'),
      maxWidth: 327,
    },
  },
  link: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const ReservationCancel = () => {
  const classes = useStyles();
  const { reservation } = useSelector((state) => state.reservations);

  return (
    <PageSection>
      <div className={classes.root}>
        <Grid container justify="center" direction="column" alignItems="center">
          <img src={CheckedSvg} alt="checked" />
          <Typography
            variant="medium3Light"
            className={classes.title}
            aria-live="polite">
            {RESERVATION_CANCEL_CONFIRMED_TITLE}
          </Typography>
        </Grid>
        <Box className={classes.cardContainer}>
          <LargeCard reservation={reservation} />
        </Box>
        <Grid container justify="center" direction="column" alignItems="center">
          <SeeMoreRestaurantsLink className={classes.link} />
        </Grid>
      </div>
    </PageSection>
  );
};

export default ReservationCancel;
