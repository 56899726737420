import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Button as MuiButton } from '@material-ui/core';

import palette from '../../theme/palette';

const useStyles = makeStyles((theme) => ({
  override: ({ color }) => ({
    backgroundColor: theme.palette[color].main,
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: theme.palette.background.grayLight,
      '& p': {
        color: theme.palette.secondary[50],
      },
    },
  }),
}));

const Button = ({ color = 'primary', className, children, ...otherPops }) => {
  const classes = useStyles({ color });

  return (
    <MuiButton
      className={clsx(className, classes.override)}
      color={Object.keys(palette).includes(color) ? 'primary' : color}
      {...otherPops}>
      {children}
    </MuiButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  otherPops: PropTypes.object,
};

export default Button;
