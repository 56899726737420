import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Item } from '@react-stately/collections';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import DesktopSearch from '../../components/search/DesktopSearch';
import FilterActions from '../../components/search/FilterActions';
import FABWrapper from './FABWrapper';
import Filter from '../../components/search/FilterModal';
import {
  BASE_SEARCH_PATHNAME,
  DEFAULT_REDIRECT,
} from '../../components/search/constants';
import {
  handleCuisineSelect,
  isValidSearchRedirect,
  mapToQueryParam,
  useSearchParams,
  useResults,
  useSearchChangeHandler,
  useSelectionHandler,
  useSuggestions,
} from '../../components/search/helpers';
import {
  BASE_SEARCH_INPUT_PROPS,
  SearchComboBox,
  SearchFormContainer,
  SearchSelect,
} from '../../components/search/SearchForm';
import {
  SearchResultsContainer,
  SearchResultsCount,
  SearchResultsHeader,
  SearchResultsList,
  SearchResultsPagination,
  ShowMoreLink,
} from '../../components/search/SearchResults';
import SearchRedirect from '../../components/search/SearchRedirect';
import FilledButton from '../../components/core/FilledButton';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import Divider from '../../components/overrides/Divider';
import useAvailabilityCallback from './helpers/useAvailabilityCallback';
import {
  actionCacheCurrentFilters,
  actionResetCachedFilters,
  actionResetCurrentFilter,
  actionResetCurrentFilters,
} from '../../store/Filter/FilterAction';
import {
  actionCacheSearchSessionId,
  actionResetSearchSessionIdCache,
  actionSetSearchCachedQuery,
  actionSetSearchCurrentInputValue,
  actionSetSearchCurrentQuery,
  actionSetSearchCurrentSessionId,
} from '../../store/Search/SearchAction';
import { actionGetMarketRequest } from '../../store/Markets/MarketsAction';
import {
  actionFindVenuesRequest,
  actionResetVenueList,
} from '../../store/Venues/VenuesAction';
import { FILTER_TYPES } from '../../utils/constants/FilterTypes';
import { useComponentWillUnmount } from '../../utils/useComponentWillUnmount';
import { useViewport } from '../../utils/useViewport';
import { getRandomString } from '../../utils/useGeneratedKey';
import { SEARCH_ACTION_BUTTON_TEXT } from '../../assets/copy';
import ReservationSheet from '../../components/core/Sheet/ReservationSheet';
import { actionListAvailabilityVenuesClear } from '../../store/Availability/AvailabilityAction';
import FooterContent from '../../components/core/Footer/FooterContent';
import ExclusiveTagging from '../../components/search/ExclusiveTagging';
import { focusVisible } from '../../utils/ada/contrastStyles';

const useStyles = makeStyles((theme) => ({
  desktopSearch: {
    paddingBottom: theme.spacing(3),
  },
  mobileTitle: {
    padding: theme.spacing(3, 3, 0),
  },
  searchButton: {
    ...focusVisible(),
  },
}));

const widthBreakpoint = 960;

const Search = () => {
  const { width } = useViewport();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pageNumber: pageNum } = useSearchParams();
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    resetSuggestions,
    suggestedCuisines,
    suggestedRestaurants,
    updateSuggestions,
  } = useSuggestions();
  const suggestedResults = useResults({
    suggestedCuisines,
    suggestedRestaurants,
  });
  const { venues, markets } = useSelector((state) => state);
  const { market } = markets;
  const { cachedFilters, currentFilters } = useSelector(
    (state) => state.filters
  );
  const {
    cachedQuery,
    cachedSessionId,
    currentQuery,
    currentSearchInputValue,
    currentSessionId,
  } = useSelector((state) => state.search);
  const classes = useStyles();

  const { navigationMenuVisible } = useSelector((state) => state.appData);

  const fetchAvailabilityOnSearch = useAvailabilityCallback();
  const fetchAvailabilityOnPageChange = useAvailabilityCallback(pageNum, false);

  useComponentWillUnmount(() => {
    dispatch(actionCacheSearchSessionId());
    dispatch(actionCacheCurrentFilters());
    dispatch(actionResetVenueList());
    dispatch(actionListAvailabilityVenuesClear());
  });

  const setSearchSession = useCallback(
    (sessionId) => {
      if (sessionId === currentSessionId) return;

      dispatch(actionSetSearchCurrentSessionId(sessionId));
      const pathname = BASE_SEARCH_PATHNAME;
      const search = mapToQueryParam({ sessionId });
      const redirectTo = isValidSearchRedirect(location.state?.redirectTo)
        ? location.state.redirectTo
        : DEFAULT_REDIRECT;

      if (!location.search) {
        history.replace({ pathname, search }, { redirectTo });
      } else {
        history.push({ pathname, search }, { redirectTo });
      }
    },
    [location, history, currentSessionId, dispatch]
  );

  const search = useCallback(
    ({
      filters = currentFilters,
      keyword = currentQuery,
      marketId = market.id,
      sessionId = getRandomString(),
    } = {}) => {
      setIsLoading(true);
      setSearchSession(sessionId);
      dispatch(
        actionFindVenuesRequest({
          filters,
          keyword,
          marketId,
        })
      );
      if (cachedQuery !== keyword) {
        dispatch(actionSetSearchCachedQuery(keyword));
      }
      // site search tracker
      window.oneTag.track("link",
      {
        event_name: "site_search",
        search_term: keyword,
        search_category: marketId,
        site_search_filters: JSON.stringify(filters),
        site_search_total_results: ""
      });
    },
    [
      setIsLoading,
      setSearchSession,
      dispatch,
      cachedQuery,
      currentFilters,
      currentQuery,
      market.id,
    ]
  );

  const setCurrentSearchFromSearchBox = () => {
    dispatch(actionSetSearchCurrentQuery(currentSearchInputValue));
    search({ keyword: currentSearchInputValue });
  };

  const resetSearchQuery = useCallback(() => {
    dispatch(actionSetSearchCurrentInputValue(''));
    dispatch(actionSetSearchCurrentQuery(''));
    resetSuggestions();
  }, [dispatch, resetSuggestions]);

  const resetSearch = useCallback(() => {
    resetSearchQuery();
    search({ keyword: '' });
  }, [resetSearchQuery, search]);

  const handleSuggestedChange = useCallback(
    (searchInput) => {
      dispatch(actionSetSearchCurrentQuery(searchInput));
      updateSuggestions(searchInput);
    },
    [dispatch, updateSuggestions]
  );

  const handleSearchChange = useSearchChangeHandler({
    update: handleSuggestedChange,
    reset: resetSearchQuery,
  });

  const selectCuisine = useCallback(
    (cuisine) => handleCuisineSelect(cuisine, dispatch),
    [dispatch]
  );

  const handleSuggestionSelect = useSelectionHandler({
    suggestedCuisines,
    suggestedRestaurants,
    selectCuisine,
  });

  const resetFilters = useCallback(
    () => dispatch(actionResetCurrentFilters()),
    [dispatch]
  );

  const resetAllSearch = useCallback(() => {
    resetFilters();
    resetSearch();
  }, [resetFilters, resetSearch]);

  const handleMarketChange = (event) => {
    const id = event.target.value;
    search({
      filters: { ...currentFilters, [FILTER_TYPES.NEIGHBORHOODS]: [] },
      marketId: id,
    });
    dispatch(actionResetCurrentFilter(FILTER_TYPES.NEIGHBORHOODS));
    dispatch(actionGetMarketRequest({ id }));
  };

  // Re-filter when currentFilters updates, but not when already finding venues
  useEffect(() => {
    if (isMounted && venues.findVenuesStatus.loading === false) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  // Set loading to false after finding venues
  useEffect(() => {
    if (venues.findVenuesStatus.loading === false) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venues.findVenuesStatus]);

  // Reset stale filter cache after mounting
  useEffect(() => {
    if (cachedFilters != null && isMounted) {
      dispatch(actionResetCachedFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  // Reset stale search session ID after mounting
  useEffect(() => {
    if (cachedSessionId && isMounted) {
      dispatch(actionResetSearchSessionIdCache());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  // Fetch availabilities for next page only when user changes page
  useEffect(() => {
    if (!isLoading) fetchAvailabilityOnPageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  // Fetch availabilities after completing search
  useEffect(() => {
    if (!isLoading) fetchAvailabilityOnSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // if the component is not mounted and currentQuery has a value, run search and mount
  useEffect(() => {
    if (!isMounted) {
      // Don't search if list already exists (when page is refreshed)
      if (
        venues.findVenuesStatus.loading == null &&
        venues.searchVenuesStatus.success == null &&
        venues.venuesByMarketStatus.success == null
      ) {
        const sessionId = currentSessionId
          ? currentSessionId
          : getRandomString();
        search({ sessionId });
      }

      setIsMounted(true);
    }
  }, [
    isMounted,
    search,
    currentSessionId,
    venues.findVenuesStatus?.loading,
    venues.searchVenuesStatus?.success,
    venues.venuesByMarketStatus?.success,
  ]);

  const searchForm = () => (
    <SearchFormContainer>
      <SearchComboBox
        {...BASE_SEARCH_INPUT_PROPS}
        autoFocus={false}
        handleChange={handleSearchChange}
        handleSearch={setCurrentSearchFromSearchBox}
        handleReset={resetSearchQuery}
        onInputChange={handleSuggestionSelect}>
        {suggestedResults.map((result) => (
          <Item key={result.id}>{result.name}</Item>
        ))}
      </SearchComboBox>
      <SearchSelect handleChange={handleMarketChange} />
    </SearchFormContainer>
  );

  useEffect(() => {
    document.body.classList.add('disable-search-scroll');
    return () => document.body.classList.remove('disable-search-scroll');
  }, []);

  return (
    !navigationMenuVisible && (
      <FABWrapper isSearchPage>
        <ScreenReaderText component="h1">Search</ScreenReaderText>
        {width > widthBreakpoint ? (
          <>
            <DesktopSearch
              className={classes.desktopSearch}
              searchButton={
                <FilledButton
                  text={SEARCH_ACTION_BUTTON_TEXT}
                  onClick={setCurrentSearchFromSearchBox}
                  style={classes.searchButton}
                />
              }
              searchForm={searchForm()}
            />
            <Divider />
          </>
        ) : (
          searchForm()
        )}
        <FilterActions />

        <ReservationSheet />

        <SearchResultsContainer isLoading={isLoading}>
          <ExclusiveTagging />
          <SearchRedirect />
          <SearchResultsHeader seeAllVenues={resetAllSearch} />
          <SearchResultsList />
          <SearchResultsCount />
          <ShowMoreLink />
          <SearchResultsPagination />
        </SearchResultsContainer>

        <Filter />
        <FooterContent isSearch isLoading={isLoading} />
      </FABWrapper>
    )
  );
};
export default Search;
