import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ENTERTAINMENT_BANNER_BUTTON_TEXT } from '../../../assets/copy';
import ExternalIcon from '../../../assets/icons/external.svg';
import Typography from '../../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  link: {
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: 'white',
    width: '100%',
  },
  label: {
    marginRight: theme.spacing(1),
  },
}));

const BannerLink = ({ handleClick }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.link}
      onClick={handleClick}
      onKeyPress={(e) => e.key === 'Enter' && handleClick()}
      role="link"
      tabIndex={0}>
      <Typography
        variant="medium1Semibold"
        color="secondary"
        className={classes.label}>
        {ENTERTAINMENT_BANNER_BUTTON_TEXT}
      </Typography>
      <img src={ExternalIcon} alt="" />
    </Box>
  );
};

export default BannerLink;
