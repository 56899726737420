import React from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  CardMedia,
  Paper,
  Box,
} from '@material-ui/core';
import EllipsisText from '../core/EllipsisText';
import useEventFlag from '../../utils/useEventFlag';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from '../../utils/stringHelperMethods';
import { EVENT_TEXT, EVENT_CTA_TEXT } from '../../assets/copy';
import { enterKeyPressHandler } from '../../utils/keyPresses';
import ExtraLargeCard from '../core/Card/ExtraLargeCard';
import { cardHoverStyles } from '../../utils/constants/hoverStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    borderRadius: 4,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    ...cardHoverStyles,
    [theme.breakpoints.up('xl')]: {
      width: 256,
    },
  },
  detailsStyles: {
    width: 352,
    borderRadius: 4,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 327,
      maxHeight: 381,
    },
  },
  card: {
    width: 240,
    boxShadow: 'none',
    borderRadius: 4,
    [theme.breakpoints.up('xl')]: {
      width: 256,
    },
  },
  cardActionArea: {
    '&:hover [class*="focusHighlight"]': {
      opacity: 0,
    },
  },
  media: {
    height: 168,
    position: 'relative',
  },
  label: {
    color: theme.palette.secondary[80],
  },
  description: {
    color: theme.palette.secondary[70],
  },
}));

export default function GalleryItem({
  image,
  label,
  description,
  onClick,
  screenReaderText,
  venue,
  isGetTicketLinkShown = false,
  isEventCarousel = false,
  isCompact = false,
}) {
  const classes = useStyles();
  const eventStatus = useEventFlag(venue);
  const eventText = eventStatus?.venueHasEvent
    ? convertNullToEmptyString(eventStatus?.renderVenueBadge()?.props?.text) +
      ' ' +
      EVENT_TEXT
    : '';
  const generateVenueText = () => {
    return generateScreenReaderText(',', eventText, screenReaderText);
  };
  const onKeyPressHandler = enterKeyPressHandler && onClick;
  const showEventCard = () => {
    return (
      <Box
        className={
          isEventCarousel && !isCompact ? classes.detailsStyles : classes.root
        }>
        <ExtraLargeCard
          venue={venue}
          isCompact={isCompact}
          isEventCarousel={isEventCarousel}></ExtraLargeCard>
      </Box>
    );
  };

  return isEventCarousel ? (
    showEventCard()
  ) : (
    <Paper
      elevation={4}
      className={classes.root}
      tabIndex={0}
      aria-label={generateVenueText()}
      onClick={onClick}
      onKeyPress={onKeyPressHandler}
      role="link">
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={image} alt="">
          {eventStatus.renderVenueBadge()}
        </CardMedia>
        <CardContent aria-hidden="true">
          <EllipsisText variant="medium1Semibold" text={label}></EllipsisText>
          <EllipsisText
            variant="small2Normal"
            text={description}
            className={classes.description}></EllipsisText>
          {eventStatus.renderGetTicketLink(
            isGetTicketLinkShown,
            EVENT_CTA_TEXT
          )}
        </CardContent>
      </Card>
    </Paper>
  );
}
