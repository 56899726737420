import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import {
  EXPLORE_RESTAURANTS_BUTTON_TEXT,
  ICON_EMPTY_LIST,
} from '../../assets/copy';

import Typography from '../overrides/Typography';
import FilledButton from './FilledButton';
import { useViewport } from '../../utils/useViewport';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    maxWidth: 371,
    marginTop: theme.spacing(7),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(5),
    },
  },
  img: {
    display: 'block',
    margin: theme.spacing(4, 'auto', 0),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2),
    },
  },
  message: ({ customTextColor }) => ({
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    color: customTextColor ? customTextColor : theme.palette.primary[120],
    maxWidth: 545,
    margin: `${theme.spacing(0)} auto`,
    [theme.breakpoints.down('md')]: {
      maxWidth: 327,
    },
  }),
  title: ({ customTextColor }) => ({
    paddingTop: theme.spacing(3),
    textAlign: 'center',
    color: customTextColor ? customTextColor : theme.palette.primary[120],
  }),
  buttonContainer: {
    textAlign: 'center',
  },
}));

const NoContent = ({
  imageSrc,
  title,
  message,
  onClickHandler,
  customTextColor,
}) => {
  const classes = useStyles({ customTextColor });
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;
  return (
    <Grid container justify="center">
      <Grid className={classes.content} item xl={8}>
        <Box
          alt={ICON_EMPTY_LIST}
          aria-hidden
          className={classes.img}
          component={'img'}
          src={imageSrc}
        />
        <Typography
          component="h2"
          className={classes.title}
          variant={isMobile ? 'medium3Light' : 'large1Light'}>
          {title}
        </Typography>
        <Typography className={classes.message} variant="medium1Normal">
          {message}
        </Typography>
        <Box className={classes.buttonContainer}>
          <FilledButton
            onClick={onClickHandler}
            style={classes.button}
            text={EXPLORE_RESTAURANTS_BUTTON_TEXT}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default NoContent;
