import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Select } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

import { actionGetUserByAuthCodeRequest } from '../../store/User/UserAction';
import { useAuth, AUTH_STATUS } from '../../utils/useAuth';
import CommonLoading from '../common/CommonLoading';
import { useConfig } from '../../utils/useConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const UserDropdown = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUserSelected, setIsUserSelected] = useState(false);
  const { user, signout } = useAuth();
  const { isDebug } = useConfig();

  // Dummy users and code tokens
  const users = [
    {
      name: 'Homer Simpson (single card)',
      authCode: 'code1',
    },
    {
      name: 'Bart Simpson (two cards)',
      authCode: 'code2',
    },
    {
      name: 'Lisa Simpson (single card)',
      authCode: 'code3',
    },
    {
      name: 'Marge Simpson (three cards)',
      authCode: 'code4',
    },
    {
      name: 'Maggie Simpson (single card)',
      authCode: 'code5',
    },
    {
      name: 'Sideshow Bob (ineligible)',
      authCode: 'code6',
    },
    {
      name: 'Milhouse Van Houten (ineligible)',
      authCode: 'code7',
    },
    {
      name: 'Barney Gumble (bad standing)',
      authCode: 'code8',
    },
    {
      name: 'Ralph Wiggum (invalid email)',
      authCode: 'code9',
    },
    {
      name: 'Ned Flanders (invalid phone number)',
      authCode: 'code10',
    },
    {
      name: 'Snake Jailbird (blank phone number)',
      authCode: 'code11',
    },
  ];

  useEffect(() => {
    if (isUserSelected && user.session.isAuthenticated) {
      history.push('/');
    }
  }, [isUserSelected, history, user.session.isAuthenticated]);

  const handleSelect = (event) => {
    if (user.session.isAuthenticated) {
      signout(true);
    }
    const authCode = event.target.value;
    dispatch(actionGetUserByAuthCodeRequest({ authCode }));
    setIsUserSelected(true);
  };

  const isAuthPending = () => user.session.status === AUTH_STATUS.PENDING;

  // Prevent accessing user dropdown if not in debug mode
  if (!isDebug) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.root}>
      {isAuthPending() ? (
        <CommonLoading active={isAuthPending()} />
      ) : (
        <Select
          native
          value="default"
          onChange={handleSelect}
          className={classes.select}>
          <option value="default" disabled>
            Choose a dummy user
          </option>
          {users &&
            users.map((_user, index) => (
              <option key={index} value={_user.authCode}>
                {_user.name}
              </option>
            ))}
        </Select>
      )}
    </div>
  );
};

export default UserDropdown;
