import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { RESERVATIONS_TYPE } from './constants';
import { useCurrentTab } from './helpers';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5),
    },
  },
}));

const ReservationsTabPanel = ({
  children,
  type = RESERVATIONS_TYPE.UPCOMING,
  ...props
}) => {
  const classes = useStyles();
  const currentTab = useCurrentTab();

  return (
    <div
      role="tabpanel"
      hidden={currentTab !== type}
      id={`reservations-tabpanel-${type}`}
      aria-labelledby={`reservations-tab-${type}`}
      {...props}>
      {currentTab === type && (
        <Box className={classes.tabPanel}>{children}</Box>
      )}
    </div>
  );
};

export default ReservationsTabPanel;
