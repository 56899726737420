import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import NativeSelector from '../../components/core/NativeSelector';
import { actionGetMarketRequest } from '../../store/Markets/MarketsAction';
import { HOME_SELECT_CITY } from '../../assets/copy';
import { HOME_SELECT_CITY_ID } from '../../assets/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.grayLight,
    position: 'fixed',
    [theme.breakpoints.up('xl')]: {
      top: theme.spacing(8),
    },
    zIndex: 1,
  },
}));

export default function MarketSelector({ list, setHasUpdatedMarket }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [labelWidth, setLabelWidth] = useState(0);
  const markets = useSelector((state) => state.markets);
  const [marketId, setMarketId] = useState(markets ? markets.market.id : null);

  useEffect(() => {
    setMarketId(markets ? markets.market.id : null);
    setHasUpdatedMarket(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets]);

  function getTextWidth(txt, font) {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');
    context.font = font;

    const { width } = context.measureText(txt);
    const ARROW_WIDTH = 10;
    // autoWidth prop on the NativeSelector doesn't work because it's Native
    return Math.ceil(width + ARROW_WIDTH);
  }

  const handleSelect = (event) => {
    const id = event.target.value;
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_id: event.currentTarget.id,
      ui_interaction_element_name: "select-city",
      ui_interaction_element_type: 'Dropdown',
      ui_interaction_element_description: id
    });
    setHasUpdatedMarket(true);
    dispatch(actionGetMarketRequest({ id }));
    setMarketId(id);
  };

  useEffect(() => {
    if (!markets.market) return;
    setLabelWidth(
      getTextWidth(markets.market.name + ' ', '14px Optimist, sans-serif')
    );
  }, [markets.market]);

  return (
    <div className={classes.root}>
      <NativeSelector
        ariaLabel={HOME_SELECT_CITY}
        idLabel={HOME_SELECT_CITY_ID}
        role="combobox"
        items={list}
        placeholder={list ? list[0] : ''}
        selectedItem={marketId}
        handleSelect={handleSelect}
        component="location"
        variant="home"
        labelWidth={labelWidth}
        data-cy="select-city"
      />
    </div>
  );
}
