import { useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { actionSetAnimationPushUp } from "../store/Animations/AnimationsAction";
import { getPermalinkRouteRedirectLocation } from "../pages/onboarding/onboardingHelpers";
import { useCallback } from "react";

const Onboarding = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleRestaurant = useCallback(() => {
        dispatch(actionSetAnimationPushUp(true));
        history.push(getPermalinkRouteRedirectLocation());
      }, [history, dispatch]);
    return {
        handleRestaurant
    }
}

export default Onboarding;