import { QUERY_PARAM_ROUTES } from './QueryParamRoutes';

const mapToWhitelist = (params) =>
  Object.freeze(
    Object.values(params).reduce(
      (whitelist, param) => ({ ...whitelist, [param]: param }),
      {}
    )
  );

export const RESERVATION_LIST_PARAMS = Object.freeze({
  TAB: 'tab',
});

export const SEARCH_PARAMS = Object.freeze({
  SESSION_ID: 'session',
  PAGE_NUMBER: 'page',
});

export const QUERY_PARAM_WHITELISTS = Object.freeze({
  [QUERY_PARAM_ROUTES.RESERVATION_LIST]: mapToWhitelist(
    RESERVATION_LIST_PARAMS
  ),
  [QUERY_PARAM_ROUTES.SEARCH]: mapToWhitelist(SEARCH_PARAMS),
});
