import {
  put,
  takeLatest,
  actionChannel,
  take,
  select,
} from 'redux-saga/effects';

import { getMarket, listMarkets } from '../../graphql/customQueries';
import { callGraphqlWithToken, dispatchError } from '../helpers';
import * as ACTIONS from './MarketsAction';
import * as TYPES from './MarketsTypes';
import { getConfig, getUser } from '../selectors';
import { VENUE_SORT_DIRECTIONS } from '../../utils/constants/VenueSortDirection';
import { isVenueAvailable } from '../../utils/eventHelpers';

export function* marketListRequestHandler() {
  try {
    const filter = { isActive: { eq: true } };
    const result = yield callGraphqlWithToken({
      query: listMarkets,
      variables: { filter },
    });
    const sortedMarkets = result.data.listMarkets.items.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    yield put(ACTIONS.actionMarketListSuccess(sortedMarkets));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionMarketListFail(e));
  }
}

export function* getMarketRequestHandler(data) {
  try {
    const user = yield select(getUser);
    const config = yield select(getConfig);
    const isTestUser = user.user.isTest;

    data.payload.searchVenuesFilter = {
      ...data.payload.searchVenuesFilter,
      marketId: { eq: data.payload.id },
      isActive: { eq: true },
      isHidden: { eq: false },
      ...(!isTestUser && { isTest: { ne: true } }),
    };

    const result = yield callGraphqlWithToken({
      query: getMarket,
      variables: {
        ...data.payload,
        sort: {
          direction:
            VENUE_SORT_DIRECTIONS[config.venueSortDirection] ||
            VENUE_SORT_DIRECTIONS.asc,
          field: 'sortOrder',
        },
      },
    });

    //remove this when the event is over
    result.data.searchVenues.items = result.data.searchVenues.items.filter(
      (venue) => isVenueAvailable(venue)
    );

    yield put(
      ACTIONS.actionGetMarketSuccess({
        ...result.data.getMarket,
        venues: result.data.searchVenues,
      })
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetMarketFail(e));
  }
}

export function* navigateToSearchHandler(data) {
  const { history, marketChanged } = data.payload;
  // Recommended pattern: https://redux-saga.js.org/docs/advanced/Channels/#using-the-actionchannel-effect
  // watch for GET_MARKET_SUCCESS channel and block the action.
  const requestChannel = yield actionChannel(TYPES.GET_MARKET_SUCCESS);

  while (marketChanged) {
    const { payload } = yield take(requestChannel);
    // break out of while loop once the channel finishes.
    if (payload) break;
  }
  yield history.push('/search');
}

export default function* marketsSaga() {
  yield takeLatest(TYPES.MARKET_LIST_REQUEST, marketListRequestHandler);
  yield takeLatest(TYPES.GET_MARKET_REQUEST, getMarketRequestHandler);
  yield takeLatest(TYPES.NAVIGATE_TO_SEARCH, navigateToSearchHandler);
}
