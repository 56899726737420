import React from 'react';
import MaskedInput from 'react-text-mask';

const MaskedDateInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      guide={true}
      keepCharPositions={true}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
    />
  );
};

export default MaskedDateInput;
