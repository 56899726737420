import { DEFAULT_PAGE_SIZE } from './constants/Pagination';

export const paginateList = (list, listSize = DEFAULT_PAGE_SIZE) =>
  list.reduce((sublists, values, index) => {
    const listIndex = Math.floor(index / listSize);

    if (!sublists[listIndex]) sublists[listIndex] = [];

    sublists[listIndex].push(values);

    return sublists;
  }, []);

const paginateSearch = (venues = [], pageSize = DEFAULT_PAGE_SIZE) => {
  const paginatedList = paginateList(venues, pageSize);
  const results = {
    pages: paginatedList,
    totalPages: paginatedList.length,
  };

  if (paginatedList.length <= 1) return results;

  const [first, second, ...rest] = paginatedList;
  results.pages = [first, [...first, ...second], ...rest];
  results.totalPages = results.pages.length - 1;

  return results;
};

export default paginateSearch;
