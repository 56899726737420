import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '../overrides/Typography';
import { ERROR_PAGE_TYPES } from '../../utils/constants/ErrorPageTypes';

const useStyles = makeStyles((theme) => ({
  img: {
    display: 'block',
    margin: theme.spacing(0, 'auto'),
  },
  title: {
    paddingTop: theme.spacing(3),
    textAlign: 'center',
  },
  messageMedium2Normal: {
    padding: theme.spacing(4, 0),
    textAlign: 'center',
  },
  messageMedium1Normal: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    textAlign: 'center',
  },
}));

const ErrorSnippet = ({ type = ERROR_PAGE_TYPES.GENERIC.value }) => {
  const classes = useStyles();
  const error = ERROR_PAGE_TYPES[type];

  return (
    <>
      <img
        alt={error.iconAlt}
        aria-hidden
        className={classes.img}
        src={error.iconSrc}
      />
      {error.errorTitle != null && (
        <Typography
          component="h1"
          className={classes.title}
          variant="medium3Light">
          {error.errorTitle}
        </Typography>
      )}
      {error.errorMessage != null && (
        <Typography
          className={
            error.errorMessageVariant === 'medium1Normal'
              ? classes.messageMedium1Normal
              : classes.messageMedium2Normal
          }
          variant={error.errorMessageVariant}>
          {error.errorMessage}
        </Typography>
      )}
    </>
  );
};

export default ErrorSnippet;
