import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../overrides/Typography';

const useStyles = makeStyles((theme) => ({
  button: ({ baseShade, color, hoverShade }) => ({
    background: 'transparent',
    border: 'none',
    color: theme.palette[color][baseShade],
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      color: theme.palette[color][hoverShade],
      textDecoration: 'underline',
    },
  }),
}));

const TextButton = ({
  color = 'interactionBlue',
  baseShade = 'main',
  hoverShade = 60,
  children,
  className,
  onClick,
  variant = 'medium1Semibold',
  ...props
}) => {
  const classes = useStyles({ baseShade, color, hoverShade });

  return (
    <Typography
      component="button"
      variant={variant}
      className={clsx(classes.button, className)}
      onClick={onClick}
      {...props}>
      {children}
    </Typography>
  );
};

export default TextButton;
