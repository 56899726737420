import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import LeftChevronIcon from '../../../../assets/icons/left-blue-chevron.svg';
import RightChevronIcon from '../../../../assets/icons/right-blue-chevron.svg';
import { useCopy } from '../../../../utils/useCopy';
import { useSearchLink, useSearchParams } from '../../helpers';
import SearchResultsLinkBase from './SearchResultsLinkBase';

export const ARROW_LINK_VARIANT = Object.freeze({
  NEXT: 'Next',
  PREVIOUS: 'Previous',
});

const useStyles = makeStyles((theme) => ({
  arrowLink: {
    '&:hover': {
      background: theme.palette.interactionBlue[10],
    },
  },
}));

const SearchResultsArrowLink = ({ variant = ARROW_LINK_VARIANT.NEXT }) => {
  const classes = useStyles();
  const { pageNumber: currentPage } = useSearchParams();
  const { getCopy } = useCopy();
  const ariaLabel = useMemo(
    () => getCopy('SEARCH_PAGINATION_ARROW', { direction: variant }),
    [getCopy, variant]
  );
  const pageNumber = useMemo(
    () =>
      variant === ARROW_LINK_VARIANT.NEXT ? currentPage + 1 : currentPage - 1,
    [currentPage, variant]
  );
  const to = useSearchLink({ pageNumber });

  return (
    <SearchResultsLinkBase
      aria-label={ariaLabel}
      to={to}
      {...(!isMobile && { className: classes.arrowLink })}>
      <img
        alt=""
        src={
          variant === ARROW_LINK_VARIANT.NEXT
            ? RightChevronIcon
            : LeftChevronIcon
        }
      />
    </SearchResultsLinkBase>
  );
};

export default SearchResultsArrowLink;
