import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Container } from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import TableParameter from '../venue/TableParameter';
import FixedBottomButtonWrapper from '../../components/core/FAB/FixedBottomButtonWrapper';
import TimeParameter from '../../components/core/FAB/TimeParameter';
import CommonLoading from '../common/CommonLoading';
import { useViewport } from '../../utils/useViewport';
import { actionGetVenueRequest } from '../../store/Venues/VenuesAction';
import { VENUE_AVAILABILITY_TITLE } from '../../assets/copy';
import { DATE_FORMAT_YEAR_FIRST } from '../../assets/dateFormats';
import moment from 'moment';
import {
  actionSetFabDisplayValues,
  actionSetFabAvailabilityTime,
  actionSetFabAvailabilityDate,
  actionSetFabAvailabilityGuests,
  actionAddTimeslotToAvailabilityList,
  actionClearListAvailabilities,
  actionGetMultipleAvailabilitiesRequest,
} from '../../store/Availability/AvailabilityAction';
import { generateListAvailabilityInput } from '../../utils/generateAvailabilityInput';
import defaultReservation from '../venue/helpers/defaultReservation';
import ReservationSheet from '../../components/core/Sheet/ReservationSheet';
import { useComponentWillUnmount } from '../../utils/useComponentWillUnmount';
import VenueHeader from './VenueHeader';
import VenueDetail from './VenueDetail';
import {
  WIDTH_BREAKPOINT,
  WIDTH_LG_BREAKPOINT,
} from '../../utils/constants/Breakpoints';
import AvailabilityList from './AvailabilityList';
import clsx from 'clsx';
import { TIME_AVAILABILITY_TYPES } from './timeAvailabilityTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 3, 0, 3),
      maxWidth: '100%',
    },
  },
  content: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 1040,
      columnGap: theme.spacing(3),
    },
  },
  container: {
    marginBottom: 0,
  },
  lgContent: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
}));

const { defaultTime } = defaultReservation();

const VenueAvailability = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { width } = useViewport();
  const location = useLocation();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const { venue } = useSelector((state) => state?.venues);
  const [isOpen, setOpen] = useState(false);
  const {
    availability,
    list,
    selectedDate,
    selectedTime,
    selectedGuests,
    submittedTime,
    submittedDate,
    submittedGuests,
  } = useSelector((state) => state?.availability);

  const [isMounted, setIsMounted] = useState(false);
  const [selDate, setSelDate] = useState(location?.state?.date);
  const [time, setTime] = useState(location?.state?.time);
  const [guests, setGuests] = useState(location?.state?.guests);
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  useComponentWillUnmount(() => {
    dispatch(actionClearListAvailabilities());
  });

  useEffect(() => {
    if (!venue || (venue && venue.id !== id)) {
      dispatch(actionGetVenueRequest(id));
    }
  }, [dispatch, id, venue]);

  useEffect(() => {
    if (venue && venue.id === id) {
      setIsMounted(true);
    }
  }, [id, venue]);

  const generateAvailability = useCallback(() => {
    const input = generateListAvailabilityInput(
      venue,
      submittedDate,
      submittedTime,
      submittedGuests
    );
    dispatch(actionGetMultipleAvailabilitiesRequest(input));
  }, [venue, submittedDate, submittedTime, submittedGuests, dispatch]);

  useEffect(() => {
    if (isMounted) {
      generateAvailability();
    }
  }, [isMounted, dispatch, generateAvailability, location]);

  useEffect(() => {
    //dispatch to an Availability reducer and add it to the list
    if (availability !== null) {
      dispatch(actionAddTimeslotToAvailabilityList(availability));
    }
  }, [availability?.date, dispatch, availability]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleDone = () => {
    setOpen(false);
    dispatch(
      actionSetFabDisplayValues({
        submittedDate: selectedDate,
        submittedTime: selectedTime,
        submittedGuests: selectedGuests,
      })
    );
  };

  const handleCloseFab = () => {
    if (
      moment(selectedDate).format(DATE_FORMAT_YEAR_FIRST) !==
      moment(submittedDate).format(DATE_FORMAT_YEAR_FIRST)
    ) {
      dispatch(actionSetFabAvailabilityDate({ selectedDate: submittedDate }));
    }

    if (selectedTime !== submittedTime) {
      dispatch(actionSetFabAvailabilityTime({ selectedTime: submittedTime }));
    }

    if (selectedGuests !== submittedGuests) {
      dispatch(
        actionSetFabAvailabilityGuests({ selectedGuests: submittedGuests })
      );
    }
  };

  const isBetweenLgAndXl = width > WIDTH_LG_BREAKPOINT && width <= 1072;

  return !isMounted ? (
    <CommonLoading active={!isMounted} />
  ) : (
    <Container className={classes.root}>
      {!navigationMenuVisible && (
        <>
          <title>{VENUE_AVAILABILITY_TITLE}</title>
          <Box ref={scrollRef} className={classes.container}>
            <Box
              className={clsx(
                classes.content,
                isBetweenLgAndXl && classes.lgContent
              )}>
              <VenueHeader width={width} />
              <VenueDetail width={width} venue={venue} />

              {width <= WIDTH_BREAKPOINT && (
                <Divider className={classes.divider} />
              )}

              <AvailabilityList
                width={width}
                venue={venue}
                list={list}
                type={TIME_AVAILABILITY_TYPES.HORIZONTAL_WITH_DATE}
              />
            </Box>
          </Box>
          <ReservationSheet />
          <FixedBottomButtonWrapper onClick={handleClick}>
            <TimeParameter date={selDate} time={time} guests={guests} />
          </FixedBottomButtonWrapper>
          <TableParameter
            isOpen={isOpen}
            setOpen={setOpen}
            date={selDate}
            time={time}
            guests={guests}
            defaultTime={defaultTime}
            handleDone={handleDone}
            setDate={setSelDate}
            setTime={setTime}
            setGuests={setGuests}
            handleCloseFab={handleCloseFab}
          />
        </>
      )}
    </Container>
  );
};

export default VenueAvailability;
