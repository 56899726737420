import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import TopHero from '../../components/core/TopHero';
import DINING_HERO_IMAGE_DESKTOP from '../../assets/images/desktop-chef-jose-andres-and-chef-dominique-crenn.png';
import DINING_HERO_IMAGE_MOBILE from '../../assets/images/mobile-chef-jose-andres-and-chef-dominique-crenn.png';

import {
  DINING_EXPERIENCE_TITLE,
  DINING_EXPERIENCE_SUBTITLE,
} from '../../assets/copy';
import EventSection from './EventSection';
import VideoSection from './VideoSection';
import { useAuth } from '../../utils/useAuth';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetEventsRequest } from '../../store/Venues/VenuesAction';
import CommonLoading from '../common/CommonLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme.palette.charcoal[60],
    minHeight: 1700,
  },
}));

const DiningExperience = () => {
  const classes = useStyles();
  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();
  const dispatch = useDispatch();

  const shouldOnboard = useOnboardingRedirect();

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.session.isAuthenticated) {
      return;
    }
    dispatch(actionGetEventsRequest({ isTestUser: user.isTest }));
  }, [user.session.isAuthenticated, dispatch, user.isTest]);

  const isMounted = () => user.session.isAuthenticated && !shouldOnboard;
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  return (
    <>
      <CommonLoading active={!isMounted()} embedded={true} />
      {isMounted() && !navigationMenuVisible && (
        <Box className={classes.root}>
          <TopHero
            title={DINING_EXPERIENCE_TITLE}
            subtitle={DINING_EXPERIENCE_SUBTITLE}
            desktopHeroImg={DINING_HERO_IMAGE_DESKTOP}
            mobileHeroImg={DINING_HERO_IMAGE_MOBILE}
          />
          <EventSection />
          <VideoSection />
        </Box>
      )}
    </>
  );
};

export default DiningExperience;
