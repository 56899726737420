import * as types from './AllergiesTypes';

export const actionAllergyListRequest = () => ({
  type: types.ALLERGY_LIST_REQUEST,
});

export const actionAllergyListSuccess = (payload) => ({
  type: types.ALLERGY_LIST_SUCCESS,
  payload,
});

export const actionAllergyListFail = (payload) => ({
  type: types.ALLERGY_LIST_FAIL,
  payload,
});
